import { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useSelector } from "react-redux";
import { IRootState } from "reducer";

import { IModalState } from "scenes/Modal/ducks";

import Preloader from "components/Preloader/Preloader";

import styles from "./pdf-reader.module.scss";

const PADDING = 20;

const PdfReader = ({ file }: { file: string }): JSX.Element => {
  const pdfView = useRef(null);

  const { modalType } = useSelector<IRootState, IModalState>(
    (state) => state.modal
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [documentWidth, setDocumentWidth] = useState(null);
  const [documentScrollStyles, setDocumentScrollStyles] = useState({
    height: "auto",
    paddingRight: 0
  });

  const pages = useMemo(() => {
    const pagesList = [];
    for (let index = 0; index < pageNumber; index++) {
      pagesList.push(
        <Page
          key={`page_${index}`}
          pageNumber={index + 1}
          renderAnnotationLayer={false}
          loading={null}
          width={documentWidth}
        />
      );
    }
    return pagesList;
  }, [documentWidth, pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(numPages);
  };

  useEffect(() => {
    if (!pdfView) return;

    const isPdfModal = modalType === "PDF_VIEW";
    let scrollPadding = 0;

    if (!isPdfModal && pdfView.current?.nextSibling) {
      let nodeStyle = window.getComputedStyle(pdfView.current?.nextSibling);
      let marginTop = nodeStyle.getPropertyValue("margin-top");
      const offsetTop =
        pdfView.current?.offsetParent.offsetTop > 0
          ? pdfView.current?.offsetParent.offsetTop
          : pdfView.current?.offsetTop;

      setDocumentScrollStyles({
        height:
          window.innerHeight -
          offsetTop -
          pdfView.current?.nextSibling?.clientHeight -
          parseInt(marginTop.replace("px", "")) -
          PADDING +
          "px",
        paddingRight: PADDING
      });
      scrollPadding = PADDING;
    }

    setDocumentWidth(pdfView.current?.clientWidth - scrollPadding);
  }, [modalType, pdfView]);

  return (
    <div
      className={styles["pdf-view"]}
      ref={pdfView}
      style={documentScrollStyles}
    >
      {documentWidth && file && (
        <Document
          file={file + "#" + new Date().getTime()}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          className="pdf-document"
          noData="Файл PDF не вказано"
          loading={
            <div className={styles["pdf-loader"]}>
              <Preloader isChildPreloader />
            </div>
          }
          error="Не вдалося завантажити файл PDF."
        >
          {pages}
        </Document>
      )}
    </div>
  );
};

export default PdfReader;
