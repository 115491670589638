import { CommonButton, VisualUploadMultipleFiles } from "gov-ua-ui";

import { formatDate } from "helpers/date";

import styles from "./saved-zsn.module.scss";

const TextItem = ({
  title,
  value
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <div className={styles["saved-zsn__text-item"]}>
      <p>{title}</p>
      <p>{value}</p>
    </div>
  );
};

const SavedZSN = ({ zsn, onDocumentView, handleChangeZSNClick, formik }) => {
  return (
    <div className={styles["saved-zsn"]}>
      <div className={styles["saved-zsn__body"]}>
        <VisualUploadMultipleFiles
          title="Завантажити скан-копію"
          accept={{
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpg"]
          }}
          loadedFiles={zsn.files}
          onDocumentView={onDocumentView}
          readonly
        />
        <div className={styles["saved-zsn__info"]}>
          <TextItem title="Тип" value="ЗСН" />
          <TextItem title="Вагон" value={formik.values["freightCarNumber"]} />
          <TextItem title="Серія" value={formik.values["series"]} />
          <TextItem title="Номер" value={formik.values["number"]} />
          <TextItem
            title="Дата"
            value={formatDate(formik.values["date"]).date}
          />
          <TextItem title="Кількість" value={formik.values["count"]} />
          <TextItem title="Сума" value={formik.values["amount"]} />
          <TextItem title="Сума з ПДВ" value={formik.values["amountPDV"]} />
        </div>
      </div>
      <div className={styles["saved-zsn-controls"]}>
        <CommonButton
          outlined={true}
          label="Змінити"
          onClick={handleChangeZSNClick}
        />
      </div>
    </div>
  );
};

export default SavedZSN;
