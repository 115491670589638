import { ApiClientAutocompete } from "services/API";

import { Autocomplete } from "gov-ua-ui";

import styles from "./autocomplete.module.scss";

interface IOption {
  value: string;
  label: string;
}

interface IEntityAutocomplete {
  name: string;
  placeholder: string;
  value: IOption;
  entity: string;
  params?: string;
  labelFieldName?: string;
  onChange: (option: any, name: string) => void;
  disabled?: boolean;
  dataFieldsNames?: any;
}

const EntityAutocomplete = ({
  name,
  placeholder,
  value,
  entity,
  params,
  onChange,
  labelFieldName,
  disabled: isDisabled,
  dataFieldsNames
}: IEntityAutocomplete): JSX.Element => {
  return (
    <Autocomplete
      name={name}
      placeholder={placeholder}
      asyncSelect
      client={ApiClientAutocompete(`${entity}/search`)}
      params={params}
      withFormik
      dataFieldsNames={
        dataFieldsNames ?? {
          valueFieldName: "externalId",
          labelFieldName: labelFieldName ?? "name"
        }
      }
      className={isDisabled ? styles["readonly"] : ""}
      value={value}
      onSelectChange={onChange}
    />
  );
};

export default EntityAutocomplete;
