import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const Search = (props: any): JSX.Element => {
  return (
    <Layout title={""} meta={""} headerType="DEFAULT">
      <features.searchApplications.page {...props} />
    </Layout>
  );
};

export default Search;
