import cookie from "js-cookie";
import { COOKIES_EXPIRATION_DATE } from "constant";

export const findGetParameter = (parameterName) => {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

export const setToken = (token) => {
  cookie.set("token", token, {
    expires: COOKIES_EXPIRATION_DATE,
    secure: true
    // domain: DOMAIN
  });
};
export const setRole = (role) => {
  cookie.set("role", role, {
    expires: COOKIES_EXPIRATION_DATE,
    secure: true
    // domain: DOMAIN
  });
};
export const setCurrentSubjectUuid = (currentSubjectUuid) => {
  cookie.set("currentSubjectUuid", currentSubjectUuid, {
    expires: COOKIES_EXPIRATION_DATE,
    secure: true
    // domain: DOMAIN
  });
};
export const setSid = (sid) => {
  cookie.set("sid", sid, {
    expires: COOKIES_EXPIRATION_DATE,
    secure: true
    // domain: DOMAIN
  });
};
export const getToken = () => {
  return cookie.get("token");
};
export const getRole = () => {
  return cookie.get("role");
};
export const getSid = () => {
  return cookie.get("sid");
};
export const getCurrentSubjectUuid = () => {
  return cookie.get("currentSubjectUuid");
};

export const deleteAuthData = () => {
  cookie.remove("token", {
    // domain: DOMAIN
  });
  cookie.remove("role", {
    // domain: DOMAIN
  });
  cookie.remove("sid", {
    // domain: DOMAIN
  });
};
