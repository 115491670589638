import { CommonButton, Dialog } from "gov-ua-ui";
import { useDispatch } from "react-redux";

import features from "features";

import styles from "./success-settings-modal.module.scss";

const SuccessfulUserSettingsUpdateModal = () => {
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <Dialog
      isOpen
      className={styles["modal-container"]}
      onClose={closeDialog}
      closeTimeoutMS={500}
    >
      <div className={styles["success-settings-modal"]}>
        <h2 className={styles["success-settings-modal__title"]}>Успіх!</h2>
        <h2 className={styles["success-settings-modal__subtitle"]}>
          Ваші дані успішно оновлено
        </h2>

        <CommonButton
          label={"ОК"}
          onClick={closeDialog}
          className={styles["close-btn"]}
        />
      </div>
    </Dialog>
  );
};
export default SuccessfulUserSettingsUpdateModal;
