import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, CommonButton, RadioButton } from "gov-ua-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { isEmpty } from "lodash";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import ResponseErrors from "components/ResponseErrors/ResponseErrors";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import Section from "components/Section/Section";

import styles from "./set-application-type.module.scss";

interface ApplicationTypeInterface {
  applicationUuid: string;
  isReadOnly: boolean;
}

const SetApplicationType = ({
  applicationUuid,
  isReadOnly
}: ApplicationTypeInterface): JSX.Element => {
  const dispatch = useDispatch();

  const { application, settingApplicationTypeError } = useSelector<
    IRootState,
    IApplicationState
  >((state) => state.application);

  const { supportedDocumentsList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  const [lockedForm, setLockedForm] = useState(false);

  // TODO back-end is not ready
  // const formatedDocumentsList = formatForSelect(supportedDocumentsList);
  const formattedDocumentsList = [
    {
      label: "Переміщення",
      value: "transfer",
      disabled: false
    }
  ];

  useEffect(() => {
    if (application.useWoodCard !== null) {
      setLockedForm(true);

      if (application.useWoodCard) {
        formik.setFieldValue("useWoodCard", "yes");

        if (
          !isEmpty(supportedDocumentsList) &&
          application.supportDocumentTypeUuid
        ) {
          const selectedOption = formattedDocumentsList.find(
            (item) => item.value === application.supportDocumentTypeUuid
          );

          if (selectedOption) {
            formik.setFieldValue("supportedDocument", selectedOption.value);
          }
        }
      }
    }
  }, [application, supportedDocumentsList]);

  const onFormSubmit = (values: {
    useWoodCard: string;
    supportedDocument: string;
  }) => {
    const fields = {
      useWoodCard: values.useWoodCard === "yes"
    };

    if (values.useWoodCard === "yes") {
      fields["supportDocumentTypeAlias"] = values.supportedDocument;
    }

    dispatch(
      features.application.actions.setApplicationTypeRequest({
        fields,
        params: {
          uuid: applicationUuid
        },
        onSuccess: () => {
          setLockedForm(true);
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      useWoodCard: "no",
      supportedDocument: null
    },
    enableReinitialize: true,
    onSubmit: onFormSubmit
  });

  useEffect(() => {
    if (!isEmpty(supportedDocumentsList) && !formik.values.supportedDocument) {
      const defaultOption = formattedDocumentsList.find(
        (item) => !item.disabled
      );

      if (defaultOption) {
        formik.setFieldValue("supportedDocument", defaultOption.value);
      }
    }
  }, [supportedDocumentsList, formik.values]);

  const handleInputChange = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    const event = e.target as HTMLInputElement;
    formik.setFieldValue(event.name, event.value);
  };

  const onEditClick = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Якщо ви змінете тип подачі заявки, всі заповнені документи підстави видачі сертифіката видаляться. Ці докумети потрібно буде заповнити повторно. Все одно змінити?",
          acceptLabel: "Так, продовжити",
          onAccept: () => {
            setLockedForm(false);
          }
        }
      })
    );
  }, [dispatch, formik.values.useWoodCard]);

  const onCancelClick = useCallback(() => {
    setLockedForm(true);
    if (application.useWoodCard) {
      formik.setFieldValue("useWoodCard", "yes");
    } else {
      formik.setFieldValue("useWoodCard", "no");
    }
  }, [application.useWoodCard]);

  return (
    <Section
      title="Тип подачі заявки"
      color={lockedForm ? "GREY" : "WHITE"}
      className={styles["set-application-type"]}
    >
      <FormikProvider value={formik}>
        <Alert type="warning" withIcon className={styles["warning"]}>
          Під час формування заявки, ви хочете використовувати дані лісорубного
          квитка?
        </Alert>
        <Form className={styles["set-application-type__form"]}>
          <div
            className={cn(styles["fields-container"], {
              [styles["fields-container--locked"]]: lockedForm
            })}
          >
            <RadioButton
              title="Ні"
              name="useWoodCard"
              value="no"
              checked={formik.values["useWoodCard"] === "no"}
              onClick={handleInputChange}
              className={styles["set-application-type__form-radio"]}
            />

            <RadioButton
              title="Так"
              name="useWoodCard"
              value="yes"
              checked={formik.values["useWoodCard"] === "yes"}
              onClick={handleInputChange}
              className={styles["set-application-type__form-radio"]}
            />
            {formik.values["useWoodCard"] === "yes" && (
              <div className={styles["set-application-type__form-sub"]}>
                <Alert type="warning" className={styles["warning"]} withIcon>
                  Який допоміжний документ ви плануєте використовувати для
                  отримання данних лісорубного квитка?
                </Alert>
                {!isEmpty(formattedDocumentsList) &&
                  formattedDocumentsList.map((item, i) => {
                    return (
                      <RadioButton
                        key={`${i}-${item.value}`}
                        title={item.label}
                        name="supportedDocument"
                        value={item.value}
                        checked={
                          formik.values["supportedDocument"] === item.value
                        }
                        onClick={handleInputChange}
                        className={styles["set-application-type__form-radio"]}
                        disabled={item.disabled}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          <ResponseErrors errors={settingApplicationTypeError} />
          {!isReadOnly && (
            <>
              {!lockedForm ? (
                <div className={styles["set-application-type__form-btns"]}>
                  {application?.useWoodCard != null && (
                    <CommonButton
                      key="cancel-button"
                      className={styles["set-application-type__form-btn"]}
                      label="Скасувати"
                      type="button"
                      outlined
                      onClick={onCancelClick}
                    />
                  )}
                  <CommonButton
                    key="submit-button"
                    className={styles["set-application-type__form-btn"]}
                    label="Зберегти"
                    type="submit"
                  />
                </div>
              ) : (
                <CommonButton
                  key="editbutton"
                  className={styles["set-application-type__form-btn"]}
                  label="Змінити"
                  type="button"
                  onClick={onEditClick}
                />
              )}
            </>
          )}
        </Form>
        <ScrollToFieldError />
      </FormikProvider>
    </Section>
  );
};

export default SetApplicationType;
