import cn from "classnames";
import { CommonButton } from "gov-ua-ui";
import React from "react";

import styles from "./form-controls.module.scss";

interface FormControlsInterface {
  declineLabel?: string;
  acceptLabel?: string;
  declineBtnType?: "button" | "submit" | "reset" | undefined;
  acceptButtonType?: "button" | "submit" | "reset" | undefined;
  onDecline?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAccept?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAcceptDisabled?: boolean;
  className?: string;
}

const FormControls = ({
  declineLabel = "Назад",
  acceptLabel = "Зберегти зміни",
  declineBtnType,
  acceptButtonType = "submit",
  onDecline,
  onAccept,
  onAcceptDisabled,
  className
}: FormControlsInterface) => {
  return (
    <div className={cn(styles["form-controls"], className)}>
      <CommonButton
        label={declineLabel}
        outlined={true}
        type={declineBtnType}
        onClick={onDecline}
      />
      <CommonButton
        label={acceptLabel}
        type={acceptButtonType}
        onClick={onAccept}
        disabled={onAcceptDisabled}
      />
    </div>
  );
};

export default FormControls;
