import { takeLatest } from "redux-saga/effects";
import { actions } from "./ducks";
import { createBrowserHistory } from "history";
import * as API from "services";
import { setToken, deleteAuthData, requestMiddleware } from "helpers";

const history = createBrowserHistory({ window });

function* signIn({ payload }) {
  const request = API.signIn;
  const { signInSuccess: setSuccess, signInError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function signOut({ payload }) {
  deleteAuthData();
  history.push("/");
  window.location.reload();
}

function* initToken({ payload }) {
  const request = API.initToken;
  const { initTokenSuccess: setSuccess, initTokenError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess: ({ data }) => {
      setToken(data.token);
      if (payload?.onSuccess) payload?.onSuccess();
    }
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.signOutRequest().type, signOut);
  yield takeLatest(actions.signInRequest().type, signIn);
  yield takeLatest(actions.initTokenRequest().type, initToken);
}
