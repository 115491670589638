import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { getSid } from "helpers/userAuthInfo";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";

import styles from "./notifications-board.module.scss";

const NotificationsBoard = () => {
  const dispatch = useDispatch();
  const { notificationBoard } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );
  const [authorised] = useState(getSid());

  useEffect(() => {
    dispatch(features.subjects.actions.fetchNotificationBoardRequest());
  }, [authorised, dispatch]);

  return notificationBoard?.notificationMessage ? (
    <div className={styles["notification-board"]}>
      {notificationBoard.notificationMessage}
    </div>
  ) : (
    <></>
  );
};

export default NotificationsBoard;
