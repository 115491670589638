import ApiProvider from "./API";
import { API_URL, CERTIFICATE_AUTH_API_URL } from "constant";
import axios from "axios";
import { getToken } from "helpers";

export * from "./applications";
export * from "./subjects";
export * from "./dataset";
export * from "./docs.js";
export * from "./official.js";

export const fetchNotificationBoard = ({ fields, params }) => {
  return ApiProvider()
    .client.get(`/notification_board`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchTermsOfService = ({ fields, params }) => {
  return ApiProvider()
    .client.get(`/unique_items/terms_of_service`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const signIn = ({ fields }) => {
  const client = axios.create({
    baseURL: API_URL,
    headers: { "Content-Type": "application/json" }
  });

  client.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  });

  return client
    .post(`/auth`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const initToken = ({ fields }) => {
  return axios
    .post(CERTIFICATE_AUTH_API_URL, fields)
    .then((response) => response)
    .catch((error) => error.response);
};
