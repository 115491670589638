import { useSelector } from "react-redux";

import { IRootState } from "reducer";

import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import styles from "./error-modal.module.scss";

interface ErrorModalInterface {
  title: string;
  description: string;
  onHide?: () => void;
  large?: boolean;
}

const ErrorModal = () => {
  const { title, description, large, onHide } = useSelector<
    IRootState,
    ErrorModalInterface
  >((state) => state.modal.modalProps);

  return (
    <ModalWrapper
      className={
        large ? styles["modal-container-large"] : styles["modal-container"]
      }
      onHide={onHide}
    >
      <div className={styles["error-modal"]}>
        <h2 className={styles["error-modal__title"]}>{title}</h2>
        <p className={styles["error-modal__description"]}>{description}</p>
      </div>
    </ModalWrapper>
  );
};
export default ErrorModal;
