import ApiProvider from "./API";

export const fetchSelectedOrganizationsList = ({ params }) => {
  return ApiProvider()
    .client.get(`/organizations/${params.externalId}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchSupportedDocumentsList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/support_document_types`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchCurrencyList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/currencies`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchDeliveryConditionList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/delivery_conditions`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchSizeList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/geometric_dimensions`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchStandardList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/product_standards`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchRequestTypeList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/catalog_requests/other_types`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchUnitList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/measurement_units`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchSpeciesList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/wood_species`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchSortList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/wood_grades`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchUktzed = ({ fields }) => {
  return ApiProvider()
    .client.get(`/products/uktzed`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchProduct = ({ fields }) => {
  return ApiProvider()
    .client.get(`/products/search`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchAccountingList = ({ fields }) => {
  return ApiProvider()
    .client.get(`/accounting`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const generateDocumentsToDownload = ({ params }) => {
  return ApiProvider()
    .client.get(`/files/${params.fileUuid}/download`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchInterregionalOfficeExternalList = () => {
  return ApiProvider()
    .client.get(`/interregional_offices/search`)
    .then((response) => response)
    .catch((error) => error.response);
};
