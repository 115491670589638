import { CommonButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import features from "features";
import { parseResErrors } from "helpers";
import { getPrevRoute } from "helpers/getPrevRoute";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { IApplicationReviewState } from "../../OfficialApplicationReviewPage/ducks";

import PdfReader from "components/PdfReader/PdfReader";

import styles from "./official-signature-certificate-decline-annul-page.module.scss";

const OfficialSignatureCertificateDeclineAnnulPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { applicationAnnulEDeclineUrl, declineDescription } = useSelector<
    IRootState,
    IApplicationReviewState
  >((state) => state.officialApplicationReview);

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { applicationId } = useParams<{
    applicationId?: string;
  }>();

  const prevRoute = useMemo(() => {
    return getPrevRoute(pathname);
  }, [pathname]);

  const goBack = useCallback(() => {
    navigate(prevRoute);
  }, [navigate, prevRoute]);

  const submit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "SIGNATURE",
        modalProps: {
          modalTitle: `Підписати та відправити заяву`,
          processedName: "Заявник",
          processedBy: currentSubject?.fullName,
          location: currentSubject?.organizationName,
          fileUrl: applicationAnnulEDeclineUrl,
          onAccept: (signedFile) => {
            dispatch(features.modal.actions.hideModal());
            dispatch(
              features.modal.actions.showModal({
                modalType: "PRELOADER",
                modalProps: {
                  title: "Опрацювання запиту",
                  loading: true
                }
              })
            );
            dispatch(
              features.officialApplicationReview.actions.acceptCertificateAnnulDecisionRequest(
                {
                  params: {
                    uuid: applicationId
                  },
                  fields: {
                    decision: "decline",
                    declineDescription,
                    signedFile
                  },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.success(
                      "Успіх",
                      "Відмова в заявці на ануляцію сертифіката успішно подана, чекайте на повідомлення."
                    );
                    navigate("/official-main");
                  },
                  onError: (errorsRes) => {
                    parseResErrors({ errorsRes });
                    dispatch(features.modal.actions.hideModal());
                  }
                }
              )
            );
          }
        }
      })
    );
  }, [declineDescription, dispatch, currentSubject, applicationId, navigate]);

  useEffect(() => {
    if (declineDescription.length === 0) goBack();
  }, [declineDescription.length, goBack]);

  return (
    <div className={styles["content"]}>
      <PdfReader file={applicationAnnulEDeclineUrl} />
      <div className={styles["controls"]}>
        <CommonButton
          label={"Назад до редагування"}
          outlined={true}
          onClick={goBack}
        ></CommonButton>
        <CommonButton
          label={"Підписати та відправити"}
          onClick={submit}
        ></CommonButton>
      </div>
    </div>
  );
};

export default OfficialSignatureCertificateDeclineAnnulPage;
