import { call, takeLatest, takeEvery } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";
import uploadFiles from "scenes/UploadFiles/saga";

function* fetchApplication({ payload }) {
  const request = API.fetchOfficialAnnulmentApplicationView;
  const {
    fetchApplicationSuccess: setSuccess,
    fetchApplicationError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchEAnnulmentData({ payload }) {
  const request = API.fetchOfficialEAnnulmentData;
  const {
    fetchEAnnulmentDataSuccess: setSuccess,
    fetchEAnnulmentDataError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* submitEAnnulmentData({ payload }) {
  const request = API.officialAnnulmentSubmit;
  const {
    submitEAnnulmentDataSuccess: setSuccess,
    submitEAnnulmentDataError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* loadReasonFile({ payload }) {
  const {
    saveLoadReasonFileCancelHandler: cancel,
    loadReasonFileError: error,
    loadReasonFileSuccess: success,
    loadReasonFileProgress: progress
  } = actions;

  yield call(
    uploadFiles,
    payload.fields,
    { cancel, error, success, progress },
    `official/application/${payload.params.uuid}/annulment/files/${
      payload.params.replaceUuid ?? ""
    }
    `
  );
}

function* deleteReasonFile({ payload }) {
  const request = API.deleteOfficialReasonFile;
  const {
    deleteReasonFileSuccess: setSuccess,
    deleteReasonFileError: setError
  } = actions;

  if (payload.params.uuid) {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError
    });
  }
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchApplicationRequest, fetchApplication);
  yield takeLatest(actions.fetchEAnnulmentDataRequest, fetchEAnnulmentData);
  yield takeLatest(actions.submitEAnnulmentDataRequest, submitEAnnulmentData);
  yield takeEvery(actions.loadReasonFileRequest, loadReasonFile);
  yield takeEvery(actions.deleteReasonFileRequest, deleteReasonFile);
}
