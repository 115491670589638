import cn from "classnames";
import { Status, Title } from "gov-ua-ui";

import { applicationStatuses, customsClearance } from "dataset";
import { formatDate } from "helpers";

import styles from "./certificate.module.scss";

const Certificate = ({ data, id }) => {
  return (
    <div className={styles["certificate-info"]} id={id}>
      <Title size={24} className={styles["certificate-info-title"]}>
        Сертифікат
      </Title>
      <div className={styles["certificate-info-container"]}>
        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Номер</h4>
          <h4 className={styles["certificate-info-text"]}>
            {data?.certificateNumber}
          </h4>
        </div>

        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-small"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Дата видачі</h4>
          <h4 className={styles["certificate-info-text"]}>
            {formatDate(data?.decisionAt).date}
          </h4>
        </div>
      </div>
      <div className={styles["certificate-info-container"]}>
        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Вантажоодержувач</h4>
          <h4 className={styles["certificate-info-text"]}>
            {data?.consigneeName}
          </h4>
        </div>

        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Покупець</h4>
          <h4 className={styles["certificate-info-text"]}>{data?.buyerName}</h4>
        </div>
      </div>
      <div className={styles["certificate-info-container"]}>
        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Дата інвойсу</h4>
          <h4 className={styles["certificate-info-text"]}>
            {formatDate(data?.invoiceDate).date}
          </h4>
        </div>

        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>
            Відсоток виходу продукції
          </h4>
          <h4 className={styles["certificate-info-text"]}>
            {data?.productYieldPercentage !== null
              ? data?.productYieldPercentage + "%"
              : "-"}
          </h4>
        </div>
      </div>
      <div className={styles["certificate-info-container"]}>
        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Номер інвойсу</h4>
          <h4 className={styles["certificate-info-text"]}>
            {data?.invoiceNumber}
          </h4>
        </div>
      </div>
      <div className={styles["certificate-info-container"]}>
        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Статус</h4>
          <Status {...applicationStatuses[data?.applicationStatus]}></Status>
        </div>

        <div
          className={cn(
            styles["certificate-info-single-wrapper"],
            styles["certificate-info-single-medium"]
          )}
        >
          <h4 className={styles["certificate-info-label"]}>Митне оформлення</h4>
          <Status {...customsClearance[data?.customsClearance]} />
        </div>
      </div>
    </div>
  );
};

export default Certificate;
