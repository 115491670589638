import { createSlice } from "@reduxjs/toolkit";
import { IPagination } from "interfaces";

export interface IDraftApplication {
  number?: string;
  uuid: string;
  beKeptUntil: number;
  createdAt: number;
  buyer: string;
  consignee: string;
  invoiceNumber: string;
  invoiceAt: number;
  completionPercentage: number;
  productYieldPercentage: number;
  costStandards: number;
}

export interface IDraftApplications {
  data: Array<IDraftApplication>;
  meta: IPagination;
}

export interface IDraftApplicationState {
  fetchingApplicationsLoading: boolean | null;
  deletionApplicationsLoading: boolean | null;
  applications: IDraftApplications;
  responseError: any;
}

const initialState: IDraftApplicationState = {
  fetchingApplicationsLoading: null,
  deletionApplicationsLoading: null,
  applications: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    fetchDraftsRequest: (state) => {
      state.applications = initialState.applications;
      state.fetchingApplicationsLoading = true;
    },
    fetchDraftsSuccess: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.applications = action.payload;
    },
    fetchDraftsError: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.responseError = action.payload;
    },

    deleteDraftRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    deleteDraftSuccess: (state) => {
      state.fetchingApplicationsLoading = false;
    },
    deleteDraftError: (state) => {
      state.fetchingApplicationsLoading = false;
    },

    copyDraftRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    copyDraftSuccess: (state) => {
      state.fetchingApplicationsLoading = false;
    },
    copyDraftError: (state) => {
      state.fetchingApplicationsLoading = false;
    },

    clearDrafts: (state) => {
      state.applications = initialState.applications;
    }
  }
});

export const { actions, reducer } = mainSlice;
