import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CommonButton } from "gov-ua-ui";
import { toastr } from "react-redux-toastr";

import features from "features";
import { getPrevRoute } from "helpers/getPrevRoute";
import { IRootState } from "reducer";

import { ISubjectsState } from "scenes/Subjects/ducks";
import { ICertificateAnnulRequestState } from "../../OfficialCertificateAnnulRequestPage/ducks";

import PdfReader from "components/PdfReader/PdfReader";
import { parseResErrors } from "helpers";

import styles from "./official-signature-certificate-annul-page.module.scss";

const OfficialSignatureCertificateAnnulPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { eAnnulmentUrl, annulmentBasisDescription } = useSelector<
    IRootState,
    ICertificateAnnulRequestState
  >((state) => state.officialCertificateAnnulRequest);

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { applicationId } = useParams<{
    applicationId?: string;
  }>();

  const prevRoute = useMemo(() => {
    return getPrevRoute(pathname);
  }, [pathname]);

  const goBack = useCallback(() => {
    navigate(prevRoute);
  }, [navigate, prevRoute]);

  const submit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "SIGNATURE",
        modalProps: {
          modalTitle: `Підписати та відправити заяву`,
          processedName: "Заявник",
          processedBy: currentSubject?.fullName,
          location: currentSubject?.organizationName,
          fileUrl: eAnnulmentUrl,
          onAccept: (signedFile) => {
            dispatch(features.modal.actions.hideModal());
            dispatch(
              features.modal.actions.showModal({
                modalType: "PRELOADER",
                modalProps: {
                  title: "Опрацювання запиту",
                  loading: true
                }
              })
            );
            dispatch(
              features.officialCertificateAnnulRequest.actions.submitEAnnulmentDataRequest(
                {
                  params: {
                    applicationUuid: applicationId
                  },
                  fields: { annulmentBasisDescription, signedFile },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.success(
                      "Успіх",
                      "Заявка на ануляцію успішно подана, чекайте на повідомлення."
                    );
                    navigate("/official-archival");
                  },
                  onError: (errorsRes) => {
                    parseResErrors({ errorsRes });
                    dispatch(features.modal.actions.hideModal());
                  }
                }
              )
            );
          }
        }
      })
    );
  }, [
    annulmentBasisDescription,
    dispatch,
    currentSubject,
    applicationId,
    navigate
  ]);

  useEffect(() => {
    if (annulmentBasisDescription.length === 0) goBack();
  }, [annulmentBasisDescription.length, goBack]);

  return (
    <div className={styles["content"]}>
      <PdfReader file={eAnnulmentUrl} />
      <div className={styles["controls"]}>
        <CommonButton
          label={"Назад до редагування"}
          outlined={true}
          onClick={goBack}
        ></CommonButton>
        <CommonButton
          label={"Підписати та відправити"}
          onClick={submit}
        ></CommonButton>
      </div>
    </div>
  );
};

export default OfficialSignatureCertificateAnnulPage;
