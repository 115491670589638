import cn from "classnames";
import { useState } from "react";

import styles from "./expand.module.scss";

interface ExpandInterface {
  title: string;
  children?: any;
  open?: boolean;
  isLast?: boolean;
}

const Expand = ({
  children,
  title,
  open = false,
  isLast = false
}: ExpandInterface): JSX.Element => {
  const [isOpen, setOpen] = useState(open);

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <section
      className={cn(styles["expand"], {
        [styles["expand_last"]]: isLast
      })}
    >
      <h2
        className={cn(styles["expand__header"], {
          [styles["expand_active"]]: isOpen
        })}
        onClick={handleToggle}
      >
        {title}
      </h2>
      <p className={cn(styles["expand__content"])}>{children}</p>
    </section>
  );
};

export default Expand;
