import cn from "classnames";
import Section from "components/Section/Section";
import SectionText from "components/Section/SectionText/SectionText";

import styles from "./applicant.module.scss";

interface ApplicantInterface {
  name?: string;
  organizationEdrpou?: string;
  organizationDrfo?: string;
  fio?: string;
  location?: string;
  drfo?: string;
  serial_pass?: string;
  idcard?: string;
  tel?: string;
  email?: string;
  organizationTel?: string | Array<string>;
  organizationEmail?: string;
  organizationAddress?: string;
  isOfficial?: boolean;
}

const Applicant = ({
  name,
  organizationEdrpou,
  organizationDrfo,
  organizationTel,
  organizationEmail,
  organizationAddress,
  fio,
  location,
  drfo,
  serial_pass,
  idcard,
  tel,
  email,
  isOfficial
}: ApplicantInterface): JSX.Element => {
  return (
    <Section title="Заявник" id="applicant">
      <div
        className={cn(styles["applicant-section"], {
          [styles["applicant-section__official"]]: isOfficial
        })}
      >
        {name ? (
          <SectionText title="Найменування">{name}</SectionText>
        ) : (
          <div />
        )}
        {organizationEdrpou ? (
          <SectionText title="ЄДРПОУ">{organizationEdrpou}</SectionText>
        ) : organizationDrfo ? (
          <SectionText title="РНОКПП">{organizationDrfo}</SectionText>
        ) : (
          <div />
        )}
        {organizationEmail ? (
          <SectionText title="Електронна пошта">
            {organizationEmail}
          </SectionText>
        ) : organizationTel ? (
          <SectionText title="Телефон">
            {typeof organizationTel === "string"
              ? organizationTel
              : organizationTel.map((item) => {
                  return (
                    <>
                      {item}
                      <br />
                    </>
                  );
                })}
          </SectionText>
        ) : isOfficial && organizationAddress ? (
          <SectionText title="Адреса">{organizationAddress}</SectionText>
        ) : (
          <div />
        )}
        {!isOfficial && organizationAddress ? (
          <SectionText title="Адреса">{organizationAddress}</SectionText>
        ) : (
          <div />
        )}
        {fio ? <SectionText title="ПІБ">{fio}</SectionText> : <div />}
        {drfo ? (
          <SectionText title="РНОКПП">{drfo}</SectionText>
        ) : serial_pass ? (
          <SectionText title="Серія та Номер паспорту">
            {serial_pass}
          </SectionText>
        ) : idcard ? (
          <SectionText title="Id картка">{idcard}</SectionText>
        ) : (
          <div />
        )}
        {email ? (
          <SectionText title="Електронна пошта">{email}</SectionText>
        ) : tel ? (
          <SectionText title="Телефон">{tel}</SectionText>
        ) : (
          <div />
        )}
        {location && (
          <SectionText title="Місцезнаходження">{location}</SectionText>
        )}
      </div>
    </Section>
  );
};

export default Applicant;
