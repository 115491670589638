import { useCallback, useEffect, useMemo, useState } from "react";

import TreeTable from "components/tables/TreeTable/TreeTable";

import { calculateSum, formatNumber } from "helpers";

import styles from "./basic-docs.module.scss";

interface BasicDocsInterface {
  defaultData: any;
  columns: any;
  isOfficial?: boolean;
  isReadonly?: boolean;
}

const BasicDocs = ({
  defaultData,
  columns,
  isOfficial,
  isReadonly,
}: BasicDocsInterface): JSX.Element => {
  const [formattedData, setFormattedData] = useState([]);

  const formatData = useCallback(
    (data, index) => {
      setFormattedData((prevData) => {
        const copiedData = [...prevData];
        if (!copiedData[index]) {
          copiedData[index] = [data];
        } else {
          copiedData[index].push(data);
        }
        return copiedData;
      });

      if (data.child) {
        formatData(
          defaultData.find((item) => item.id === data.child),
          index
        );
      }
    },
    [defaultData]
  );

  const totalQuantity = useMemo(() => {
    const total = calculateSum(
      defaultData.map((item) => (item.primal ? item.takenQuantity : 0))
    );
    return formatNumber(total, 5);
  }, [defaultData]);

  useEffect(() => {
    setFormattedData([]);
    defaultData
      .filter((item) => item.primal)
      .map((item, index) => {
        return formatData(item, index);
      });
  }, [defaultData, formatData]);


  return (
    // <Section title="Документи підстави" id="basic-docs">
    <div className={styles["basic-docs-section"]}>
      {formattedData && formattedData.length ? (
        <>
          {formattedData.map((item, index) => (
            <TreeTable
              key={index}
              defaultData={item}
              columns={columns}
              isOfficial={isOfficial}
              isReadonly={isReadonly}
            />
          ))}
          <div
            className={styles["basic-docs-section-footer"]}
          >
            <div className={styles["basic-docs-section-totals"]}>
              <p className={styles["basic-docs-section-totals__desc"]}>
                Всього за первинними документами
              </p>
              <p className={styles["basic-docs-section-totals__title"]}>
                Кількість
              </p>
              <p className={styles["basic-docs-section-totals__value"]}>
                {totalQuantity}
              </p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
    // </Section>
  );
};

export default BasicDocs;
