import { createSlice } from "@reduxjs/toolkit";

export interface IUserSettings {
  fetchSettingsSendLoading: boolean;
  fetchSettingsGetLoading: boolean;
  userSettings: any;
  responseError: any;
}
const initialState: IUserSettings = {
  fetchSettingsSendLoading: false,
  fetchSettingsGetLoading: false,
  userSettings: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    getUserSettingsRequest: (state, ) => {
      state.fetchSettingsGetLoading = true;
    },
    getUserSettingsSuccess: (state, action) => {
      state.fetchSettingsGetLoading = false;
      state.userSettings = action.payload;
    },
    getUserSettingsError: (state, action) => {
      state.fetchSettingsGetLoading = false;
      state.responseError = action.payload;
    },
    sendUserSettingsRequest: (state, ) => {
      state.fetchSettingsSendLoading = true;
    },
    sendUserSettingsSuccess: (state, action) => {
      state.fetchSettingsSendLoading = false;
      state.userSettings = action.payload;
    },
    sendUserSettingsError: (state, action) => {
      state.fetchSettingsSendLoading = false;
      state.responseError = action.payload;
    }
  }
});

export const { actions, reducer } = mainSlice;
