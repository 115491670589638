import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchDrafts({ payload }) {
  const request = API.fetchDraftsApplications;
  const { fetchDraftsSuccess: setSuccess, fetchDraftsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* deleteDraft({ payload }) {
  const request = API.deleteDraft;
  const { deleteDraftSuccess: setSuccess, deleteDraftError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* copyDraft({ payload }) {
  const request = API.copyDraft;
  const { copyDraftSuccess: setSuccess, copyDraftError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDraftsRequest().type, fetchDrafts);
  yield takeLatest(actions.deleteDraftRequest().type, deleteDraft);
  yield takeLatest(actions.copyDraftRequest().type, copyDraft);
}
