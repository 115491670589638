import { CommonButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import features from "features";
import { parseResErrors } from "helpers";
import { getPrevRoute } from "helpers/getPrevRoute";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { fetchEAnnulmentFileData } from "services/applications";
import { ICertificateAnnulRequestState } from "../../CertificateAnnulRequestPage/ducks";

import PdfReader from "components/PdfReader/PdfReader";

import styles from "./signature-certificate-annul-page.module.scss";

const SignatureCertificateAnnulPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { eAnnulmentUrl, annulmentBasisDescription } = useSelector<
    IRootState,
    ICertificateAnnulRequestState
  >((state) => state.certificateAnnulRequest);

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { applicationId } = useParams<{
    applicationId?: string;
  }>();

  const prevRoute = useMemo(() => {
    return getPrevRoute(pathname);
  }, [pathname]);

  const goBack = useCallback(() => {
    navigate(prevRoute);
  }, [navigate, prevRoute]);

  const submit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "SIGNATURE",
        modalProps: {
          modalTitle: `Підписати та відправити заяву`,
          processedName: "Заявник",
          processedBy: currentSubject?.fullName,
          location: currentSubject?.organizationName,
          fileUrl: eAnnulmentUrl,
          getFileUrl: async () =>
            await fetchEAnnulmentFileData({
              params: {
                applicationUuid: applicationId
              },
              fields: { annulmentBasisDescription }
            }),
          onAccept: (signedFile) => {
            dispatch(features.modal.actions.hideModal());
            dispatch(
              features.modal.actions.showModal({
                modalType: "PRELOADER",
                modalProps: {
                  title: "Опрацювання запиту",
                  loading: true
                }
              })
            );
            dispatch(
              features.certificateAnnulRequest.actions.submitEAnnulmentDataRequest(
                {
                  params: {
                    applicationUuid: applicationId
                  },
                  fields: { annulmentBasisDescription, signedFile },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    toastr.success(
                      "Успіх",
                      "Заявка на ануляцію успішно подана, чекайте на повідомлення."
                    );
                    navigate("/personal/applications/received");
                  },
                  onError: (errorsRes) => {
                    parseResErrors({ errorsRes });
                    dispatch(features.modal.actions.hideModal());
                  }
                }
              )
            );
          }
        }
      })
    );
  }, [
    annulmentBasisDescription,
    dispatch,
    currentSubject,
    applicationId,
    navigate
  ]);

  useEffect(() => {
    if (annulmentBasisDescription.length === 0) goBack();
  }, [annulmentBasisDescription.length, goBack]);

  return (
    <div className={styles["content"]}>
      <PdfReader file={eAnnulmentUrl} />
      <div className={styles["controls"]}>
        <CommonButton
          label={"Назад до редагування"}
          outlined={true}
          onClick={goBack}
        ></CommonButton>
        <CommonButton
          label={"Підписати та відправити"}
          onClick={submit}
        ></CommonButton>
      </div>
    </div>
  );
};

export default SignatureCertificateAnnulPage;
