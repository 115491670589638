import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { subjectApplicationsPagesNames } from "dataset";
import { getLastPartOfRoute } from "helpers";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";

import WithNavigationLayout from "components/layouts/WithNavigationLayout/WithNavigationLayout";

import SearchIcon from "assets/images/icons/search.svg";

import styles from "./personal-layout.module.scss";

interface PersonalLayoutInterface {
  children?: any;
}

const PersonalLayout = ({ children }: PersonalLayoutInterface) => {
  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { pathname } = useLocation();

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Особистий кабінет",
        to: "/personal/applications/drafts"
      },
      {
        text: subjectApplicationsPagesNames[getLastPartOfRoute(pathname)],
        to: "/"
      }
    ],
    []
  );

  const navigationLinks = useMemo(
    () => [
      {
        text: "Чернетки",
        to: "/personal/applications/drafts"
      },
      {
        text: "Замовлені",
        to: "/personal/applications/ordered"
      },
      {
        text: "Отримані",
        to: "/personal/applications/received"
      },
      {
        text: "Відмовлені",
        to: "/personal/applications/declined"
      },
      {
        text: "Анульовані",
        to: "/personal/applications/annulled"
      },
      {
        text: "",
        to: "/personal/applications/search",
        customContent: (
          <>
            <img src={SearchIcon} alt="Search" />
            <p>Пошук</p>
          </>
        ),
        customStyles: styles["search-tab"]
      }
    ],
    []
  );

  return (
    <WithNavigationLayout
      breadcrumbs={breadcrumbs}
      navLinks={navigationLinks}
      title={`Вітаємо, ${currentSubject?.fullName || ""}`}
      subtitle={currentSubject?.organizationName}
      titleImg="👋"
    >
      {children}
    </WithNavigationLayout>
  );
};

export default PersonalLayout;
