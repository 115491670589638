import Section from "components/Section/Section";

import styles from "./other-docs.module.scss";

interface OtherDocsInterface {
  docs: Array<JSX.Element>;
}

const OtherDocs = ({ docs }: OtherDocsInterface): JSX.Element => {
  return (
    <Section title="Інші документи" color="GREY">
      <div className={styles["other-docs-section"]}>
        {docs.map((doc, index) => (
          <div key={index}>{doc}</div>
        ))}
      </div>
    </Section>
  );
};

export default OtherDocs;
