import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import { SIGN_WIDGET_URI } from "constant";
import features from "features";
import eusign from "helpers/eusign";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

const SignatureWidget = ({ fileUrl, getFileUrl, controls }) => {
  const dispatch = useDispatch();

  const [isFrameLoading, setIsFrameLoading] = useState(true);
  const [signFile, setSignFile] = useState(null);
  const SIGN_WIDGET_PARENT_ID = "sign-widget-parent";
  const SIGN_WIDGET_ID = "sign-widget";
  const [euSign, setEusign] = useState<any>(null);

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  useEffect(() => {
    setTimeout(() => {
      setEusign(
        new eusign.EndUser(
          SIGN_WIDGET_PARENT_ID,
          SIGN_WIDGET_ID,
          SIGN_WIDGET_URI,
          eusign.EndUser.FormType.ReadPKey,
          eusign.EndUser.FormParams
        )
      );
    }, 0);
  }, []);

  function onConfirmKSPOperation(event) {
    var node = "";
    node += '<a href="' + event.url + '">';
    node +=
      '<img src="data:image/bmp;base64,' +
      event.qrCode +
      '" style="padding: 10px; background: white;">';
    node += "</a>";

    document.getElementById("qr-code-block").innerHTML = node;
    document.getElementById("qr-code-block").style.display = "block";
  }

  async function onSign() {
    let file = await fetch(
      getFileUrl ? await getFileUrl().then((data) => data.data.url) : fileUrl
    ).then((r) => r.blob());
    var data;
    let reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onloadend = async (e) => {
      if (e.target?.result instanceof ArrayBuffer) {
        const uint8Array = new Uint8Array(e.target.result);
        data = uint8Array;
      }

      var asBase64String = true;
      var signAlgo = eusign.EndUser.SignAlgo.DSTU4145WithGOST34311;
      var signType = eusign.EndUser.SignType.CAdES_BES;

      euSign
        .SignData(data, false, asBase64String, signAlgo, null, signType)
        .then(function (sign) {
          setSignFile(sign);
          document.getElementById("qr-code-block").style.display = "none";
        })
        .catch(function (e) {
          document.getElementById("qr-code-block").style.display = "none";
          console.log(e);

          toastr.error(`Виникла помилка при підписі даних.`, e.message || e);
        });
    };
  }

  const signingHandler = () => {
    setIsFrameLoading(false);
    euSign
      .ReadPrivateKey()
      .then(function (keyData) {
        if (keyData) {
          if (keyData[0]?.infoEx?.subjDRFOCode !== currentSubject.drfo) {
            return euSign.ResetPrivateKey().then(function () {
              dispatch(
                features.modal.actions.showModal({
                  modalType: "ERROR",
                  modalProps: {
                    title: "Помилка",
                    description:
                      "РНОКПП електронно-цифрового підпису не збігається з РНОКПП заявника"
                  }
                })
              );
              const newError = {
                message: "subjDRFOCode"
              };
              throw newError;
            });
          }
        }
        return euSign.AddEventListener(
          eusign.EndUser.EventType.ConfirmKSPOperation,
          onConfirmKSPOperation
        );
      })
      .then(function () {
        document.getElementById("qr-code-block").style.display = "none";
        document.getElementById("sign-widget-parent").style.display = "none";
        onSign();
      })
      .catch(function (e) {
        console.log(e);

        if (e?.message !== "subjDRFOCode")
          toastr.error(
            `Виникла помилка при зчитуванні ос. ключа.`,
            e.message || e
          );
      });
  };

  useEffect(() => {
    if (euSign) {
      // for IE
      if (euSign.iframe.attachEvent) {
        euSign.iframe.attachEvent("onload", function () {
          signingHandler();
        });
      } else {
        euSign.iframe.onload = function () {
          signingHandler();
        };
      }
    }
  }, [euSign]);

  return (
    <>
      <PreloaderWrapper loading={isFrameLoading}>
        <div
          id="sign-widget-parent"
          style={{ width: "100%", height: "500px" }}
        ></div>
      </PreloaderWrapper>
      <div id="qr-code-block" style={{ display: "none" }}></div>
      {controls(signFile)}
    </>
  );
};

export default SignatureWidget;
