import features from "features";
import { Dialog, DownloadIcon } from "gov-ua-ui";
import { useEffect } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "reducer";

import { IDatasetState } from "scenes/Dataset/ducks";

import PdfReader from "components/PdfReader/PdfReader";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

import styles from "./pdf-view-modal.module.scss";

interface PdfViewModalInterface {
  url: string;
  uuid: string;
  withDownload?: boolean;
  prevModalProps?: object;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewModal = () => {
  const dispatch = useDispatch();

  const { url, withDownload, prevModalProps, uuid } = useSelector<
    IRootState,
    PdfViewModalInterface
  >((state) => state.modal.modalProps);

  const {
    generatedDocumentsToDownloadUrl,
    generatingDocumentsToDownloadLoading
  } = useSelector<IRootState, IDatasetState>((state) => state.dataset);

  const onClose = () => {
    if (prevModalProps)
      dispatch(features.modal.actions.showModal(prevModalProps));
    else dispatch(features.modal.actions.hideModal());
  };

  useEffect(() => {
    if (withDownload)
      dispatch(
        features.dataset.actions.generateDocumentsToDownloadRequest({
          params: { fileUuid: uuid }
        })
      );
  }, [dispatch, uuid, withDownload]);

  useEffect(() => {
    return () =>
      dispatch(features.dataset.actions.clearGeneratedDocumentsToDownload());
  }, []);

  return (
    <Dialog
      isOpen
      className={styles["modal-container"]}
      onClose={onClose}
      closeTimeoutMS={500}
    >
      <PdfReader file={url} />
      <PreloaderWrapper loading={generatingDocumentsToDownloadLoading}>
        {withDownload && (
          <a
            className={styles["modal-download"]}
            href={generatedDocumentsToDownloadUrl}
            target="_blank"
            download
            rel="noreferrer"
          >
            <DownloadIcon />
            Завантажити документ
          </a>
        )}
      </PreloaderWrapper>
    </Dialog>
  );
};
export default PdfViewModal;
