import { CommonButton, LongArrowIcon } from "gov-ua-ui";
import { Parser } from "html-to-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CERTIFICATE_AUTH_REDIRECT_URL } from "constant";
import features from "features";
import { getRole, getToken } from "helpers";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";

import styles from "./main-page.module.scss";

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userRole] = useState(getRole());
  const [isAuthenticated] = useState(getToken());

  const { termsOfService } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const helpLinks = () => {
    const relevantLinks = [
      {
        id: 0,
        title: "Про адміністративну послугу",
        link: "/regular-questions"
      },
      {
        id: 1,
        title: "Навчальні матеріали",
        link: "/educational-materials"
      },
      {
        id: 2,
        title: "Законодавча база",
        link: "/legislative-base"
      }
    ];

    if (userRole === "user") {
      relevantLinks.push({
        id: 3,
        title: "Запит до системи",
        link: "/request-to-system-list"
      });
      return relevantLinks;
    }
    if (userRole) {
      relevantLinks.splice(1, 1);
      return relevantLinks;
    }
    return relevantLinks;
  };

  const handleOrderButtonClick = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title:
            "Створюється заявка на створення сертифіката про походження деревини",
          loading: true
        }
      })
    );

    dispatch(features.application.actions.createApplicationRequest());
  }, []);

  const handleRedirect = (link) => {
    navigate(link);
  };

  useEffect(() => {
    dispatch(features.subjects.actions.fetchTermsOfServiceRequest());
  }, [dispatch]);

  return (
    <main className={styles["get-certificate"]}>
      <section className={styles["get-certificate__header"]}>
        <div className={styles["get-certificate__header-controls"]}></div>
        <h1 className={styles["get-certificate__title"]}>
          {userRole !== "user" ? "Cертифікат " : "Отримання cертифіката "}
          про походження <br /> лісоматеріалів та виготовлених з них <br />
          пиломатеріалів
        </h1>
        {isAuthenticated ? (
          userRole === "user" && (
            <CommonButton
              className={styles["get-certificate__order-btn"]}
              label="Замовити"
              onClick={handleOrderButtonClick}
            />
          )
        ) : (
          <a href={CERTIFICATE_AUTH_REDIRECT_URL}>
            <CommonButton
              className={styles["get-certificate__order-btn"]}
              label="Замовити"
            />
          </a>
        )}

        {!isAuthenticated && (
          <small className={styles["get-certificate__notice"]}>
            потрібен вхід за допомогою цифрового <br /> підпису, BankID або
            MobileID
          </small>
        )}
      </section>

      {termsOfService && (
        <div className={styles["get-certificate__info"]}>
          <section className={styles["get-certificate__info-box"]}>
            <h4 className={styles["get-certificate__info-title"]}>
              Терміни надання послуги:
            </h4>
            <div className={styles["get-certificate__info-text"]}>
              {Parser().parse(termsOfService?.term)}
            </div>
          </section>
          <section className={styles["get-certificate__info-box"]}>
            <h4 className={styles["get-certificate__info-title"]}>
              Умови одержання послуги:
            </h4>
            <div className={styles["get-certificate__info-text"]}>
              {Parser().parse(termsOfService?.conditions)}
            </div>
          </section>
        </div>
      )}

      <div className={styles["get-certificate__questions"]}>
        <div className={styles["service-items"]}>
          {helpLinks().map(
            (item, index) =>
              item && (
                <div
                  className={styles["service-item"]}
                  key={index}
                  onClick={() => handleRedirect(item.link)}
                >
                  {item.title}
                  <LongArrowIcon className={styles["service-arrow"]} />
                </div>
              )
          )}
        </div>
      </div>
    </main>
  );
};

MainPage.propTypes = {};

export default MainPage;
