import cn from "classnames";
import { CommonButton, LongArrowIcon } from "gov-ua-ui";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDocTypeAlias } from "constant";
import features from "features";
import { formatDate, generateColumnData, productResParser } from "helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import DocumentForUse from "components/certificateComponents/viewCertificateComponents/DocumentForUse/DocumentForUse";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";
import CommonTable from "components/tables/CommonTable/CommonTable";

import DocIcon from "assets/images/icons/e_doc.svg";

import styles from "./specify-previous-usage-layout.module.scss";

interface SpecifyPreviousUsageLayoutInterface {
  data?: any;
  docType?: string;
  previousDocuments?: Array<any>;
  onDocumentChange?: (document: any) => void;
  documentIndex?: number;
  currentUsedDocument: any;
}

const SpecifyPreviousUsageLayout = ({
  data,
  docType,
  previousDocuments,
  onDocumentChange,
  documentIndex,
  currentUsedDocument
}: SpecifyPreviousUsageLayoutInterface) => {
  const dispatch = useDispatch();

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const columns = useMemo(() => {
    const columnsData = generateColumnData({ isPrevious: true });

    return columnsData;
  }, [application, docType]);

  useEffect(
    () => () => dispatch(features.previousUsage.actions.clearDocumentData()),
    []
  );

  const previousTitle = useMemo(() => {
    let title = `Документ-підстава до сертифікату/заявки: ${currentUsedDocument.status} `;
    title = title + `${currentUsedDocument.number} `;

    title =
      title +
      `${
        currentUsedDocument.date
          ? ` від ${formatDate(currentUsedDocument.date).date}`
          : ""
      }`;

    return title;
  }, [currentUsedDocument]);

  return (
    <ApplicationLayout className={styles["specify-usage"]}>
      <h1 className={styles["specify-usage__title"]}>Попереднє використання</h1>
      <div className={styles["certificates-navigations-controls-container"]}>
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__first"]
          )}
          onClick={() => onDocumentChange(previousDocuments[0])}
        />
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__previous"]
          )}
          onClick={() =>
            documentIndex !== 0 &&
            onDocumentChange(previousDocuments[documentIndex - 1])
          }
        />
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__next"]
          )}
          onClick={() =>
            documentIndex !== previousDocuments.length - 1 &&
            onDocumentChange(previousDocuments[documentIndex + 1])
          }
        />
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__last"]
          )}
          onClick={() =>
            onDocumentChange(previousDocuments[previousDocuments.length - 1])
          }
        />
      </div>
      <p className={styles["certificates-numbering"]}>
        Запис <span>{documentIndex + 1}</span> з{" "}
        <span>{previousDocuments.length}</span>
      </p>
      <h4 className={styles["dp-for-certificate"]}>{previousTitle}</h4>
      {/* TODO delete */}
      {/* {data && (
        <TransitionDocSearchResult
          loadedFiles={getScanCopyFiles}
          image={DocIcon}
          type={data.typeName}
          serial={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          sellerTitle="Лісокористувач-продавець"
          sellerText={data.sourceOrganizationName}
          availableCountTitle="Кількість доступно"
          availableCountText={data.quantityAvailable}
          className={styles["specify-usage__doc"]}
        />
      )} */}
      {data && (
        <DocumentForUse
          className={styles["specify-usage__doc"]}
          loadedFiles={data.files}
          additionalDocuments={data.additionalDocuments}
          docImg={DocIcon}
          type={getDocTypeAlias(data.typeName)}
          typeName={data.typeName}
          series={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          seller={{
            sellerName: data.sourceOrganizationName,
            sellerExternalId: data.sourceOrganizationExternalId,
            sellerEdrpou: data.sourceOrganizationEdrpou
          }}
          shopper={{
            shopperName: data.recipientOrganizationName,
            shopperExternalId: data.recipientOrganizationExternalId,
            shopperEdrpou: data.recipientOrganizationEdrpou
          }}
          documentId={data.externalId}
          uuid={data.uuid}
          initialCount={data.quantityOrigin}
          availableCount={data.quantityAvailable}
          wasteQuantity={data.wasteQuantity}
          wasteQuantityAvailable={data.wasteQuantityAvailable}
          wasteQuantityPercent={data.wasteQuantityPercent}
          isHiddenControls
          isPrimal={false}
        />
      )}
      <div className={styles["specify-usage__table-form"]}>
        {currentUsedDocument?.isWaste && (
          <h4 className={styles["dp-for-certificate"]}>
            Зворотні відходи, отримані в результаті переробки
          </h4>
        )}
        <CommonTable
          tableType="SIMPLE"
          defaultData={productResParser(
            currentUsedDocument?.usedProducts,
            docType,
            true
          )}
          columns={columns}
          footerTitle="Всього"
          footer={[
            {
              name: "usedInCurrentCertificate",
              label: "Використано в сертифікаті",
              value: currentUsedDocument?.totalQuantityInCertificate
            },
            {
              name: "usedForAllCertificates",
              label: "Загалом використано по всіх сертифікатах",
              value: currentUsedDocument?.totalQuantityInAllCertificate
            },
            {
              name: "totalVolumeInDocument",
              label: "Загальний обсяг в документі",
              value: currentUsedDocument?.totalQuantityInBasisDocument
            }
          ]}
        />
      </div>
    </ApplicationLayout>
  );
};

export default SpecifyPreviousUsageLayout;
