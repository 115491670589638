import RandExp from "randexp";
import { env } from "./env";

export const API_URL = env.REACT_APP_API_URL;
export const CABINET_API_URL = env.REACT_APP_CABINET_API_URL;
export const SIGN_WIDGET_URI = env.REACT_APP_SIGN_WIDGET_URI
  ? env.REACT_APP_SIGN_WIDGET_URI + "/index.php"
  : undefined;
export const CABINET_URL = env.REACT_APP_CABINET_URL;
export const APP_URL = env.REACT_APP_URL;

export const CERTIFICATE_AUTH_REDIRECT_URL =
  env.REACT_APP_CERTIFICATE_AUTH_REDIRECT_URL +
  "&state=" +
  // eslint-disable-next-line no-useless-escape
  new RandExp(/^[0-9A-Za-z\_\-\=]]{10,512}$/).gen();
export const CERTIFICATE_AUTH_API_URL = env.REACT_APP_CERTIFICATE_AUTH_API_URL;
export const DOMAIN = ".eco.gov.ua";
export const DRAFT_LIFETIME = 10800000;

export const COOKIES_EXPIRATION_DATE = 0.33;

export const PDV_PERCENT = 1.2;

export enum statuses {
  "RECEIVED",
  "CANCELED",
  "ANNUL",
  "ORDERED",
  "DRAFT",
  "NOTHING"
}

export enum docTypes {
  "EDOC",
  "APPLICATION",
  "REFUSAL",
  "ANNUL",
  "ANNUL_APPLICATION",
  "ANNUL_ALT",
  "CUSTOM"
}

export const getDocTypeAlias = (typeName) => {
  let alias = null;
  Object.keys(PrimaryDocumentTypes).forEach((key) => {
    if (PrimaryDocumentTypes[key] === typeName) alias = key;
  });

  if (alias === "MD") alias = "customs_declaration";

  return alias;
};

export enum PrimaryDocumentEnum {
  ttn = "ttn",
  ttn_wood = "ttn_wood",
  zn = "zn",
  zsn = "zsn",
  md = "md",
  tn = "tn",
  transfer = "transfer"
}

export type PrimaryDocumentType = {
  [P in PrimaryDocumentEnum]: string;
};

export const PrimaryDocumentTypes: PrimaryDocumentType = {
  ttn: "ТТН",
  ttn_wood: "ТТН-ліс",
  zn: "Залізнична накладна",
  zsn: "ЗСН",
  md: "Митна декларація",
  tn: "ТН",
  transfer: "Переміщення"
};

export interface NotRequireSeriesType {
  ttn: string;
  md: string;
  tn: string;
  zn: string;
}

export const NotRequireSeriesTypes: NotRequireSeriesType = {
  ttn: PrimaryDocumentTypes.ttn,
  md: PrimaryDocumentTypes.md,
  tn: PrimaryDocumentTypes.tn,
  zn: PrimaryDocumentTypes.zn
};

export interface DismissDocumentCreationType {
  ttn: string;
  transfer: string;
}

export const DismissDocumentCreation: DismissDocumentCreationType = {
  ttn: PrimaryDocumentTypes.ttn_wood,
  transfer: PrimaryDocumentTypes.transfer
};

export const MEDIA_MAX_WINDOW_WIDTH: number = 991;
