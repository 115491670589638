import { createSlice } from "@reduxjs/toolkit";
import { parseResErrors } from "helpers/parseResErrors";

export interface IEDeclineError {
  unknown?: string[];
}

export interface IOfficialDeclinedApplicationsForSigningState {
  isLoadingFetchDeclined: boolean;
  isLoadingFetchEDeclined: boolean;
  responseError: any;
  declined: any[];
  applicationDeclined?: string;
  applicationDeclinedReason: string;
  fetchingEDeclinedError: IEDeclineError;
}

const initEDeclineError: IEDeclineError = {
  unknown: null
};

const initialState: IOfficialDeclinedApplicationsForSigningState = {
  responseError: {},
  isLoadingFetchDeclined: false,
  isLoadingFetchEDeclined: false,
  declined: [],
  applicationDeclined: null,
  applicationDeclinedReason: "",
  fetchingEDeclinedError: initEDeclineError
};

export const mainSlice = createSlice({
  name: "officialDeclinedApplicationsForSigning",
  initialState,
  reducers: {
    fetchDeclinedRequest: (state) => {
      state.isLoadingFetchDeclined = true;
    },
    fetchDeclinedSuccess: (state, { payload }) => {
      state.isLoadingFetchDeclined = false;
      state.declined = payload;
    },
    fetchDeclinedError: (state) => {
      state.isLoadingFetchDeclined = false;
    },

    fetchEDeclinedRequest: (state, { payload }) => {
      state.applicationDeclinedReason = payload.fields.declineDescription;
      state.isLoadingFetchEDeclined = true;
    },
    fetchEDeclinedSuccess: (state, { payload }) => {
      state.isLoadingFetchEDeclined = false;
      state.applicationDeclined = payload.url;
    },
    fetchEDeclinedError: (state, { payload }) => {
      state.isLoadingFetchEDeclined = false;
      parseResErrors({
        onUnknownErrors: (errors) =>
          (state.fetchingEDeclinedError.unknown = errors),
        errorsRes: payload
      });
    },

    clearEDecline: (state) => {
      state.applicationDeclined = initialState.applicationDeclined;
      state.applicationDeclinedReason = initialState.applicationDeclinedReason;
    },

    signEDeclinedRequest: (state) => {
      state.isLoadingFetchEDeclined = true;
    },
    signEDeclinedSuccess: (state, { payload }) => {
      state.isLoadingFetchEDeclined = false;
      state.applicationDeclined = payload.url;
    },
    signEDeclinedError: (state, { payload }) => {
      state.isLoadingFetchEDeclined = false;
      parseResErrors({
        onUnknownErrors: (errors) =>
          (state.fetchingEDeclinedError.unknown = errors),
        errorsRes: payload
      });
    }
  }
});

export const { actions, reducer } = mainSlice;
