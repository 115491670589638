export const formatNumber = (number, fixedLength, withZero) => {
  let formatNumber = number;

  if (typeof formatNumber === "string") {
    formatNumber = formatNumber.replace(/,/, ".");
  }

  formatNumber = +formatNumber;

  formatNumber = Number.isNaN(formatNumber) ? 0 : formatNumber;
  if (fixedLength) {
    formatNumber = customToFixed(formatNumber, fixedLength, withZero);
  }
  return formatNumber;
};

//Method was created to fix default .toFixed
//0.855.toFixed(2) return 0.85 but customToFixed return 0.86 4,791
const customToFixed = (number, fixedLength, withZero) => {
  const initialDecimals = Number.isInteger(number)
    ? 0
    : number.toString().split(".")[1].length;
  const decimals = `1${"0".repeat(fixedLength)}`;
  let roundedNumber = Math.round(number * decimals) / decimals;
  const newDecimals = Number.isInteger(roundedNumber)
    ? 0
    : roundedNumber.toString().split(".")[1].length;

  if (newDecimals > initialDecimals) {
    roundedNumber = roundedNumber.toFixed(initialDecimals);
  }

  roundedNumber = +roundedNumber;

  return withZero ? roundedNumber.toFixed(fixedLength) : roundedNumber;
};

export const calculateSum = (data) => {
  return data.reduce(
    (accumulator, currentValue) => addNumbers(accumulator, currentValue),
    0
  );
};

export const addNumbers = (a, b) => {
  if (a === undefined || b === undefined) {
    // Return 0 or throw an error
    return 0;
    // or
    // throw new Error("Invalid arguments");
  }
  const [, aDecimals = ""] = a.toString().split(".");
  const [, bDecimals = ""] = b.toString().split(".");
  const maxPlaces = Math.max(aDecimals.length, bDecimals.length);
  const factor = 10 ** maxPlaces;
  const aInt = Math.round(a * factor);
  const bInt = Math.round(b * factor);
  const sumInt = aInt + bInt;
  const sum = sumInt / factor;
  return sum;
};
