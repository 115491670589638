import { CommonButton } from "gov-ua-ui";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.scss";

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <div className={styles["error-page"]}>
      <h1 className={styles["error-page__title"]}>404</h1>
      <div className={styles["error-page__desc"]}>Сторінка не знайдена</div>
      <CommonButton
        onClick={handleButtonClick}
        className={styles["error-page__btn"]}
        outlined={true}
        label="На головну"
      />
    </div>
  );
};

export default ErrorPage;
