export const requestsToSystemList = [
  {
    id: 1,
    type: "product",
    title: "Заявка на додавання або редагування типів продукції"
  },
  {
    id: 2,
    type: "ws",
    title: "Заявка на додавання або редагування породи деревини"
  },
  {
    id: 3,
    type: "wg",
    title: "Заявка на додавання або редагування сортів деревини"
  },
  {
    id: 4,
    type: "uktzed",
    title: "Заявка на додавання або редагування кодів УКТЗЕД"
  },
  {
    id: 5,
    type: "org",
    title: "Заявка на додавання або редагування підприємств"
  },
  // {
  //   id: 6,
  //   type: "edit-documents",
  //   title:
  //     "Заявка на редагування документів ТТН, ЗН, МД, ТН  (перехідні або документи підстави, які були частково або повністю використані у заявках та/або сертифікатах)"
  // },
  {
    id: 7,
    type: "other",
    title: "Інші питання"
  }
];
