import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, DatePicker, FormField, Select } from "gov-ua-ui";
import { useCallback, useState } from "react";
import * as Yup from "yup";

import { getLastTimeOfDate } from "helpers/date";

import Autocomplete from "components/Autocomplete/Autocomplete";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";

import ArrowBottomIcon from "assets/images/icons/arrow-bottom.svg";

import styles from "./applications-search-form.module.scss";

interface ApplicationsSearchFormInterface {
  children;
  onFormSubmit: (values: any) => void;
  onClearFormClick?: () => void;
  statuses;
  applicationTypes?;
  interregionalOfficeExternalList?;
  isCertificateNumber?: boolean;
}

const ApplicationsSearchForm = ({
  children,
  onFormSubmit,
  onClearFormClick,
  statuses,
  applicationTypes,
  interregionalOfficeExternalList,
  isCertificateNumber
}: ApplicationsSearchFormInterface) => {
  const [maxDate] = useState(new Date());
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const parseFields = (values) => {
    return {
      number: values.number || null,
      certificateNumber: values.certificateNumber || null,
      dateFrom: values.dateFrom || null,
      dateTo: getLastTimeOfDate(values.dateTo) || null,
      exporterExternalId: values.customer?.value || null,
      buyerExternalId: values.buyer?.value || null,
      consigneeExternalId: values.seller?.value || null,
      interregionalOfficeExternalId:
        values.interregionalOfficeExternal?.value || null,
      ...(isCertificateNumber
        ? {
            status: values.status?.value || null
          }
        : {
            isProcessing: values.isProcessing?.value || null
          })
    };
  };
  const handleStatusChange = (value) => {
    formik.setFieldValue(
      isCertificateNumber ? "status" : "isProcessing",
      value
    );
  };
  const handleProcessingTypeChange = (value) => {
    formik.setFieldValue("processingType", value);
  };
  const onAdvancedSearchChangeStatus = () => {
    setIsAdvancedSearch(!isAdvancedSearch);
  };
  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
  }, []);

  const handleDateFromChange = (date: Date) => {
    formik.setFieldValue("dateFrom", date);
  };
  const handleDateToChange = (date: Date) => {
    formik.setFieldValue("dateTo", date);
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      number: null,
      dateFrom: null,
      dateTo: null,
      processingType: { value: "", label: "" },
      buyer: {
        value: "",
        label: ""
      },
      customer: {
        value: "",
        label: ""
      },
      seller: {
        value: "",
        label: ""
      },

      ...(isCertificateNumber
        ? {
            certificateNumber: null,
            status: { value: "", label: "" }
          }
        : {
            interregionalOfficeExternal: {
              value: "",
              label: ""
            },
            isProcessing: { value: "", label: "" }
          })
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      dateTo: Yup.date()
        .nullable()
        .notRequired()
        .when("dateFrom", (dateFrom, schema) => {
          return dateFrom[0]
            ? schema.min(dateFrom, "Дата по не повинна бути більше дати з")
            : schema.notRequired();
        })
    }),
    onSubmit: (values) => {
      const fields = parseFields(values);
      onFormSubmit(fields);
    }
  });

  const onClearForm = useCallback(() => {
    formik.resetForm();
    onClearFormClick ? onClearFormClick() : formik.submitForm();
  }, [onClearFormClick]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className={styles["search-container"]}>
          <FormField
            name="number"
            placeholder="За номером заявки"
            className={styles["search-container__input"]}
            value={formik.values.number || ""}
          />
          <CommonButton
            outlined={true}
            className={styles["search-container__search-button"]}
            label="Пошук"
            type="submit"
          />
          <div className={styles["advanced-wrapper"]}>
            <CommonButton
              outlined={true}
              className={styles["advanced-wrapper__button"]}
              onClick={onAdvancedSearchChangeStatus}
              label={
                <>
                  <h4>Розширений пошук</h4>
                  <img
                    src={ArrowBottomIcon}
                    className={
                      isAdvancedSearch
                        ? styles["advanced-wrapper__advanced"]
                        : undefined
                    }
                    alt="advinced button arrow"
                  />
                </>
              }
            />
          </div>
        </div>
        {isAdvancedSearch && (
          <div className={styles["search-page"]}>
            <div
              className={cn(
                styles["search-page__content-row"],
                styles["search-page__content-row_similar"]
              )}
            >
              {isCertificateNumber && (
                <FormField
                  name="certificateNumber"
                  placeholder="За номером сертифікату"
                  value={formik.values["certificateNumber"] || ""}
                />
              )}
              <DatePicker
                placeholder="Період з"
                name="dateFrom"
                maxDate={formik.values["dateTo"] ?? maxDate}
                withFormik
                date={formik.values["dateFrom"]}
                onChange={handleDateFromChange}
              />
              <DatePicker
                name="dateTo"
                placeholder="Період до"
                minDate={formik.values["dateFrom"]}
                maxDate={maxDate}
                withFormik
                date={formik.values["dateTo"]}
                onChange={handleDateToChange}
              />
            </div>
            {interregionalOfficeExternalList?.length > 0 && (
              <div
                className={cn(
                  styles["search-page__content-row"],
                  styles["search-page__content-row_large"]
                )}
              >
                <Select
                  name="interregionalOfficeExternal"
                  value={formik.values["interregionalOfficeExternal"]}
                  options={interregionalOfficeExternalList.map((item) => {
                    return {
                      value: item.externalId,
                      label: item.officeName
                    };
                  })}
                  placeholder="МРУ"
                  onSelectChange={onAutocompleteChange}
                  withFormik
                />
              </div>
            )}
            <div
              className={cn(
                styles["search-page__content-row"],
                styles["search-page__content-row_large_small"]
              )}
            >
              <Autocomplete
                name="customer"
                placeholder="Підприємство - експортер"
                value={formik.values["customer"]}
                entity="organizations"
                onChange={onAutocompleteChange}
              />
              {applicationTypes && (
                <Select
                  value={formik.values["processingType"]}
                  options={applicationTypes}
                  placeholder="Тип заявки"
                  onSelectChange={handleProcessingTypeChange}
                />
              )}
            </div>
            <div
              className={cn(
                styles["search-page__content-row"],
                styles["search-page__content-row_large_small"]
              )}
            >
              <Autocomplete
                name="buyer"
                placeholder="Покупець"
                value={formik.values["buyer"]}
                entity="organizations"
                onChange={onAutocompleteChange}
              />
              <Select
                value={
                  formik.values[isCertificateNumber ? "status" : "isProcessing"]
                }
                options={statuses}
                placeholder="Статус"
                onSelectChange={handleStatusChange}
              />
            </div>
            <div className={styles["search-page__content-controls"]}>
              <CommonButton
                outlined={true}
                label="Скинути фільтр"
                onClick={onClearForm}
              />
              <CommonButton label="Пошук" type="submit" />
            </div>
          </div>
        )}
        {children}
      </Form>
      <ScrollToFieldError />
    </FormikProvider>
  );
};
export default ApplicationsSearchForm;
