import { useFormik, useFormikContext } from "formik";
import { FormField, RadioButton } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import Autocomplete from "components/Autocomplete/Autocomplete";
import Preloader from "components/Preloader/Preloader";

import styles from "./forms.module.scss";

interface IEditEnterprisesForm {
  type: string;
  onInnerFormValueChange?: (innerFormValues: any, validation?: any) => void;
}

const inputForestUserTypes = [
  {
    radioType: false,
    radioText: "Ні"
  },
  {
    radioType: true,
    radioText: "Так"
  }
];

const EditEnterprisesForm = ({
  type,
  onInnerFormValueChange
}: IEditEnterprisesForm): JSX.Element => {
  const dispatch = useDispatch();

  const externalFormik = useFormikContext();

  const { standardList, speciesList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  const [isUkraineResident, setIsUkraineResident] = useState(false);

  useEffect(() => {
    dispatch(features.dataset.actions.fetchStandardListRequest());
    dispatch(features.dataset.actions.fetchSpeciesListRequest());
  }, []);

  const onFormSubmit = () => {};

  const validationSchema = (isResident) =>
    Yup.object().shape({
      name:
        type === "edit"
          ? Yup.object().shape({
              value: Yup.string().required("Виберіть підприємство")
            })
          : Yup.string().required("Введіть назву підприємства"),
      country: Yup.string().required("Виберіть країну"),
      edrpou: isResident && Yup.string().required("Введіть ЄДРПОУ"),
      address: isResident && Yup.string().required("Введіть адресу")
    });

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      name: externalFormik.values["name"] || "",
      oldCountry: externalFormik.values["oldCountry"] || "",
      oldAddress: externalFormik.values["oldCountry"] || "",
      oldForestUser: externalFormik.values["oldForestUser"] || false,
      oldEdrpou: externalFormik.values["oldEdrpou"] || "",
      country: externalFormik.values["country"] || "",
      address: externalFormik.values["address"] || "",
      forestUser: externalFormik.values["forestUser"] || false,
      edrpou: externalFormik.values["edrpou"] || "",
      comment: externalFormik.values["comment"] || ""
    },
    onSubmit: onFormSubmit
  });

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);

    if (option.value) {
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: option.value
          },
          onSuccess: (response) => {
            if (response?.country) {
              formik.setFieldValue("oldCountry", response?.country || "");
              formik.setFieldValue("country", response?.country || "");
              if (response?.country.toLowerCase() === "україна") {
                setIsUkraineResident(true);
              }
            }
            if (response?.address) {
              formik.setFieldValue("oldAddress", response?.address || "");
              formik.setFieldValue("address", response?.address || "");
            }
            if (response?.edrpou) {
              formik.setFieldValue("oldEdrpou", response?.edrpou || "");
              formik.setFieldValue("edrpou", response?.edrpou || "");
            }
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    const actualValues = {
      ...formik.values,
      ...(externalFormik.values as any)
    };
    delete actualValues.type;
    formik.setValues(actualValues);
  }, [
    externalFormik.values["newCodeUktzed"],
    externalFormik.values["comment"],
    externalFormik.values["address"],
    externalFormik.values["edrpou"],
    externalFormik.values["country"]
  ]);

  useEffect(() => {
    if (formik && formik.values["country"].toLowerCase() === "україна") {
      setIsUkraineResident(true);
      validationSchema(true);
    } else {
      setIsUkraineResident(false);
      validationSchema(false);
    }
  }, [formik.values["country"]]);

  useEffect(() => {
    if (formik && type === "create") formik.setFieldValue("name", "");
  }, [type]);

  useEffect(() => {
    onInnerFormValueChange(formik.values, validationSchema(isUkraineResident));
  }, [formik.values, type, isUkraineResident]);

  const handleRadioChange = (radioType) => {
    if (formik.values["forestUser"] !== radioType) {
      formik.setFieldValue("forestUser", radioType);
    }
  };

  return !isEmpty(standardList) && !isEmpty(speciesList) ? (
    <div className={styles["product-types-form__container"]}>
      <h4 className={styles["product-types-form__title"]}>
        {type === "edit" ? "Редагувати" : "Додати"} підприємство
      </h4>
      <div className={styles["product-types-form__field-container"]}>
        {type === "edit" ? (
          <Autocomplete
            name="name"
            placeholder={"Назва підприємства, існуюча"}
            value={formik.values["name"]}
            entity="organizations"
            params="type=all"
            onChange={onAutocompleteChange}
          />
        ) : (
          <FormField name={"name"} placeholder="Назва підприємства, нова" />
        )}
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField name={"country"} placeholder="Країна" />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField name={"address"} placeholder="Адреса" />
      </div>
      <div className={styles["radio-block__container"]}>
        <h4 className={styles["product-types-form__title"]}>Лісокористувач</h4>
        {inputForestUserTypes.map(({ radioType, radioText }) => {
          return (
            <div key={radioText}>
              <RadioButton
                key={radioText}
                title={radioText}
                name="forestUser"
                value={formik.values["forestUser"]}
                checked={formik.values["forestUser"] === radioType}
                onClick={() => handleRadioChange(radioType)}
              />
            </div>
          );
        })}
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField name={"edrpou"} placeholder="ЄДРПОУ / Паспортні дані" />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField
          name="comment"
          placeholder="Коментар (не обов’язково)"
          as="textarea"
          className={"textarea-field"}
        />
      </div>
    </div>
  ) : (
    <Preloader />
  );
};

export default EditEnterprisesForm;
