import { Link, useParams } from "react-router-dom";

import { applicationRouteStatuses } from "dataset/applicationStatuses";
import { getRole } from "helpers/userAuthInfo";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { ICertificateAnnulRequestState } from "scenes/subject/certificates/CertificateAnnulRequestPage/ducks";

import Clock from "components/Clock/Clock";
import IconButton from "components/buttons/IconButton/IconButton";
import HeaderWrapper from "components/headers/HeaderWrapper/HeaderWrapper";

import ArrowLeftIcon from "assets/images/icons/arrow_left.svg";
import PlusIcon from "assets/images/icons/plus.svg";

import styles from "./print-header.module.scss";

const PrintHeader = (): JSX.Element => {
  const { applicationType } = useParams<{
    applicationType?: string;
  }>();

  const [userRole] = useState(getRole());

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const { application: applicationAnnul } = useSelector<
    IRootState,
    ICertificateAnnulRequestState
  >((state) => state.certificateAnnulRequest);

  const prevRoute = useMemo(() => {
    if (userRole === "subject")
      return `/personal/applications/${
        applicationRouteStatuses[
          application?.status ?? applicationAnnul?.applicationStatus
        ] ?? "drafts"
      }`;
    else {
      if (applicationType) return "/official-annul/declined";
      return "/official-archival";
    }
  }, [application, applicationAnnul, applicationType, userRole]);

  return (
    <HeaderWrapper
      className={styles["print-header"]}
      leftPart={
        <div className={styles["print-header__left"]}>
          <IconButton icon={ArrowLeftIcon}>
            <Link to={prevRoute}>
              <p className={styles["print-header__disappear-part"]}>
                Повернутися до кабінету
              </p>
            </Link>
          </IconButton>
        </div>
      }
      centerPart={
        <div className={styles["print-header__center"]}>
          <p className={styles["print-header__center-text"]}>
            Час зберігання чернетки
          </p>
          <Clock initialTime={new Date(2022, 9, 26, 15, 0, 0, 0)} />
        </div>
      }
      rightPart={
        <div className={styles["print-header__right"]}>
          <IconButton
            icon={PlusIcon}
            className={styles["print-header__right-btn"]}
          />
        </div>
      }
    />
  );
};

export default PrintHeader;
