import { useFormik, useFormikContext } from "formik";
import { FormField, Select } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import Autocomplete from "components/Autocomplete/Autocomplete";
import Preloader from "components/Preloader/Preloader";

import styles from "./forms.module.scss";

interface IProductTypesForm {
  type: string;
  onInnerFormValueChange?: (innerFormValues: any, validation?: any) => void;
}

const ProductTypesForm = ({
  type,
  onInnerFormValueChange
}: IProductTypesForm): JSX.Element => {
  const dispatch = useDispatch();
  const externalFormik = useFormikContext();

  const { standardList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  useEffect(() => {
    dispatch(features.dataset.actions.fetchStandardListRequest());
  }, []);

  const onFormSubmit = () => {};

  const validationSchema = Yup.object().shape({
    oldName:
      type === "edit" &&
      Yup.object().shape({
        value: Yup.string().required("Виберіть існуючу назву продукції")
      }),
    name: Yup.string().required("Введіть нову назву продукції")
  });

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      oldName: externalFormik.values["oldName"] || "",
      name: externalFormik.values["name"] || "",
      comment: externalFormik.values["comment"] || "",
      productStandard: null
    },

    onSubmit: onFormSubmit
  });

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
  }, []);

  const onStandardChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
    formik.setFieldValue("oldName", "");
  }, []);

  useEffect(() => {
    if (!isEmpty(standardList)) {
      onAutocompleteChange(standardList[0], "productStandard");
    }
  }, [onAutocompleteChange, standardList]);

  useEffect(() => {
    onInnerFormValueChange(formik.values, validationSchema);
  }, [formik.values, type]);

  useEffect(() => {
    const actualValues = {
      ...formik.values,
      ...(externalFormik.values as any)
    };
    delete actualValues.type;
    formik.setValues(actualValues);
  }, [externalFormik.values["name"], externalFormik.values["comment"]]);

  return formik.values.productStandard ? (
    <div className={styles["product-types-form__container"]}>
      <h4 className={styles["product-types-form__title"]}>Тип продукції</h4>
      <div className={styles["product-types-form__field-container"]}>
        <Select
          name="productStandard"
          value={formik.values.productStandard}
          options={standardList}
          onSelectChange={onStandardChange}
          blurInputOnSelect={true}
        />
      </div>
      {type === "edit" && (
        <div className={styles["product-types-form__field-container"]}>
          <Autocomplete
            name="oldName"
            placeholder="Назва продукції, існуюча"
            value={formik.values["oldName"]}
            entity="products"
            params={`productStandardUuid=${formik.values.productStandard?.value}`}
            onChange={onAutocompleteChange}
            dataFieldsNames={{
              valueFieldName: "id",
              labelFieldName: "name"
            }}
          />
        </div>
      )}
      <div className={styles["product-types-form__field-container"]}>
        <FormField
          name="name"
          placeholder={`Назва продукції${type === "edit" ? ", нова" : ""}`}
        />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField
          name="comment"
          placeholder="Коментар (не обов’язково)"
          as="textarea"
          className={"textarea-field"}
        />
      </div>
    </div>
  ) : (
    <Preloader />
  );
};

export default ProductTypesForm;
