import cn from "classnames";

import styles from "./section-text.module.scss";

interface SectionTextInterface {
  title: string;
  children: Array<JSX.Element> | JSX.Element | string | number;
  subcontent?: string;
  className?: string;
}

const SectionText = ({
  title,
  children,
  subcontent,
  className = ""
}: SectionTextInterface) => {
  return (
    <div className={cn(styles["section-text"], className)}>
      <h4 className={styles["section-text__title"]}>{title}</h4>
      <p className={styles["section-text__text"]}>{children}</p>
      {subcontent && (
        <p className={styles["section-text__sub-text"]}>{subcontent}</p>
      )}
    </div>
  );
};

export default SectionText;
