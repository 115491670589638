import features from "features";
import { Dialog } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "reducer";

import styles from "./picture-view-modal.module.scss";

interface PictureViewModalInterface {
  url: string;
  uuid: string;
  prevModalProps?: object;
}

const PictureViewModal = () => {
  const dispatch = useDispatch();

  const { url, prevModalProps } = useSelector<
    IRootState,
    PictureViewModalInterface
  >((state) => state.modal.modalProps);

  const onClose = () => {
    if (prevModalProps)
      dispatch(features.modal.actions.showModal(prevModalProps));
    else dispatch(features.modal.actions.hideModal());
  };

  return (
    <Dialog
      isOpen
      className={styles["modal-container"]}
      onClose={onClose}
      closeTimeoutMS={500}
    >
      <div className={styles["picture"]}>
        <img src={url + "#" + new Date().getTime()} alt="" />
      </div>
    </Dialog>
  );
};
export default PictureViewModal;
