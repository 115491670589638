import cn from "classnames";
import { VisualUploadMultipleFiles } from "gov-ua-ui";

import { IFile, IRemark } from "interfaces";

import DocumentView from "components/DocumentView/DocumentView";
import SectionText from "components/Section/SectionText/SectionText";
import OfficialControls from "components/certificateComponents/viewCertificateComponents/OfficialControls/OfficialControls";

import styles from "./section-doc.module.scss";

interface TextItemInterface {
  title: string;
  content: string | number;
  subcontent?: string;
}

interface SectionDocInterface {
  textItems?: Array<TextItemInterface>;
  textPosition: "LEFT" | "RIGHT" | "BOTTOM";
  loadedFiles?: IFile[];
  docImg?: string;
  className?: string;
  documentImageClassName?: string;
  style?: React.CSSProperties;
  isOfficialRemarkControl?: boolean;
  onDocumentView?: (file?) => void;
  remark?: IRemark;
  blockType?: string;
}

const SectionDoc = ({
  textItems,
  textPosition,
  loadedFiles,
  docImg,
  className,
  style,
  isOfficialRemarkControl,
  onDocumentView,
  documentImageClassName,
  remark,
  blockType
}: SectionDocInterface) => {
  const renderText = (classes?: string) => {
    return (
      <div className={styles["doc-section__texts"]}>
        {textItems &&
          textItems.map(({ title, content, subcontent }: TextItemInterface) => {
            return (
              <SectionText
                className={classes}
                title={title}
                subcontent={subcontent}
                key={title}
              >
                {content}
              </SectionText>
            );
          })}
        {isOfficialRemarkControl && (
          <OfficialControls isSmall remark={remark} blockType={blockType} />
        )}
      </div>
    );
  };

  const renderDoc = () => {
    return docImg ? (
      <DocumentView
        docImg={docImg}
        onDocumentView={onDocumentView}
        className={documentImageClassName}
      />
    ) : (
      <VisualUploadMultipleFiles
        accept={{
          "application/pdf": [".pdf"],
          "image/jpeg": [".jpg"]
        }}
        containerClassName={documentImageClassName}
        loadedFiles={loadedFiles}
        onDocumentView={onDocumentView}
        readonly={true}
        title={"Документи не завантажено"}
      />
    );
  };

  const construct = () => {
    switch (textPosition) {
      case "RIGHT":
        return (
          <div
            className={cn(
              styles["doc-section"],
              styles["doc-section_text-right"],
              className
            )}
            style={style}
          >
            {renderDoc()}
            {renderText()}
          </div>
        );
      case "BOTTOM":
        return (
          <div
            className={cn(
              styles["doc-section"],
              styles["doc-section_text-bottom"],
              className
            )}
            style={style}
          >
            {renderDoc()}
            {renderText(styles["doc-section__bottom-text"])}
          </div>
        );
      case "LEFT":
        return (
          <div
            className={cn(
              styles["doc-section"],
              styles["doc-section_text-left"],
              className
            )}
            style={style}
          >
            {renderText()}
            {renderDoc()}
          </div>
        );
    }
  };

  return <>{construct()}</>;
};

export default SectionDoc;
