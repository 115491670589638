import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { reducer as toastrReducer } from "react-redux-toastr";
import features from "features";
import { IModalState } from "scenes/Modal/ducks";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { IDraftApplicationState } from "scenes/subject/applications/DraftApplicationsPage/ducks";
import { IOrderedApplicationsState } from "scenes/subject/applications/OrderedApplicationsPage/ducks";
import { IReceivedApplicationsState } from "scenes/subject/applications/ReceivedApplicationsPage/ducks";
import { IDeclinedApplicationsState } from "scenes/subject/applications/DeclinedApplicationsPage/ducks";
import { IAnnulledApplicationsState } from "scenes/subject/applications/AnnulledApplicationsPage/ducks";
import { IRegularQuestionsState } from "scenes/subject/helps/RegularQuestionsPage/ducks";
import { ILegislativeBaseState } from "scenes/subject/helps/LegislativeBasePage/ducks";
import { IEducationalMaterialsState } from "scenes/subject/helps/EducationalMaterialsPage/ducks";
import { IRequestToSystemListState } from "scenes/subject/helps/RequestToSystemListPage/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { ICertificateAnnulRequestState } from "scenes/subject/certificates/CertificateAnnulRequestPage/ducks";
import { ISearchApplicationsState } from "scenes/subject/applications/SearchApplicationsPage/ducks";
import { IDatasetState } from "scenes/Dataset/ducks";
import { IAuthState } from "scenes/Auth/ducks";
import { IUserSettings } from "scenes/SettingsPage/ducks";
import { ISubjectDocs } from "scenes/subject/documents/ducks";
import { IZNDocState } from "scenes/subject/documents/ZNDocPage/ducks";
import { IProductUsageState } from "scenes/subject/documents/ProductUsage/ducks";
import { IPreviousUsageState } from "scenes/subject/documents/PreviousUsage/ducks";
import { IDashboardState } from "scenes/official/OfficialDashboardPage/ducks";
import { IProgressedApplicationsState } from "scenes/official/OfficialInProgressPage/ducks";
import { IApplicationReviewState } from "scenes/official/certificates/OfficialApplicationReviewPage/ducks";
import { ICertificateAnnulRequestState as IOfficialCertificateAnnulRequestState } from "scenes/official/certificates/OfficialCertificateAnnulRequestPage/ducks";
import { IArchivalApplicationsState } from "scenes/official/OfficialArchivalPage/ducks";
import { IOfficialAnnulledApplicationsForSigning as IOfficialAnnulledApplicationsForSigningState } from "scenes/official/OfficialAnnulledApplicationsForSigningPage/ducks";
import { IOfficialDeclinedApplicationsForSigningState } from "scenes/official/OfficialDeclinedApplicationsForSigningPage/ducks";

interface IReducer {
  toastr: any;
  subjects: ISubjectsState;
  draftApplications: IDraftApplicationState;
  orderedApplications: IOrderedApplicationsState;
  receivedApplications: IReceivedApplicationsState;
  declinedApplications: IDeclinedApplicationsState;
  annulledApplications: IAnnulledApplicationsState;
  searchApplications: ISearchApplicationsState;
  application: IApplicationState;
  certificateAnnulRequest: ICertificateAnnulRequestState;
  modal: IModalState;
  dataset: IDatasetState;
  auth: IAuthState;
  subjectDocs: ISubjectDocs;
  ZNDoc: IZNDocState;
  productUsage: IProductUsageState;
  previousUsage: IPreviousUsageState;
  officialDashboard: IDashboardState;
  officialProgressed: IProgressedApplicationsState;
  officialApplicationReview: IApplicationReviewState;
  officialCertificateAnnulRequest: IOfficialCertificateAnnulRequestState;
  officialApplicationsArchival: IArchivalApplicationsState;
  subjectRegularQuestions: IRegularQuestionsState;
  subjectLegislativeBase: ILegislativeBaseState;
  subjectEducationalMaterials: IEducationalMaterialsState;
  subjectRequestToSystemList: IRequestToSystemListState;
  settings: IUserSettings;
  officialAnnulledApplicationsForSigning: IOfficialAnnulledApplicationsForSigningState;
  officialDeclinedApplicationsForSigning: IOfficialDeclinedApplicationsForSigningState;
}

export const rootReducers = combineReducers<IReducer>({
  toastr: toastrReducer,
  subjects: features.subjects.reducer,
  draftApplications: features.draftApplications.reducer,
  orderedApplications: features.orderedApplications.reducer,
  receivedApplications: features.receivedApplications.reducer,
  declinedApplications: features.declinedApplications.reducer,
  annulledApplications: features.annulledApplications.reducer,
  searchApplications: features.searchApplications.reducer,
  application: features.application.reducer,
  certificateAnnulRequest: features.certificateAnnulRequest.reducer,
  modal: features.modal.reducer,
  dataset: features.dataset.reducer,
  auth: features.auth.reducer,
  subjectDocs: features.subjectDocs.reducer,
  ZNDoc: features.ZNDoc.reducer,
  productUsage: features.productUsage.reducer,
  previousUsage: features.previousUsage.reducer,
  officialDashboard: features.officialDashboard.reducer,
  officialProgressed: features.officialProgressed.reducer,
  officialApplicationReview: features.officialApplicationReview.reducer,
  officialCertificateAnnulRequest:
    features.officialCertificateAnnulRequest.reducer,
  officialApplicationsArchival: features.officialArchival.reducer,
  subjectRegularQuestions: features.regularQuestions.reducer,
  subjectEducationalMaterials: features.educationalMaterials.reducer,
  subjectLegislativeBase: features.legislativeBase.reducer,
  subjectRequestToSystemList: features.requestToSystemList.reducer,
  settings: features.settings.reducer,
  officialAnnulledApplicationsForSigning:
    features.officialAnnulledApplicationsForSigning.reducer,
  officialDeclinedApplicationsForSigning:
    features.officialDeclinedApplicationsForSigning.reducer
});

export const rootSagas = function* rootSaga() {
  yield all([
    features.subjects.saga(),
    features.draftApplications.saga(),
    features.orderedApplications.saga(),
    features.receivedApplications.saga(),
    features.declinedApplications.saga(),
    features.annulledApplications.saga(),
    features.searchApplications.saga(),
    features.application.saga(),
    features.certificateAnnulRequest.saga(),
    features.dataset.saga(),
    features.auth.saga(),
    features.subjectDocs.saga(),
    features.ZNDoc.saga(),
    features.productUsage.saga(),
    features.previousUsage.saga(),
    features.officialDashboard.saga(),
    features.officialProgressed.saga(),
    features.officialApplicationReview.saga(),
    features.officialCertificateAnnulRequest.saga(),
    features.officialArchival.saga(),
    features.regularQuestions.saga(),
    features.educationalMaterials.saga(),
    features.legislativeBase.saga(),
    features.requestToSystemList.saga(),
    features.settings.saga(),
    features.officialAnnulledApplicationsForSigning.saga(),
    features.officialDeclinedApplicationsForSigning.saga()
  ]);
};

export type IRootState = ReturnType<typeof rootReducers>;
