export * from "./api";
export * from "./date";
export * from "./userAuthInfo";
export * from "./jwt";
export * from "./numbers";
export * from "./products-table";
export * from "./parseResErrors";
export * from "./parseFilesErrors";
export * from "./getLastPartOfRoute";
export * from "./generateNavButton";
export * from "./table-helpers";
export * from "./previewFilesNaming";
export * from "./getImageUrl";
export * from "./getVideoUrl";
export * from "./getChainNumber";
export * from "./converteFileToBase64";
export * from "./validators";
export * from "./bread-crumbs";

export const generateAvatar = (userData) => {
  return (
    (userData?.firstName?.[0] ?? "") + (userData?.lastName?.[0] ?? "")
  )?.toUpperCase();
};

export const getDocumentViewType = (name: string): string =>
  name.split(".").pop() === "pdf" ? "PDF_VIEW" : "PICTURE_VIEW";

export const scrollToResults = (ref) => {
  setTimeout(() => {
    const { offsetTop } = ref;
    window.scrollTo({ top: offsetTop - 100, behavior: "smooth" });
  }, 100);
};
