import { createSlice } from "@reduxjs/toolkit";
import { requestsToSystemList } from "dataset";

export interface IRequestListItem {
  id: any;
  type: string;
  title: string;
}
export interface IRequestToSystemListState {
  requestsListLoading: boolean | null;
  requestsToSystemList: Array<IRequestListItem>;
  responseError: any;
  sendRequestLoading: boolean;
  isRequestSend: boolean;
}

const initialState: IRequestToSystemListState = {
  requestsListLoading: null,
  requestsToSystemList: null,
  responseError: {},
  sendRequestLoading: false,
  isRequestSend: false
};

export const mainSlice = createSlice({
  name: "requestToSystemList",
  initialState,
  reducers: {
    fetchRequestsToSystemListRequest: (state) => {
      state.requestsListLoading = true;
    },
    fetchRequestsToSystemListSuccess: (state, { payload }) => {
      state.requestsListLoading = false;
      state.requestsToSystemList = payload;
    },
    fetchRequestsToSystemListError: (state) => {
      state.requestsListLoading = false;
      state.requestsToSystemList = requestsToSystemList;
    },
    fetchSendRequestsToSystem: (state) => {
      state.sendRequestLoading = true;
    },
    fetchSendRequestsToSystemSuccess: (state) => {
      state.sendRequestLoading = false;
      state.isRequestSend = true;
    },
    fetchSendRequestsToSystemError: (state) => {
      state.sendRequestLoading = false;
      state.isRequestSend = false;
    },
    resetSendRequestsToSystemData: (state) => {
      state.sendRequestLoading = false;
      state.isRequestSend = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
