import cn from "classnames";
import React from "react";

import styles from "./checkbox.module.scss";

interface CheckboxInterface {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: any;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

const Checkbox = ({
  checked,
  className,
  name,
  value,
  onChange,
  readOnly,
  disabled
}: CheckboxInterface) => {
  return (
    <label
      className={cn(styles["checkbox"], className, {
        [styles["checkbox--disabled"]]: disabled
      })}
    >
      <input
        className={styles["checkbox__input"]}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
      />
      <p className={styles["checkbox__checkmark"]} />
    </label>
  );
};

export default Checkbox;
