import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const OfficialMainPage = (props: any): JSX.Element => {
  return (
    <Layout title={""} meta={""} headerType="DEFAULT" isOfficial={true}>
      <features.officialDashboard.page {...props} />
    </Layout>
  );
};

export default OfficialMainPage;
