import { takeLatest } from "redux-saga/effects";
import { fetchAnnulledApplications } from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchAnnulled({ payload }) {
  const request = fetchAnnulledApplications;
  const { fetchAnnulledSuccess: setSuccess, fetchAnnulledError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchAnnulledRequest().type, fetchAnnulled);
}
