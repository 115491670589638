import { takeLatest } from "redux-saga/effects";
import { fetchOrderedApplications } from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchOrders({ payload }) {
  const request = fetchOrderedApplications;
  const { fetchOrdersSuccess: setSuccess, fetchOrdersError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchOrdersRequest().type, fetchOrders);
}
