import { createSlice } from "@reduxjs/toolkit";

export interface IPreviousUsageState {
  fetchingDocumentLoading: boolean;
  document: any;
  fetchingPreviousDocumentsLoading?: boolean;
  previousUsedDocuments?: Array<any>;
}

const initialState: IPreviousUsageState = {
  fetchingDocumentLoading: null,
  document: null,
  previousUsedDocuments: [],
  fetchingPreviousDocumentsLoading: null
};

export const mainSlice = createSlice({
  name: "previousUsage",
  initialState,
  reducers: {
    fetchDocumentRequest: (state) => {
      state.fetchingDocumentLoading = true;
    },
    fetchDocumentSuccess: (state, action) => {
      state.fetchingDocumentLoading = false;
      state.document = action.payload;
    },
    fetchDocumentError: (state) => {
      state.fetchingDocumentLoading = false;
      state.document = initialState.document;
    },
    clearDocumentData: (state) => {
      state.document = initialState.document;
      state.fetchingDocumentLoading = initialState.fetchingDocumentLoading;
    },
    fetchPreviousDocumentsRequest: (state) => {
      state.fetchingPreviousDocumentsLoading = true;
    },
    fetchPreviousDocumentsSuccess: (state, action) => {
      state.fetchingPreviousDocumentsLoading = false;
      state.previousUsedDocuments = action.payload;
    },
    fetchPreviousDocumentsError: (state) => {
      state.fetchingPreviousDocumentsLoading = false;
      state.previousUsedDocuments = initialState.previousUsedDocuments;
    },
    setPreviousWasteUsage: (state, { payload }) => {
      state.previousUsedDocuments = [
        ...state.previousUsedDocuments,
        ...payload
      ];
    }
  }
});

export const { actions, reducer } = mainSlice;
