import axios from "axios";
import { API_URL, CABINET_API_URL } from "constant";
import { getToken, getSid } from "helpers";
import { has } from "lodash";

export default function ApiProvider() {
  const token = getSid();
  if (
    ApiProvider.provider &&
    ApiProvider.provider.apiURL === API_URL &&
    ApiProvider.provider.token === token
  ) {
    return ApiProvider.provider;
  }

  const client = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json"
    }
  });

  const clientUpload = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  if (token) {
    client.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    client.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });

    clientUpload.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    clientUpload.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });
  } else {
    client.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });
  }

  ApiProvider.provider = {
    client,
    clientUpload,
    apiURL: API_URL,
    token: token
  };

  return ApiProvider.provider;
}

export function CabinetApiProvider() {
  const token = getToken();
  if (
    CabinetApiProvider.provider &&
    CabinetApiProvider.provider.apiURL === CABINET_API_URL &&
    CabinetApiProvider.provider.token === token
  ) {
    return CabinetApiProvider.provider;
  }

  const client = axios.create({
    baseURL: CABINET_API_URL,
    headers: {
      "Content-Type": "application/json"
    }
  });

  const clientUpload = axios.create({
    baseURL: CABINET_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  if (token) {
    client.interceptors.request.use((config) => {
      config.headers.token = `${token}`;
      return config;
    });

    client.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });

    clientUpload.interceptors.request.use((config) => {
      config.headers.token = `${token}`;
      return config;
    });

    clientUpload.interceptors.response.use((config) => {
      config.data = has(config.data, "data") ? config.data.data : config.data;
      return config;
    });
  }

  CabinetApiProvider.provider = {
    client,
    clientUpload,
    apiURL: API_URL,
    token: token
  };

  return CabinetApiProvider.provider;
}

export const ApiClientAutocompete = (apiPoint) => {
  const token = getSid();

  const client = {
    url: `${API_URL}/${apiPoint}`,
    headers: { "Content-Type": "application/json" },
    formatRes: (res) => res["data"]
  };

  client.headers.Authorization = `Bearer ${token}`;

  return client;
};
