import { takeLatest } from "redux-saga/effects";
import * as API from "services";

import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchDocument({ payload }) {
  const request = API.fetchDocument;
  const { fetchDocumentSuccess: setSuccess, fetchDocumentError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDocumentRequest().type, fetchDocument);
}
