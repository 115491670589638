import cn from "classnames";

import "./status.scss";

export interface StatusInterface {
  title: string;
  color: string;
  background: string;
  className?: string;
}

const Status = ({
  title,
  color,
  background,
  className = ""
}: StatusInterface): JSX.Element => {
  return (
    <h3
      className={cn("status", className)}
      style={{ color: color, backgroundColor: background }}
    >
      {title}
    </h3>
  );
};

export default Status;
