import { takeLatest } from "redux-saga/effects";
import { fetchReceivedApplications } from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchReceived({ payload }) {
  const request = fetchReceivedApplications;
  const { fetchReceivedSuccess: setSuccess, fetchReceivedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchReceivedRequest().type, fetchReceived);
}
