import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* searchArchived({ payload }) {
  const request = API.searchArchivedApplication;
  const { searchArchivedSuccess: setSuccess, searchArchivedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.searchArchivedRequest().type, searchArchived);
}
