import WithNavigationLayout from "components/layouts/WithNavigationLayout/WithNavigationLayout";

import styles from "./certificates-layout.module.scss";

interface CertificatesLayoutInterface {
  children?: any;
  navLinks: Array<{
    text: string;
    to: string;
    customContent?: any;
    customStyles?: string;
  }>;
  title: string;
  subtitle?: string | JSX.Element;
  isOfficial?: boolean;
  navigationClassName?: string;
  navTabsClassName?: string;
  applicationStatus?: string;
}

const CertificatesLayout = ({
  children,
  navLinks,
  title,
  isOfficial,
  subtitle = "",
  navigationClassName,
  navTabsClassName,
  applicationStatus
}: CertificatesLayoutInterface) => {
  const breadcrumbs = [
    {
      text: "Особистий кабінет",
      to: "/personal/applications/drafts"
    },
    {
      text:
        applicationStatus === "draft"
          ? "Заявка на видачу сертифіката"
          : "єСертифікат",
      to: "/"
    }
  ];

  return (
    <WithNavigationLayout
      breadcrumbs={breadcrumbs}
      navLinks={navLinks}
      title={title}
      subtitle={subtitle}
      noLogo={true}
      titleClass={styles["certificate-title"]}
      subtitleClass={styles["certificate-subtitle"]}
      isOfficial={isOfficial}
      className={navigationClassName}
      navTabsClassName={navTabsClassName}
      isDefaultTop
    >
      {children}
    </WithNavigationLayout>
  );
};

export default CertificatesLayout;
