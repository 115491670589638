import { call, put, take } from "redux-saga/effects";
import axios from "axios";
import { buffers, eventChannel, END } from "redux-saga";
import { toastr } from "react-redux-toastr";
import { API_URL } from "constant";
import { getSid } from "helpers";
import { BASIS_DOC_TYPE } from "scenes/subject/documents/interfaces/interfaces";

const createUploadFileChannel = (endpoint, payload) => {
  return eventChannel((emitter) => {
    const formData = new FormData();

    for (var key in payload) {
      formData.append(key, payload[key]);
    }

    const onProgress = (e) => {
      if (e) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        if (percentCompleted !== 100) {
          emitter({ progress: percentCompleted });
        }
      }
    };

    const token = getSid();

    axios
      .post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
        cancelToken: new axios.CancelToken(function executor(cancel) {
          emitter({ cancel });
        }),
        onUploadProgress: onProgress
      })
      .then((res) => {
        emitter({ response: res.data.data });
        emitter(END);
      })
      .catch((error, res) => {
        if (!axios.isCancel(error)) {
          if (error.response?.data) {
            emitter({
              error: `Виникла помилка під час завантаження файлу ${payload.file.path}`
            });
          }

          if (error.response?.data?.errors?.["[file]"]) {
            emitter({
              error:
                error.response?.data?.errors["[file]"][0] ||
                `Виникла помилка під час завантаження файлу ${payload.file.path}`
            });
          }
        }
        emitter(END);
      });

    return () => {};
  }, buffers.sliding(2));
};

function* uploadFile(payload, callbacks, apiRoute, basisDocumentUuid?) {
  const { file } = payload;
  const channel = yield call(
    createUploadFileChannel,
    `${API_URL}/${apiRoute}`,
    payload
  );

  while (true) {
    const { cancel, progress = 0, error, response } = yield take(channel);

    if (error) {
      toastr.error("Помилка", error);
      yield put(
        callbacks.error({
          file,
          error,
          basisDocumentUuid:
            file.alias === BASIS_DOC_TYPE.ZSN_SCANCOPY
              ? basisDocumentUuid
              : undefined
        })
      );
      return;
    }

    if (cancel && callbacks.cancel) {
      yield put(
        callbacks.cancel({
          alias: file.alias,
          fileName: file.name,
          cancel,
          basisDocumentUuid:
            file.alias === BASIS_DOC_TYPE.ZSN_SCANCOPY
              ? basisDocumentUuid
              : undefined
        })
      );
    }

    if (response && response.filePath) {
      yield put(
        callbacks.success({
          ...response,
          alias: file.alias,
          fileName: file.name,
          filePath: response.filePath,
          uuid: response.uuid,
          basisDocumentUuid:
            file.alias === BASIS_DOC_TYPE.ZSN_SCANCOPY
              ? basisDocumentUuid
              : undefined
        })
      );

      return;
    }
    yield put(
      callbacks.progress({
        alias: file.alias,
        fileName: file.name,
        progress,
        basisDocumentUuid:
          file.alias === BASIS_DOC_TYPE.ZSN_SCANCOPY
            ? basisDocumentUuid
            : undefined
      })
    );
  }
}

export default function* uploadFiles(
  payload,
  callbacks,
  apiRoute,
  basisDocumentUuid?
) {
  yield call(uploadFile, payload, callbacks, apiRoute, basisDocumentUuid);
}
