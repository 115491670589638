import { LongArrowIcon } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { IRootState } from "reducer";
import { ILegislativeBaseState } from "../ducks";

import FormLayout from "components/layouts/FormLayout/FormLayout";
import Preloader from "components/Preloader/Preloader";

import styles from "./legislative-base-page.module.scss";

const breadcrumbs = [
  {
    text: "ЕкоСисема",
    to: `/`
  },
  {
    text: "Законодавча база",
    to: "/"
  }
];

const LegislativeBasePage = () => {
  const dispatch = useDispatch();

  const { lawsLoading, laws } = useSelector<IRootState, ILegislativeBaseState>(
    (state) => state.subjectLegislativeBase
  );

  useEffect(() => {
    dispatch(features.legislativeBase.actions.fetchLegislativeBaseRequest());
  }, []);

  const handleLawRedirect = (link: string) => {
    return window.open(link, "_blank");
  };

  return (
    <FormLayout breadcrumbs={breadcrumbs}>
      <h1 className={styles["title"]}>Законодавча база</h1>
      {lawsLoading ? (
        <Preloader />
      ) : (
        <div className={styles["get-certificate__questions"]}>
          {!isEmpty(laws) && (
            <div className={styles["service-items"]}>
              {laws.map(({ title, link }, index) => (
                <div
                  className={styles["service-item"]}
                  key={index}
                  onClick={() => handleLawRedirect(link)}
                >
                  {title}
                  <LongArrowIcon className={styles["service-arrow"]} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </FormLayout>
  );
};

export default LegislativeBasePage;
