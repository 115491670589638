import { createSlice } from "@reduxjs/toolkit";
import { IeDoc, IPagination } from "interfaces";

export interface IDeclinedApplication {
  number?: string;
  uuid: string;
  declinedAt: number;
  applicationId: string;
  orderedAt: number;
  buyer: string;
  consignee: string;
  invoiceNumber: string;
  invoiceAt: number;
  productYieldPercentage: number;
  costStandards: number;
  completionPercentage: number;
  remarksQuantity: number;
  eApplication: IeDoc;
  eDecline: IeDoc;
}

export interface IDeclinedApplications {
  data: Array<IDeclinedApplication>;
  meta: IPagination;
}

export interface IDeclinedApplicationsState {
  fetchingApplicationsLoading: boolean | null;
  applications: IDeclinedApplications;
  responseError: any;
}

const initialState: IDeclinedApplicationsState = {
  fetchingApplicationsLoading: null,
  applications: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "declined",
  initialState,
  reducers: {
    fetchDeclinedRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    fetchDeclinedSuccess: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.applications = action.payload;
    },
    fetchDeclinedError: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.responseError = action.payload;
    },

    clearDeclined: (state) => {
      state.applications = initialState.applications;
    }
  }
});

export const { actions, reducer } = mainSlice;
