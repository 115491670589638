import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, CommonButton, FormField } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";

import features from "features";
import { formatDate, parseResErrors } from "helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { ITrustedDoc } from "scenes/subject/applications/ApplicationPage/interfaces/ITrustedDoc";
import { ICertificateAnnulRequestState } from "scenes/subject/certificates/CertificateAnnulRequestPage/ducks";

import ResponseErrors from "components/ResponseErrors/ResponseErrors";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import Section from "components/Section/Section";
import SectionDoc from "components/Section/SectionDoc/SectionDoc";
import LoadSectionDoc from "components/certificateComponents/createCertificateComponents/LoadSectionDoc/LoadSectionDoc";
import TrustedDoc from "components/certificateComponents/viewCertificateComponents/TrustedDoc/TrustedDoc";

import styles from "./add-trusted-doc.module.scss";

interface IAddTrustedDoc {
  applicationUuid: string;
  isReadonly?: boolean;
  onEdit?: (status: boolean) => void;
  isAnnul?: boolean;
  alreadyUsedDocsNames?: Array<string>;
}

const AddTrustedDoc = ({
  applicationUuid,
  isReadonly,
  onEdit,
  isAnnul,
  alreadyUsedDocsNames
}: IAddTrustedDoc): JSX.Element => {
  const dispatch = useDispatch();
  const { application } = useSelector<
    IRootState,
    IApplicationState | ICertificateAnnulRequestState
  >((state) => (isAnnul ? state.certificateAnnulRequest : state.application));

  const [lockedForm, setLockedForm] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [unknownError, setUnknownError] = useState(null);

  const trustedDoc: ITrustedDoc = useMemo(() => {
    if (application && application.trustedDocData) {
      return application.trustedDocData;
    }

    return null;
  }, [application]);

  const trustedDocFiles = useMemo(() => {
    return application?.files[isAnnul ? "annulmentTrustedDoc" : "trustedDoc"];
  }, [application?.files, isAnnul]);

  useEffect(() => {
    if (application?.trustedDocData) {
      if (!isInitialized && trustedDocFiles?.length !== 0) {
        setLockedForm(true);
        if (onEdit) onEdit(false);
        setIsInitialized(true);
      }
    }
  }, [application, isInitialized, onEdit, trustedDocFiles?.length]);

  const toggleLockedForm = () => {
    setLockedForm((prevState) => {
      if (onEdit) onEdit(prevState);
      return !prevState;
    });
  };

  const handleFormSubmit = (values, { setFieldError }) => {
    setUnknownError(null);
    if (
      trustedDocFiles.length === 0 ||
      trustedDocFiles.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }

    let fields = {
      ...values
    };

    if (isAnnul)
      dispatch(
        features.application.actions.setTrustedDocsRequest({
          fields,
          params: {
            uuid: applicationUuid
          },
          onSuccess: () => {
            toggleLockedForm();
            dispatch(
              features.certificateAnnulRequest.actions.fetchApplicationRequest({
                params: { uuid: applicationUuid }
              })
            );
          },
          onError: (e) =>
            parseResErrors({
              setFieldError,
              errorsRes: e,
              fields: values,
              onUnknownErrors: setUnknownError
            })
        })
      );
    else
      dispatch(
        features.application.actions.setTrustedDocsRequest({
          fields,
          params: {
            uuid: applicationUuid
          },
          onSuccess: () => {
            toggleLockedForm();
            dispatch(
              features.application.actions.fetchApplicationRequest({
                params: { uuid: applicationUuid }
              })
            );
          },
          onError: (e) =>
            parseResErrors({
              setFieldError,
              errorsRes: e,
              fields: values,
              onUnknownErrors: setUnknownError
            })
        })
      );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      ...trustedDoc,
      type: trustedDoc?.type ?? "",
      date: trustedDoc?.date ? new Date(trustedDoc.date) : null,
      dateEnd: trustedDoc?.dateEnd ? new Date(trustedDoc.dateEnd) : null
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Введіть тип документа"),
      date: Yup.date().required("Введіть дату видачі документу"),
      dateEnd: Yup.date().required("Введіть термін дії документу")
    }),
    onSubmit: handleFormSubmit
  });

  const handleFormCancel = useCallback(() => {
    if (
      trustedDocFiles.length === 0 ||
      trustedDocFiles.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }
    formik.setValues(formik.initialValues);
    toggleLockedForm();
  }, [formik]);

  const onDocDateChange = (date: Date, name) => {
    formik.setFieldValue(name, date);
  };

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  return (
    <div className={styles["add-trusted-doc-wrapper"]}>
      {lockedForm ? (
        <TrustedDoc
          {...formik.values}
          isAnnul={isAnnul}
          additionalElements={
            !isReadonly ? (
              <CommonButton
                className={cn(styles["form-btn"], styles["invoice-btn"])}
                outlined={true}
                onClick={toggleLockedForm}
                label="Змінити"
              />
            ) : null
          }
          docs={[
            <SectionDoc
              textItems={[
                {
                  title: "Дата видачі документу",
                  content: formatDate(formik.values["date"]).date
                },
                {
                  title: "Термін дії документу",
                  content: formatDate(formik.values["dateEnd"]).date
                }
              ]}
              textPosition="LEFT"
              loadedFiles={trustedDocFiles}
              onDocumentView={(file) => onDocumentView(file)}
            />
          ]}
        />
      ) : (
        <Section
          title={`Документ, яким підтверджується право представляти інтереси заявника для ${
            isAnnul ? "анулювання" : "отримання"
          } сертифіката`}
          color="WHITE"
          className={styles["add-trusted-doc"]}
        >
          {!isInitialized && (
            <Alert
              className={styles["add-trusted-doс__reminder"]}
              type="warning"
              withIcon
            >
              {
                "Для подачі заявки на видачу сертифіката заповніть наступні обовʼязкові поля: тип документу, дата видачі і термін дії"
              }
            </Alert>
          )}
          <FormikProvider value={formik}>
            <Form className={styles["add-trusted-doc__form"]}>
              <div className={styles["add-trusted-doc__form-info"]}>
                <div className={styles["add-trusted-doc__info-input"]}>
                  <FormField
                    name="type"
                    placeholder="Тип документу"
                    className={styles["add-trusted-doc__info-input-country"]}
                  />
                </div>
                <div className={styles["add-trusted-doc__info-input"]}>
                  <FormField
                    name="series"
                    placeholder="Серія"
                    className={styles["add-trusted-doc__info-input-country"]}
                  />
                </div>
                <div className={styles["add-trusted-doc__info-input"]}>
                  <FormField
                    name="number"
                    placeholder="Номер"
                    className={styles["add-trusted-doc__info-input-country"]}
                  />
                </div>
              </div>
              <div className={styles["add-trusted-doc__form-files"]}>
                <LoadSectionDoc
                  docFileName={
                    isAnnul ? "annulment_trusted_doc" : "trusted_doc"
                  }
                  className={styles["add-trusted-doc__form-file"]}
                  applicationUuid={applicationUuid}
                  loadedFiles={trustedDocFiles}
                  selectedDate={formik.values["date"]}
                  selectedValidityDate={formik.values["dateEnd"]}
                  docFilePlaceholder="Завантажити документ"
                  docDatePlaceholder="Дата видачі документу"
                  docDateValidityPlaceholder="Термін дії документу"
                  onDocDateChange={onDocDateChange}
                  isAnnul={isAnnul}
                  alreadyUsedDocsNames={alreadyUsedDocsNames}
                />
              </div>
              <ResponseErrors errors={unknownError} />
              <div className={styles["form-btns"]}>
                {application?.trustedDocData && (
                  <CommonButton
                    className={styles["form-btn"]}
                    label="Скасувати"
                    onClick={handleFormCancel}
                    outlined
                  />
                )}
                <CommonButton
                  className={styles["form-btn"]}
                  label="Зберегти"
                  type="submit"
                />
              </div>
            </Form>
            <ScrollToFieldError />
          </FormikProvider>
        </Section>
      )}
    </div>
  );
};

export default AddTrustedDoc;
