import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, DatePicker, FormField, Select } from "gov-ua-ui";
import { isEmpty } from "lodash";
import {
  MutableRefObject,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";

import { applicationStatuses } from "dataset";
import features from "features";
import { getLastTimeOfDate } from "helpers/date";
import { IRootState } from "reducer";
import { ISearchApplicationsState } from "scenes/subject/applications/SearchApplicationsPage/ducks";
import { IDraftApplicationState } from "../../DraftApplicationsPage/ducks";

import ApplicationsSearchResult from "components/ApplicationsSearchResult/ApplicationsSearchResult";
import Autocomplete from "components/Autocomplete/Autocomplete";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import PersonalLayout from "components/layouts/PersonalCabinetLayout/PersonalLayout";

import styles from "./search-page.module.scss";

interface ISearchFieldsContext {
  fields?: Object;
  resultsRef?: MutableRefObject<any>;
}
export const SearchFieldsContext = createContext<ISearchFieldsContext>({});

const SearchPage = () => {
  const dispatch = useDispatch();
  const resultsRef = useRef(null);

  const { fetchingSearchApplicationsLoading, applications } = useSelector<
    IRootState,
    ISearchApplicationsState
  >((state) => state.searchApplications);

  const { fetchingApplicationsLoading } = useSelector<
    IRootState,
    IDraftApplicationState
  >((state) => state.draftApplications);

  const [maxDate] = useState(new Date());

  const onFormSubmit = (values: any) => {
    let isNoEmptyForm = true;

    Object.keys(values).forEach((key) => {
      if (
        values[key] &&
        typeof values[key] === "object" &&
        values[key].hasOwnProperty("value")
      ) {
        if (values[key]?.value) {
          isNoEmptyForm = false;
        }
      } else {
        if (values[key]) {
          isNoEmptyForm = false;
        }
      }
    });

    if (isNoEmptyForm) {
      toastr.error("Помилка", "Заповніть хоча б одне поле");
      return;
    }
    const fields = parseFields(values);

    dispatch(
      features.searchApplications.actions.fetchApplicationsRequest({
        fields,
        onSuccess: () => {
          setTimeout(() => {
            const { offsetTop } = resultsRef.current;
            window.scrollTo({ top: offsetTop - 150, behavior: "smooth" });
          }, 100);
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      status: {
        value: "",
        label: ""
      },
      number: "",
      certificateNumber: "",
      dateFrom: null,
      dateTo: null,
      buyer: {
        value: "",
        label: ""
      },
      consignee: {
        value: "",
        label: ""
      }
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      dateTo: Yup.date()
        .nullable()
        .notRequired()
        .when("dateFrom", (dateFrom, schema) => {
          return dateFrom[0]
            ? schema.min(dateFrom, "Дата по не повинна бути більше дати з")
            : schema.notRequired();
        })
    }),
    onSubmit: onFormSubmit
  });

  useEffect(() => {
    return () => {
      dispatch(features.searchApplications.actions.clearApplications());
    };
  }, []);

  const parseFields = (values) => {
    return {
      status: values.status?.value || null,
      number: values.number || null,
      certificateNumber: disabledSearchByCertificateNumber
        ? null
        : values.certificateNumber || null,
      dateFrom: values.dateFrom || null,
      dateTo: getLastTimeOfDate(values.dateTo) || null,
      buyerExternalId: values.buyer?.value || null,
      consigneeExternalId: values.consignee?.value || null
    };
  };

  const statuses = useMemo(() => {
    return Object.keys(applicationStatuses).map((key) => {
      return {
        label: applicationStatuses[key].title,
        value: key
      };
    });
  }, [applicationStatuses]);

  const disabledSearchByCertificateNumber = useMemo(() => {
    return !(
      formik.values["status"].value === "" ||
      formik.values["status"].value === "annulled" ||
      formik.values["status"].value === "received"
    );
  }, [formik.values]);

  const handleStatusChange = (value) => {
    formik.setFieldValue("status", value);
  };

  const handleDateFromChange = (date: Date) => {
    formik.setFieldValue("dateFrom", date);
  };

  const handleDateToChange = (date: Date) => {
    formik.setFieldValue("dateTo", date);
  };

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
  }, []);

  const onClearFormClick = useCallback(() => {
    formik.resetForm();
  }, []);

  return (
    <PersonalLayout>
      <div
        className={cn(styles["personal-cabinet-page"], styles["search-page"])}
      >
        <PreloaderWrapper loading={fetchingSearchApplicationsLoading}>
          <FormikProvider value={formik}>
            <Form>
              <h2 className={styles["search-page__title"]}>Пошук</h2>
              <div className={styles["search-page__content"]}>
                <div className={styles["search-page__content-row"]}>
                  <Select
                    value={formik.values["status"]}
                    options={statuses}
                    placeholder="Статус"
                    onSelectChange={handleStatusChange}
                  />
                  <FormField placeholder="Номер заявки" name="number" />
                  <FormField
                    placeholder="Номер сертифіката (тільки для отриманих і анульованих)"
                    name="certificateNumber"
                    {...(disabledSearchByCertificateNumber
                      ? { disabled: true, value: "" }
                      : {})}
                  />
                </div>
                <div className={styles["search-page__content-row"]}>
                  <DatePicker
                    placeholder="Дата з"
                    name="dateFrom"
                    maxDate={formik.values["dateTo"] ?? maxDate}
                    withFormik
                    date={formik.values["dateFrom"]}
                    onChange={handleDateFromChange}
                  />
                  <DatePicker
                    placeholder="Дата по"
                    name="dateTo"
                    minDate={formik.values["dateFrom"]}
                    maxDate={maxDate}
                    withFormik
                    date={formik.values["dateTo"]}
                    onChange={handleDateToChange}
                  />
                </div>
                <div
                  className={cn(
                    styles["search-page__content-row"],
                    styles["search-page__content-row_large"]
                  )}
                >
                  <Autocomplete
                    name="buyer"
                    placeholder="Покупець"
                    value={formik.values["buyer"]}
                    entity="organizations"
                    onChange={onAutocompleteChange}
                  />
                  <Autocomplete
                    name="consignee"
                    placeholder="Вантажоодержувач"
                    value={formik.values["consignee"]}
                    entity="organizations"
                    onChange={onAutocompleteChange}
                  />
                </div>
                <div className={styles["search-page__content-controls"]}>
                  <CommonButton
                    outlined={true}
                    label="Скинути фільтр"
                    onClick={onClearFormClick}
                  />
                  <CommonButton label="Пошук" type="submit" />
                </div>
              </div>
            </Form>
            <ScrollToFieldError />
          </FormikProvider>
        </PreloaderWrapper>
        <div ref={resultsRef}>
          {!isEmpty(applications) && (
            <SearchFieldsContext.Provider
              value={{ fields: parseFields(formik.values), resultsRef }}
            >
              <ApplicationsSearchResult
                applications={applications}
                fetchingApplicationsLoading={fetchingApplicationsLoading}
              />
            </SearchFieldsContext.Provider>
          )}
        </div>
      </div>
    </PersonalLayout>
  );
};

export default SearchPage;
