import styles from "./official-dashboard-page.module.scss";

interface CardInterface {
  title: string;
  titleImage?: any;
  contentImage?: any;
  count: number;
  countClass?: string;
}

const StatusCard = ({
  title,
  titleImage,
  contentImage,
  count,
  countClass
}: CardInterface): JSX.Element => {
  return (
    <div className={styles["card__wrapper"]}>
      <div className={styles["card__header"]}>
        <h4>{title}</h4>
        <img src={titleImage} alt="" />
      </div>
      <div className={styles["card__content"]}>
        <img src={contentImage} alt="today application smile" />
        <h1 className={styles[`card__${countClass}`]}>{count}</h1>
      </div>
    </div>
  );
};

export default StatusCard;
