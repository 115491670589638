import { createSlice } from "@reduxjs/toolkit";
import { IeDoc, IPagination } from "interfaces";

export interface IFoundApplication {
  number: string;
  date: number;
  invoiceNumber: any;
  uuid: string;
  status: string;
  buyer: string;
  consignee: string;
  customsClearance?: boolean;
  customClearanceAmount?: string;
  eApplication?: IeDoc;
  eCertificate?: IeDoc;
  annulmentIsOrderedByOfficial?: any;
  annulmentIsOrderedByExporter?: any;
}

export interface IFoundApplications {
  data: Array<IFoundApplication>;
  meta: IPagination;
}

export interface ISearchApplicationsState {
  fetchingSearchApplicationsLoading: boolean;
  applications: IFoundApplications;
}

const initialState: ISearchApplicationsState = {
  fetchingSearchApplicationsLoading: null,
  applications: null
};

export const mainSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    fetchApplicationsRequest: (state) => {
      state.fetchingSearchApplicationsLoading = true;
    },
    fetchApplicationsSuccess: (state, action) => {
      state.fetchingSearchApplicationsLoading = false;
      state.applications = action.payload;
    },
    fetchApplicationsError: (state) => {
      state.fetchingSearchApplicationsLoading = false;
    },
    clearApplications: (state) => {
      state.applications = initialState.applications;
    }
  }
});

export const { actions, reducer } = mainSlice;
