import { createSlice } from "@reduxjs/toolkit";
import { IApplication, IFile, IOrganization } from "./interfaces";
import isEmpty from "lodash/isEmpty";
import { parseResErrors } from "helpers";
import { ITrustedDoc } from "./interfaces/ITrustedDoc";
import { snakeToCamel } from "helpers/snakeToCamel";

export interface IApplyingApplicationError {
  invoice?: Array<string>;
  files?: Array<string>;
  products?: Array<string>;
  applicationBasisDocuments?: Array<string>;
  businessEdrpou?: Array<string>;
  trustedDocData?: Array<string>;
  unknown?: Array<string>;
}

export interface IApplicationState {
  creatingApplicationLoading: boolean;
  fetchingApplicationLoading: boolean;
  fetchingApplicationError: any;
  fetchingSelectedOrganizationsListLoading: boolean;
  settingApplicationTypeLoading: boolean;
  settingApplicationTypeError: any;
  settingApplicationInvoiceLoading: boolean;
  settingApplicationTrustedDocsLoading: boolean;
  settingApplicationInvoiceError: any;
  settingProductsLoading: boolean;
  settingProductsError: any;
  deletingApplicationDocumentLoading: boolean;
  application: IApplication;
  invoiceFiles: Array<IFile>;
  selectedOrganizationsList: Array<IOrganization>;
  applyingApplicationLoading: boolean;
  applyingApplicationError: IApplyingApplicationError;
  eApplication?: string;
  trustedDocData: ITrustedDoc;
  downloadBasicsExcelLoading: boolean;
  downloadBasicsExcelError: any;
}

const initApplyApplicationError: IApplyingApplicationError = {
  invoice: null,
  files: null,
  products: null,
  applicationBasisDocuments: null,
  businessEdrpou: null,
  trustedDocData: null
};

const initialState: IApplicationState = {
  creatingApplicationLoading: null,
  fetchingApplicationLoading: null,
  fetchingApplicationError: null,
  fetchingSelectedOrganizationsListLoading: null,
  settingApplicationTypeLoading: null,
  settingApplicationInvoiceLoading: null,
  deletingApplicationDocumentLoading: false,
  application: null,
  invoiceFiles: [],
  settingApplicationTypeError: null,
  settingApplicationInvoiceError: null,
  selectedOrganizationsList: [],
  settingProductsLoading: null,
  settingProductsError: null,
  applyingApplicationLoading: null,
  applyingApplicationError: initApplyApplicationError,
  eApplication: null,
  trustedDocData: null,
  settingApplicationTrustedDocsLoading: false,
  downloadBasicsExcelLoading: false,
  downloadBasicsExcelError: null
};

export const mainSlice = createSlice({
  name: "creatingApplication",
  initialState,
  reducers: {
    createApplicationRequest: (state) => {
      state.creatingApplicationLoading = true;
    },
    createApplicationSuccess: (state) => {
      state.creatingApplicationLoading = false;
    },
    createApplicationError: (state) => {
      state.creatingApplicationLoading = false;
    },

    fetchApplicationRequest: (state) => {
      state.fetchingApplicationLoading = true;
    },
    fetchApplicationSuccess: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.application = {
        ...action.payload,
        files: isEmpty(action.payload.files)
          ? {
              additional: [],
              eCertificate: [],
              eApplication: [],
              eDecline: [],
              trustedDoc: []
            }
          : action.payload.files,
        remarksForUser: undefined,
        remarks: action.payload.remarksForUser?.data
      };
    },
    fetchApplicationError: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.fetchingApplicationError = action.payload;
    },
    clearApplication: (state) => {
      state.application = initialState.application;
      state.applyingApplicationError = initialState.applyingApplicationError;
      state.fetchingApplicationError = initialState.fetchingApplicationError;
    },
    clearApplicationError: (state) => {
      state.applyingApplicationError = initialState.applyingApplicationError;
      state.fetchingApplicationError = initialState.fetchingApplicationError;
    },

    setApplicationTypeRequest: (state) => {
      state.settingApplicationTypeLoading = true;
    },
    setApplicationTypeSuccess: (state, action) => {
      state.settingApplicationTypeLoading = false;
      state.application.useWoodCard = action.payload.useWoodCard;
      state.application.basisDocuments = [];
    },
    setApplicationTypeError: (state) => {
      state.settingApplicationTypeLoading = false;
    },

    loadInvoiceFileRequest: (state, action) => {
      const replaceUuid = action.payload.params.replaceUuid;
      const fileType = action.payload.fields.fileType;
      const fileCustomType = snakeToCamel(fileType);

      const fileData = {
        name: action.payload?.fields?.file.name,
        progress: 0,
        size: action.payload?.fields?.file.size,
        createdAt: action.payload?.fields?.file.createdAt,
        type: fileType
      };

      if (replaceUuid) {
        state.application.files[fileCustomType] = state.application.files[
          fileCustomType
        ].map((file) => (file.uuid === replaceUuid ? fileData : file));
      } else {
        state.application.files[fileCustomType] = state.application.files[
          fileCustomType
        ].concat([fileData]);
      }
    },
    loadInvoiceFileSuccess: (state, action) => {
      const fileCustomType = snakeToCamel(action.payload.alias);

      state.application.files[fileCustomType] = state.application.files[
        fileCustomType
      ].map((item) => {
        if (item.name === action.payload.fileName) {
          return {
            ...item,
            filePath: action.payload.filePath,
            uuid: action.payload.uuid,
            progress: 100,
            cancel: null
          };
        }
        return item;
      });
    },
    loadInvoiceFileError: () => {},
    loadInvoiceFileProgress: (state, action) => {
      const fileCustomType = snakeToCamel(action.payload.alias);

      state.application.files[fileCustomType] = state.application.files[
        fileCustomType
      ].map((item) => {
        if (item.name === action.payload.fileName) {
          return {
            ...item,
            progress: action.payload.progress
          };
        }
        return item;
      });
    },
    saveLoadInvoiceFileCancelHandler: (state, action) => {
      const fileCustomType = snakeToCamel(action.payload.alias);

      state.application.files[fileCustomType] = state.application.files[
        fileCustomType
      ].map((item) => {
        if (item.name === action.payload.fileName) {
          return {
            ...item,
            cancel: action.payload.cancel
          };
        }
        return item;
      });
    },

    deleteInvoiceFileRequest: (state, action) => {
      const fileCustomType = snakeToCamel(action.payload.type);
      if (!action.payload.params.uuid && action.payload.name) {
        state.application.files[fileCustomType] = state.application.files[
          fileCustomType
        ].filter((item) => {
          return item.name !== action.payload.name;
        });
      }
    },
    deleteInvoiceFileSuccess: (state, action) => {
      const fileCustomType = snakeToCamel(action.payload.type);

      state.application.files[fileCustomType] = state.application.files[
        fileCustomType
      ].filter((item) => {
        return item.uuid !== action.payload.uuid;
      });
    },
    deleteInvoiceFileError: () => {},

    setInvoiceRequest: (state) => {
      state.settingApplicationInvoiceLoading = true;
    },
    setInvoiceSuccess: (state) => {
      state.applyingApplicationError.invoice = null;
      state.settingApplicationInvoiceLoading = false;
    },
    setInvoiceError: (state) => {
      state.settingApplicationInvoiceLoading = false;
    },

    setTrustedDocsRequest: (state) => {
      state.settingApplicationTrustedDocsLoading = true;
    },
    setTrustedDocsSuccess: (state) => {
      state.applyingApplicationError.trustedDocData = null;
      state.settingApplicationTrustedDocsLoading = false;
    },
    setTrustedDocsError: (state) => {
      state.settingApplicationTrustedDocsLoading = false;
    },

    fetchSelectedOrganizationsListRequest: (state) => {
      state.fetchingSelectedOrganizationsListLoading = true;
    },
    fetchSelectedOrganizationsListSuccess: (state, action) => {
      state.fetchingSelectedOrganizationsListLoading = false;
      state.selectedOrganizationsList = state.selectedOrganizationsList.concat(
        action.payload
      );
    },
    fetchSelectedOrganizationsListError: (state) => {
      state.fetchingSelectedOrganizationsListLoading = false;
    },
    clearSelectedOrganizationsList: (state) => {
      state.selectedOrganizationsList = initialState.selectedOrganizationsList;
    },

    setProductsRequest: (state) => {
      state.settingProductsLoading = true;
    },
    setProductsSuccess: (state) => {
      state.applyingApplicationError.products = null;
      state.settingProductsLoading = false;
    },
    setProductsError: (state, action) => {
      state.settingProductsLoading = false;
      state.settingProductsError = action.payload;
    },

    deleteBasisDocumentRequest: (state) => {
      state.deletingApplicationDocumentLoading = true;
    },
    deleteBasisDocumentSuccess: (state) => {
      state.deletingApplicationDocumentLoading = false;
      state.application = { ...state.application, basisDocuments: [] };
    },
    deleteBasisDocumentError: (state) => {
      state.deletingApplicationDocumentLoading = false;
    },

    deleteAllBasisDocumentsRequest: (state) => {
      state.deletingApplicationDocumentLoading = true;
    },
    deleteAllBasisDocumentsSuccess: (state) => {
      state.deletingApplicationDocumentLoading = false;
      state.application.basisDocuments = [];
    },
    deleteAllBasisDocumentsError: (state) => {
      state.deletingApplicationDocumentLoading = false;
    },

    extendApplicationTimerRequest: (state) => {
      state.application = initialState.application;
      state.fetchingApplicationLoading = true;
    },
    extendApplicationTimerSuccess: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.application = action.payload;
    },
    extendApplicationTimerError: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.fetchingApplicationError = action.payload;
    },

    fetchEApplicationDataRequest: (state) => {
      state.applyingApplicationLoading = true;
    },
    fetchEApplicationDataSuccess: (state, action) => {
      state.applyingApplicationLoading = false;
      state.eApplication = action.payload.url;
    },
    fetchEApplicationDataError: (state, action) => {
      state.applyingApplicationLoading = false;
      parseResErrors({
        setFieldError: (property, value) =>
          (state.applyingApplicationError[property] = value),
        onUnknownErrors: (errors) =>
          (state.applyingApplicationError.unknown = errors),
        errorsRes: action.payload,
        fields: initApplyApplicationError
      });
    },

    applyApplicationRequest: (state) => {
      state.applyingApplicationError = initApplyApplicationError;
      state.applyingApplicationLoading = true;
    },
    applyApplicationSuccess: (state) => {
      state.applyingApplicationLoading = false;
    },
    applyApplicationError: (state, action) => {
      state.applyingApplicationLoading = false;

      parseResErrors({
        setFieldError: (property, value) =>
          (state.applyingApplicationError[property] = value),
        onUnknownErrors: (errors) =>
          (state.applyingApplicationError.unknown = errors),
        errorsRes: action.payload,
        fields: initApplyApplicationError
      });
    },
    downloadBasicDocumentsExcelRequest: (state) => {
      state.downloadBasicsExcelLoading = true;
    },
    downloadBasicDocumentsExcelSuccess: (state) => {
      state.downloadBasicsExcelLoading = false;
    },
    downloadBasicDocumentsExcelError: (state, action) => {
      state.downloadBasicsExcelLoading = false;
      state.downloadBasicsExcelError = action.payload;
    }
  }
});

export const { actions, reducer } = mainSlice;
