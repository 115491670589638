export interface iGenerateNavButton {
  to: string;
  title: string;
  icon?: string;
  count?: number;
  imgClass?: string;
  btnClass?: string;
  indicatorClass?: string;
  btnIndicatorClass?: string;
}

export const generateNavButton = ({
  to,
  title,
  icon,
  count,
  imgClass,
  btnClass,
  indicatorClass,
  btnIndicatorClass
}: iGenerateNavButton) => {
  return {
    text: "",
    to,
    anchor: true,
    customContent: (
      <>
        {title}
        {icon && <img src={icon} alt={title} className={imgClass} />}
        {count > 0 && <div className={indicatorClass}>{count}</div>}
      </>
    ),
    customStyles: count != null ? btnIndicatorClass : btnClass
  };
};
