export const getChainNumber = (basisDocuments) => {
  if (!basisDocuments) return 1;

  const notClosedDocument = basisDocuments?.find(
    (item) => !item.chainChecked && item.primal
  );
  if (notClosedDocument) return notClosedDocument.chainNumber;

  const chainCount = basisDocuments?.filter((item) => item.chainChecked).length;
  return chainCount + 1;
};
