import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { LinkInterface } from "components/NavigationHead/NavigationHead";

import styles from "./form-layout.module.scss";

interface FormLayoutInterface {
  breadcrumbs: Array<LinkInterface>;
  children: Array<JSX.Element> | JSX.Element;
}

const FormLayout = ({
  children,
  breadcrumbs
}: FormLayoutInterface): JSX.Element => {
  return (
    <main className={styles["form-layout"]}>
      <Breadcrumbs
        className={styles["navigation__breadcrumbs"]}
        elements={breadcrumbs}
      />
      {children}
    </main>
  );
};

export default FormLayout;
