import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const OfficialAnnulledApplicationsForSigningPage = (
  props: any
): JSX.Element => {
  return (
    <Layout title={""} meta={""} headerType="DEFAULT" isOfficial={true}>
      <features.officialAnnulledApplicationsForSigning.page {...props} />
    </Layout>
  );
};

export default OfficialAnnulledApplicationsForSigningPage;
