import HumanIcon from "assets/images/icons/human.svg";

import styles from "./blank-page.module.scss";

interface BlankPageInterface {
  title: string;
}

const BlankPage = ({ title }: BlankPageInterface): JSX.Element => {
  return (
    <section className={styles["blank-page"]}>
      <img
        src={HumanIcon}
        alt={title}
        className={styles["blank-page__image"]}
      />
      <h2 className={styles["blank-page__title"]}>{title}</h2>
    </section>
  );
};

export default BlankPage;
