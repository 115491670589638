import { createSlice } from "@reduxjs/toolkit";
import { IeDoc, IPagination } from "interfaces";

export interface IOrderedApplication {
  isProcessing?: boolean;
  uuid: string;
  beProcessedUntil: number;
  number: number;
  orderedAt: number;
  buyer: string;
  consignee: string;
  invoiceNumber: number;
  invoiceAt: number;
  productYieldPercentage: number;
  costStandards: number;
  eApplication: IeDoc;
}

export interface IOrderedApplications {
  data: Array<IOrderedApplication>;
  meta: IPagination;
}

export interface IOrderedApplicationsState {
  fetchingApplicationsLoading: boolean | null;
  applications: IOrderedApplications;
  responseError: any;
}

const initialState: IOrderedApplicationsState = {
  fetchingApplicationsLoading: null,
  applications: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    fetchOrdersRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    fetchOrdersSuccess: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.applications = action.payload;
    },
    fetchOrdersError: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.responseError = action.payload;
    },

    clearOrders: (state) => {
      state.applications = initialState.applications;
    }
  }
});

export const { actions, reducer } = mainSlice;
