import cn from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IRootState } from "reducer";

import { getRole } from "helpers";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { IDashboardState } from "scenes/official/OfficialDashboardPage/ducks";

import AvatarIcon from "assets/images/icons/avatar.svg";

import styles from "./official-office-navigation.module.scss";

const OfficialOfficeNavigation = (): JSX.Element => {
  const { statistics } = useSelector<IRootState, IDashboardState>(
    (state) => state.officialDashboard
  );
  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const [userRole] = useState(getRole());

  const linkStyles = ({ isActive }: any) => {
    return cn(styles["navigation__tab"], {
      [styles["navigation__tab_active"]]: isActive
    });
  };

  return (
    <section className={styles["navigation"]}>
      <div className={styles["navigation__office-status"]}>
        <h4>Кабінет чиновника</h4>
      </div>

      <div className={styles["navigation-header"]}>
        <div className={styles["navigation-header__image"]}>
          <img src={AvatarIcon} alt="avatar" />
        </div>
        <div className={styles["navigation-header__text-wrapper"]}>
          <h1 className={styles["navigation-header__title"]}>
            {`Вітаємо, ${currentSubject?.fullName || ""}`}
          </h1>
        </div>
      </div>

      <nav className={styles["navigation__tabs-wrapper"]} role="navigation">
        <ul className={styles["navigation__tabs"]}>
          <NavLink to="/official-main" className={linkStyles}>
            Головна
          </NavLink>
          <NavLink to="/official-in-progress" className={linkStyles}>
            В процесі
            {statistics?.inProcessCount > 0 && (
              <span className={styles["navigation__tab--bullet"]}>
                {statistics.inProcessCount}
              </span>
            )}
          </NavLink>
          <NavLink to="/official-archival" className={linkStyles}>
            Архівні
          </NavLink>
          {userRole === "headOfficial" && (
            <NavLink to="/official-annul/annulled" className={linkStyles}>
              Анулювання 60 днів
              {statistics?.annulledForSignCount > 0 && (
                <span className={styles["navigation__tab--bullet"]}>
                  {statistics.annulledForSignCount}
                </span>
              )}
            </NavLink>
          )}
          <NavLink to="/official-annul/declined" className={linkStyles}>
            Відмовлені на підпис
            {statistics?.annulmentDeclinesCount > 0 && (
              <span className={styles["navigation__tab--bullet"]}>
                {statistics.annulmentDeclinesCount}
              </span>
            )}
          </NavLink>
        </ul>
      </nav>
    </section>
  );
};

export default OfficialOfficeNavigation;
