import { CommonButton, Status } from "gov-ua-ui";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { docTypes } from "constant";
import { customsClearance } from "dataset";
import features from "features";
import { formatDate } from "helpers";

import EDocButton from "components/buttons/EDocButton/EDocButton";
import DeclinedSignDescription from "../DeclinedSignDescription/DeclinedSignDescription";

import styles from "./declined-application-for-signing.module.scss";

const DeclinedApplicationForSigning = ({ application }) => {
  const dispatch = useDispatch();
  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  const goToSigning = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          details: <DeclinedSignDescription uuid={application.uuid} />,
          hideControls: true
        }
      })
    );
  }, [application.uuid, dispatch]);

  return (
    <div className={styles["container"]}>
      <div className={styles["container__title"]}>
        Заява на анулювання сертифіката
      </div>
      <div className={styles["container__info"]}>
        <div className={styles["info"]}>
          <p className={styles["info__title"]}>Номер заяви</p>
          <p className={styles["info__value"]}>
            {application.annulmentApplicationNumber}
          </p>
        </div>
        <div className={styles["info-long"]}>
          <p className={styles["info__title"]}>Контрагент</p>
          <p className={styles["info__value"]}>{application.exporterName}</p>
        </div>
        <div className={styles["info-long"]}>
          <p className={styles["info__title"]}>Покупець</p>
          <p className={styles["info__value"]}>{application.buyerName}</p>
        </div>
        <div className={styles["info"]}>
          <p className={styles["info__title"]}>Дата видачі</p>
          <p className={styles["info__value"]}>
            {formatDate(application.createdAt).date}
          </p>
        </div>
        <div className={styles["info"]}>
          <p className={styles["info__title"]}>Митне оформлення</p>
          <p className={styles["info__value"]}>
            <Status
              className="nowrap"
              {...customsClearance[application.customsClearance]}
            />
          </p>
        </div>
        <div className={styles["info"]}>
          <p className={styles["info__title"]}>Заява про анулювання</p>
          <EDocButton
            title={application.eAnnulmentApplication.title}
            contentTitle={application.eAnnulmentApplication.name}
            contentSubtitle={application.eAnnulmentApplication.serialNumber}
            contentText={application.eAnnulmentApplication.date}
            type={docTypes.APPLICATION}
            onClick={() => onDocumentView(application.eAnnulmentApplication)}
          />
        </div>
      </div>
      <div className={styles["container__info"]}>
        <div className={styles["info"]}>
          <p className={styles["info__title"]}>Причина відмови</p>
          <p className={styles["info__value"]}>
            {application.pzDeclineDescription}
          </p>
        </div>
      </div>
      <CommonButton label={"Підписати відмову"} onClick={goToSigning} />
    </div>
  );
};

export default DeclinedApplicationForSigning;
