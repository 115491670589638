import { AnswerButton } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { IRemark } from "interfaces";
import { IRootState } from "reducer";
import { IApplicationReviewState } from "scenes/official/certificates/OfficialApplicationReviewPage/ducks";

import SmallControls from "../SmallControls/SmallControls";

import styles from "./official-controls.module.scss";

interface OfficialControlsInterface {
  remark?: IRemark;
  blockType?: string;
  isSmall?: boolean;
}

const OfficialControls = ({
  remark,
  isSmall,
  blockType
}: OfficialControlsInterface): JSX.Element => {
  const { application } = useSelector<IRootState, IApplicationReviewState>(
    (state) => state.officialApplicationReview
  );
  const dispatch = useDispatch();

  const onDecline = () => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "ADD_REMARK",
        modalProps: {
          title: "Опишіть, що неправильно в цьому блоці",
          notificationText:
            "Ви вказали, що в цьому блоці є помилка. Будь ласка, коротко опишіть в чому помилка, щоб заявник зміг її виправити",
          onAccept: addRemark,
          remark: remark,
          blockType
        }
      })
    );
  };

  const onAccept = () => addRemark({ correct: true });

  const addRemark = (remarkData) => {
    dispatch(
      features.officialApplicationReview.actions.addApplicationRemarkRequest({
        params: {
          applicationUuid: application.uuid,
          remarkUuid: remark.uuid
        },
        fields: { ...remarkData, blockType }
      })
    );
  };

  return isSmall ? (
    <SmallControls
      onAccept={onAccept}
      onDecline={onDecline}
      isAccepted={remark?.correct}
    />
  ) : (
    <div className={styles["controls-container"]}>
      <AnswerButton
        cancel
        label="Не правильно"
        onClick={onDecline}
        hoverEyeIcon={remark?.correct === false}
        selected={remark?.correct === false}
        round={remark?.correct === false}
      />
      <AnswerButton
        label="Правильно"
        onClick={remark?.correct === true ? null : onAccept}
        selected={remark?.correct === true}
        round={remark?.correct === true}
      />
    </div>
  );
};

export default OfficialControls;
