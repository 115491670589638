import cn from "classnames";
import { useEffect, useState } from "react";

import styles from "./clock.module.scss";

interface ClockInterface {
  initialTime: Date;
  className?: string;
}

const Clock = ({ initialTime, className = "" }: ClockInterface) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const setTime = () => {
    const time = initialTime.getTime() + new Date().getTime();

    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => setTime(), 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className={cn(styles["clock"], className)}>
      <time
        dateTime={`${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`}
      >
        <p className={styles["clock__hours"]}>
          {hours < 10 ? "0" + hours : hours}
        </p>
        <span className={styles["clock__time-divider"]}>:</span>
        <p className={styles["clock__minutes"]}>
          {minutes < 10 ? "0" + minutes : minutes}
        </p>
        <span className={styles["clock__time-divider"]}>:</span>
        <p className={styles["clock__seconds"]}>
          {seconds < 10 ? "0" + seconds : seconds}
        </p>
      </time>
    </div>
  );
};

export default Clock;
