const addDummyZero = (value) => {
  if (value < 10) {
    return `0${value}`;
  }

  return value;
};

export const formatDate = (timestamp) => {
  if (!timestamp) return { date: "", time: "" };

  const date = new Date(timestamp);

  const month = addDummyZero(date.getMonth() + 1);
  const year = addDummyZero(date.getFullYear());
  const day = addDummyZero(date.getDate());
  const hours = addDummyZero(date.getHours());
  const minutes = addDummyZero(date.getMinutes());

  return {
    date: `${day || "-"}.${month || "-"}.${year || "-"}`,
    time: `${hours || "-"}:${minutes || "-"}`
  };
};

export const getLastTimeOfDate = (date) => {
  if (!date) return date;
  const newDateTime = new Date(date);
  newDateTime.setUTCHours(23);
  newDateTime.setUTCMinutes(59);
  newDateTime.setUTCSeconds(59);
  newDateTime.setUTCMilliseconds(999);
  return newDateTime;
};

export const formatTime = (milli) => {
  let time = new Date(milli);
  let hours = time.getUTCHours();
  let minutes = time.getUTCMinutes();
  let seconds = time.getUTCSeconds();
  let milliseconds = time.getUTCMilliseconds();

  return {
    hours,
    minutes,
    seconds,
    milliseconds
  };
};
