import { createSlice } from "@reduxjs/toolkit";
import { parseResErrors } from "helpers/parseResErrors";
import { IApplication } from "interfaces";

export interface IApplicationCertificateError {
  unknown?: string[];
}

export interface IApplicationReviewState {
  fetchingApplicationLoading: boolean;
  addingRemarkLoading?: string;
  application: IApplication;
  fetchingApplicationError: any;
  applicationCertificate?: string;
  fetchingApplicationCertificateLoading: boolean;
  fetchingApplicationCertificateError: IApplicationCertificateError;
  applicationAnnulEDeclineUrl?: string;
  fetchingApplicationAnnulEDeclineLoading: boolean;
  declineDescription?: string;
}

const initApplicationCertificateError: IApplicationCertificateError = {
  unknown: null
};

const initialState: IApplicationReviewState = {
  fetchingApplicationLoading: null,
  addingRemarkLoading: null,
  application: null,
  fetchingApplicationError: null,
  applicationCertificate: null,
  fetchingApplicationCertificateLoading: false,
  fetchingApplicationCertificateError: initApplicationCertificateError,
  applicationAnnulEDeclineUrl: null,
  fetchingApplicationAnnulEDeclineLoading: false,
  declineDescription: ""
};

export const mainSlice = createSlice({
  name: "officialApplicationReview",
  initialState,
  reducers: {
    fetchApplicationRequest: (state, ) => {
      state.fetchingApplicationLoading = true;
    },
    fetchApplicationSuccess: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.application = {
        ...action.payload,
        remarksForUser: action.payload.remarksForUser?.data,
        files: {
          ...action.payload.files,
          ...action.payload.annulmentApplicationFiles
        }
      };
    },
    fetchApplicationError: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.fetchingApplicationError = action.payload;
    },
    clearApplication: (state, ) => {
      state.application = initialState.application;
      state.fetchingApplicationError = initialState.fetchingApplicationError;
    },

    addApplicationRemarkRequest: (state, action) => {
      state.addingRemarkLoading = action.payload.fields.blockType;
    },
    addApplicationRemarkSuccess: (state, action) => {
      state.application.checkedPercent = action.payload.checkedPercent;
      if ("application_basis_document" === state.addingRemarkLoading) {
        if (state.application.woodCards?.length > 0) {
          state.application.woodCards = state.application.woodCards.map(
            (document) => {
              return document["remark"].uuid === action.payload.uuid
                ? { ...document, remark: action.payload }
                : document;
            }
          );
        } else {
          state.application.basisDocuments =
            state.application.basisDocuments.map((document) => {
              return document["remark"].uuid === action.payload.uuid
                ? { ...document, remark: action.payload }
                : document;
            });
        }
      } else {
        state.application.remarks[state.addingRemarkLoading] = action.payload;
      }
      state.addingRemarkLoading = null;
    },

    addApplicationRemarkError: (state, ) => {
      state.addingRemarkLoading = null;
    },

    acceptCertificateDecisionRequest: () => {},
    acceptCertificateDecisionSuccess: () => {},
    acceptCertificateDecisionError: () => {},

    fetchCertificateAnnulDeclineRequest: (state, { payload }) => {
      state.fetchingApplicationAnnulEDeclineLoading = true;
      state.declineDescription = payload.fields.declineDescription;
    },
    fetchCertificateAnnulDeclineSuccess: (state, { payload }) => {
      state.fetchingApplicationAnnulEDeclineLoading = false;
      state.applicationAnnulEDeclineUrl = payload.url;
    },
    fetchCertificateAnnulDeclineError: (state, { payload }) => {
      parseResErrors({
        onUnknownErrors: (errors) =>
          (state.fetchingApplicationCertificateError.unknown = errors),
        errorsRes: payload
      });
      state.fetchingApplicationAnnulEDeclineLoading = false;
    },

    acceptCertificateAnnulDecisionRequest: () => {},
    acceptCertificateAnnulDecisionSuccess: () => {},
    acceptCertificateAnnulDecisionError: () => {},

    fetchApplicationCertificateRequest: (state) => {
      state.applicationCertificate = initialState.applicationCertificate;
      state.fetchingApplicationCertificateLoading = true;
    },
    fetchApplicationCertificateSuccess: (state, action) => {
      state.applicationCertificate = action.payload.url;
      state.fetchingApplicationCertificateLoading = false;
    },
    fetchApplicationCertificateError: (state, action) => {
      state.applicationCertificate = initialState.applicationCertificate;
      parseResErrors({
        onUnknownErrors: (errors) =>
          (state.fetchingApplicationCertificateError.unknown = errors),
        errorsRes: action.payload
      });
      state.fetchingApplicationCertificateLoading = false;
    },
    clearApplicationCertificateData: (state) => {
      state.fetchingApplicationCertificateError.unknown = null;
      state.applicationCertificate = null;
      state.fetchingApplicationCertificateLoading = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
