import {
  Cell,
  Header,
  HeaderGroup,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import cn from "classnames";
import { formatDate } from "helpers";
import { IBasisDoc } from "interfaces";

import styles from "./children-table.module.scss";

interface ITable extends IBasisDoc {
  amount: number;
  docs: string;
  freightCarNumber: string;
  takenQuantity: number;
  quantityOrigin: number;
}

const ChildrenTable = ({ data }) => {
  const basicDocsColumnsHelper = createColumnHelper<ITable>();

  const columns = [
    basicDocsColumnsHelper.accessor("supportDocumentTypeName", {
      cell: (info: any) => info.getValue(),
      header: () => "Тип"
    }),
    basicDocsColumnsHelper.accessor("series", {
      cell: (info: any) => info.getValue(),
      header: () => "Серія"
    }),
    basicDocsColumnsHelper.accessor("number", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер"
    }),
    basicDocsColumnsHelper.accessor("freightCarNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Вагон"
    }),
    basicDocsColumnsHelper.accessor("date", {
      cell: (info: any) => formatDate(info.getValue()).date,
      header: () => "Дата"
    }),
    basicDocsColumnsHelper.accessor("quantityOrigin", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість за ЗСН"
    }),
    basicDocsColumnsHelper.accessor("takenQuantity", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість списано"
    })
  ];
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={styles["children-container"]}>
      {table.getRowModel().rows.map((row: Row<any>, index: number) => (
        <table
          className={cn(styles["children-container__table"], {
            [styles["children-container__table_last-item"]]:
              index === table.getRowModel().rows.length - 1
          })}
          key={index}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: Header<any, unknown>) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              {row
                .getVisibleCells()
                .map((cell: Cell<any, unknown>, index: number) => {
                  return (
                    <td
                      key={index}
                      className={cn({
                        [styles["children-container__doc-cell"]]:
                          table.options.columns[index].meta?.docCell,
                        [styles["children-container__button-cell"]]:
                          table.options.columns[index].meta?.buttonCell
                      })}
                    >
                      {table.options.columns[index].meta?.isOverflowEllipses ? (
                        <div
                          className={
                            styles["children-container__overflow-ellipses"]
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );
};
export default ChildrenTable;
