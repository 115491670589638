import {
  Cell,
  ExpandedState,
  GroupingState,
  Header,
  HeaderGroup,
  Row,
  RowSelectionState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";
import cn from "classnames";
import { FieldArray } from "formik";
import { Autocomplete, FormField, Select } from "gov-ua-ui";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { PDV_PERCENT } from "constant";
import features from "features";
import { addNumbers, formatNumber } from "helpers";
import { ApiClientAutocompete } from "services/API";

import IconButton from "components/buttons/IconButton/IconButton";
import UKTZED from "./UKTZED";

import CopyIcon from "assets/images/icons/copy.svg";
import CreateIcon from "assets/images/icons/create.svg";
import MinusIcon from "assets/images/icons/minus.svg";
import PlusIcon from "assets/images/icons/plus.svg";

import "./editable-table-selects.scss";
import styles from "./editable-table.module.scss";

interface EditableTableInterface {
  formik?: any;
  defaultData?: any;
  columns: any;
  pagination?: boolean;
  itemsPerPage?: number;
  onRowsSelected?: (items: object, isWaste: boolean) => void;
  onCopyRowsClick?: (items: object) => void;
  onCreateRowClick?: () => void;
  onCustomButtonClick?: () => void;
  footerTitle?: string;
  footer?: Array<{
    name: string;
    label: string;
    value?: string | number;
  }>;
  removeControls?: boolean;
  groupData?: boolean;
  withPDV?: boolean;
  readonlyNotSelected?: boolean;
  isWaste?: boolean;
}

const EditableTable = ({
  formik,
  columns,
  defaultData,
  pagination,
  onCopyRowsClick,
  onRowsSelected,
  onCreateRowClick,
  onCustomButtonClick,
  footer,
  footerTitle,
  removeControls,
  groupData = false,
  withPDV,
  readonlyNotSelected,
  isWaste
}: EditableTableInterface) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(defaultData);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [grouping, setGrouping] = useState<GroupingState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const gropeHandlerIndex = 1;
  const minValue = 0.00001;
  const [tableType] = useState(isWaste ? "wasteProducts" : "products");

  useEffect(() => {
    defaultData.length !== data.length && setData(defaultData);
  }, [defaultData, data.length]);

  const handleAddRow = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    onCreateRowClick && setTimeout(onCreateRowClick, 0);
  };

  const handleCopyRow = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    const selectedRows = table.getSelectedRowModel().flatRows;
    onCopyRowsClick && onCopyRowsClick(selectedRows);
  };

  const defaultColumn = useMemo(() => {
    return {
      cell: ({
        row: { index, getIsGrouped, getIsSelected },
        column: { id },
        table,
        getValue
      }) => {
        const formikHandler = table.options.meta?.formik;
        const {
          fieldType,
          fieldValueType,
          fieldOptions,
          readonly,
          searchEntity,
          isMulti,
          isSearchable,
          useDefaultColumn,
          isForWaste
        } = table.getColumn(id).columnDef.meta;
        let field = formikHandler.values[tableType][index];
        const fieldValue = field[id];
        const fieldName = `${tableType}.${index}.${id}`;

        const setSumFields = (value, name, settingName) => {
          const sum = formikHandler.values[tableType].reduce((tot, item, i) => {
            if (index === i) {
              if (value.length) {
                return addNumbers(tot, formatNumber(value));
              }
            } else if (item[name]) {
              return addNumbers(tot, formatNumber(item[name]));
            }
            return tot;
          }, 0);
          formikHandler.setFieldValue(settingName, formatNumber(sum, 5));
        };

        const setAmount = (value, value2, byFieldIndex?) => {
          if (byFieldIndex) index = byFieldIndex;
          let amount = 0;
          if (value && value2) {
            amount = formatNumber(
              formatNumber(value) * formatNumber(value2),
              2,
              true
            );
          }

          formikHandler.setFieldValue(`${tableType}.${index}.amount`, amount);

          let totalAmount = formikHandler.values[tableType].reduce(
            (tot, item, i) => {
              if (index === i) {
                if (amount) {
                  return addNumbers(tot, formatNumber(amount));
                }
              } else if (item["amount"]) {
                return addNumbers(tot, formatNumber(item["amount"]));
              }
              return tot;
            },
            0
          );

          formikHandler.setFieldValue(
            "amount",
            formatNumber(totalAmount, 2, true)
          );

          if (withPDV) {
            formikHandler.setFieldValue(
              "amountPDV",
              formatNumber(totalAmount * PDV_PERCENT, 2, true)
            );
          }
        };

        const onInputBlur = (e, byFieldIndex?) => {
          const { name, value } = e.target;

          if (byFieldIndex)
            field = formikHandler.values[tableType][byFieldIndex];

          formikHandler.setFieldValue(name, value);
          if (name.indexOf("count") !== -1 && value) {
            const parsedValue = formatNumber(value, 5).toString();
            setSumFields(value, "count", "count");
            setAmount(parsedValue, field.price);
            formikHandler.setFieldValue(name, parsedValue);
          } else if (name.indexOf("price") !== -1 && value) {
            const parsedValue = formatNumber(value, 2).toString();
            setAmount(parsedValue, field.count);
            formikHandler.setFieldValue(name, parsedValue);
          } else if (name.indexOf("decommissioned") !== -1) {
            const rowModel = table.getRowModel().rows;
            const parentRow = rowModel.find((item) => {
              return item.subRows.find((row) => row.original.id === index);
            });

            let parsedValue;
            const checkerValue =
              field["available"] <= field["original"]
                ? field["available"]
                : field["original"];
            if (checkerValue <= formatNumber(value, 5)) {
              parsedValue = formatNumber(checkerValue, 5);
            } else {
              if (
                value.length === 0 ||
                value === "0" ||
                formatNumber(value, 5) === 0
              )
                parsedValue = checkerValue;
              else parsedValue = formatNumber(value, 5).toString();
            }

            formikHandler.setFieldValue(name, parsedValue);
            setSumFields(value, "decommissioned", "decommissioned");

            setAmount(parsedValue, field.price, byFieldIndex);
            if (parentRow) {
              const { groupeSum } = parentRow.getValue(id);

              if (groupeSum)
                formikHandler.setFieldValue(
                  "groupeHelper" + parentRow.index,
                  groupeSum
                );
            }
            return parsedValue;
          }
        };

        const onGroupeInputBlur = (e) => {
          const { name, value } = e.target;
          const { itemsInGroupe, groupeMaxValue } = getValue();
          let newValue = formatNumber(value);

          if (
            value.length === 0 ||
            value === "0" ||
            formatNumber(value, 5) === 0 ||
            groupeMaxValue < value
          ) {
            newValue = groupeMaxValue;
          } else {
            const minValueByGroupe = itemsInGroupe.length * minValue;
            if (newValue < minValueByGroupe) {
              newValue = minValueByGroupe;
            }
          }

          const usagePercent = (newValue * 100) / groupeMaxValue;

          const step = 0.00001;
          let remainder = 0;
          let usedValue = 0;
          let values = [];

          const fillChildProportionally = () => {
            for (const element of itemsInGroupe) {
              let value = addNumbers(
                (element.original.available * usagePercent) / 100,
                remainder < 0 ? -step : remainder > 0 ? step : 0
              );

              if (value > element.original.available) {
                value = element.original.available;
              }

              value = formatNumber(value, 5);
              values.push(value);
              usedValue = addNumbers(usedValue, value);
              if (remainder !== 0) {
                remainder = addNumbers(
                  remainder,
                  remainder > 0 ? -step : remainder < 0 ? step : 0
                );
              }
            }

            remainder = formatNumber(newValue - usedValue, 5);
            if (remainder !== 0) {
              values = [];
              usedValue = 0;
              fillChildProportionally();
            }
          };

          fillChildProportionally();

          for (const element of itemsInGroupe) {
            const id = element.original.id;
            const name = `${tableType}.${id}.decommissioned`;
            let value = values.shift();

            const e = {
              target: {
                name,
                value
              }
            };

            onInputBlur(e, id);
          }

          formikHandler.setFieldValue(
            name,
            formatNumber(newValue, 5).toString()
          );
        };

        const handleSelectChange = (option, name) => {
          if (name.indexOf("standard") !== -1) {
            const isNeedConfirm = () => {
              if (
                formikHandler.values[tableType][index]["productType"]?.value &&
                formikHandler.values[tableType][index]["productType"]?.label
                  ?.length
              )
                return true;
              if (formikHandler.values[tableType][index]["wood"].length)
                return true;
              if (formikHandler.values[tableType][index]["sort"].length)
                return true;
              if (formikHandler.values[tableType][index]["uktzed"].length)
                return true;

              return false;
            };
            if (isNeedConfirm()) handleChangeStandardClick(option);
            else formikHandler.setFieldValue(name, option);
          } else {
            formikHandler.setFieldValue(name, option);
          }
        };

        const handleChangeStandardClick = (value) => {
          dispatch(
            features.modal.actions.showModal({
              modalType: "CONFIRM_ACTION",
              modalProps: {
                notificationText:
                  "Якщо ви змінете стандарт продукції, всі заповнені поля в стовпчиках продукція, порода, сорт та УКТЗЕД видаляться. Ці поля потрібно буде заповнити повторно. Все одно змінити?",
                acceptLabel: "Так, продовжити",
                onAccept: () => {
                  formikHandler.setFieldValue(`${tableType}.${index}`, {
                    ...formikHandler.values[tableType][index],
                    standard: value,
                    productType: { label: "", value: "" },
                    wood: [],
                    sort: [],
                    uktzed: ""
                  });
                }
              }
            })
          );
        };

        const getAutocompleteParams = () => {
          if (searchEntity === "products") {
            const standard = formikHandler.values["standard"] || field.standard;
            return `productStandardUuid=${standard.value}${
              isForWaste ? "&forWaste=true" : ""
            }`;
          } else if (searchEntity === "organizations") {
            return `productProductUuid=${field.productType.value}`;
          }

          return null;
        };

        if (getIsGrouped() && !useDefaultColumn) return <></>;

        if (getIsGrouped() && useDefaultColumn) {
          const formikGroupeName = "groupeHelper" + index;
          const formikGroupeValue = formikHandler.values[formikGroupeName];
          const { isSelectedGroupe, groupeSum } = getValue();
          if (
            isSelectedGroupe &&
            formikGroupeValue === undefined &&
            groupeSum
          ) {
            formikHandler.setFieldValue(formikGroupeName, groupeSum);
          } else if (!isSelectedGroupe && formikGroupeValue) {
            formikHandler.setFieldValue(formikGroupeName, undefined);
          }
          return (
            <div className={styles["table__data-container"]}>
              <FormField
                value={
                  !isSelectedGroupe
                    ? groupeSum
                    : formikHandler.values[formikGroupeName]
                }
                name={"groupeHelper" + index}
                readonly={!isSelectedGroupe}
                type={fieldValueType !== "number" ? fieldValueType : ""}
                onBlur={onGroupeInputBlur}
                onInput={
                  fieldValueType
                    ? (e) => {
                        e.target.value = e.target.value.replace(
                          /[^0-9,.]/g,
                          ""
                        );
                      }
                    : null
                }
                noBorders={!isSelectedGroupe}
                className={cn(styles["table-input"], {
                  [styles["table-input_has-value"]]: true
                })}
                errClassName={styles["table-input__error-message"]}
                widthByValue
                minWidth="40px"
                maxWidth="100px"
                textAlign="center"
              />
            </div>
          );
        }

        switch (fieldType) {
          case "SELECT":
            return (
              <div className={styles["table__data-container"]}>
                <Select
                  name={fieldName}
                  value={fieldValue}
                  withFormik
                  isMulti={isMulti}
                  options={fieldOptions}
                  onSelectChange={handleSelectChange}
                  blurInputOnSelect={true}
                  className={cn(
                    "table__input",
                    {
                      ["table__input--has-value"]: isMulti
                        ? fieldValue?.length
                        : fieldValue?.label?.length
                    },
                    {
                      ["table__input--multi-select"]: isMulti
                    },
                    {
                      ["table__input--searchable"]: isMulti
                    }
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menu: (provided: any) => {
                      return {
                        ...provided,
                        minWidth: "max-content"
                      };
                    },
                    menuList: (provided: any) => {
                      return {
                        ...provided,
                        fontSize: "10px"
                      };
                    }
                  }}
                  isSearchable={isSearchable}
                  filterOption={(option, inputValue) => {
                    return option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  }}
                />
              </div>
            );
          case "UKTZED":
            return (
              <div className={styles["table__data-container"]}>
                <UKTZED
                  index={index}
                  productTypeId={field.productType.value}
                  woodUuid={field.wood[0]?.value}
                />
              </div>
            );
          case "INPUT":
            return (
              <div className={styles["table__data-container"]}>
                <FormField
                  name={fieldName}
                  readonly={
                    readonly || (readonlyNotSelected && !getIsSelected())
                  }
                  type={fieldValueType !== "number" ? fieldValueType : ""}
                  onBlur={onInputBlur}
                  onInput={
                    fieldValueType
                      ? (e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9,.]/g,
                            ""
                          );
                        }
                      : null
                  }
                  noBorders={readonly}
                  className={cn(styles["table-input"], {
                    [styles["table-input_has-value"]]:
                      !isNil(fieldValue) && fieldValue !== ""
                  })}
                  errClassName={styles["table-input__error-message"]}
                  widthByValue
                  minWidth="40px"
                  maxWidth="100px"
                  textAlign="center"
                />
              </div>
            );
          case "AUTOCOMPLETE":
            return (
              <div className={styles["table__data-container"]}>
                <Autocomplete
                  name={fieldName}
                  value={fieldValue}
                  asyncSelect
                  client={ApiClientAutocompete(`${searchEntity}/search`)}
                  withFormik
                  dataFieldsNames={{
                    valueFieldName:
                      searchEntity === tableType ? "id" : "externalId",
                    labelFieldName: "name"
                  }}
                  onSelectChange={handleSelectChange}
                  className={cn("table__input", "table__autocomplete", {
                    "table__input--has-value": fieldValue?.label?.length
                  })}
                  params={getAutocompleteParams()}
                  menuPortalTarget={document.body}
                  isSearchable={searchEntity !== "organizations"}
                  styles={{
                    menu: (provided: any) => {
                      return {
                        ...provided,
                        minWidth: "max-content",
                        maxWidth: "200px"
                      };
                    },
                    menuList: (provided: any) => {
                      return {
                        ...provided,
                        fontSize: "10px"
                      };
                    }
                  }}
                />
              </div>
            );
          default:
            return <div></div>;
        }
      }
    };
  }, []);

  useEffect(() => {
    if (onRowsSelected) onRowsSelected(Object.keys(rowSelection), isWaste);
  }, [onRowsSelected, rowSelection]);

  const table = useReactTable({
    data: defaultData,
    columns: columns,
    defaultColumn: defaultColumn,
    initialState: {
      columnVisibility: { multiGroupe: false }
    },
    state: {
      rowSelection: rowSelection,
      grouping,
      expanded
    },
    groupedColumnMode: false,
    meta: {
      formik: formik
    },
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: (val) => {
      if (typeof val === "function") {
        setExpanded(val);
      }
    },
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: pagination && getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    enableGrouping: true
  });

  useEffect(() => {
    let columns = table.getAllColumns();
    let columnsToGroupe = columns.filter(
      (item) => item.columnDef.enableGrouping === true
    );

    if (groupData) {
      table.setGrouping(columnsToGroupe.map((item) => item.id));
    } else {
      table.resetGrouping();
      setExpanded({});
    }
  }, [groupData, table]);

  const renderHeaderColumns = (header: Header<any, unknown>, index: number) => {
    const renderGroupingCol = () => {
      if (index === gropeHandlerIndex && groupData) {
        return (
          <React.Fragment key={header.id}>
            <th></th>
            <th>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          </React.Fragment>
        );
      } else {
        return (
          <th key={header.id}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        );
      }
    };

    return header.isPlaceholder ? null : renderGroupingCol();
  };

  const renderBodyColumns = (
    cell: Cell<any, unknown>,
    row: Row<any>,
    index: number
  ) => {
    if (row.getCanExpand()) {
      if (cell.column.id === "id") {
        return (
          <td>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
        );
      }
    }
    if (groupData && index === gropeHandlerIndex && row.getCanExpand()) {
      return (
        <>
          <td className={styles["min-width-unset"]}>
            <div
              className={styles["table_editable__cell-grouping-controls"]}
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? (
                <img
                  src={MinusIcon}
                  alt="minus"
                  className={styles["table__grouping-img"]}
                />
              ) : (
                <img
                  src={PlusIcon}
                  alt="plus"
                  className={styles["table__grouping-img"]}
                />
              )}
            </div>
          </td>
          <td
            className={
              cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
                ? styles["green"]
                : ""
            }
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        </>
      );
    }
    if (table.options.columns[index].meta?.groupByThisColumn && groupData) {
      return cell.getIsGrouped() ? (
        <td
          key={cell.id}
          onClick={
            table.options.columns[index].meta?.buttonCell
              ? onCustomButtonClick
              : undefined
          }
        ></td>
      ) : (
        <td
          className={
            cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
              ? styles["green"]
              : ""
          }
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      );
    } else {
      if (groupData)
        return (
          <>
            {groupData && index === 0 && !row.getCanExpand() && (
              <td className={styles["none-right-border"]}></td>
            )}
            <td
              key={cell.id}
              onClick={
                table.options.columns[index].meta?.buttonCell
                  ? onCustomButtonClick
                  : undefined
              }
              style={table.options.columns[index].meta?.columnStyles}
              className={cn(
                groupData && index === 0 && !row.getCanExpand()
                  ? styles["none-left-border"]
                  : "",
                cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
                  ? styles["green"]
                  : "",
                cell.column.columnDef.meta?.useDefaultColumn
                  ? styles["green"]
                  : ""
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          </>
        );

      return (
        <td
          key={cell.id}
          onClick={
            table.options.columns[index].meta?.buttonCell
              ? onCustomButtonClick
              : undefined
          }
          style={table.options.columns[index].meta?.columnStyles}
          className={
            cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
              ? styles["green"]
              : ""
          }
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      );
    }
  };

  return (
    <div className={styles["table-wrapper"]}>
      <div className={styles["table-scroll-wrapper"]}>
        <table className={cn(styles["table"], styles["table_editable"])}>
          <thead>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(
                  (header: Header<any, unknown>, index: number) => {
                    return renderHeaderColumns(header, index);
                  }
                )}
              </tr>
            ))}
          </thead>
          <tbody>
            <FieldArray name="products">
              {() =>
                table.getRowModel().rows.map((row: Row<any>) => {
                  let isGreen: boolean;
                  let isRed: boolean;

                  if (row.original.decommissioned)
                    isGreen =
                      row.getIsSelected() ||
                      (row.getValue("decommissioned")?.["isSelectedGroupe"] &&
                        row.getValue("decommissioned")?.["groupeSum"]);
                  if (row.original.available)
                    isRed = row.getValue("available") === 0;

                  return (
                    <tr
                      key={row.id}
                      className={cn(styles["table__row"], {
                        [styles["table__row_green"]]: isGreen,
                        [styles["table__row_red"]]: isRed
                      })}
                    >
                      {row
                        .getVisibleCells()
                        .map((cell: Cell<any, unknown>, index) => (
                          <React.Fragment key={index}>
                            {renderBodyColumns(cell, row, index)}
                          </React.Fragment>
                        ))}
                    </tr>
                  );
                })
              }
            </FieldArray>
          </tbody>
        </table>
      </div>
      {!removeControls && (
        <div className={styles["table-controls"]}>
          <IconButton
            icon={CopyIcon}
            onClick={handleCopyRow}
            disabled={isEmpty(rowSelection)}
          >
            Копіювати обраний рядок
          </IconButton>
          <IconButton icon={CreateIcon} onClick={handleAddRow}>
            Додати пустий рядок
          </IconButton>
        </div>
      )}
      {footer && (
        <div
          className={cn(
            styles["table-footer"],
            styles["editable-table-footer"]
          )}
        >
          {footerTitle && (
            <h5
              className={cn(
                styles["table-footer__title"],
                styles["editable-table-footer__title"]
              )}
            >
              {footerTitle}
            </h5>
          )}
          <div
            className={cn(
              styles["table-footer__info"],
              styles["editable-table-footer__info"]
            )}
          >
            {footer.map((element) => {
              return (
                <div
                  className={styles["editable-table-footer__info-element"]}
                  key={element.name}
                >
                  <p>{element.label}</p>
                  <FormField
                    readonly
                    name={element.name}
                    value={element.value}
                    className={cn(
                      styles["table-footer__info-input"],
                      styles["editable-table-footer__input"],
                      styles["disable-input"]
                    )}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditableTable;
