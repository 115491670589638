import { createSlice } from "@reduxjs/toolkit";

export interface IEDeclineError {
  unknown?: string[];
}

export interface IOfficialAnnulledApplicationsForSigning {
  isLoadingFetchAnnulled: boolean;
  isLoadingFetchDeclined: boolean;
  isLoadingFetchEDeclined: boolean;
  responseError: any;
  annulled: any[];
}

const initialState: IOfficialAnnulledApplicationsForSigning = {
  responseError: {},
  isLoadingFetchAnnulled: false,
  isLoadingFetchDeclined: false,
  isLoadingFetchEDeclined: false,
  annulled: []
};

export const mainSlice = createSlice({
  name: "officialAnnulledApplicationsForSigning",
  initialState,
  reducers: {
    fetchAnnulledRequest: (state) => {
      state.isLoadingFetchAnnulled = true;
    },
    fetchAnnulledSuccess: (state, { payload }) => {
      state.isLoadingFetchAnnulled = false;
      state.annulled = payload;
    },
    fetchAnnulledError: (state) => {
      state.isLoadingFetchAnnulled = false;
    },

    signAnnulledRequest: (state) => {
      state.isLoadingFetchAnnulled = true;
    },
    signAnnulledSuccess: (state) => {
      state.isLoadingFetchAnnulled = false;
      state.annulled = [];
    },
    signAnnulledError: (state) => {
      state.isLoadingFetchAnnulled = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
