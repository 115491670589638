import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, CommonButton, Select } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import {
  calculateTotals,
  formatNumber,
  parseResErrors,
  productValidationSchemaReq
} from "helpers";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import DisabledWrapper from "components/DisabledWrapper/DisabledWrapper";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import Section from "components/Section/Section";
import EditableProductsTable from "./EditableProductsTable";
import SavedProductsTable from "./SavedProductsTable";

import styles from "./define-products.module.scss";

interface IDefineProducts {
  isReadonly?: boolean;
  onEdit?: (status: boolean) => void;
}

const DefineProducts = ({
  isReadonly,
  onEdit
}: IDefineProducts): JSX.Element => {
  const dispatch = useDispatch();
  const [lockedForm, setLockedForm] = useState(false);
  const [initSavedProducts, setInitSavedProducts] = useState(false);

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const {
    currencyList,
    deliveryConditionList,
    sizeList,
    standardList,
    unitList,
    speciesList,
    sortList
  } = useSelector<IRootState, IDatasetState>((state) => state.dataset);

  const tableRow = useMemo(() => {
    let standard = {
      value: "",
      label: ""
    };

    if (!isEmpty(standardList)) {
      standard = standardList[0];
    }

    return {
      standard,
      productType: {
        value: "",
        label: ""
      },
      clarification: "",
      wood: [],
      sort: [],
      diameter: "",
      thickness: "",
      width: "",
      length: "",
      count: "",
      unit: {
        value: "",
        label: ""
      },
      price: "",
      amount: "",
      uktzed: ""
    };
  }, [standardList]);

  const onFormSubmit = (values) => {
    const fields = {
      deliveryConditionUuid: values.deliveryCondition.value,
      currencyUuid: values.currency.value,
      geometricDimensionUuid: values.size.value,
      products: [],
      totalQuantity: values.count,
      totalPrice: parseFloat(values.amount)
    };

    fields.products = values.products.map((item) => {
      const product = {
        productExternalId: parseInt(item.productType.value),
        productName: item.productType.label,
        productStandardUuid: item.standard.value,
        note: item.clarification || null,
        woodSpecieUuids: item.wood.map((item) => ({ uuid: item.value })),
        woodGradeUuids: item.sort.map((item) => ({ uuid: item.value })),
        diameter: item.diameter ? item.diameter.toString() : null,
        height: item.thickness ? item.thickness.toString() : null,
        width: item.width ? item.width.toString() : null,
        length: item.length ? item.length.toString() : null,
        quantity: formatNumber(item.count),
        quantityMeasurementUnitUuid: item.unit.value,
        price: formatNumber(item.price),
        totalPrice: formatNumber(item.amount),
        uktzed: item.uktzed
      };

      return product;
    });

    dispatch(
      features.application.actions.setProductsRequest({
        fields,
        params: {
          uuid: application.uuid
        },
        onSuccess: () => {
          setLockedForm(true);
          onEdit(false);
          dispatch(
            features.application.actions.fetchApplicationRequest({
              params: { uuid: application.uuid }
            })
          );
        },
        onError: (error) => {
          parseResErrors({
            setFieldError: formik.setFieldError,
            errorsRes: error,
            fields: formik.values,
            fieldsAliases: {
              productExternalId: "productType",
              productName: "productType",
              productStandardUuid: "standard",
              note: "clarification",
              height: "thickness",
              woodGradeUuids: "sort",
              woodSpecieUuids: "wood",
              quantity: "count",
              quantityMeasurementUnitUuid: "unit",
              totalPrice: "amount",
              forbidden: "uktzed"
            }
          });
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      deliveryCondition: {
        value: "",
        label: ""
      },
      currency: {
        value: "",
        label: ""
      },
      size: {
        value: "",
        label: ""
      },
      products: [tableRow],
      count: "",
      amount: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      deliveryCondition: Yup.object().shape({
        value: Yup.string().required("Виберіть умову поставки")
      }),
      currency: Yup.object().shape({
        value: Yup.string().required("Виберіть валюту")
      }),
      size: Yup.object().shape({
        value: Yup.string().required("Виберіть геометричний розмір")
      }),
      products: productValidationSchemaReq(Yup, true)
    }),
    onSubmit: onFormSubmit
  });

  const initFields = useCallback(() => {
    const products = application.products.map((product) => {
      const standard = standardList.find(
        (item) => item.value === product.productStandardUuid
      );
      const unit = unitList.find(
        (item) => item.value === product.quantityMeasurementUnitUuid
      );
      const wood = product.woodSpecieUuids.map((uuid) => {
        const foundWood = speciesList.find((item) => item.value === uuid);
        return foundWood;
      });
      const sort = product.woodGradeUuids.map((uuid) => {
        const foundSort = sortList.find((item) => item.value === uuid);
        return foundSort;
      });

      const productRow = {
        productType: {
          value: product.productExternalId,
          label: product.productName
        },
        standard: standard,
        clarification: product.note || "",
        wood: wood,
        sort: sort,
        diameter: product.diameter || "",
        thickness: product.height || "",
        width: product.width || "",
        length: product.length || "",
        count: product.quantity,
        unit: unit,
        price: product.price,
        amount: product.totalPrice,
        uktzed: product.uktzed
      };

      return productRow;
    });

    const { amount, quantity } = calculateTotals(products);

    formik.setFieldValue("products", products);
    formik.setFieldValue("count", quantity);
    formik.setFieldValue("amount", amount);

    setLockedForm(true);
    onEdit(false);

    setInitSavedProducts(true);

    if (application.deliveryConditionName) {
      const deliveryCondition = deliveryConditionList.find(
        (item) => item.label === application.deliveryConditionName
      );
      formik.setFieldValue("deliveryCondition", deliveryCondition);
    }

    if (application.currencyName) {
      const currency = currencyList.find(
        (item) => item.label === application.currencyName
      );
      formik.setFieldValue("currency", currency);
    }

    if (application.geometricDimensionName) {
      const size = sizeList.find(
        (item) => item.label === application.geometricDimensionName
      );
      formik.setFieldValue("size", size);
    }
  }, [
    application.products,
    application.deliveryConditionName,
    application.currencyName,
    application.geometricDimensionName,
    standardList,
    unitList,
    speciesList,
    sortList,
    deliveryConditionList,
    currencyList,
    sizeList
  ]);

  const setDefaultFields = useCallback(() => {
    if (
      currencyList &&
      currencyList.length &&
      !formik.values["currency"].value
    ) {
      formik.setFieldValue("currency", currencyList[0]);
    }
    if (
      deliveryConditionList &&
      deliveryConditionList.length &&
      !formik.values["deliveryCondition"].value
    ) {
      formik.setFieldValue("deliveryCondition", deliveryConditionList[0]);
    }
    if (sizeList && sizeList.length && !formik.values["size"].value) {
      formik.setFieldValue("size", sizeList[0]);
    }
  }, [formik.values, currencyList, deliveryConditionList, sizeList]);

  useEffect(() => {
    if (
      !isEmpty(application?.products) &&
      !isEmpty(standardList) &&
      !isEmpty(unitList) &&
      !isEmpty(speciesList) &&
      !isEmpty(sortList) &&
      !isEmpty(deliveryConditionList) &&
      !isEmpty(currencyList) &&
      !isEmpty(sizeList) &&
      !initSavedProducts
    ) {
      initFields();
    } else if (!initSavedProducts) {
      setDefaultFields();
    }
  }, [
    application?.products,
    currencyList,
    deliveryConditionList,
    initFields,
    initSavedProducts,
    sizeList,
    sortList,
    speciesList,
    standardList,
    unitList,
    setDefaultFields
  ]);

  const handleSelectChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const handleSaveData = () => {
    setLockedForm(false);
    onEdit(true);
  };

  const handleCancelSaveData = () => {
    initFields();
    setLockedForm(true);
    onEdit(false);
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <div className={styles["define-products-wrapper"]}>
      {!lockedForm ? (
        <Section title="Продукція" color={"WHITE"}>
          {!application?.invoice && (
            <Alert
              className={styles["define-products__reminder"]}
              type="warning"
              withIcon
            >
              Перед заповненям блоку продукції потрібно заповнити блок даних про
              інвойс.
            </Alert>
          )}
          <DisabledWrapper disabled={!application?.invoice}>
            <FormikProvider value={formik}>
              <Form className={styles["define-products"]} onKeyDown={onKeyDown}>
                <div className={styles["define-products__selects"]}>
                  <Select
                    name="deliveryCondition"
                    value={formik.values["deliveryCondition"]}
                    options={deliveryConditionList}
                    placeholder="Умови поставки"
                    onSelectChange={handleSelectChange}
                    withFormik
                  />
                  <Select
                    name="currency"
                    value={formik.values["currency"]}
                    options={currencyList}
                    placeholder="Валюта"
                    onSelectChange={handleSelectChange}
                    withFormik
                  />
                  <Select
                    name="size"
                    value={formik.values["size"]}
                    options={sizeList}
                    placeholder="Геометричні розміри"
                    onSelectChange={handleSelectChange}
                    withFormik
                  />
                </div>
                <div className={styles["define-products__table"]}>
                  <div className={styles["set-application-type__form"]}>
                    <EditableProductsTable
                      formik={formik}
                      tableRow={tableRow}
                      enabledColumns={[
                        "select",
                        "standard",
                        "productType",
                        "clarification",
                        "wood",
                        "sort",
                        "diameter",
                        "thickness",
                        "width",
                        "length",
                        "count",
                        "unit",
                        "price",
                        "amount",
                        "uktzed",
                        "actions"
                      ]}
                    />
                  </div>
                </div>
                <div className={styles["form-btns"]}>
                  {application.products.length > 0 && (
                    <CommonButton
                      className={cn(
                        styles["form-btn"],
                        styles["define-products__btn"]
                      )}
                      outlined={true}
                      onClick={handleCancelSaveData}
                      label="Скасувати"
                    />
                  )}
                  <CommonButton
                    type="submit"
                    className={cn(
                      styles["form-btn"],
                      styles["define-products__btn"]
                    )}
                    label="Зберегти"
                  />
                </div>
              </Form>
              <ScrollToFieldError />
            </FormikProvider>
          </DisabledWrapper>
        </Section>
      ) : (
        <PreloaderWrapper loading={!initSavedProducts}>
          <SavedProductsTable
            formik={formik}
            handleSaveData={handleSaveData}
            isReadonly={isReadonly}
          />
        </PreloaderWrapper>
      )}
    </div>
  );
};

export default DefineProducts;
