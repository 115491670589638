import { IFile } from "interfaces";

export interface IDocChildren {
  files?: [];
  quantityOrigin: string | number;
  id: string;
  typeName: string;
  series: string;
  number: string;
  date: number;
  quantity: number;
  quantityAvailable: number;
  forestUserName: string;
  counterParty: string;
  freightCarNumber: string;
}

export interface IDocReview {
  additionalDocuments: any;
  quantityOrigin: number;
  freightCarNumber: string | number;
  children: IDocChildren[];
  files?: IFile[];
  id: number;
  typeName: string;
  series: string;
  number: string;
  date: string;
  quantity: number;
  quantityAvailable: number;
  forestUserName: string;
  counterParty?: string;
  counterPartyExternalId?: number;
}

export interface IDoc {
  additionalDocuments: any;
  byUnion?: boolean;
  quantityOrigin?: number;
  quantityAvailable?: number;
  wasteQuantity?: number;
  wasteQuantityAvailable?: number;
  wasteQuantityPercent?: number;
  externalId?: string;
  sourceOrganizationCountry?: string;
  recipientOrganizationCountry?: string;
  id: number;
  date: string;
  files: Array<any>;
  number: string;
  recipientOrganizationEdrpou: string;
  recipientOrganizationExternalId: string;
  recipientOrganizationName: string;
  series: string;
  sourceOrganizationEdrpou: string;
  sourceOrganizationExternalId: string;
  sourceOrganizationName: string;
  supportDocumentTypeName: string;
  supportDocumentTypeUuid: string;
  tnAdditionalDocuments: Array<any>;
  typeName: string;
  uuid: string;
  products: Array<any>;
  quantity: number;
  primal: boolean;
  children?: any;
}

export const BASIS_DOC_TYPE = {
  TN_SCANCOPY: "tn_scan_copy",
  TTN_SCANCOPY: "ttn_scan_copy",
  TTN_WARRANT: "ttn_removal_greenery_warrant",
  TTN_CERTIFICATE: "ttn_green_spaces_inspection_certificate",
  ZN_SCANCOPY: "zn_scan_copy",
  ZSN_SCANCOPY: "zsn_scan_copy",
  MD_SCANCOPY: "customs_declaration_scan_copy",
  MD_CMR_OR_ZN: "customs_declaration_cmr_or_waybill",
  MD_SPECIFICATION: "customs_declaration_specification"
};

export const BASIS_DOC_TYPE_NAME = {
  tn_scan_copy: "ТН скан-копія",
  ttn_scan_copy: "ТТН скан-копія",
  ttn_removal_greenery_warrant: "Ордер",
  ttn_green_spaces_inspection_certificate: "Акт",
  zn_scan_copy: "ЗН скан-копія",
  zsn_scan_copy: "ЗСН скан-копія",
  customs_declaration_scan_copy: "Митна декларація скан-копія",
  customs_declaration_cmr_or_waybill: "CMR або ЗН",
  customs_declaration_specification: "Специфікація скан-копія"
};

export const BASIS_DOC_TYPE_UPLOAD_NAME = {
  tn_scan_copy: "Завантажити скан-копію",
  ttn_scan_copy: "Завантажити скан-копію",
  ttn_removal_greenery_warrant: "Завантажити Ордер",
  ttn_green_spaces_inspection_certificate: "Завантажити акт",
  zn_scan_copy: "Завантажити скан-копію",
  zsn_scan_copy: "Завантажити скан-копію",
  customs_declaration_scan_copy: "Завантажити скан-копію",
  customs_declaration_cmr_or_waybill: "Завантажити CMR або ЗН",
  customs_declaration_specification: "Завантажити скан-копія специфікації"
};
