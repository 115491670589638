import { Fragment } from "react";

export const renderError = (errors) => (
  <div className="rrt-text">
    {errors.map((error, index) => (
      <Fragment key={index}>
        {index > 0 && (
          <>
            <br />
            <br />
          </>
        )}
        {error}
      </Fragment>
    ))}
  </div>
);
