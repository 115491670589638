import {
  Cell,
  ExpandedState,
  GroupingState,
  Header,
  HeaderGroup,
  Row,
  RowSelectionState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable
} from "@tanstack/react-table";
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import MinusIcon from "assets/images/icons/minus.svg";
import PlusIcon from "assets/images/icons/plus.svg";

import styles from "./common-table.module.scss";

interface CommonTableInterface {
  defaultData: any;
  columns: Array<any>;
  id?: string;
  tableType?: "COMMON" | "ROW-GAP" | "SIMPLE";
  pagination?: boolean;
  itemsPerPage?: number;
  onRowClick?: (par?: any) => any;
  footer?: Array<{
    name: string;
    label: string;
    value?: string | number;
  }>;
  footerTitle?: string;
  groupData?: boolean;
  onCustomButtonClick?: () => void;
  rowSelection?: RowSelectionState;
  smallText?: boolean;
}

const CommonTable = ({
  defaultData,
  columns,
  tableType = "COMMON",
  itemsPerPage,
  id,
  onRowClick,
  footer,
  footerTitle,
  groupData = false,
  onCustomButtonClick,
  rowSelection,
  smallText
}: CommonTableInterface) => {
  const [data, setData] = useState(() => [...defaultData]);
  const gropeHandlerIndex = 0;
  const [grouping, setGrouping] = useState<GroupingState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: { multiGroupe: false }
    },
    state: {
      grouping,
      expanded,
      ...(rowSelection ? { rowSelection } : {})
    },
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: (val) => {
      if (typeof val === "function") {
        setExpanded(val);
      }
    },
    onGroupingChange: setGrouping,

    enableGrouping: true
  });

  useEffect(() => {
    itemsPerPage && table.setPageSize(itemsPerPage);
  }, []);

  useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  useEffect(() => {
    let columns = table.getAllColumns();
    let columnsToGroupe = columns.filter(
      (item) => item.columnDef.enableGrouping === true
    );
    if (groupData) {
      table.setGrouping(columnsToGroupe.map((item) => item.id));
    } else {
      table.resetGrouping();
    }
  }, [groupData, table]);

  const renderHeaderColumns = (header: Header<any, unknown>, index: number) => {
    const renderGroupingCol = () => {
      if (index === gropeHandlerIndex && groupData) {
        return (
          <React.Fragment key={header.id}>
            <th></th>
            <th>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          </React.Fragment>
        );
      } else {
        return (
          <th key={header.id}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        );
      }
    };

    return header.isPlaceholder ? null : renderGroupingCol();
  };

  const renderBodyColumns = (
    cell: Cell<any, unknown>,
    row: Row<any>,
    index: number
  ) => {
    if (groupData && index === gropeHandlerIndex && row.getCanExpand()) {
      return (
        <>
          <td className={styles["min-width-unset"]}>
            <div
              className={styles["table_editable__cell-grouping-controls"]}
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? (
                <img
                  src={MinusIcon}
                  alt="minus"
                  className={styles["table__grouping-img"]}
                />
              ) : (
                <img
                  src={PlusIcon}
                  alt="plus"
                  className={styles["table__grouping-img"]}
                />
              )}
            </div>
          </td>
          <td
            className={
              cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
                ? styles["green"]
                : ""
            }
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        </>
      );
    }
    if (table.options.columns[index].meta?.groupByThisColumn && groupData) {
      return (
        !cell.getIsGrouped() && (
          <>
            <td></td>
            <td
              className={
                cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
                  ? styles["green"]
                  : ""
              }
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          </>
        )
      );
    } else {
      if (groupData)
        return (
          <>
            {groupData && index === 0 && !row.getCanExpand() && (
              <td className={styles["none-right-border"]}></td>
            )}
            <td
              key={cell.id}
              onClick={
                table.options.columns[index].meta?.buttonCell
                  ? onCustomButtonClick
                  : undefined
              }
              style={table.options.columns[index].meta?.columnStyles}
              className={cn(
                groupData && index === 0 && !row.getCanExpand()
                  ? styles["none-left-border"]
                  : "",
                cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
                  ? styles["green"]
                  : ""
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          </>
        );

      return (
        <td
          key={cell.id}
          onClick={
            table.options.columns[index].meta?.buttonCell
              ? onCustomButtonClick
              : undefined
          }
          style={table.options.columns[index].meta?.columnStyles}
          className={
            cell.column.columnDef.meta?.fieldType && !row.getCanExpand()
              ? styles["green"]
              : ""
          }
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      );
    }
  };

  const [isScroll, setIsScroll] = useState(false);
  const [isDown, setIsDown] = useState(false);
  const [pos, setPos] = useState({ top: 0, left: 0, x: 0, y: 0 });
  const itemRef = useRef(null);

  const onMouseDown = (e) => {
    if (isScroll) {
      itemRef.current.style.cursor = "grabbing";
      itemRef.current.style.userSelect = "none";
      setPos({
        left: itemRef.current.scrollLeft,
        top: itemRef.current.scrollTop,

        x: e.clientX,
        y: e.clientY
      });
      setIsDown(true);
    }
  };

  const onMouseUp = () => {
    if (isScroll) {
      itemRef.current.style.cursor = "grab";
      itemRef.current.style.removeProperty("user-select");
    }
    setIsDown(false);
  };

  const onMouseMove = (e) => {
    if (isScroll && isDown) {
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      itemRef.current.scrollTop = pos.top - dy;
      itemRef.current.scrollLeft = pos.left - dx;
    }
  };

  useEffect(() => {
    if (itemRef.current.scrollWidth > itemRef.current.clientWidth) {
      setIsScroll(true);
      itemRef.current.style.cursor = "grab";
    }
  }, []);

  return (
    <div className={styles["table-wrapper"]}>
      <div
        className={styles["table-scroll-wrapper"]}
        id={id}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
        ref={itemRef}
      >
        <table
          className={cn(styles["table"], {
            [styles["table_common"]]: tableType === "COMMON",
            [styles["table_row-gap"]]: tableType === "ROW-GAP",
            [styles["table_row-gapsinio"]]: smallText,
            [styles["table_simple"]]: tableType === "SIMPLE"
          })}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(
                  (header: Header<any, unknown>, index: number) => {
                    return renderHeaderColumns(header, index);
                  }
                )}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row: Row<any>) => {
              return (
                <tr
                  key={row.id}
                  className={cn(styles["table__row"], {
                    [styles["table__row_green"]]: row.getIsSelected(),
                    [styles["table__row_red"]]: row.original?.available === 0
                  })}
                  onClick={() =>
                    onRowClick ? onRowClick(row.original.uuid) : null
                  }
                >
                  {row
                    .getVisibleCells()
                    .map((cell: Cell<any, unknown>, index) => (
                      <React.Fragment key={index}>
                        {renderBodyColumns(cell, row, index)}
                      </React.Fragment>
                    ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {footer && (
          <div
            className={cn(
              styles["table-footer"],
              styles["editable-table-footer"]
            )}
          >
            {footerTitle && (
              <h5 className={styles["table-footer__title"]}>{footerTitle}</h5>
            )}
            <div className={styles["table-footer__info"]}>
              {footer.map((element) => {
                return (
                  <div
                    className={styles["table-footer__info-element"]}
                    key={element.name}
                  >
                    <p>{element.label}</p>
                    <p>{element.value}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonTable;
