export const regularQuestions = [
  {
    id: 0,
    question: "Документи для подання заявки",
    answer: "пункт-1"
  },
  {
    id: 1,
    question: "Як отримати і що для цього потрібно",
    answer: "пункт-1"
  },
  {
    id: 2,
    question: "Строк та вартість надання послуги",
    answer: "пункт-1"
  },
  {
    id: 3,
    question: "Куди звернутися, якщо відмовлено у наданні послуги?",
    answer: "пункт-1"
  },
  {
    id: 4,
    question: "Пов’язані закони й накази",
    answer: "пункт-1"
  },
  {
    id: 5,
    question: "Часті питання та відповіді",
    answer: "пункт-1"
  }
];
