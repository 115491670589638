import { takeLatest, put } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchEducationalMaterials({ payload }) {
  const request = API.fetchEducationalMaterials;
  const {
    fetchEducationalMaterialsSuccess: setSuccess,
    fetchEducationalMaterialsError: setError
  } = actions;

  let correctResponseData = [];

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess: (resp) => {
      if (!!resp?.data) {
        correctResponseData = resp?.data;
      } else {
        correctResponseData = resp;
      }
    }
  });

  yield put(setSuccess(correctResponseData));
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchEducationalMaterialsRequest().type,
    fetchEducationalMaterials
  );
}
