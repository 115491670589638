import { useFormik, useFormikContext } from "formik";
import { FormField, Select } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import Preloader from "components/Preloader/Preloader";

import styles from "./forms.module.scss";

interface IWoodTypeForm {
  type: string;
  onInnerFormValueChange?: (innerFormValues: any, validation?: any) => void;
}

const WoodTypeForm = ({
  type,
  onInnerFormValueChange
}: IWoodTypeForm): JSX.Element => {
  const dispatch = useDispatch();
  const externalFormik = useFormikContext();

  const { speciesList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  useEffect(() => {
    dispatch(features.dataset.actions.fetchSpeciesListRequest());
  }, []);

  const onFormSubmit = () => {};

  const validationSchema = Yup.object().shape({
    oldName:
      type === "edit" &&
      Yup.object().shape({
        value: Yup.string().required("Виберіть існуючу породу")
      }),
    name: Yup.string().required("Введіть нову породу")
  });

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      oldName: externalFormik.values["oldName"] || "",
      name: externalFormik.values["name"] || "",
      comment: externalFormik.values["comment"] || ""
    },
    onSubmit: onFormSubmit
  });

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
  }, []);

  useEffect(() => {
    onInnerFormValueChange(formik.values, validationSchema);
  }, [formik.values, type]);

  useEffect(() => {
    const actualValues = {
      ...formik.values,
      ...(externalFormik.values as any)
    };
    delete actualValues.type;
    formik.setValues(actualValues);
  }, [externalFormik.values["name"], externalFormik.values["comment"]]);

  return !isEmpty(speciesList) ? (
    <div className={styles["product-types-form__container"]}>
      <h4 className={styles["product-types-form__title"]}>Породи деревини</h4>
      {type === "edit" && (
        <div className={styles["product-types-form__field-container"]}>
          <Select
            name="oldName"
            placeholder="Назва породи, існуюча"
            value={formik.values["oldName"]}
            withFormik
            options={speciesList}
            onSelectChange={onAutocompleteChange}
            blurInputOnSelect={true}
            isSearchable={true}
          />
        </div>
      )}
      <div className={styles["product-types-form__field-container"]}>
        <FormField
          name="name"
          placeholder={`Назва породи${type === "edit" ? ", нова" : ""}`}
        />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField
          name="comment"
          placeholder="Коментар (не обов’язково)"
          as="textarea"
          className={"textarea-field"}
        />
      </div>
    </div>
  ) : (
    <Preloader />
  );
};

export default WoodTypeForm;
