import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import features from "features";
import { getSid } from "helpers";

import IconButton from "components/buttons/IconButton/IconButton";
import HeaderWrapper from "components/headers/HeaderWrapper/HeaderWrapper";

import ArrowLeftIcon from "assets/images/icons/arrow_left.svg";

import styles from "./back-header.module.scss";

const BackHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSid] = useState(getSid());

  const handleBackClick = () => {
    isSid ? navigate("/") : dispatch(features.auth.actions.signOutRequest());
  };

  return (
    <HeaderWrapper
      className={styles["back-header"]}
      leftPart={
        <div className={styles["back-header__left"]}>
          <IconButton icon={ArrowLeftIcon} onClick={handleBackClick}>
            <p className={styles["back-header__disappear-part"]}>Назад</p>
          </IconButton>
        </div>
      }
    />
  );
};

export default BackHeader;
