import { CommonButton, Dialog, Notification, Title } from "gov-ua-ui";
import { useDispatch } from "react-redux";

import features from "features";

import styles from "./confirm-action-modal.module.scss";

interface ConfirmActionModalInterface {
  title?: string;
  notificationText?: string;
  details?: JSX.Element;
  declineLabel?: string;
  acceptLabel?: string;
  onAccept?: () => void;
  onClose?: () => void;
  withoutCloseButton?: boolean;
  hideControls?: boolean;
}

const ConfirmActionModal = ({
  title,
  notificationText,
  details,
  declineLabel,
  acceptLabel,
  onAccept,
  onClose,
  withoutCloseButton = false,
  hideControls = false
}: ConfirmActionModalInterface) => {
  const dispatch = useDispatch();

  const handleHide = () => dispatch(features.modal.actions.hideModal());

  const handleAccept = () => {
    handleHide();
    onAccept && onAccept();
  };

  const handleClose = () => {
    handleHide();
    onClose && onClose();
  };

  return (
    <Dialog
      withoutCloseButton={withoutCloseButton}
      onClose={handleHide}
      className={styles["confirm-action-modal"]}
      isOpen
    >
      <Title size={24} className={styles["confirm-action-modal__title"]}>
        {title ? title : "Підтвердіть дію"}
      </Title>
      {notificationText && <Notification text={notificationText} />}
      {details && details}
      {!hideControls && (
        <div className={styles["confirm-action-modal__controls-container"]}>
          <CommonButton
            label={declineLabel ? declineLabel : "Скасувати"}
            outlined
            onClick={handleClose}
          />
          <CommonButton
            label={acceptLabel ? acceptLabel : "Підтвердити"}
            onClick={handleAccept}
          />
        </div>
      )}
    </Dialog>
  );
};
export default ConfirmActionModal;
