import { Alert } from "gov-ua-ui";
import { isEmpty } from "lodash";

import { renderError } from "./renderError";

interface IResponseErrors {
  errors: Array<string>;
  withIcon?: boolean;
}

const ResponseErrors = ({
  errors,
  withIcon = true
}: IResponseErrors): JSX.Element => {
  if (isEmpty(errors)) return <></>;

  return (
    <Alert type="error" withIcon={withIcon}>
      <div>{renderError(errors)}</div>
    </Alert>
  );
};

export default ResponseErrors;
