import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, SwitchButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { getDocTypeAlias } from "constant";
import features from "features";
import {
  calculateTotals,
  formatDate,
  generateColumnData,
  getChainNumber,
  parseFilesErrors,
  parseResErrors,
  productResParser
} from "helpers";
import { getSearchTitle } from "helpers/bread-crumbs";
import { testDigitsAfterComma } from "helpers/table-helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { NUMBER_OF_DECIMALS } from "./helpers/consts";
import { tableRowDefault } from "./helpers/table";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import DocumentForUse from "components/certificateComponents/viewCertificateComponents/DocumentForUse/DocumentForUse";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";
import FormControls from "components/railDocsComponents/FormControls/FormControls";
import EditableTable from "components/tables/EditableTable/EditableTable";
import CreateReturnWaste from "./components/CreateReturnWaste/CreateReturnWaste";

import DocIcon from "assets/images/icons/e_doc.svg";
import EyeIcon from "assets/images/icons/eye.svg";

import styles from "./specify-current-usage-layout.module.scss";

interface SpecifyCurrentUsageLayoutInterface {
  applicationId?: string;
  docId?: string;
  docType?: string;
  chain?: string;
  data?: any;
}

const SpecifyCurrentUsageLayout = ({
  applicationId,
  docId,
  docType,
  chain,
  data
}: SpecifyCurrentUsageLayoutInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentId = parseInt(searchParams.get("parent"));

  const [isGrouped, setIsGrouped] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isEmptyFiles, setIsEmptyFiles] = useState(null);
  const [isReturnWasteProductsDoc] = useState(
    (data?.supportDocumentTypeAlias === "ttn-wood" ||
      data?.supportDocumentTypeAlias === "ttn") &&
      !parentId
  );
  const [updatedByCreateReturnWaste, setUpdatedByCreateReturnWaste] =
    useState(false);

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const tableRow = tableRowDefault(docType);

  const handleRadioButtonClick = () => {
    setIsGrouped((prevState) => !prevState);
  };

  const isSelectedRow = (index) => {
    return selectedRows.indexOf(index) !== -1;
  };

  const handleLoadFiles =
    (docType) =>
    (acceptedFiles, rejectedFiles, replaceFile, additionalDocType) => {
      if (acceptedFiles)
        acceptedFiles.forEach((file) => {
          dispatch(
            features.productUsage.actions.uploadDocFileRequest({
              params: {
                basisDocumentUuid: data.uuid,
                replaceUuid: replaceFile?.uuid
              },
              fields: {
                fileType: additionalDocType ?? docType,
                file: Object.assign(file, {
                  alias: additionalDocType ?? docType
                })
              }
            })
          );
        });

      parseFilesErrors(rejectedFiles);
    };

  const onDeleteFile = async (file, uuid?) => {
    await new Promise((resolve) => {
      dispatch(
        features.productUsage.actions.deleteDocFileRequest({
          params: {
            documentUuid: uuid ?? data.uuid,
            fileUuid: file.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const handleFormSubmit = (values) => {
    let files = data.files;
    data?.additionalDocuments.forEach((element) => {
      files = files.filter((item) => item.type !== element.typeName);
    });
    if (
      (!(files.length > 0) ||
        files.find(
          (file) => file.progress !== 100 && file.progress !== undefined
        )) &&
      data.supportDocumentTypeAlias !== "ttn-wood" &&
      data.supportDocumentTypeAlias !== "transfer"
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }
    if (!selectedRows.length) {
      toastr.error("Помилка", "Виберіть продукцію");
      return;
    }

    if (
      getSelectedProducts.findIndex((item) => item.woodCard === null) !== -1
    ) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "ERROR",
          modalProps: {
            title: "Увага",
            description:
              "Оберіть продукцію з лісорубним квитком. У разі відсутності лісорубного квитка зверніться до оператора ЕОД."
          }
        })
      );
      return;
    }

    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Ви впевнені що ви ввели правильні обсяги використання? Підтверджуючи дію ви резервуєте обсяги даного ДП для вашої заявки.",
          acceptLabel: "Все вірно, продовжити",
          onAccept: () => {
            const products = getSelectedProducts.map((item) => {
              return {
                productUuid: item.uuid,
                takenQuantity: parseFloat(item.decommissioned)
              };
            });

            dispatch(
              features.productUsage.actions.joinDocumentRequest({
                params: {
                  applicationUuid: applicationId,
                  docId: data.uuid
                },
                fields: {
                  primal: !parentId,
                  parentId: chain === "transition" ? parentId : null,
                  products,
                  chainNumber: getChainNumber(application?.basisDocuments)
                },
                onSuccess: () => {
                  navigate(`/personal/applications/${applicationId}`);
                },
                onError: (e) =>
                  parseResErrors({
                    setFieldError: formik.setFieldError,
                    errorsRes: e,
                    fields: values
                  })
              })
            );
          }
        }
      })
    );
  };

  const productsYup = (tableType) => {
    return Yup.array().of(
      Yup.object().shape({
        decommissioned: Yup.number()
          .transform((_, value) => {
            if (typeof value === "number") return value;
            return parseFloat(value.replace(/,/, "."));
          })
          .typeError("Значення має бути числовим")
          .nullable()
          .when(["id", "available"], ([id, available], schema) => {
            return selectedTable === tableType && isSelectedRow(id)
              ? schema
                  .transform((_, value) => {
                    if (typeof value === "number") return value;
                    return parseFloat(value.replace(/,/, "."));
                  })
                  .typeError("Значення має бути числовим")
                  .positive("Значення має бути більше 0")
                  .test(
                    "is-decimal",
                    "Максимальна кількість значень після коми " +
                      NUMBER_OF_DECIMALS,
                    (val: any) => {
                      if (val !== undefined) {
                        return testDigitsAfterComma(val, NUMBER_OF_DECIMALS);
                      }
                      return true;
                    }
                  )
                  .required("Заповніть значення")
                  .max(
                    available,
                    "Значенния Списується не повинно бути більше ніж доступно"
                  )
              : schema.notRequired();
          })
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      original: 0,
      available: 0,
      decommissioned: 0,
      totalSum: 0,
      totalSumPDV: 0,
      original_summary: 0,
      available_summary: 0,
      decommissioned_summary: 0,
      amount: 0,
      amountPDV: 0,
      products: [tableRow]
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      products: productsYup("products"),
      wasteProducts: productsYup("wasteProducts")
    }),
    onSubmit: handleFormSubmit
  });

  const columns = useCallback(
    (isWaste = false) => {
      const isDisabledSelect =
        ((isWaste && selectedTable === "products") ||
          (!isWaste && selectedTable === "wasteProducts")) &&
        selectedRows.length > 0;

      const columnsData = generateColumnData({
        formik,
        docType,
        isWaste,
        isDisabledSelect
      });

      return columnsData;
    },
    [docType, formik, selectedTable, selectedRows]
  );

  const getSelectedProducts = useMemo(() => {
    const selectedProducts =
      selectedRows.length > 0
        ? selectedRows.map((index) => formik.values[selectedTable][index])
        : [];

    return selectedProducts.filter((item) => item !== undefined);
  }, [formik.values[selectedTable], selectedRows]);

  const initTotalsOfSelected = {
    amount: 0,
    amountPDV: 0,
    decommissioned: 0
  };

  const [totalsOfSelected, setTotalsOfSelected] =
    useState(initTotalsOfSelected);

  useEffect(() => {
    if (getSelectedProducts.length > 0) {
      const { amount, amountPDV, decommissioned } =
        calculateTotals(getSelectedProducts);
      setTotalsOfSelected({ amount, amountPDV, decommissioned });
    } else {
      setTotalsOfSelected(initTotalsOfSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedProducts]);

  const [parsedProducts, setParsedProducts] = useState(false);

  useEffect(() => {
    if (data && data?.products.length && docType && !parsedProducts) {
      setParsedProducts(true);
      const products = productResParser(data?.products, docType);
      formik.setFieldValue("products", products);
    }
  }, [data, docType, parsedProducts]);

  const [parsedWasteProducts, setParsedWasteProducts] = useState(false);

  useEffect(() => {
    if (
      data &&
      data?.wasteProducts?.length &&
      docType &&
      !parsedWasteProducts
    ) {
      setParsedWasteProducts(true);
      const products = productResParser(data.wasteProducts, docType);
      formik.setFieldValue("wasteProducts", products);
    }
  }, [data, docType, parsedWasteProducts]);

  const onRowsSelected = useCallback(
    (items, isWaste) => {
      if (updatedByCreateReturnWaste) {
        //IMPORTANT not best solution
        if (!isWaste && !items.length) {
          setUpdatedByCreateReturnWaste(false);
          setSelectedTable(null);
          setSelectedRows(items);
        } else if (!isWaste && items.length) setSelectedRows(items);

        return;
      }
      if (items.length)
        setSelectedTable(isWaste ? "wasteProducts" : "products");
      else setSelectedTable(null);
      setSelectedRows(items);
    },
    [updatedByCreateReturnWaste]
  );

  const backPath = useMemo(() => {
    const path = parentId
      ? `transition/search?parent=${parentId}`
      : "primary/search";
    return `/personal/applications/${applicationId}/documents/${path}`;
  }, [applicationId, parentId]);

  const onBack = useCallback(() => navigate(backPath), [backPath, navigate]);

  const searchTitle = useMemo(() => {
    return getSearchTitle(!parentId, application?.useWoodCard);
  }, [application?.useWoodCard, parentId]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: searchTitle,
        to: backPath
      },
      {
        text: "Зазначання обсягів",
        to: "/"
      }
    ],
    [applicationId, backPath, searchTitle]
  );

  useEffect(() => {
    if (data && isEmptyFiles === null) {
      if (
        data.supportDocumentTypeAlias === "ttn-wood" ||
        data.supportDocumentTypeAlias === "transfer"
      ) {
        setIsEmptyFiles(false);
      } else {
        setIsEmptyFiles(!(data.files.length > 0));
      }
    }
  }, [data, isEmptyFiles]);

  const handlePreviousUsageClick = () => {
    const isPrimal = !parentId ? "primal" : "transition";
    window.open(
      `/personal/applications/${applicationId}/documents/${
        chain || isPrimal
      }/${docType}/${docId}/previous-usage`,
      "_blank"
    );
  };

  const onCreateReturnWaste = useCallback(() => {
    if (selectedRows.length && selectedTable === "products")
      setUpdatedByCreateReturnWaste(true);
  }, [selectedRows.length, selectedTable]);

  useEffect(
    () => () => {
      dispatch(features.productUsage.actions.clearDocumentData());
      dispatch(features.application.actions.clearApplication());
      dispatch(features.subjectDocs.actions.clearDoc());
    },
    []
  );

  return (
    <ApplicationLayout className={styles["specify-usage"]}>
      <Breadcrumbs elements={breadcrumbs} />
      <h1 className={styles["specify-usage__title"]}>
        Вказати поточне використання
      </h1>
      {/* TODO delete */}
      {/* {data && (
        <TransitionDocSearchResult
          loadedFiles={data.files}
          additionalDocuments={data.additionalDocuments}
          image={DocIcon}
          type={data.typeName}
          serial={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          sellerTitle={returnDocTitle()}
          sellerText={data.sourceOrganizationName}
          sellerOrganizationEdrpou={data.sourceOrganizationEdrpou}
          buyerTitle={"Контрагент"}
          buyerText={data.recipientOrganizationName}
          buyerOrganizationEdrpou={data.recipientOrganizationEdrpou}
          originalCountTitle="Кількість оригінал"
          originalCountText={data.quantityOrigin}
          availableCountTitle="Кількість доступно"
          availableCountText={data.quantityAvailable}
          className={styles["specify-usage__doc"]}
          allowUpload={isEmptyFiles}
          onLoadFiles={handleLoadFiles(
            data.supportDocumentTypeAlias === "md"
              ? "customs_declaration_scan_copy"
              : data.supportDocumentTypeAlias + "_scan_copy"
          )}
          onDeleteFile={onDeleteFile}
        />
      )} */}
      {data && (
        <DocumentForUse
          className={styles["specify-usage__doc"]}
          loadedFiles={data.files}
          additionalDocuments={data.additionalDocuments}
          docImg={DocIcon}
          type={getDocTypeAlias(data.typeName)}
          typeName={data.typeName}
          series={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          seller={{
            sellerName: data.sourceOrganizationName,
            sellerExternalId: data.sourceOrganizationExternalId,
            sellerEdrpou: data.sourceOrganizationEdrpou
          }}
          shopper={{
            shopperName: data.recipientOrganizationName,
            shopperExternalId: data.recipientOrganizationExternalId,
            shopperEdrpou: data.recipientOrganizationEdrpou
          }}
          isPrimal={!parentId}
          onLoadFiles={handleLoadFiles(
            data.supportDocumentTypeAlias === "md"
              ? "customs_declaration_scan_copy"
              : data.supportDocumentTypeAlias + "_scan_copy"
          )}
          onDeleteFile={onDeleteFile}
          documentId={data.externalId}
          uuid={data.uuid}
          initialCount={data.quantityOrigin}
          availableCount={data.quantityAvailable}
          wasteQuantity={data.wasteQuantity}
          wasteQuantityAvailable={data.wasteQuantityAvailable}
          wasteQuantityPercent={data.wasteQuantityPercent}
          allowUpload={isEmptyFiles}
          isHiddenControls
        />
      )}
      <div className={styles["specify-usage__table-controls"]}>
        <CommonButton
          className={styles["specify-usage__table-controls-button"]}
          label="Попереднє використання"
          outlined={true}
          image={EyeIcon}
          onClick={handlePreviousUsageClick}
        />
        <div
          className={
            styles["specify-usage__table-controls-radio-button-wrapper"]
          }
        >
          <SwitchButton active={isGrouped} onChange={handleRadioButtonClick} />
          <p>Згрупувати</p>
        </div>
        {/* {data?.typeName !== PrimaryDocumentTypes.ttn_wood &&
          data?.typeName !== PrimaryDocumentTypes.transfer && (
            <div className={styles["specify-usage__table-controls-text"]}>
              <p>Стандарт:</p>
              {data?.products[0].productStandardName && (
                <p>{data.products[0].productStandardName}</p>
              )}
            </div>
          )} */}
        <div className={styles["specify-usage__table-controls-text"]}>
          <p>Геометричні розміри:</p>
          {data?.products[0]?.geometricDimension && (
            <p>{data.products[0]?.geometricDimension}</p>
          )}
        </div>
      </div>
      <FormikProvider value={formik}>
        <Form className={styles["specify-usage__table-form"]}>
          <EditableTable
            formik={formik}
            defaultData={formik.values["products"]}
            columns={columns()}
            footerTitle="Всього"
            removeControls={true}
            groupData={isGrouped}
            onRowsSelected={onRowsSelected}
            readonlyNotSelected
            footer={[
              {
                name: "original",
                label: "Оригінал",
                value: data?.quantityOrigin
              },
              {
                name: "available",
                label: "Доступно",
                value: data?.quantityAvailable
              },
              {
                name: "decommissioned",
                label: "Списано",
                value: (selectedTable === "products"
                  ? totalsOfSelected
                  : initTotalsOfSelected
                ).decommissioned
              },
              ...(docType !== "transfer"
                ? [
                    {
                      name: "amount",
                      label: "Сума",
                      value: (selectedTable === "products"
                        ? totalsOfSelected
                        : initTotalsOfSelected
                      ).amount
                    },
                    {
                      name: "amountPDV",
                      label: "Сума з ПДВ",
                      value: (selectedTable === "products"
                        ? totalsOfSelected
                        : initTotalsOfSelected
                      ).amountPDV
                    }
                  ]
                : [])
            ]}
          />
          {formik.values["wasteProducts"] && isReturnWasteProductsDoc && (
            <>
              <div>Зворотні відходи</div>
              <div className={styles["specify-usage__table-controls"]}>
                <div className={styles["specify-usage__table-controls-text"]}>
                  <p>Геометричні розміри:</p>
                  {data?.wasteProducts[0]?.geometricDimension && (
                    <p>{data.wasteProducts[0]?.geometricDimension}</p>
                  )}
                </div>
              </div>
              <EditableTable
                formik={formik}
                defaultData={formik.values["wasteProducts"]}
                columns={columns(true)}
                footerTitle="Всього"
                removeControls={true}
                onRowsSelected={onRowsSelected}
                isWaste
                readonlyNotSelected
                footer={[
                  {
                    name: "original",
                    label: "Оригінал",
                    value: data?.wasteQuantity
                  },
                  {
                    name: "available",
                    label: "Доступно",
                    value: data?.wasteQuantityAvailable
                  },
                  {
                    name: "decommissioned",
                    label: "Списано",
                    value: (selectedTable === "wasteProducts"
                      ? totalsOfSelected
                      : initTotalsOfSelected
                    ).decommissioned
                  },
                  ...(docType !== "transfer"
                    ? [
                        {
                          name: "amount",
                          label: "Сума",
                          value: (selectedTable === "wasteProducts"
                            ? totalsOfSelected
                            : initTotalsOfSelected
                          ).amount
                        },
                        {
                          name: "amountPDV",
                          label: "Сума з ПДВ",
                          value: (selectedTable === "wasteProducts"
                            ? totalsOfSelected
                            : initTotalsOfSelected
                          ).amountPDV
                        }
                      ]
                    : [])
                ]}
              />
            </>
          )}
          <FormControls onDecline={onBack} acceptButtonType={"submit"} />
        </Form>
        <ScrollToFieldError />
      </FormikProvider>
      {isReturnWasteProductsDoc && !formik.values["wasteProducts"] && (
        <CreateReturnWaste
          documentUuid={data.uuid}
          onAccept={onCreateReturnWaste}
          size={data?.products[0]?.geometricDimensionUuid}
        />
      )}
    </ApplicationLayout>
  );
};

export default SpecifyCurrentUsageLayout;
