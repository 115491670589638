import cn from "classnames";

import styles from "./disabled-wrapper.module.scss";

const DisabledWrapper = (props) => {
  const { disabled, children } = props;

  return (
    <div
      className={cn(styles["disabled-container"], {
        [styles["disabled-container--active"]]: disabled
      })}
    >
      {children}
    </div>
  );
};

export default DisabledWrapper;
