import cn from "classnames";
import { PrintIcon } from "gov-ua-ui";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { applicationRouteStatuses } from "dataset/applicationStatuses";
import { getRole } from "helpers";
import { IRootState } from "reducer";
import { IApplicationReviewState } from "scenes/official/certificates/OfficialApplicationReviewPage/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import CircleProgressBar from "components/CircleProgressBar/CircleProgressBar";
import CountdownTimer from "components/CountdownTimer/CountdownTimer";
import IconButton from "components/buttons/IconButton/IconButton";
import HeaderWrapper from "components/headers/HeaderWrapper/HeaderWrapper";
import useMediaQuery from "components/useMediaQuery";

import ArrowLeftIcon from "assets/images/icons/arrow_left.svg";
import PlusIcon from "assets/images/icons/plus.svg";

import styles from "./info-header.module.scss";

interface InfoHeaderInterface {
  children?: any;
  isOfficial?: boolean;
  infoType?: string;
}

const InfoHeader = ({
  isOfficial = false,
  infoType
}: InfoHeaderInterface): JSX.Element => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(992);
  const { applicationId, applicationType } = useParams<{
    applicationId?: string;
    applicationType?: string;
  }>();
  const [userRole] = useState(getRole());

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const { application: applicationOfficial } = useSelector<
    IRootState,
    IApplicationReviewState
  >((state) => state.officialApplicationReview);

  const prevLink = useMemo(() => {
    if (userRole === "user") {
      const linkApplicationStatus =
        applicationRouteStatuses[application?.status] ?? "drafts";

      return `/personal/applications/${linkApplicationStatus}`;
    } else {
      if (applicationType === "archived") return "/official-archival";
      if (applicationType === "annulled") return "/official-annul/annulled";
      return "/official-in-progress";
    }
  }, [application?.status, applicationType, userRole]);

  const headerInfo = useMemo(() => {
    switch (infoType) {
      case "TO_PERSONAL":
        return {
          title: "Повернутися до кабінету",
          link: prevLink
        };
      case "TO_APPLICATION":
        return {
          title: "Назад до подачі заяви",
          link: `/personal/applications/${applicationId}`
        };
      default:
        return {
          title: "",
          link: ""
        };
    }
  }, [infoType, prevLink, applicationId]);

  const handleButtonClick = () => {
    navigate(headerInfo.link);
  };

  const productYieldPercentage = useMemo(() => {
    const value = isOfficial
      ? applicationOfficial?.productYieldPercentage
      : application?.productYieldPercentage;
    return value != null ? value + "%" : "-";
  }, [
    application?.productYieldPercentage,
    applicationOfficial?.productYieldPercentage,
    isOfficial
  ]);

  const costStandards = useMemo(() => {
    const value = isOfficial
      ? applicationOfficial?.costStandards
      : application?.costStandards;
    return value ?? "-";
  }, [
    application?.costStandards,
    applicationOfficial?.costStandards,
    isOfficial
  ]);

  return (
    <HeaderWrapper
      className={styles["info-header"]}
      leftPart={
        <div className={styles["info-header__left"]}>
          <IconButton icon={ArrowLeftIcon} onClick={handleButtonClick}>
            <p className={styles["info-header__disappear-part"]}>
              {headerInfo.title}
            </p>
          </IconButton>
          <div
            className={cn(
              styles["info-header__flex-item"],
              styles["info-header__grey-text"],
              styles["info-header__disappear-part"]
            )}
          >
            {application?.beKeptUntil && application?.status === "draft" && (
              <>
                <p className={styles["info-header__grey-text"]}>
                  {isOfficial ? "Час на обробку" : " Час зберігання чернетки"}
                </p>
                <CountdownTimer
                  endTime={application.beKeptUntil}
                  completedText="час вийшов. обсяги розблоковані"
                  className={styles["info-header__clock"]}
                />
              </>
            )}
          </div>
          {applicationType !== "archived" &&
            applicationOfficial?.type !== "annulment" &&
            (applicationOfficial || application) && (
              <div
                className={cn(
                  styles["info-header__flex-item"],
                  styles["info-header__grey-text"]
                )}
              >
                <p
                  className={cn(
                    styles["info-header__grey-text"],
                    styles["info-header__disappear-part"]
                  )}
                >
                  {isOfficial ? "Перевірено на" : "Заповнено на"}
                </p>
                {!isMobile && (
                  <CircleProgressBar
                    progress={
                      isOfficial
                        ? applicationOfficial?.checkedPercent
                        : application?.completionPercentage
                    }
                  />
                )}
              </div>
            )}
        </div>
      }
      centerPart={
        <div className={styles["info-header__center"]}>
          <div
            className={cn(
              styles["info-header__flex-item"],
              styles["info-header__grey-text"]
            )}
          >
            {application?.beKeptUntil && application?.status === "draft" && (
              <>
                <p className={styles["info-header__clock-text"]}>
                  Час зберігання чернетки
                </p>
                <CountdownTimer
                  endTime={application.beKeptUntil}
                  completedText="час вийшов. обсяги розблоковані"
                  className={styles["info-header__clock"]}
                />
              </>
            )}

            {isMobile && (
              <CircleProgressBar progress={application?.completionPercentage} />
            )}
          </div>
        </div>
      }
      rightPart={
        !isMobile ? (
          <div className={styles["info-header__right"]}>
            <p className={styles["info-header__right-text"]}>
              Відсоток виходу продукції
              <span>{productYieldPercentage}</span>
            </p>
            <p className={styles["info-header__right-text"]}>
              Нормативи витрат
              <span>{costStandards}</span>
            </p>
            <IconButton
              icon={PlusIcon}
              className={styles["info-header__right-btn"]}
            />
          </div>
        ) : (
          <div className={styles["info-header__statistics-container"]}>
            <p className={styles["info-header__statistics-text"]}>
              {productYieldPercentage}
            </p>
            <p className={styles["info-header__statistics-text"]}>
              {costStandards}
            </p>
            <PrintIcon />
          </div>
        )
      }
    />
  );
};

export default InfoHeader;
