import cn from "classnames";

import styles from "./section-delimiter.module.scss";

const SectionDelimiter = ({
  className
}: {
  className?: string;
}): JSX.Element => {
  return <hr className={cn(styles["section-delimiter"], className)} />;
};

export default SectionDelimiter;
