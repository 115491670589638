import { CommonButton, Dialog } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { IRootState } from "reducer";

import CircleProgressBar from "components/CircleProgressBar/CircleProgressBar";
import ResponseErrors from "components/ResponseErrors/ResponseErrors";

import styles from "./generate-e-document-error-modal.module.scss";

interface GenerateEDocumentErrorModalInterface {
  percentages?: number;
  error: Array<string>;
  onClose: () => void;
}

const GenerateEDocumentErrorModal = () => {
  const { percentages, error, onClose } = useSelector<
    IRootState,
    GenerateEDocumentErrorModalInterface
  >((state) => state.modal.modalProps);

  const dispatch = useDispatch();

  const closeDialog = () => {
    onClose();
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <Dialog
      isOpen
      className={styles["modal-container"]}
      onClose={closeDialog}
      closeTimeoutMS={500}
    >
      <div className={styles["error-modal"]}>
        <h2 className={styles["error-modal__title"]}>Увага!</h2>
        {percentages !== null && !Number.isNaN(Number(percentages)) && (
          <div className={styles["error-modal__progress"]}>
            <p className={styles["error-modal__progress-title"]}>Перевірено</p>
            <CircleProgressBar progress={percentages} mobile={false} />
          </div>
        )}
        <ResponseErrors errors={error} />
        <CommonButton
          label={"ОК"}
          onClick={closeDialog}
          className={styles["close-btn"]}
        />
      </div>
    </Dialog>
  );
};
export default GenerateEDocumentErrorModal;
