import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Footer from "components/Footer/Footer";
import NotificationsBoard from "components/NotificationsBoard/NotificationsBoard";
import Toast from "components/Toast/Toast";
import BackHeader from "components/headers/BackHeader/BackHeader";
import InfoHeader from "components/headers/InfoHeader/InfoHeader";
import MainHeader from "components/headers/MainHeader/MainHeader";
import PrintHeader from "components/headers/PrintHeader/PrintHeader";
import ModalRoot from "components/modals/ModalRoot";

import styles from "./layout.module.scss";

interface LayoutInterface {
  title?: string;
  meta?: string;
  children: any;
  headerType: "NO_HEADER" | "DEFAULT" | "INFO" | "PRINT" | "BACK";
  infoType?: "TO_PERSONAL" | "TO_APPLICATION";
  isOfficial?: boolean;
  isHideFooter?: boolean;
}

const Layout = ({
  headerType = "DEFAULT",
  children,
  infoType,
  isOfficial,
  isHideFooter
}: LayoutInterface) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getHeader = (): JSX.Element | null => {
    switch (headerType) {
      case "DEFAULT":
        return <MainHeader />;
      case "INFO":
        return <InfoHeader infoType={infoType} isOfficial={isOfficial} />;
      case "PRINT":
        return <PrintHeader />;
      case "BACK":
        return <BackHeader />;
      default:
        return null;
    }
  };

  return (
    <>
      <header className={styles["header"]}>
        {headerType !== "NO_HEADER" && <NotificationsBoard />}
        {getHeader()}
      </header>
      {children}
      {!isHideFooter && <Footer />}
      <ModalRoot />
      <Toast />
    </>
  );
};

export default Layout;
