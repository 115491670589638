import cn from "classnames";
import { CloseIcon } from "gov-ua-ui";
import React from "react";

import TripleDotsIcon from "assets/images/icons/triple_dots.svg";

import styles from "./round-menu-button.module.scss";

export interface IRoundMenuButton {
  className?: string;
  label?: string;
  toggleMenuButton?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSideMenuOpen?: boolean;
}

const RoundMenuButton = ({
  className,
  label,
  toggleMenuButton,
  onClick,
  isSideMenuOpen
}: IRoundMenuButton) => {
  return (
    <button
      className={cn(styles["round-menu-button-wrapper"], className)}
      onClick={onClick}
    >
      {toggleMenuButton &&
        (!isSideMenuOpen ? (
          <div className={styles["round-menu-button__images"]}>
            <img src={TripleDotsIcon} alt="..." />
            <img src={TripleDotsIcon} alt="..." />
            <img src={TripleDotsIcon} alt="..." />
          </div>
        ) : (
          <div className={styles["round-menu-button__images"]}>
            <CloseIcon className={styles["round-menu-button__close-image"]} />
          </div>
        ))}
      <div className={cn(styles["round-menu-button__text"])}>
        {toggleMenuButton || label}
      </div>
    </button>
  );
};

export default RoundMenuButton;
