import { CommonButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import features from "features";
import { parseResErrors } from "helpers";
import { getPrevRoute } from "helpers/getPrevRoute";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { fetchEApplicationFileData } from "services/applications";
import { IApplicationState } from "../../ApplicationPage/ducks";

import PdfReader from "components/PdfReader/PdfReader";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

import styles from "./signature-application-page.module.scss";

const SignatureApplicationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { eApplication, applyingApplicationLoading, applyingApplicationError } =
    useSelector<IRootState, IApplicationState>((state) => state.application);

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { applicationId } = useParams<{
    applicationId?: string;
  }>();

  const prevRoute = useMemo(() => {
    return getPrevRoute(pathname);
  }, [pathname]);

  const goBack = useCallback(() => {
    dispatch(features.application.actions.clearApplicationError());
    navigate(prevRoute);
  }, [dispatch, navigate, prevRoute]);

  const submit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "SIGNATURE",
        modalProps: {
          modalTitle: `Підписати та відправити заяву`,
          processedName: "Заявник",
          processedBy: currentSubject.fullName,
          location: currentSubject.organizationName,
          getFileUrl: async () =>
            await fetchEApplicationFileData({
              params: {
                applicationUuid: applicationId
              }
            }),
          fileUrl: eApplication,
          onAccept: (signedFile) => {
            dispatch(features.modal.actions.hideModal());
            dispatch(
              features.modal.actions.showModal({
                modalType: "PRELOADER",
                modalProps: {
                  title: "Опрацювання запиту",
                  loading: true
                }
              })
            );

            dispatch(
              features.application.actions.applyApplicationRequest({
                params: {
                  applicationUuid: applicationId
                },
                fields: {
                  signedFile
                },
                onSuccess: () => {
                  dispatch(features.modal.actions.hideModal());
                  toastr.success(
                    "Успіх",
                    "Заявка успішно подана, чекайте на повідомлення."
                  );
                  navigate("/personal/applications/ordered");
                },
                onError: (errorsRes) => parseResErrors({ errorsRes })
              })
            );
          }
        }
      })
    );
  }, [eApplication, dispatch, currentSubject, applicationId, navigate]);

  useEffect(() => {
    if (eApplication === null) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Створюється єЗаява",
            loading: true
          }
        })
      );
      dispatch(
        features.application.actions.fetchEApplicationDataRequest({
          params: { uuid: applicationId },
          onSuccess: () => {
            dispatch(
              features.modal.actions.hideModal({
                modalType: "PRELOADER"
              })
            );
          },
          onError: () => {
            dispatch(
              features.modal.actions.hideModal({
                modalType: "PRELOADER"
              })
            );
          }
        })
      );
    }
  }, [dispatch, applicationId, eApplication]);

  useEffect(() => {
    dispatch(features.modal.actions.hideModal());
  }, [dispatch, applyingApplicationError]);

  return (
    <div className={styles["content"]}>
      <PreloaderWrapper loading={applyingApplicationLoading}>
        <PdfReader file={eApplication} />
        {!applyingApplicationLoading && (
          <div className={styles["controls"]}>
            <CommonButton
              label={"Назад до редагування"}
              outlined={true}
              onClick={goBack}
            ></CommonButton>
            <CommonButton
              label={"Підписати та відправити"}
              onClick={submit}
            ></CommonButton>
          </div>
        )}
      </PreloaderWrapper>
    </div>
  );
};

export default SignatureApplicationPage;
