import cn from "classnames";
import { Notification, Title } from "gov-ua-ui";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { getDocTypeAlias } from "constant";
import features from "features";
import { formatDate, parseResErrors } from "helpers";
import { getSearchTitle } from "helpers/bread-crumbs";
import { IRootState } from "reducer";
import { ISubjectDocs } from "scenes/subject/documents/ducks";

import DocumentForUse from "components/certificateComponents/viewCertificateComponents/DocumentForUse/DocumentForUse";
import FormLayout from "components/layouts/FormLayout/FormLayout";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

import EdocIcon from "assets/images/icons/e_doc.svg";

import styles from "./success-document-creation-page.module.scss";

const SuccessDocumentCreationPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const parentId = parseInt(searchParams.get("parent"));

  const { applicationId, documentId } = useParams();
  const { document, fetchingDocLoading } = useSelector<
    IRootState,
    ISubjectDocs
  >((state) => state.subjectDocs);

  const backPath = useMemo(() => {
    const path = parentId
      ? `transition/search?parent=${parentId}`
      : "primary/search";
    return `/personal/applications/${applicationId}/documents/${path}`;
  }, [applicationId, parentId]);

  const searchTitle = useMemo(() => {
    return getSearchTitle(!parentId);
  }, [parentId]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: searchTitle,
        to: backPath
      },
      {
        text: "Успішно створений документ",
        to: "/"
      }
    ],
    [applicationId, backPath, searchTitle]
  );

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId }
        })
      );
    }
    if (documentId) {
      dispatch(
        features.subjectDocs.actions.fetchDocRequest({
          params: {
            uuid: documentId
          },
          fields: {
            validateForApplication: applicationId,
            validateForParentId: parentId.toString()
          },
          onError: (errorsRes) => {
            parseResErrors({
              errorsRes
            });
          }
        })
      );
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(features.application.actions.clearApplication());
      dispatch(features.subjectDocs.actions.clearDoc());
    },
    []
  );

  return (
    <FormLayout breadcrumbs={breadcrumbs}>
      <PreloaderWrapper loading={fetchingDocLoading} withModal>
        <div className={styles["success-document-creation"]}>
          <Title
            size={32}
            className={styles["success-document-creation__title"]}
          >
            Успіх
          </Title>

          <Notification
            text="Ви додали документ в базу документів. Тепер ви можете вказати поточне використання об’ємів"
            className={cn(styles["success-document-creation__notification"])}
          />
          <div
            className={
              styles["success-document-creation__document-for-use-container"]
            }
          >
            {document && (
              <DocumentForUse
                documentId={document.externalId}
                uuid={document.uuid}
                loadedFiles={document?.files}
                additionalDocuments={document?.additionalDocuments}
                docImg={EdocIcon}
                type={getDocTypeAlias(document.typeName)}
                typeName={document.typeName}
                series={document.series}
                number={document.number}
                date={formatDate(document.date).date}
                seller={{
                  sellerName: document.sourceOrganizationName,
                  sellerExternalId: document.sourceOrganizationExternalId,
                  sellerEdrpou: document.sourceOrganizationEdrpou
                }}
                shopper={{
                  shopperName: document.recipientOrganizationName,
                  shopperExternalId: document.recipientOrganizationExternalId,
                  shopperEdrpou: document.recipientOrganizationEdrpou
                }}
                initialCount={document.quantityOrigin}
                availableCount={document.quantityAvailable}
                wasteQuantity={document.wasteQuantity}
                wasteQuantityAvailable={document.wasteQuantityAvailable}
                wasteQuantityPercent={document.wasteQuantityPercent}
                isPrimal={!parentId}
                zsnList={document?.children}
                isCreatedMultiDoc
              />
            )}
          </div>
        </div>
      </PreloaderWrapper>
    </FormLayout>
  );
};

export default SuccessDocumentCreationPage;
