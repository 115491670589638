import { takeEvery } from "redux-saga/effects";
import * as API from "services";

import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchApplications({ payload }) {
  const request = API.fetchApplications;
  const {
    fetchApplicationsSuccess: setSuccess,
    fetchApplicationsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeEvery(actions.fetchApplicationsRequest().type, fetchApplications);
}
