import { createSlice } from "@reduxjs/toolkit";

export interface ILaw {
  id: any;
  title: string;
  link: string;
}
export interface ILegislativeBaseState {
  lawsLoading: boolean | null;
  laws: Array<ILaw>;
  responseError: any;
}

const initialState: ILegislativeBaseState = {
  lawsLoading: null,
  laws: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "legislativeBase",
  initialState,
  reducers: {
    fetchLegislativeBaseRequest: (state) => {
      state.lawsLoading = true;
    },
    fetchLegislativeBaseSuccess: (state, { payload }) => {
      state.lawsLoading = false;
      state.laws = payload;
    },
    fetchLegislativeBaseError: (state) => {
      state.lawsLoading = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
