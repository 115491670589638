import { CommonButton } from "gov-ua-ui";
import { cloneDeep, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PDV_PERCENT } from "constant";
import features from "features";
import { calculateTotals, formatNumber } from "helpers";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import Checkbox from "components/inputs/Checkbox/Checkbox";
import EditableTable from "components/tables/EditableTable/EditableTable";

import DeleteIcon from "assets/images/icons/delete.svg";

import styles from "./define-products.module.scss";

interface IEditableProductsColumns {
  formik: any;
  tableRow: any;
  enabledColumns: Array<string>;
  withPDV?: boolean;
  isForWaste?: boolean;
}

const EditableProductsTable = ({
  formik,
  tableRow,
  enabledColumns,
  withPDV,
  isForWaste
}: IEditableProductsColumns) => {
  const dispatch = useDispatch();

  const { standardList, unitList, speciesList, sortList, accountingList } =
    useSelector<IRootState, IDatasetState>((state) => state.dataset);

  useEffect(() => {
    dispatch(features.dataset.actions.fetchStandardListRequest());
    dispatch(features.dataset.actions.fetchSizeListRequest());
    dispatch(features.dataset.actions.fetchSpeciesListRequest());
    dispatch(features.dataset.actions.fetchSortListRequest());
    dispatch(features.dataset.actions.fetchUnitListRequest());
    dispatch(features.dataset.actions.fetchAccountingListRequest());
  }, []);

  const setSumFields = useCallback(
    (values) => {
      const { amount, quantity } = calculateTotals(values);

      formik.setFieldValue("count", quantity);
      formik.setFieldValue("amount", amount);

      if (withPDV) {
        formik.setFieldValue(
          "amountPDV",
          formatNumber(amount * PDV_PERCENT, 2, true)
        );
      }
    },
    [formik, withPDV]
  );

  const onCopyRowsClick = (items) => {
    const rows = formik.values["products"].slice();

    const selectedRows = items.map((item) => item.original);

    const updatedRows = rows.concat(cloneDeep(selectedRows));

    formik.setFieldValue("products", updatedRows);

    setSumFields(updatedRows);
  };

  const onCreateRowClick = () => {
    const productRow =
      formik.values["products"][formik.values["products"].length - 1];
    const newProductRow = cloneDeep(tableRow);

    Object.keys(productRow).forEach((key) => {
      if (
        ["standard", "productType", "wood", "unit", "uktzed"].indexOf(key) !==
        -1
      ) {
        newProductRow[key] = cloneDeep(productRow[key]);
      }
    });

    const rows = formik.values["products"].slice();

    const updatedRows = rows.concat(newProductRow);

    formik.setFieldValue("products", updatedRows);

    setSumFields(updatedRows);
  };

  const onRemoveRowClick = useCallback(
    (rowIndex) => {
      const rows = formik.values["products"].filter(
        (_, index) => index !== parseInt(rowIndex)
      );

      if (!rows.length) {
        formik.setFieldValue("products", [cloneDeep(tableRow)]);
      } else {
        formik.setFieldValue("products", rows);
      }

      setSumFields(rows);
    },
    [formik, setSumFields, tableRow]
  );

  const editableProductColumns = useMemo(() => {
    const columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className={styles["define-products__table-checkbox-wrapper"]}>
            <Checkbox
              checked={table.getIsAllPageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className={styles["define-products__table-checkbox-wrapper"]}>
            <Checkbox
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        )
      },
      {
        accessorKey: "standard",
        header: "Стандарт",
        meta: {
          fieldType: "SELECT",
          fieldOptions: standardList
        }
      },
      {
        accessorKey: "productType",
        header: "Продукція",
        meta: {
          fieldType: "AUTOCOMPLETE",
          searchEntity: "products",
          isForWaste
        }
      },
      {
        accessorKey: "clarification",
        header: "Уточнення",
        meta: {
          fieldType: "INPUT"
        }
      },
      {
        accessorKey: "wood",
        header: "Порода",
        meta: {
          fieldType: "SELECT",
          isMulti: true,
          fieldOptions: speciesList,
          isSearchable: true
        }
      },
      {
        accessorKey: "sort",
        header: "Сорт",
        meta: {
          fieldType: "SELECT",
          isMulti: true,
          fieldOptions: sortList,
          isSearchable: true
        }
      },
      {
        accessorKey: "diameter",
        header: "Діаметр",
        meta: {
          fieldType: "INPUT"
        }
      },
      {
        accessorKey: "diameterGroup",
        header: "Група діаметрів",
        meta: {
          fieldType: "INPUT"
        }
      },
      {
        accessorKey: "thickness",
        header: "Товщина",
        meta: {
          fieldType: "INPUT"
        }
      },
      {
        accessorKey: "width",
        header: "Ширина",
        meta: {
          fieldType: "INPUT"
        }
      },
      {
        accessorKey: "length",
        header: "Довжина",
        meta: {
          fieldType: "INPUT"
        }
      },
      {
        accessorKey: "count",
        header: "Кількість",
        meta: {
          fieldType: "INPUT",
          fieldValueType: "number"
        }
      },
      {
        accessorKey: "unit",
        header: "Од. вимір.",
        meta: {
          fieldType: "SELECT",
          fieldOptions: unitList
        }
      },
      {
        accessorKey: "price",
        header: "Ціна",
        meta: {
          fieldType: "INPUT",
          fieldValueType: "number"
        }
      },
      {
        accessorKey: "amount",
        header: "Вартість",
        meta: {
          fieldType: "INPUT",
          readonly: true
        }
      },
      {
        accessorKey: "uktzed",
        header: "УКТЗЕД",
        meta: {
          fieldType: "UKTZED"
        }
      },
      {
        accessorKey: "accounting",
        header: "Облік",
        meta: {
          fieldType: "SELECT",
          fieldOptions: accountingList
        }
      },
      {
        id: "actions",
        header: "Дії",
        cell: (info: any) => {
          return (
            <CommonButton
              image={DeleteIcon}
              round={true}
              outlined={true}
              onClick={() => onRemoveRowClick(info.row.id)}
              className={styles["define-products__table-btn"]}
            />
          );
        }
      }
    ];

    if (isEmpty(enabledColumns)) {
      return [];
    }

    return columns.filter(
      (item) => enabledColumns.indexOf(item.accessorKey || item.id) !== -1
    );
  }, [
    enabledColumns,
    formik,
    standardList,
    unitList,
    speciesList,
    sortList,
    onRemoveRowClick
  ]);

  const footerElements = useMemo(() => {
    const elements = [
      {
        name: "count",
        label: "Кількість"
      },
      {
        name: "amount",
        label: "Вартість"
      }
    ];

    if (withPDV) {
      elements.push({
        name: "amountPDV",
        label: "Сума з ПДВ"
      });
    }

    return elements;
  }, [formik, withPDV]);

  return (
    <EditableTable
      formik={formik}
      defaultData={formik.values["products"]}
      columns={editableProductColumns}
      onCopyRowsClick={onCopyRowsClick}
      onCreateRowClick={onCreateRowClick}
      footerTitle="Всього"
      footer={footerElements}
      withPDV={withPDV}
    />
  );
};

export default EditableProductsTable;
