export function converteFileToBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onloadend = function () {
      let base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}
