import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const Main = (props: any): JSX.Element => {
  return (
    <Layout
      title={""}
      meta={""}
      headerType="INFO"
      infoType="TO_PERSONAL"
      isOfficial={true}
    >
      <features.officialApplicationReview.page {...props} />
    </Layout>
  );
};

export default Main;
