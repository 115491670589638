import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import ErrorPage from "pages/error/error";
import Main from "pages/main";
import SettingsPage from "pages/settings";

// pages for subjects
import AnnulledApplicationsPage from "pages/personal/applications/annulled";
import ApplicationPage from "pages/personal/applications/application";
import DeclinedApplicationsPage from "pages/personal/applications/declined";
import DraftApplicationsPage from "pages/personal/applications/drafts";
import OrderedApplicationsPage from "pages/personal/applications/ordered";
import ReceivedApplicationsPage from "pages/personal/applications/received";
import SearchApplications from "pages/personal/applications/search";
import SignatureApplicationPage from "pages/personal/applications/signature-application";
import CertificateAnnulRequestPage from "pages/personal/certificates/certificate-annul-request";
import SignatureCertificateAnnulPage from "pages/personal/certificates/signature-certificate-annul";

import MDDocPage from "pages/personal/documents/MD-doc";
import PreviousUsagePage from "pages/personal/documents/previous-usage";
import ProductUsagePage from "pages/personal/documents/product-usage";
import AddBasisDocPage from "pages/personal/documents/search-basis-doc";
import TNDocPage from "pages/personal/documents/TN-doc";
import TTNDocPage from "pages/personal/documents/TTN-doc";
import ZNDocPage from "pages/personal/documents/ZN-doc";

import SuccessDocumentCreationPage from "pages/personal/certificates/success-document-creation";
import EducationalMaterials from "pages/personal/help/educational-materials";
import LegislativeBasePage from "pages/personal/help/legislative-base";
import RegularQuestionsPage from "pages/personal/help/regular-questions";
import RequestToSystemPage from "pages/personal/help/request-to-system";
import RequestToSystemListPage from "pages/personal/help/request-to-system-list";

// pages for an official
import OfficialApplicationReviewPage from "pages/official-personal/certificates/official-application-review";
import OfficialAnnulledApplicationsForSigningPage from "pages/official-personal/official-annulled-applications-for-signing";
import OfficialArchivalPage from "pages/official-personal/official-archival";
import OfficialDeclinedApplicationsForSigningPage from "pages/official-personal/official-declined-applications-for-signing";
import OfficialInProgressPage from "pages/official-personal/official-in-progress";
import OfficialMainPage from "pages/official-personal/official-main";

import OfficialCertificateAnnulRequestPage from "pages/official-personal/certificates/official-certificate-annul-request";
import OfficialCertificateSignPage from "pages/official-personal/certificates/official-certificate-signature";
import OfficialSignatureCertificateAnnulPage from "pages/official-personal/certificates/official-signature-certificate-annul";
import OfficialSignatureCertificateDeclineAnnulPage from "pages/official-personal/certificates/official-signature-certificate-decline-annul";
import OfficialSignatureDeclinedCertificatePage from "pages/official-personal/certificates/official-signature-certificate-declined";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Main />} />
      <Route path="/404" element={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/regular-questions" element={<RegularQuestionsPage />} />
      <Route path="/legislative-base" element={<LegislativeBasePage />} />
      <Route path="/educational-materials" element={<EducationalMaterials />} />

      <Route element={<ProtectedRoute role="user" />}>
        {/* Applications */}
        <Route
          path="/personal/applications/drafts"
          element={<DraftApplicationsPage />}
        />
        <Route
          path="/personal/applications/annulled"
          element={<AnnulledApplicationsPage />}
        />
        <Route
          path="/personal/applications/ordered"
          element={<OrderedApplicationsPage />}
        />
        <Route
          path="/personal/applications/declined"
          element={<DeclinedApplicationsPage />}
        />
        <Route
          path="/personal/applications/received"
          element={<ReceivedApplicationsPage />}
        />
        <Route
          path="/personal/applications/search"
          element={<SearchApplications />}
        />
        <Route
          path="/personal/applications/:applicationId"
          element={<ApplicationPage />}
        />
        <Route
          path="/personal/applications/:applicationId/e-application-confirmation"
          element={<SignatureApplicationPage />}
        />
        <Route
          path="/personal/applications/:applicationId/annul-request"
          element={<CertificateAnnulRequestPage />}
        />
        <Route
          path="/personal/applications/:applicationId/annul-request/e-annulment-confirmation"
          element={<SignatureCertificateAnnulPage />}
        />
        {/* Applications/Documents */}
        <Route
          path="/personal/applications/:applicationId/documents/:chain/search"
          element={<AddBasisDocPage />}
        />
        <Route
          path="/personal/applications/:applicationId/documents/:chain/:type/:docId/usage"
          element={<ProductUsagePage />}
        />
        <Route
          path="/personal/applications/:applicationId/documents/:chain/:type/:docId/previous-usage"
          element={<PreviousUsagePage />}
        />

        <Route
          path="/personal/applications/:applicationId/documents/TN/:documentId"
          element={<TNDocPage />}
        />
        <Route
          path="/personal/applications/:applicationId/documents/TTN/:documentId"
          element={<TTNDocPage />}
        />
        <Route
          path="/personal/applications/:applicationId/documents/MD/:documentId"
          element={<MDDocPage />}
        />
        <Route
          path="/personal/applications/:applicationId/documents/ZN/:documentId"
          element={<ZNDocPage />}
        />

        <Route
          path="/personal/applications/:applicationId/documents/:documentType/:documentId/success"
          element={<SuccessDocumentCreationPage />}
        />
        <Route path="/regular-questions" element={<RegularQuestionsPage />} />
        <Route path="/legislative-base" element={<LegislativeBasePage />} />
        <Route
          path="/request-to-system-list"
          element={<RequestToSystemListPage />}
        />
        <Route
          path="/request-to-system-list/:type"
          element={<RequestToSystemPage />}
        />
      </Route>

      <Route element={<ProtectedRoute role="officialUser" />}>
        <Route path="/official-main" element={<OfficialMainPage />} />
        <Route path="/official-annul/*">
          <Route
            path="annulled"
            element={<OfficialAnnulledApplicationsForSigningPage />}
          />
          <Route
            path="declined"
            element={<OfficialDeclinedApplicationsForSigningPage />}
          />
          <Route
            path=":applicationType/:applicationId/signing"
            element={<OfficialSignatureDeclinedCertificatePage />}
          />
        </Route>

        <Route path="/official-archival" element={<OfficialArchivalPage />} />
        <Route
          path="/official-in-progress"
          element={<OfficialInProgressPage />}
        />

        <Route
          path="/official/applications/:applicationId"
          element={<OfficialApplicationReviewPage />}
        />
        <Route
          path="/official/applications/:applicationType/:applicationId"
          element={<OfficialApplicationReviewPage />}
        />

        <Route
          path="/official/applications/:applicationId/annul-request"
          element={<OfficialCertificateAnnulRequestPage />}
        />
        <Route
          path="/official/applications/:applicationId/annul-request/e-annulment-confirmation"
          element={<OfficialSignatureCertificateAnnulPage />}
        />
        <Route
          path="/official/applications/:applicationId/e-certificate-confirmation"
          element={<OfficialCertificateSignPage />}
        />
        <Route
          path="/official/applications/:applicationId/e-certificate-deviation"
          element={<OfficialCertificateSignPage />}
        />
        <Route
          path="/official/applications/:applicationId/e-decline-confirmation"
          element={<OfficialSignatureCertificateDeclineAnnulPage />}
        />
        <Route
          path="/official/applications/:applicationId/documents/:chain/:type/:docId/previous-usage"
          element={<PreviousUsagePage />}
        />
      </Route>
    </>
  )
);

function Routes(): JSX.Element {
  return <RouterProvider router={router} />;
}

export default Routes;
