import { createSlice } from "@reduxjs/toolkit";
import { IFile } from "interfaces";

interface IApplication {
  productYieldPercentage: number;
  files: {
    eApplication: Array<IFile>;
    eCertificate: Array<IFile>;
    annulmentApplicationDocumentsCopy: Array<IFile>;
  };
  certificateNumber: string;
  decisionAt: number;
  createdAt: number;
  consigneeName: string;
  buyerName: string;
  invoiceDate: string;
  invoiceNumber: string;
  applicationStatus: string;
  customsClearance: string;
}

export interface ICertificateAnnulRequestState {
  application: IApplication;
  fetchingApplicationLoading: boolean;
  eAnnulmentUrl?: string;
  fetchingEAnnulmentUrlLoading: boolean;
  submitAnnulmentLoading: boolean;
  annulmentBasisDescription: string;
}

const initialState: ICertificateAnnulRequestState = {
  application: null,
  fetchingApplicationLoading: false,
  eAnnulmentUrl: null,
  fetchingEAnnulmentUrlLoading: false,
  annulmentBasisDescription: "",
  submitAnnulmentLoading: false
};

export const mainSlice = createSlice({
  name: "officialCertificateAnnulRequest",
  initialState,
  reducers: {
    fetchApplicationRequest: (state, ) => {
      state.fetchingApplicationLoading = true;
    },
    fetchApplicationSuccess: (state, action) => {
      state.fetchingApplicationLoading = false;
      state.application = action.payload;
    },
    fetchApplicationError: (state, ) => {
      state.fetchingApplicationLoading = false;
    },

    fetchEAnnulmentDataRequest: (state, action) => {
      state.annulmentBasisDescription =
        action.payload.fields.annulmentBasisDescription;
      state.fetchingEAnnulmentUrlLoading = true;
    },
    fetchEAnnulmentDataSuccess: (state, action) => {
      state.fetchingEAnnulmentUrlLoading = false;
      state.eAnnulmentUrl = action.payload.url;
    },
    fetchEAnnulmentDataError: (state, ) => {
      state.fetchingEAnnulmentUrlLoading = false;
    },

    submitEAnnulmentDataRequest: (state, ) => {
      state.submitAnnulmentLoading = true;
    },
    submitEAnnulmentDataSuccess: (state, action) => {
      state.submitAnnulmentLoading = false;
      state.eAnnulmentUrl = action.payload.url;
    },
    submitEAnnulmentDataError: (state, ) => {
      state.submitAnnulmentLoading = false;
    },

    loadReasonFileRequest: (state, action) => {
      const alias = action.payload?.fields.file.alias;

      const replaceUuid = action.payload.params.replaceUuid;

      const fileData = {
        name: action.payload?.fields?.file.name,
        progress: 0,
        size: action.payload?.fields?.file.size,
        createdAt: action.payload?.fileds?.file.createdAt,
        type: action.payload.fields.fileType
      };

      if (replaceUuid) {
        state.application.files[alias] = state.application.files[alias].map(
          (file) => (file.uuid === replaceUuid ? fileData : file)
        );
      } else {
        state.application.files[alias] = state.application.files[alias].concat([
          fileData
        ]);
      }
    },
    loadReasonFileSuccess: (state, action) => {
      state.application.files.annulmentApplicationDocumentsCopy =
        state.application.files.annulmentApplicationDocumentsCopy.map(
          (item) => {
            if (item.name === action.payload.fileName) {
              return {
                ...item,
                filePath: action.payload.filePath,
                uuid: action.payload.uuid,
                progress: 100,
                cancel: null
              };
            }
            return item;
          }
        );
    },
    loadReasonFileError: () => {},
    loadReasonFileProgress: (state, action) => {
      state.application.files.annulmentApplicationDocumentsCopy =
        state.application.files.annulmentApplicationDocumentsCopy.map(
          (item) => {
            if (item.name === action.payload.fileName) {
              return {
                ...item,
                progress: action.payload.progress
              };
            }
            return item;
          }
        );
    },
    saveLoadReasonFileCancelHandler: (state, action) => {
      state.application.files.annulmentApplicationDocumentsCopy =
        state.application.files.annulmentApplicationDocumentsCopy.map(
          (item) => {
            if (item.name === action.payload.fileName) {
              return {
                ...item,
                cancel: action.payload.cancel
              };
            }
            return item;
          }
        );
    },

    deleteReasonFileRequest: (state, action) => {
      if (!action.payload.params.uuid && action.payload.name) {
        state.application.files.annulmentApplicationDocumentsCopy =
          state.application.files.annulmentApplicationDocumentsCopy.filter(
            (item) => {
              return item.name !== action.payload.name;
            }
          );
      }
    },
    deleteReasonFileSuccess: (state, action) => {
      state.application.files.annulmentApplicationDocumentsCopy =
        state.application.files.annulmentApplicationDocumentsCopy.filter(
          (item) => {
            return item.uuid !== action.payload.uuid;
          }
        );
    },
    deleteReasonFileError: () => {}
  }
});

export const { actions, reducer } = mainSlice;
