import cn from "classnames";

import Countdown from "react-countdown";

import styles from "./countdown-timer.module.scss";

interface ClockInterface {
  endTime: number;
  className?: string;
  completedText?: string;
}

const CountdownTimer = ({
  endTime,
  className = "",
  completedText
}: ClockInterface) => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    return (
      <div className={cn(styles["clock"], className)}>
        <time
          dateTime={`${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }:${seconds < 10 ? "0" + seconds : seconds}`}
        >
          <p className={styles["clock__hours"]}>
            {hours < 10 ? "0" + hours : hours}
          </p>
          <span className={styles["clock__time-divider"]}>:</span>
          <p className={styles["clock__minutes"]}>
            {minutes < 10 ? "0" + minutes : minutes}
          </p>
          <span className={styles["clock__time-divider"]}>:</span>
          <p className={styles["clock__seconds"]}>
            {seconds < 10 ? "0" + seconds : seconds}
          </p>
        </time>
        {completed && (
          <div className={styles["clock__completed-text"]}>{completedText}</div>
        )}
      </div>
    );
  };

  return <Countdown date={endTime} renderer={renderer} />;
};

export default CountdownTimer;
