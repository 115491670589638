import { CommonButton, Notification } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import features from "features";
import { IRootState } from "reducer";
import { IRequestToSystemListState } from "../ducks";

import FormLayout from "components/layouts/FormLayout/FormLayout";
import Preloader from "components/Preloader/Preloader";

import styles from "./request-to-system-list-page.module.scss";

const breadcrumbs = [
  {
    text: "ЕкоСисема",
    to: `/`
  },
  {
    text: "Запит до системи",
    to: "/"
  }
];

const RequestToSystemListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { requestsListLoading, requestsToSystemList } = useSelector<
    IRootState,
    IRequestToSystemListState
  >((state) => state.subjectRequestToSystemList);

  useEffect(() => {
    dispatch(
      features.requestToSystemList.actions.fetchRequestsToSystemListRequest()
    );
  }, []);

  const handleLawRedirect = (type: string) => {
    return navigate(`/request-to-system-list/${type}`);
  };

  return (
    <FormLayout breadcrumbs={breadcrumbs}>
      <h1 className={styles["title"]}>Запит до системи</h1>
      <Notification text="Якщо ви хочете додати \ відредагувати довідники або у вас виникли питання по документах ви можете зробити запит до системи. Після погодження або відхилення вашого запиту вам прийде повідомлення." />
      {requestsListLoading ? (
        <Preloader />
      ) : (
        <div className={styles["get-certificate__questions"]}>
          {!isEmpty(requestsToSystemList) && (
            <div className={styles["service-items"]}>
              {requestsToSystemList.map(({ type, title }, index) => (
                <div className={styles["service-item"]} key={index}>
                  {title}
                  <CommonButton
                    label="Зробити запит"
                    className={styles["service-btn"]}
                    onClick={() => handleLawRedirect(type)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </FormLayout>
  );
};

export default RequestToSystemListPage;
