import { createSlice } from "@reduxjs/toolkit";

import { IOption } from "interfaces";

export const formatForSelect = (options) => {
  if (!options) return [];

  return options.map((item) => ({ value: item.uuid, label: item.name }));
};

export interface ISupportedDocumentsType {
  alias: string;
  disabled: boolean;
  name: string;
  uuid: string;
}

export interface IDatasetState {
  fetchingSupportedDocumentsListLoading: boolean;
  supportedDocumentsList: Array<ISupportedDocumentsType>;
  fetchingCurrencyListLoading: boolean;
  currencyList: Array<IOption>;
  fetchingDeliveryConditionListLoading: boolean;
  deliveryConditionList: Array<IOption>;
  fetchingSizeListLoading: boolean;
  sizeList: Array<IOption>;
  fetchingStandardListLoading: boolean;
  standardList: Array<IOption>;
  fetchingRequestTypeListLoading: boolean;
  requestTypeList: Array<IOption>;
  fetchingUnitListLoading: boolean;
  unitList: Array<IOption>;
  fetchingSpeciesListLoading: boolean;
  speciesList: Array<IOption>;
  fetchingSortListLoading: boolean;
  sortList: Array<IOption>;
  fetchingUktzedLoading: boolean;
  Uktzed: string;
  accountingList: Array<IOption>;
  fetchingAccountingListLoading: boolean;
  generatingDocumentsToDownloadLoading: boolean;
  generatedDocumentsToDownloadUrl: string;
  fetchingInterregionalOfficeExternalListLoading: boolean;
  interregionalOfficeExternalList: Array<any>;
}

const initialState: IDatasetState = {
  fetchingSupportedDocumentsListLoading: null,
  supportedDocumentsList: [],
  fetchingCurrencyListLoading: null,
  currencyList: [],
  fetchingDeliveryConditionListLoading: null,
  deliveryConditionList: [],
  fetchingSizeListLoading: null,
  sizeList: [],
  fetchingStandardListLoading: null,
  standardList: [],
  fetchingRequestTypeListLoading: null,
  requestTypeList: [],
  fetchingUnitListLoading: null,
  unitList: [],
  fetchingSpeciesListLoading: null,
  speciesList: [],
  fetchingSortListLoading: null,
  sortList: [],
  fetchingUktzedLoading: null,
  Uktzed: null,
  fetchingAccountingListLoading: null,
  accountingList: [],
  generatingDocumentsToDownloadLoading: false,
  generatedDocumentsToDownloadUrl: null,
  fetchingInterregionalOfficeExternalListLoading: false,
  interregionalOfficeExternalList: null
};

export const mainSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    fetchSupportedDocumentsListRequest: (state) => {
      state.fetchingSupportedDocumentsListLoading = true;
    },
    fetchSupportedDocumentsListSuccess: (state, action) => {
      state.fetchingSupportedDocumentsListLoading = false;
      state.supportedDocumentsList = action.payload;
    },
    fetchSupportedDocumentsListError: (state) => {
      state.fetchingSupportedDocumentsListLoading = false;
    },
    clearSupportedDocumentsList: (state) => {
      state.supportedDocumentsList = initialState.supportedDocumentsList;
    },

    fetchCurrencyListRequest: (state) => {
      state.fetchingCurrencyListLoading = true;
    },
    fetchCurrencyListSuccess: (state, action) => {
      state.fetchingCurrencyListLoading = false;
      state.currencyList = formatForSelect(action.payload);
    },
    fetchCurrencyListError: (state) => {
      state.fetchingCurrencyListLoading = false;
    },
    clearCurrencyList: (state) => {
      state.currencyList = initialState.currencyList;
    },

    fetchDeliveryConditionListRequest: (state) => {
      state.fetchingDeliveryConditionListLoading = true;
    },
    fetchDeliveryConditionListSuccess: (state, action) => {
      state.fetchingDeliveryConditionListLoading = false;
      state.deliveryConditionList = formatForSelect(action.payload);
    },
    fetchDeliveryConditionListError: (state) => {
      state.fetchingDeliveryConditionListLoading = false;
    },
    clearDeliveryConditionList: (state) => {
      state.deliveryConditionList = initialState.deliveryConditionList;
    },

    fetchSizeListRequest: (state) => {
      state.fetchingSizeListLoading = true;
    },
    fetchSizeListSuccess: (state, action) => {
      state.fetchingSizeListLoading = false;
      state.sizeList = formatForSelect(action.payload);
    },
    fetchSizeListError: (state) => {
      state.fetchingSizeListLoading = false;
    },
    clearSizeList: (state) => {
      state.sizeList = initialState.sizeList;
    },

    fetchStandardListRequest: (state) => {
      state.fetchingStandardListLoading = true;
    },
    fetchStandardListSuccess: (state, action) => {
      state.fetchingStandardListLoading = false;
      state.standardList = formatForSelect(action.payload);
    },
    fetchStandardListError: (state) => {
      state.fetchingStandardListLoading = false;
    },
    fetchRequestTypeListRequest: (state) => {
      state.fetchingRequestTypeListLoading = true;
    },
    fetchRequestTypeListSuccess: (state, action) => {
      state.fetchingRequestTypeListLoading = false;
      state.requestTypeList = action.payload.map((item) => ({
        value: item.alias,
        label: item.name
      }));
    },
    fetchRequestTypeListError: (state) => {
      state.fetchingRequestTypeListLoading = false;
    },
    clearStandardList: (state) => {
      state.standardList = initialState.standardList;
    },

    fetchUnitListRequest: (state) => {
      state.fetchingUnitListLoading = true;
    },
    fetchUnitListSuccess: (state, action) => {
      state.fetchingUnitListLoading = false;
      state.unitList = formatForSelect(action.payload);
    },
    fetchUnitListError: (state) => {
      state.fetchingUnitListLoading = false;
    },
    clearUnitList: (state) => {
      state.unitList = initialState.unitList;
    },

    fetchSpeciesListRequest: (state) => {
      state.fetchingSpeciesListLoading = true;
    },
    fetchSpeciesListSuccess: (state, action) => {
      state.fetchingSpeciesListLoading = false;
      state.speciesList = formatForSelect(action.payload);
    },
    fetchSpeciesListError: (state) => {
      state.fetchingSpeciesListLoading = false;
    },
    clearSpeciesList: (state) => {
      state.speciesList = initialState.speciesList;
    },

    fetchSortListRequest: (state) => {
      state.fetchingSortListLoading = true;
    },
    fetchSortListSuccess: (state, action) => {
      state.fetchingSortListLoading = false;
      state.sortList = formatForSelect(action.payload);
    },
    fetchSortListError: (state) => {
      state.fetchingSortListLoading = false;
    },
    clearSortList: (state) => {
      state.sortList = initialState.sortList;
    },

    fetchUktzedRequest: (state) => {
      state.fetchingUktzedLoading = true;
    },
    fetchUktzedSuccess: (state, action) => {
      state.fetchingUktzedLoading = false;
      state.Uktzed = action.payload?.uktzed;
    },
    fetchUktzedError: (state) => {
      state.fetchingUktzedLoading = false;
    },

    fetchAccountingListRequest: (state) => {
      state.fetchingAccountingListLoading = true;
    },
    fetchAccountingListSuccess: (state, action) => {
      state.fetchingAccountingListLoading = false;
      state.accountingList = formatForSelect(action.payload);
    },
    fetchAccountingListError: (state) => {
      state.fetchingAccountingListLoading = false;
    },
    clearAccountingList: (state) => {
      state.accountingList = initialState.accountingList;
    },

    generateDocumentsToDownloadRequest: (state) => {
      state.generatingDocumentsToDownloadLoading = true;
    },
    generateDocumentsToDownloadSuccess: (state, action) => {
      state.generatingDocumentsToDownloadLoading = false;
      state.generatedDocumentsToDownloadUrl = action.payload.url;
    },
    generateDocumentsToDownloadError: (state) => {
      state.generatingDocumentsToDownloadLoading = false;
    },
    clearGeneratedDocumentsToDownload: (state) => {
      state.generatedDocumentsToDownloadUrl =
        initialState.generatedDocumentsToDownloadUrl;
    },

    fetchInterregionalOfficeExternalListRequest: (state) => {
      state.fetchingInterregionalOfficeExternalListLoading = true;
    },
    fetchInterregionalOfficeExternalListSuccess: (state, action) => {
      state.fetchingInterregionalOfficeExternalListLoading = false;
      state.interregionalOfficeExternalList = action.payload;
    },
    fetchInterregionalOfficeExternalListError: (state) => {
      state.fetchingInterregionalOfficeExternalListLoading = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
