import { PrimaryDocumentTypes } from "constant";
import { Alert, CommonButton } from "gov-ua-ui";

import styles from "../../search-basis-doc-page.module.scss";

const NoDocumentsFound = ({
  documentType,
  onCreateDocumentClick,
  modifiedSearch
}) => {
  return (
    <>
      <Alert
        type="error"
        withIcon
        className={styles["create-primary-document__notification"]}
      >
        {documentType === PrimaryDocumentTypes.ttn_wood
          ? `Документ не знайдено, при пошуку ${PrimaryDocumentTypes.ttn_wood} створення відсутнє. Введіть інші параметри пошуку`
          : documentType === PrimaryDocumentTypes.transfer
          ? `Документ не знайдено, при пошуку ${PrimaryDocumentTypes.transfer} створення відсутнє. Введіть інші параметри пошуку`
          : "Документ не знайдено, створіть його або введіть інші параметри пошуку"}
      </Alert>
      {documentType !== PrimaryDocumentTypes.ttn_wood &&
        documentType !== PrimaryDocumentTypes.transfer && (
          <>
            <div className={styles["create-primary-document__container"]}>
              <CommonButton
                label="Створити новий"
                onClick={onCreateDocumentClick}
                disabled={modifiedSearch}
              />
            </div>
          </>
        )}
    </>
  );
};

export default NoDocumentsFound;
