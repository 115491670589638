import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const OfficialArchivalPage = (props: any): JSX.Element => {
  return (
    <Layout title={""} meta={""} headerType="DEFAULT" isOfficial={true}>
      <features.officialArchival.page {...props} />
    </Layout>
  );
};

export default OfficialArchivalPage;
