import { CommonButton, Dialog } from "gov-ua-ui";
import { useDispatch, useSelector } from "react-redux";

import { SIGN_WIDGET_URI } from "constant";
import features from "features";
import { IRootState } from "reducer";

import SignatureWidget from "./components/SignatureWidget/SignatureWidget";

import styles from "./signature-modal.module.scss";

interface SignatureModalInterface {
  modalTitle?: string;
  processedName: string;
  processedBy: string;
  location?: string;
  percentages?: number;
  onAccept: (signFile?) => void;
  onDecline?: () => void;
  isPositive: boolean;
  fileUrl: string;
  getFileUrl: any;
}

const SignatureModal = () => {
  const {
    modalTitle,
    processedName,
    processedBy,
    location,
    onAccept,
    onDecline,
    fileUrl,
    getFileUrl
  } = useSelector<IRootState, SignatureModalInterface>(
    (state) => state.modal.modalProps
  );

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(features.modal.actions.hideModal());
  };

  const closeDialog = () => {
    dispatch(features.modal.actions.hideModal());
  };

  return (
    <Dialog
      isOpen
      className={styles["modal-container"]}
      onClose={onClose}
      closeTimeoutMS={500}
    >
      <div className={styles["signature-modal"]}>
        <h2 className={styles["signature-modal__title"]}>
          {modalTitle ? modalTitle : "Підписати"}
        </h2>
        <div className={styles["signature-modal__status-container"]}>
          <div className={styles["signature-modal__status-text-block"]}>
            <h4 className={styles["signature-modal__processed"]}>
              {processedName}
            </h4>
            <h4 className={styles["signature-modal__info"]}>{processedBy}</h4>
            {location && (
              <h4 className={styles["signature-modal__info"]}>{location}</h4>
            )}
          </div>
        </div>

        <div className={styles["signature-modal__form"]}>
          {(fileUrl || getFileUrl) && SIGN_WIDGET_URI ? (
            <SignatureWidget
              fileUrl={fileUrl}
              getFileUrl={getFileUrl}
              controls={(signFile) => (
                <div className={styles["signature-modal__form-controls"]}>
                  <CommonButton
                    className={styles.btn}
                    outlined
                    label="Скасувати"
                    onClick={onDecline ? onDecline : closeDialog}
                  />
                  <CommonButton
                    className={styles.btn}
                    label="Підтвердити"
                    disabled={!signFile}
                    onClick={() => onAccept(signFile)}
                  />
                </div>
              )}
            />
          ) : (
            <div className={styles["signature-modal__form-controls"]}>
              <CommonButton
                className={styles.btn}
                outlined
                label="Скасувати"
                onClick={onDecline ? onDecline : closeDialog}
              />
              <CommonButton
                className={styles.btn}
                label="Підтвердити"
                onClick={() => onAccept()}
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default SignatureModal;
