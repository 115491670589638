import cn from "classnames";
import { Link } from "react-router-dom";

import styles from "./breadcrumbs.module.scss";

interface BreadcrumbsInterface {
  className?: string;
  elements: Array<{
    text: string;
    to: string;
  }>;
}

const Breadcrumbs = ({
  className = "",
  elements
}: BreadcrumbsInterface): JSX.Element => {
  return (
    <nav
      aria-label="Breadcrumb"
      className={cn(styles["breadcrumbs-wrapper"], className)}
    >
      <menu className={styles["breadcrumbs"]}>
        {elements?.map((elem, index) => {
          return (
            <li key={index} className={styles["breadcrumbs__item"]}>
              {index !== elements.length - 1 ? (
                <div className={styles["breadcrumbs-link-container"]}>
                  <Link
                    to={elem.to}
                    className={styles["breadcrumbs__item-link"]}
                  >
                    {elem.text}
                  </Link>
                  <Link
                    to={elem.to}
                    className={styles["breadcrumbs__item-link"]}
                  >
                    {index < elements.length - 1 ? <>&nbsp;&rarr;&nbsp;</> : ""}
                  </Link>
                </div>
              ) : (
                <span className={styles["breadcrumbs__item_no-link"]}>
                  {elem.text}
                </span>
              )}
            </li>
          );
        })}
      </menu>
    </nav>
  );
};

export default Breadcrumbs;
