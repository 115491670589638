import { createSlice } from "@reduxjs/toolkit";
import { educationalMaterials } from "dataset";

export interface IEducationalMaterial {
  id: any;
  title: string;
  description: string;
  links?: string;
  images?: string;
}
export interface IEducationalMaterialsState {
  educationalMaterialsLoading: boolean | null;
  educationalMaterials: Array<IEducationalMaterial>;
  responseError: any;
}

const initialState: IEducationalMaterialsState = {
  educationalMaterialsLoading: null,
  educationalMaterials: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "educationalMaterials",
  initialState,
  reducers: {
    fetchEducationalMaterialsRequest: (state) => {
      state.educationalMaterialsLoading = true;
    },
    fetchEducationalMaterialsSuccess: (state, { payload }) => {
      state.educationalMaterialsLoading = false;
      state.educationalMaterials = payload;
    },
    fetchEducationalMaterialsError: (state) => {
      state.educationalMaterialsLoading = false;
      state.educationalMaterials = educationalMaterials;
    }
  }
});

export const { actions, reducer } = mainSlice;
