import cn from "classnames";
import { CommonButton, EnterIcon } from "gov-ua-ui";
import { createBrowserHistory } from "history";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { SimpleTooltip } from "gov-ua-ui";

import { CABINET_URL, CERTIFICATE_AUTH_REDIRECT_URL } from "constant";
import features from "features";
import {
  generateAvatar,
  getCurrentSubjectUuid,
  getRole,
  getToken,
  setCurrentSubjectUuid,
  setSid
} from "helpers";
import { IOption } from "interfaces";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";

import RoundMenuButton from "components/buttons/RoundMenuButton/RoundMenuButton";
import useMediaQuery from "components/useMediaQuery";

import CertificateIcon from "assets/images/icons/certificate-logo.svg";
import ChatIcon from "assets/images/icons/chat.svg";
import EmblemBlackIcon from "assets/images/icons/emblem_black.svg";
import EmblemGreenIcon from "assets/images/icons/emblem_green.svg";
import SettingsIcon from "assets/images/icons/settings.svg";

import styles from "./main-header.module.scss";

const history = createBrowserHistory({ window });

interface formatOptionLabel {
  value?: string;
  label?: string;
  subLabel?: string;
}

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(768);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const sideMenuCanBeClosed = useRef<boolean>(false);
  const [selectedSubject, setSelectedSubject] = useState<IOption>();
  const [isAuthenticated] = useState(getToken());

  const [userRole] = useState(getRole());
  const [currentSubjectUuid] = useState(getCurrentSubjectUuid);
  const [subjectsMenuIsOpen, setSubjectsMenuIsOpen] = useState<boolean>(false);

  const helpLinks = () => {
    const relevantLinks: any = [
      {
        id: 0,
        title: "Про адміністративну послугу",
        link: "/regular-questions"
      },
      {
        id: 1,
        title: "Навчальні матеріали",
        link: "/educational-materials"
      },
      {
        id: 2,
        title: "Законодавча база",
        link: "/legislative-base"
      }
    ];

    if (userRole === "user") {
      relevantLinks.push(
        {
          id: 3,
          icon: ChatIcon,
          title: "Запит до системи",
          link: "/request-to-system-list"
        },
        {
          id: 4,
          icon: SettingsIcon,
          title: "Налаштування",
          link: "/settings"
        }
      );
      return relevantLinks;
    }
    if (userRole) {
      relevantLinks.splice(1, 1);
      return relevantLinks;
    }
    return relevantLinks;
  };

  const toggleSubjectsMenu = useCallback(() => {
    setSubjectsMenuIsOpen((prevState) => !prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectsMenuIsOpen]);

  const { subjects, currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const parseSubject = useCallback((subject) => {
    return {
      value: subject.edrpou,
      uuid: subject.uuid,
      label: subject.fullName,
      subLabel: subject.role === "DIRECTOR" ? "Керівник" : "Довірена особа"
    };
  }, []);

  useEffect(() => {
    if (sideMenuOpen && isMobile) {
      document.body.classList.add("fixed");
    } else {
      document.body.classList.remove("fixed");
    }

    return () => {
      document.body.classList.remove("fixed");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuOpen]);

  const subjectsOptions = useMemo(() => {
    if (isEmpty(subjects)) return [];

    return subjects.map((item) => parseSubject(item));
  }, [parseSubject, subjects]);

  const handleNavigateToPersonalCabinet = () => {
    window.open(CABINET_URL + "/subjects", "_blank");
  };

  const handleNavigateToMain = () => {
    if (userRole === "user") {
      navigate("/personal/applications/drafts");
    } else if (userRole !== "user") {
      navigate("/official-main");
    }
  };

  const handleSideMenuToggle = () => {
    setSideMenuOpen((prevState) => {
      sideMenuCanBeClosed.current = false;

      return !prevState;
    });
  };

  const handleOutsideMenuClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const className = target.getAttribute("class");

    if (
      className &&
      !className.includes("side-menu") &&
      sideMenuCanBeClosed.current
    ) {
      sideMenuCanBeClosed.current = false;
      setSideMenuOpen(false);
      return;
    }

    if (className && !className.includes("side-menu"))
      sideMenuCanBeClosed.current = true;
  };

  useEffect(() => {
    if (!isMobile) {
      document.body.addEventListener("click", handleOutsideMenuClick);
    } else {
      document.body.removeEventListener("click", handleOutsideMenuClick);
    }
    return () => {
      document.body.removeEventListener("click", handleOutsideMenuClick);
    };
  }, [isMobile]);

  const formatOptionLabel = ({ label, subLabel }: formatOptionLabel) => (
    <div
      className={styles["select-option"]}
      data-tooltip-id="subject-tooltip"
      data-tooltip-content={label}
    >
      <div className={styles["select-option__label"]}>{label}</div>
      <div className={styles["select-option__sublabel"]}>{subLabel}</div>
    </div>
  );

  const setSelectedOption = useCallback(
    (e) => {
      dispatch(
        features.subjects.actions.generateSidRequest({
          fields: {
            uuid: currentSubject.uuid,
            bUuid: e.value,
            orgUuid: e.uuid,
            userDrfo: currentSubject.drfo,
            user: currentSubject,
            orgs: subjects
          },
          onSuccess: (response) => {
            setSid(response.sid);
            setCurrentSubjectUuid(e.uuid);
            setSelectedSubject(e);
            history.push("/");
            window.location.reload();
          },
          onError: () => {
            setSelectedSubject(
              parseSubject(
                subjects.find((item) => item.uuid === currentSubjectUuid)
              )
            );
          }
        })
      );
    },
    [currentSubject, currentSubjectUuid, dispatch, parseSubject, subjects]
  );

  const filteredOptions = useCallback(
    () =>
      subjectsOptions.filter((item) => item?.value !== selectedSubject?.value),
    [selectedSubject, subjectsOptions]
  );

  const onCreateApplicationClick = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title:
            "Створюється заявка на створення сертифіката про походження деревини",
          loading: true
        }
      })
    );

    dispatch(features.application.actions.createApplicationRequest());
  }, [dispatch]);

  const onSignOutClick = useCallback(() => {
    dispatch(features.auth.actions.signOutRequest());
  }, [dispatch]);

  return (
    <header className={cn(styles["common-header-styles"], styles["header"])}>
      <a
        href="https://my.eco.gov.ua"
        target="blank"
        className={styles["header__title"]}
      >
        <div className={styles["header__title-emblems"]}>
          <img src={EmblemBlackIcon} alt="Emblem of Ukraine" />
          <img src={EmblemGreenIcon} alt="Emblem of Ukraine" />
        </div>
        <div className={styles["vertical-divider"]} />
        <p className={styles["header__text"]}>ЕкоСистема</p>
      </a>
      <div
        className={cn(
          styles["header__center-part"],
          styles["header__disappear-part"]
        )}
      >
        <div className={styles["header__subpart"]}>
          <div
            className={cn(
              styles["vertical-divider"],
              styles["vertical-divider_gray"]
            )}
          />
          <img src={CertificateIcon} alt="OVD" />
          <Link to="/" className={styles["header__text"]}>
            Сертифікат про <br />
            походження лісоматеріалів
          </Link>
        </div>
        {isAuthenticated && userRole === "user" && (
          <div className={styles["header__subpart-md"]}>
            <div
              className={cn(
                styles["vertical-divider"],
                styles["vertical-divider_gray"]
              )}
            />
            <span
              className={styles["header__create-app-btn"]}
              onClick={onCreateApplicationClick}
            >
              Створити Заявку
            </span>
          </div>
        )}
      </div>
      <div className={styles["header__right-part"]}>
        {isAuthenticated ? (
          <div className={styles["header__user-section"]}>
            <div
              className={cn(styles["subjects-select"], {
                [styles["subjects-select__one-subject"]]:
                  !filteredOptions().length
              })}
            >
              {!isEmpty(subjectsOptions) && (
                <Select
                  value={selectedSubject}
                  className={"select-container"}
                  classNamePrefix="eco-select"
                  placeholder=""
                  isSearchable={false}
                  defaultValue={parseSubject(currentSubject.organization)}
                  onChange={setSelectedOption}
                  options={filteredOptions()}
                  formatOptionLabel={formatOptionLabel}
                  menuIsOpen={
                    subjectsMenuIsOpen && filteredOptions().length > 0
                  }
                  onMenuOpen={toggleSubjectsMenu}
                  onMenuClose={subjectsMenuIsOpen && toggleSubjectsMenu}
                />
              )}
            </div>
            {!isMobile ? (
              <div className={styles["header__controls-container"]}>
                <RoundMenuButton
                  label={generateAvatar(currentSubject)}
                  onClick={handleNavigateToMain}
                />
                <RoundMenuButton
                  className={"header__side-menu-btn"}
                  toggleMenuButton={true}
                  onClick={handleSideMenuToggle}
                />
              </div>
            ) : (
              <RoundMenuButton
                className={"header__side-menu-btn"}
                toggleMenuButton={true}
                onClick={handleSideMenuToggle}
                isSideMenuOpen={sideMenuOpen}
              />
            )}
          </div>
        ) : !isMobile ? (
          <a href={CERTIFICATE_AUTH_REDIRECT_URL}>
            <CommonButton label="Авторизуватись" />
          </a>
        ) : (
          <RoundMenuButton
            className={"header__side-menu-btn"}
            toggleMenuButton={true}
            onClick={handleSideMenuToggle}
            isSideMenuOpen={sideMenuOpen}
          />
        )}
        <SimpleTooltip
          id="subject-tooltip"
          float
          place="right"
          className={styles["tooltip-hint"]}
          noArrow
        />
        <aside
          className={cn(styles["side-menu"], {
            [styles["side-menu_visible"]]: sideMenuOpen
          })}
        >
          {isAuthenticated && (
            <div>
              {userRole === "user" && (
                <CommonButton
                  label="Створити Заявку"
                  onClick={onCreateApplicationClick}
                />
              )}
            </div>
          )}
          <div className={styles["side-menu__header"]}>
            <CommonButton
              outlined={true}
              label="Особистий кабінет"
              onClick={handleNavigateToMain}
            />
          </div>
          <div className={styles["side-menu__header"]}>
            <CommonButton
              outlined={true}
              label="Обліковий запис"
              onClick={handleNavigateToPersonalCabinet}
            />
          </div>
          {isMobile && (
            <div className={styles["mobile-subject__container"]}>
              {isAuthenticated ? (
                <div>
                  {/* {userRole === "user" && (
                    <CommonButton label="Створити Заявку" />
                  )} */}

                  <div
                    className={cn(styles["subjects-select"], {
                      [styles["subjects-select__one-subject"]]:
                        !filteredOptions().length
                    })}
                  >
                    {!isEmpty(subjectsOptions) && (
                      <Select
                        value={selectedSubject}
                        className={"select-container"}
                        classNamePrefix="eco-select"
                        placeholder=""
                        isSearchable={false}
                        defaultValue={subjectsOptions[0]}
                        onChange={setSelectedOption}
                        options={filteredOptions()}
                        formatOptionLabel={formatOptionLabel}
                        menuIsOpen={
                          subjectsMenuIsOpen && filteredOptions().length > 0
                        }
                        onMenuOpen={toggleSubjectsMenu}
                        onMenuClose={subjectsMenuIsOpen && toggleSubjectsMenu}
                      />
                    )}
                  </div>
                  <div className={styles["mobile-subject__personal-container"]}>
                    <RoundMenuButton
                      label={generateAvatar(currentSubject)}
                      onClick={handleNavigateToMain}
                    />
                    <span onClick={handleNavigateToMain}>
                      Особистий кабінет
                    </span>
                  </div>
                </div>
              ) : (
                <a
                  href={CERTIFICATE_AUTH_REDIRECT_URL}
                  className={styles["side-menu__mobile-log-in-btn"]}
                >
                  <CommonButton label="Авторизуватись" />
                </a>
              )}
            </div>
          )}
          <div className={styles["helps-menu"]}>
            {helpLinks().map((el, index) => (
              <Link to={el.link} className={styles["helps-link"]} key={index}>
                {el.icon && <img src={el.icon} alt="help" />}
                <h4 className={styles["helps-title"]}>{el.title}</h4>
              </Link>
            ))}
          </div>
          <div className={styles["menu__item"]}>
            <div className={styles["item__img"]}>
              <EnterIcon />
            </div>
            <div className={styles["item__text"]} onClick={onSignOutClick}>
              Вийти
            </div>
          </div>
        </aside>
      </div>
    </header>
  );
};

export default Header;
