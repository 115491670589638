import { createSlice } from "@reduxjs/toolkit";

export interface IProgressedApplication {
  uuid: string;
  buyer: string;
  invoiceNumber: number;
  invoiceAt: number;
  consignee: string;
  checkedPercent: number;
  productYieldPercentage: number;
  costStandards: number;
  remarksNumber: number;
  processingTime: number;
}

export interface IArchivalApplicationsState {
  fetchingApplicationsLoading: boolean | null;
  applications: any;
  responseError: any;
}

const initialState: IArchivalApplicationsState = {
  fetchingApplicationsLoading: null,
  applications: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "officialArchival",
  initialState,
  reducers: {
    searchArchivedRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    searchArchivedSuccess: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.applications = action.payload;
    },
    searchArchivedError: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.responseError = action.payload;
    }
  }
});

export const { actions, reducer } = mainSlice;
