import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchStatistics({ payload }) {
  const request = API.fetchStatistics;
  const { fetchStatisticsSuccess: setSuccess, fetchStatisticsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* startProcessingApplication({ payload }) {
  const request = API.startProcessingApplication;
  const {
    startProcessingApplicationSuccess: setSuccess,
    startProcessingApplicationError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchStatisticsRequest().type, fetchStatistics);
  yield takeLatest(
    actions.startProcessingApplicationRequest().type,
    startProcessingApplication
  );
}
