import { useFormik, useFormikContext } from "formik";
import { FormField, RadioButton, Select } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import Autocomplete from "components/Autocomplete/Autocomplete";
import Preloader from "components/Preloader/Preloader";

import styles from "./forms.module.scss";

interface IEditCodeForm {
  type: string;
  onInnerFormValueChange?: (innerFormValues: any, validation?: any) => void;
}

const EditCodeForm = ({
  type,
  onInnerFormValueChange
}: IEditCodeForm): JSX.Element => {
  const dispatch = useDispatch();

  const externalFormik = useFormikContext();

  const { standardList, speciesList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  useEffect(() => {
    dispatch(features.dataset.actions.fetchStandardListRequest());
    dispatch(features.dataset.actions.fetchSpeciesListRequest());
  }, []);

  const onFormSubmit = () => {};

  const validationSchema = Yup.object().shape({
    productName: Yup.object().shape({
      value: Yup.string().required("Виберіть назву продукції")
    }),
    productSpecie: Yup.object().shape({
      value: Yup.string().required("Виберіть породу")
    }),
    uktzed: Yup.string()
      .required("Введіть новий код УКТЗЕД")
      .max(10, "Максимальна кількість 10 символів")
  });

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      productName: externalFormik.values["productName"] || "",
      productSpecie: externalFormik.values["productSpecie"] || "",
      oldUktzed: externalFormik.values["oldUktzed"] || "",
      uktzed: externalFormik.values["uktzed"] || "",
      comment: externalFormik.values["comment"] || "",
      productStandard: externalFormik.values["productStandard"] || null
    },
    onSubmit: onFormSubmit
  });

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
  }, []);

  const onStandardChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
    formik.setFieldValue("productName", "");
    formik.setFieldValue("oldUktzed", "");
  }, []);

  useEffect(() => {
    if (!isEmpty(standardList)) {
      onAutocompleteChange(standardList[0], "productStandard");
    }
  }, [onAutocompleteChange, standardList]);

  useEffect(() => {
    onInnerFormValueChange(formik.values, validationSchema);
  }, [formik.values, type]);

  useEffect(() => {
    const actualValues = {
      ...formik.values,
      ...(externalFormik.values as any)
    };
    delete actualValues.type;
    formik.setValues(actualValues);
  }, [externalFormik.values["uktzed"], externalFormik.values["comment"]]);

  const setUktzed = useCallback((productTypeId, woodUuid) => {
    dispatch(
      features.dataset.actions.fetchUktzedRequest({
        fields: {
          productId: productTypeId,
          woodSpecieUuid: woodUuid
        },
        onSuccess: (response) => {
          if (!isEmpty(response)) {
            formik.setFieldValue("oldUktzed", response[0]);
          } else {
            formik.setFieldValue("oldUktzed", "");
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    if (
      formik.values.productName?.value &&
      !isEmpty(formik.values.productSpecie?.value)
    )
      setUktzed(
        formik.values.productName?.value,
        formik.values.productSpecie?.value
      );
  }, [formik.values.productName?.value, formik.values.productSpecie?.value]);

  return !isEmpty(formik.values.productStandard) && !isEmpty(speciesList) ? (
    <div className={styles["product-types-form__container"]}>
      <h4 className={styles["product-types-form__title"]}>
        {type === "edit" ? "Редагувати" : "Додати"} коди
      </h4>
      <div className={styles["product-types-form__field-container"]}>
        <Select
          name="productStandard"
          value={formik.values.productStandard}
          options={standardList}
          onSelectChange={onStandardChange}
          blurInputOnSelect={true}
        />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <Autocomplete
          name="productName"
          placeholder="Продукція"
          value={formik.values["productName"]}
          entity="products"
          params={`productStandardUuid=${formik.values.productStandard.value}`}
          onChange={onAutocompleteChange}
          dataFieldsNames={{
            valueFieldName: "id",
            labelFieldName: "name"
          }}
        />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <Select
          name="productSpecie"
          placeholder="Порода"
          value={formik.values["productSpecie"]}
          withFormik
          options={speciesList}
          onSelectChange={onAutocompleteChange}
          blurInputOnSelect={true}
          isSearchable={true}
        />
      </div>
      {type === "edit" && (
        <div className={styles["radio-block__container"]}>
          {/* <h4 className={styles["product-types-form__title"]}>
            Код УКТЗЕД, існуючий
          </h4> */}
          {!isEmpty(formik.values["oldUktzed"]) && (
            <div>
              <RadioButton
                title={formik.values["oldUktzed"]}
                name="oldUktzed"
                value={formik.values["oldUktzed"]}
                checked={formik.values["oldUktzed"]}
                onClick={() => {}}
                disabled
              />
            </div>
          )}
        </div>
      )}
      <div className={styles["product-types-form__field-container"]}>
        <FormField name="uktzed" placeholder="Код УКТЗЕД, новий" />
      </div>
      <div className={styles["product-types-form__field-container"]}>
        <FormField
          name="comment"
          placeholder="Коментар (не обов’язково)"
          as="textarea"
          className={"textarea-field"}
        />
      </div>
    </div>
  ) : (
    <Preloader />
  );
};

export default EditCodeForm;
