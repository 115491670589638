import { BlankPage } from "gov-ua-ui";
import { isEmpty } from "lodash";

import { IFoundApplications } from "scenes/subject/applications/SearchApplicationsPage/ducks";

import ApplicationsTable from "components/ApplicationsTable/ApplicationsTable";

import styles from "./applications-search-result.module.scss";

interface IApplicationsSearchResult {
  applications: IFoundApplications;
  fetchingApplicationsLoading: boolean;
}

const ApplicationsSearchResult = ({
  applications,
  fetchingApplicationsLoading
}: IApplicationsSearchResult) => {
  return (
    <div className={styles["results-container"]}>
      <div className={styles["results-title"]}>Результат пошуку</div>

      {!isEmpty(applications?.data) ? (
        <>
          <div className={styles["results-subtitle"]}>
            Знайдені за параметрами {applications.meta.totalItems}
          </div>
          <ApplicationsTable
            applications={applications}
            fetchingApplicationsLoading={fetchingApplicationsLoading}
          />
        </>
      ) : (
        <BlankPage title="На ваш запит нічого не знайдено" />
      )}
    </div>
  );
};

export default ApplicationsSearchResult;
