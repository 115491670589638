import Section from "components/Section/Section";
import SectionText from "components/Section/SectionText/SectionText";
import OfficialControls from "components/certificateComponents/viewCertificateComponents/OfficialControls/OfficialControls";
import CommonTable from "components/tables/CommonTable/CommonTable";

import { IRemark } from "interfaces";

import styles from "./products.module.scss";

interface ProductsInterface {
  terms: string;
  currency: string;
  sizeUnits: string;
  defaultData: any;
  columns: any;
  isOfficial?: boolean;
  isOfficialEdit?: boolean;
  additionalElements?: any;
  count?: number;
  amount?: number;
  remark?: IRemark;
  blockType?: string;
}

const Products = ({
  terms,
  currency,
  sizeUnits,
  defaultData,
  columns,
  isOfficial,
  additionalElements,
  isOfficialEdit,
  count,
  amount,
  remark,
  blockType
}: ProductsInterface): JSX.Element => {
  return (
    <Section title="Продукція" color={isOfficial ? "WHITE" : "GREY"}>
      <div className={styles["production-section"]}>
        <div className={styles["production-section__info"]}>
          <SectionText title="Умови поставки">{terms}</SectionText>
          <SectionText title="Валюта">{currency}</SectionText>
          <SectionText title="Геометричні розміри">{sizeUnits}</SectionText>
        </div>
        <div className={styles["production-section__table"]}>
          <CommonTable defaultData={defaultData} columns={columns} />
        </div>
        <div className={styles["production-section__total-block"]}>
          <h4 className={styles["production-section__total-title"]}>Всього</h4>
          <div className={styles["production-section__total-flex-container"]}>
            <h4 className={styles["production-section__total-label"]}>
              Кількість
            </h4>
            <h4 className={styles["production-section__total-text"]}>
              {count}
            </h4>
          </div>
          <div className={styles["production-section__total-flex-container"]}>
            <h4 className={styles["production-section__total-label"]}>
              Вартість
            </h4>
            <h4 className={styles["production-section__total-text"]}>
              {amount}
            </h4>
          </div>
        </div>
        {isOfficialEdit && (
          <div className="controls-wrapper">
            <OfficialControls remark={remark} blockType={blockType} />
          </div>
        )}
        {additionalElements}
      </div>
    </Section>
  );
};

export default Products;
