import { AnswerButton, CommonButton } from "gov-ua-ui";

import EyeIcon from "assets/images/icons/eye.svg";

import styles from "./small-controls.module.scss";

interface SmallControlsInterface {
  onView?: () => void;
  onAccept: () => void;
  onDecline: () => void;
  isAccepted?: boolean;
}

const SmallControls = ({
  onView,
  onAccept,
  onDecline,
  isAccepted
}: SmallControlsInterface): JSX.Element => {
  return (
    <div className={styles["doc-section__buttons-container"]}>
      <AnswerButton
        className={styles["doc-section__close-btn"]}
        round
        cancel
        hoverEyeIcon={isAccepted === false}
        onClick={onDecline}
        selected={isAccepted === false}
      />
      <AnswerButton
        className={styles["doc-section__done-btn"]}
        round
        onClick={isAccepted === true ? null : onAccept}
        selected={isAccepted === true}
      />
      {onView && (
        <CommonButton
          image={EyeIcon}
          round={true}
          outlined={true}
          className={styles["doc-section__preview-btn"]}
          onClick={onView}
        />
      )}
    </div>
  );
};

export default SmallControls;
