import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchProgressed({ payload }) {
  const request = API.fetchProgressedApplication;
  const { fetchProgressedSuccess: setSuccess, fetchProgressedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* searchInQueue({ payload }) {
  const request = API.searchInQueueApplications;
  const { searchInQueueSuccess: setSuccess, searchInQueueError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProgressedRequest().type, fetchProgressed);
  yield takeLatest(actions.searchInQueueRequest().type, searchInQueue);
}
