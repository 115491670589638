import { createSlice } from "@reduxjs/toolkit";
import { regularQuestions } from "dataset";

export interface IRegularQuestion {
  id: any;
  question: string;
  answer: string;
  links?: string;
  images?: string;
}
export interface IRegularQuestionsState {
  regularQuestionsLoading: boolean | null;
  regularQuestions: Array<IRegularQuestion>;
  responseError: any;
}

const initialState: IRegularQuestionsState = {
  regularQuestionsLoading: null,
  regularQuestions: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "regularQuestions",
  initialState,
  reducers: {
    fetchRegularQuestionsRequest: (state) => {
      state.regularQuestionsLoading = true;
    },
    fetchRegularQuestionsSuccess: (state, { payload }) => {
      state.regularQuestionsLoading = false;
      state.regularQuestions = payload;
    },
    fetchRegularQuestionsError: (state) => {
      state.regularQuestionsLoading = false;
      state.regularQuestions = regularQuestions;
    }
  }
});

export const { actions, reducer } = mainSlice;
