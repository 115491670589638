import Section from "components/Section/Section";
import SectionText from "components/Section/SectionText/SectionText";

import styles from "./invoice.module.scss";

interface InvoiceInterface {
  buyer: string;
  buyerCountry: string;
  consignee: string;
  consigneeCountry: string;
  docs: Array<JSX.Element>;
  additionalElements?: any;
}

const Invoice = ({
  buyer,
  buyerCountry,
  consignee,
  consigneeCountry,
  docs,
  additionalElements
}: InvoiceInterface) => {
  return (
    <Section title="Інвойс" color="GREY">
      <div className={styles["invoice-section"]}>
        <div className={styles["invoice-section__text-subsection"]}>
          <SectionText title="Покупець">{buyer}</SectionText>
          <SectionText title="Країна">{buyerCountry}</SectionText>
          <SectionText title="Вантажоодержувач">{consignee}</SectionText>
          <SectionText title="Країна">{consigneeCountry}</SectionText>
        </div>
        {docs.map((doc, index) => {
          return (
            <div
              className={styles["invoice-section__doc-subsection"]}
              key={index}
            >
              {doc}
            </div>
          );
        })}
      </div>
      {additionalElements}
    </Section>
  );
};

export default Invoice;
