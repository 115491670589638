import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import {
  findGetParameter,
  getCurrentSubjectUuid,
  getSid,
  getToken
} from "helpers";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import Routes from "./Routes";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

export const OffsetContext = createContext({
  scrollOffset: 0,
  setScrollOffset: null,
  headerHeight: 0,
  setHeaderHeight: null
});

function App(): JSX.Element {
  const { fetchingSubjectsLoading, fetchingSubjectInfoLoading } = useSelector<
    IRootState,
    ISubjectsState
  >((state) => state.subjects);

  const dispatch = useDispatch();
  const [scrollOffset, setScrollOffset] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [authorised] = useState(getToken());
  const [isSid] = useState(getSid());
  const [currentSubjectUuid] = useState(getCurrentSubjectUuid());

  useEffect(() => {
    const code = findGetParameter("code");
    const state = findGetParameter("state");
    if (code && state)
      dispatch(
        features.auth.actions.initTokenRequest({
          fields: { code, state },
          onSuccess: () => {
            dispatch(
              features.subjects.actions.fetchUserDataRequest({
                fields: { isSid, currentSubjectUuid },
                onError: () => {
                  dispatch(
                    features.modal.actions.showModal({
                      modalType: "CONFIRM_ACTION",
                      modalProps: {
                        notificationText:
                          "Недостатньо даних для авторизації, для початку роботи налаштуйте обліковий запис в ЕкоСистемі",
                        acceptLabel: "Перейти в ЕкоСистему",
                        onAccept: () => {
                          window.open(
                            "https://my.eco.gov.ua/subjects",
                            "_self"
                          );
                        },
                        onClose: () => {
                          dispatch(features.auth.actions.signOutRequest());
                        }
                      }
                    })
                  );
                }
              })
            );
          }
        })
      );
    if (authorised)
      dispatch(
        features.subjects.actions.fetchUserDataRequest({
          fields: { isSid, currentSubjectUuid },
          onError: () => {
            dispatch(
              features.modal.actions.showModal({
                modalType: "CONFIRM_ACTION",
                modalProps: {
                  notificationText:
                    "Недостатньо даних для авторизації, для початку роботи налаштуйте обліковий запис в ЕкоСистемі",
                  acceptLabel: "Перейти в ЕкоСистему",
                  onAccept: () => {
                    window.open("https://my.eco.gov.ua/subjects", "_self");
                  },
                  onClose: () => {
                    dispatch(features.auth.actions.signOutRequest());
                  }
                }
              })
            );
          }
        })
      );
  }, [authorised, currentSubjectUuid, dispatch, isSid]);

  return (
    <PreloaderWrapper
      loading={fetchingSubjectsLoading || fetchingSubjectInfoLoading}
    >
      <OffsetContext.Provider
        value={{ scrollOffset, setScrollOffset, headerHeight, setHeaderHeight }}
      >
        <Routes />
      </OffsetContext.Provider>
    </PreloaderWrapper>
  );
}

export default App;
