import ApiProvider, { CabinetApiProvider } from "./API";

const getRole = (organizationUuid) =>
  CabinetApiProvider()
    .client.post(`/personal_account/roles`, {
      uuid: organizationUuid
    })
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });

export const generateSid = async ({ fields }) => {
  const data = {
    uuid: fields.uuid,
    bUuid: fields.bUuid,
    rUuid: "",
    user: fields.user,
    orgs: fields.orgs
  };
  const role = await getRole(fields.orgUuid);
  const currentRole = role.data.find((item) => item.drfo === fields.userDrfo);
  data.rUuid = currentRole.id;
  return await ApiProvider().client.post("/auth", data);
};

export const updateUserData = async ({ fields }) => {
  const data = {
    uuid: fields.uuid,
    bUuid: fields.bUuid,
    rUuid: "",
    ...fields
  };

  const role = await getRole(fields.orgUuid);

  delete data.orgUuid;

  const currentRole = role.data.find((item) => item.drfo === fields.userDrfo);
  data.rUuid = currentRole.id;
  delete data.userDrfo;

  return await ApiProvider().client.put("/auth/me", data);
};

export const getUserData = async ({ fields }) => {
  const data = {
    uuid: fields.uuid,
    bUuid: fields.bUuid,
    rUuid: ""
  };

  const role = await getRole(fields.orgUuid);

  const currentRole = role.data.find((item) => item.drfo === fields.userDrfo);
  data.rUuid = currentRole?.id || "";

  return await ApiProvider().client.get(
    `/auth/me?uuid=${data.uuid}&bUuid=${data.bUuid}&rUuid=${data.rUuid}`
  );
};

export const fetchUserData = async ({ fields }) => {
  const info = await CabinetApiProvider()
    .client.get(`/personal_account/info`)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });

  const init = await CabinetApiProvider()
    .client.get(`/personal_account/init`)
    .then((response) => {
      if (response.data.orgs.length === 0) {
        const newError = {
          response: {
            status: "empty_orgs"
          }
        };
        throw newError;
      }
      return response;
    })
    .catch((error) => {
      throw error.response;
    });

  const orgs = init.data.orgs.map((org) => {
    return {
      ...org,
      fullName:
        (org.edrpou === org.data.ownerIpn ? "ФОП " : "") + (org?.fullName ?? "")
    };
  });

  const currentSubject =
    orgs.find((item) => item.uuid === fields.currentSubjectUuid) ?? orgs[0];

  if (fields.isSid) {
    return {
      ...info,
      data: {
        ...info.data,
        organization: {
          ...currentSubject
        },
        organizationName: currentSubject?.fullName,
        subjects: orgs
      }
    };
  }

  const sid = await generateSid({
    fields: {
      uuid: info.data.uuid,
      bUuid: init.data.orgs[0].edrpou,
      orgUuid: init.data.orgs[0].uuid,
      userDrfo: info.data.drfo,
      user: info.data,
      orgs: init.data.orgs
    }
  })
    .then((res) => {
      return res.data.sid ?? res.data.data.sid;
    })
    .catch((err) => {
      console.log(err);
      if (window.location.pathname !== "/settings") {
        window.location.href = "/settings";
      }
      return null;
    });

  return {
    ...info,
    data: {
      ...info.data,
      sid,
      organization: {
        ...currentSubject,
        organizationName: currentSubject?.fullName
      },
      subjects: orgs
    }
  };
};

export const fetchRegularQuestions = ({ fields, params }) => {
  return ApiProvider()
    .client.get(`/faq`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchEducationalMaterials = ({ fields, params }) => {
  return ApiProvider()
    .client.get(`/educational_materials`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getLegislativeBase = ({ fields, params }) => {
  return ApiProvider()
    .client.get(`/legal_basis`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const getRequestsToSystemList = ({ fields, params }) => {
  return ApiProvider()
    .client.get(`/requests_system_list`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const requestsToSystem = ({ fields, params }) => {
  return ApiProvider()
    .client.post(`/catalog_requests`, params)
    .then((response) => response)
    .catch((error) => error.response);
};
