import ApiProvider from "./API";

export const fetchStatistics = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/official/dashboard`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const searchArchivedApplication = ({ fields }) => {
  return ApiProvider()
    .client.get(`/official/application/archived`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchProgressedApplicationView = ({ params }) => {
  return ApiProvider()
    .client.get(`/official/application/in-process/${params?.uuid}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchInQueueApplicationView = ({ params }) => {
  return ApiProvider()
    .client.get(`/official/application/in-queue/${params?.uuid}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const searchInQueueApplications = ({ fields }) => {
  return ApiProvider()
    .client.get(`/official/application/in-queue`, {
      params: fields
    })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchArchivedApplicationView = ({ params }) => {
  return ApiProvider()
    .client.get(`/official/application/archived/${params?.uuid}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchOfficialAnnulmentApplicationView = ({ params }) => {
  return ApiProvider()
    .client.get(`/official/application/${params?.uuid}/annulment`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchOfficialEAnnulmentData = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/official/application/${params?.uuid}/annulment/submit`, {
      params: fields
    })
    .then((response) => response)
    .catch((error) => error.response);
};

export const officialAnnulmentSubmit = ({ params, fields }) => {
  return ApiProvider()
    .client.post(
      `/official/application/${params.applicationUuid}/annulment/submit`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteOfficialReasonFile = ({ params }) => {
  return ApiProvider()
    .client.delete(
      `/official/application/${params?.applicationUuid}/annulment/files/${params.uuid}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchOfficialAnnulledApplications = () => {
  return ApiProvider()
    .client.get(`/official/application/annulments_for_signing`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const signAnnulledApplications = () => {
  return ApiProvider()
    .client.post(`/official/application/annulments_for_signing`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const fetchOfficialDeclinedApplications = () => {
  return ApiProvider()
    .client.get(`/official/application/annulment_decline_for_sign`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const fetchDeclinedApplicationFile = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/official/application/annulment_decline/${params.uuid}`, {
      params: fields
    })
    .then((response) => response)
    .catch((error) => error.response);
};
export const signDeclinedApplicationFile = ({ fields, params }) => {
  return ApiProvider()
    .client.post(
      `/official/application/annulment_decline/${params.uuid}`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchProgressedApplication = () => {
  return ApiProvider()
    .client.get(`/official/application/in-process`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const startProcessingApplication = () => {
  return ApiProvider()
    .client.post(`/official/application/in-process`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const addProgressedApplicationView = ({ params, fields }) => {
  return ApiProvider()
    .client.post(
      `/official/application/in-process/${params.applicationUuid}/remarks/${params.remarkUuid}`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const progressedApplicationConfirm = async ({ params, fields }) => {
  // For dev[START]
  let file;
  const signedFile = fields.signedFile;
  if (!signedFile) {
    const data = await progressedApplicationFileConfirm({ params });
    file = await imageUrlToBase64(data.data.url);
  }
  // For dev[`END]
  return ApiProvider()
    .client.post(`/official/application/in-process/${params.uuid}/confirm`, {
      ...fields,
      signedFile: file ?? signedFile
    })
    .then((response) => response)
    .catch((error) => error.response);
};
export const progressedApplicationFileConfirm = ({ params }) => {
  return ApiProvider()
    .client.put(`/official/application/in-process/${params.uuid}/confirm`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const progressedApplicationDecline = async ({ params, fields }) => {
  // For dev[START]
  let file;
  const signedFile = fields.signedFile;
  if (!signedFile) {
    const data = await fetchECertificateDeclineData({ params });
    file = await imageUrlToBase64(data.data.url);
  }
  // For dev[END]
  return ApiProvider()
    .client.post(`/official/application/in-process/${params.uuid}/decline`, {
      ...fields,
      signedFile: file ?? signedFile
    })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchAnnulApplication = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/official/application/in-process/${params.uuid}/annulment`, {
      params: fields
    })
    .then((response) => response)
    .catch((error) => error.response);
};

export const annulApplication = ({ params, fields }) => {
  return ApiProvider()
    .client.post(
      `/official/application/in-process/${params.uuid}/annulment`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchECertificateData = ({ params }) => {
  return ApiProvider()
    .client.get(`/official/application/in-process/${params.uuid}/confirm/`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const fetchECertificateDeclineData = ({ params }) => {
  return ApiProvider()
    .client.get(`/official/application/in-process/${params.uuid}/decline/`)
    .then((response) => response)
    .catch((error) => error.response);
};
