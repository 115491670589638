import { takeLatest } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as API from "services";
import { setRole, requestMiddleware, parseJwt, setSid } from "helpers";
import { actions } from "./ducks";

const history = createBrowserHistory({ window });

function* fetchUserData({ payload }) {
  const request = API.fetchUserData;
  const { fetchUserDataSuccess: setSuccess, fetchUserDataError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess: ({ sid }) => {
      if (sid) {
        setRole(parseJwt(sid).role);
        setSid(sid);
        history.push("/");
        window.location.reload();
      }
    }
  });
}

function* generateSid({ payload }) {
  const request = API.generateSid;
  const { generateSidSuccess: setSuccess, generateSidError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchNotificationBoard({ payload }) {
  const request = API.fetchNotificationBoard;
  const {
    fetchNotificationBoardSuccess: setSuccess,
    fetchNotificationBoardError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchTermsOfService({ payload }) {
  const request = API.fetchTermsOfService;
  const {
    fetchTermsOfServiceSuccess: setSuccess,
    fetchTermsOfServiceError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchUserDataRequest().type, fetchUserData);
  yield takeLatest(actions.generateSidRequest().type, generateSid);
  yield takeLatest(
    actions.fetchNotificationBoardRequest().type,
    fetchNotificationBoard
  );
  yield takeLatest(
    actions.fetchTermsOfServiceRequest().type,
    fetchTermsOfService
  );
}
