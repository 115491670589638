import { createColumnHelper } from "@tanstack/react-table";
import cn from "classnames";
import { CommonButton } from "gov-ua-ui";
import { IProduct } from "./interfaces";

import Products from "components/certificateComponents/viewCertificateComponents/Products/Products";

import styles from "./define-products.module.scss";

interface ISavedProductsColumns {
  formik: any;
  handleSaveData: () => void;
  isReadonly?: boolean;
}

const SavedProductsTable = ({
  formik,
  handleSaveData,
  isReadonly
}: ISavedProductsColumns) => {
  const columnHelper = createColumnHelper<IProduct>();

  const productColumns = [
    columnHelper.accessor("standard", {
      cell: (info: any) => {
        return info.getValue().label;
      },
      header: () => "Стандарт"
    }),
    columnHelper.accessor("productType", {
      cell: (info: any) => info.getValue().label,
      header: () => "Продукція"
    }),
    columnHelper.accessor("clarification", {
      cell: (info: any) => info.getValue(),
      header: () => "Уточнення"
    }),
    columnHelper.accessor("wood", {
      cell: (info: any) => {
        return info.getValue().map((item, i) => {
          if (i !== 0) return `, ${item.label}`;
          return item.label;
        });
      },
      header: () => "Порода"
    }),
    columnHelper.accessor("sort", {
      cell: (info: any) => {
        return info.getValue().map((item, i) => {
          if (i !== 0) return `, ${item.label}`;
          return item.label;
        });
      },
      header: () => "Сорт"
    }),
    columnHelper.accessor("diameter", {
      cell: (info: any) => info.getValue(),
      header: () => "Діаметр"
    }),
    columnHelper.accessor("thickness", {
      cell: (info: any) => info.getValue(),
      header: () => "Товщина"
    }),
    columnHelper.accessor("width", {
      cell: (info: any) => info.getValue(),
      header: () => "Ширина"
    }),
    columnHelper.accessor("length", {
      cell: (info: any) => info.getValue(),
      header: () => "Довжина"
    }),
    columnHelper.accessor("count", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість"
    }),
    columnHelper.accessor("unit", {
      cell: (info: any) => info.getValue().label,
      header: () => "Од. вимір."
    }),
    columnHelper.accessor("price", {
      cell: (info: any) => info.getValue(),
      header: () => "Ціна"
    }),
    columnHelper.accessor("amount", {
      cell: (info: any) => info.getValue(),
      header: () => "Вартість"
    }),
    columnHelper.accessor("uktzed", {
      cell: (info: any) => info.getValue(),
      header: () => "УКТЗЕД"
    })
  ];

  return (
    <Products
      terms={formik.values["deliveryCondition"].label}
      currency={formik.values["currency"].label}
      sizeUnits={formik.values["size"].label}
      defaultData={formik.values["products"]}
      count={formik.values["count"]}
      amount={formik.values["amount"]}
      columns={productColumns}
      additionalElements={
        !isReadonly ? (
          <CommonButton
            className={cn(styles["form-btn"], styles["define-products__btn"])}
            outlined={true}
            onClick={handleSaveData}
            label="Змінити"
          />
        ) : null
      }
    />
  );
};

export default SavedProductsTable;
