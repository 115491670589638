import { renderError } from "components/ResponseErrors/renderError";
import isEmpty from "lodash/isEmpty";
import { toastr } from "react-redux-toastr";

interface iParseResErrors {
  setFieldError?: (property, value) => void;
  errorsRes: any;
  fields?: any;
  fieldsAliases?: any;
  onUnknownErrors?: (errors: Array<string>) => void;
}

/**
 * Check the form and API properties, if they don't match, toast will be shown.
 * onUnknownErrors allow u define your own method to resolve unknown errors
 * fieldsAliases can be used to define aliases for fields which not existed in res
 **/
export const parseResErrors = ({
  setFieldError,
  errorsRes,
  fields,
  fieldsAliases,
  onUnknownErrors
}: iParseResErrors) => {
  const errors = errorsRes?.data?.json?.errors;
  const unknownErrors = [];

  let buildKey = "";
  let layer = 0;
  let recursiveErrors = [];
  const recursiveParse = (items) => {
    items.forEach((item) => {
      const property = fieldsAliases?.hasOwnProperty(item.property)
        ? fieldsAliases[item.property]
        : item.property;
      buildKey =
        (buildKey.length > 0 && layer > 0 ? buildKey + "." : "") +
        property?.toString();

      if (!isEmpty(item.children)) {
        layer = layer + 1;
        recursiveParse(item.children);
      } else {
        if (!isEmpty(item.constraints) && layer === 0) {
          setError(item);
        }
        if (layer > 0) {
          if (!isEmpty(item.constraints)) {
            setFieldError(buildKey, Object.values(item.constraints));
            recursiveErrors.push({
              property: buildKey,
              constrains: item.constraints
            });
          }

          let buildKeySplit = buildKey.split(".");
          buildKeySplit.pop();

          buildKey = buildKeySplit.toString().replaceAll(",", ".");
        }
      }
    });
    if (layer > 0) {
      layer = layer - 1;
      let buildKeySplit = buildKey.split(".");
      buildKeySplit.pop();

      buildKey = buildKeySplit.toString().replaceAll(",", ".");
    }
  };

  const setError = (error) => {
    if (fields?.hasOwnProperty(error.property))
      setFieldError(error.property, Object.values(error.constraints));
    else if (fieldsAliases?.hasOwnProperty(error.property))
      setFieldError(
        fieldsAliases[error.property],
        Object.values(error.constraints)
      );
    else {
      unknownErrors.push(...Object.values(error.constraints));
    }
  };

  if (errors || errors?.length > 0) recursiveParse(errors);
  else {
    if (errorsRes?.data.statusCode === 404)
      unknownErrors.push("Щось пішло не так, спробуйте пізніше");
  }

  if (unknownErrors.length > 0) {
    if (onUnknownErrors) onUnknownErrors(unknownErrors);
    else
      setTimeout(
        () =>
          toastr.error("Помилка", {
            component: renderError(unknownErrors)
          }),
        0
      );
  }
};
