import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchRequestsToSystemList({ payload }) {
  const request = API.getRequestsToSystemList;
  const {
    fetchRequestsToSystemListSuccess: setSuccess,
    fetchRequestsToSystemListError: setError
  } = actions;
  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchSendRequestsToSystem({ payload }) {
  const request = API.requestsToSystem;
  const {
    fetchSendRequestsToSystemSuccess: setSuccess,
    fetchSendRequestsToSystemError: setError
  } = actions;
  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchRequestsToSystemListRequest().type,
    fetchRequestsToSystemList
  );
  yield takeLatest(
    actions.fetchSendRequestsToSystem().type,
    fetchSendRequestsToSystem
  );
}
