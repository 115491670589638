import cn from "classnames";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { CABINET_URL } from "constant";
import features from "features";
import { getToken } from "helpers";

import EcoEmblemIcon from "assets/images/icons/emblem_white-black.svg";
import UkraineEmblemIcon from "assets/images/icons/emblem_white.svg";
import FacebookIcon from "assets/images/icons/facebook_white.svg";

import styles from "./footer.module.scss";

const Footer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isAuthenticated] = useState(getToken());

  const handleSignOut = useCallback(() => {
    dispatch(features.auth.actions.signOutRequest());
  }, []);

  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer__navigation"]}>
        <div className={styles["footer__navigation-group"]}>
          <a
            href="https://eco.gov.ua/services"
            className={styles["footer__navigation-link"]}
          >
            Адміністративні послуги
          </a>
          <a
            href="https://eco.gov.ua/news"
            className={styles["footer__navigation-link"]}
          >
            Новини
          </a>
          <a
            href="https://ecozagroza.gov.ua/"
            className={styles["footer__navigation-link"]}
          >
            ЕкоЗагроза
          </a>
          <a
            href="https://drovae.gov.ua/"
            className={styles["footer__navigation-link"]}
          >
            ДроваЄ
          </a>
        </div>
        <div className={styles["footer__navigation-group"]}>
          <a
            href="https://eco.gov.ua/registers"
            className={styles["footer__navigation-link"]}
          >
            Реєстри
          </a>
          <a
            href="https://eco.gov.ua/pro-proyekt"
            className={styles["footer__navigation-link"]}
          >
            Про проект
          </a>
          <a
            href="https://eco.gov.ua/"
            className={styles["footer__navigation-link"]}
          >
            ЕкоОсвіта
          </a>
        </div>
        <div className={styles["footer__navigation-group"]}>
          {!isAuthenticated ? (
            <Link to="/" className={styles["footer__navigation-link"]}>
              Увійти
            </Link>
          ) : (
            <button
              className={styles["footer__navigation-link"]}
              onClick={handleSignOut}
            >
              Вийти
            </button>
          )}

          <a href={CABINET_URL} className={styles["footer__navigation-link"]}>
            Зареєструватись
          </a>
        </div>
        <div
          className={cn(
            styles["footer__navigation-group"],
            styles["footer__navigation-group_follow"]
          )}
        >
          <h4 className={styles["footer__text"]}>Слідкуй за нами тут</h4>
          <a
            href="https://www.facebook.com/EnvironmentalofUkraine"
            target="blank"
            className={styles["footer__link"]}
          >
            <img src={FacebookIcon} alt="Facebook" />
          </a>
        </div>
      </div>
      <hr className={styles["footer-hr"]} />
      <div className={styles["footer__copyright"]}>
        <img src={UkraineEmblemIcon} alt="Emblem of Ukraine" />
        <img src={EcoEmblemIcon} alt="Emblem of Ukraine" />
        <small className={styles["footer__copyright-text"]}>
          eco.gov.ua <br />
          {new Date().getFullYear()}. Всі права захищені.
        </small>
      </div>
      <div className={styles["footer__about"]}>
        Реалізовано у партнерстві Міністерства захисту довкілля та природних
        ресурсів, Міністерства цифрової трансформації за підтримки проєкту
        USAID/UK aid «Прозорість та підзвітність у державному управлінні та
        послугах/TAPAS».
      </div>
    </footer>
  );
};

export default Footer;
