export const tableRowDefault = (docType) => {
  return {
    id: "",
    production: "",
    clarification: "",
    breed: [""],
    grade: [""],
    diameter: "",
    diametersGroup: "",
    thickness: "",
    width: "",
    length: "",
    original: "",
    available: "",
    decommissioned: "",
    unit: "",
    price: "",
    cost: "",
    uktzed: "",
    accounting: "",
    ...(docType === "transfer" ? { woodCard: null } : {})
  };
};
