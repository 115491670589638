import cn from "classnames";
import { Navigation } from "gov-ua-ui";
import { useContext } from "react";

import { OffsetContext } from "App";
import BlankPage from "components/BlankPage/BlankPage";
import NavigationHead, {
  LinkInterface
} from "components/NavigationHead/NavigationHead";

import styles from "./with-navigation-layout.module.scss";

interface WithNavigationInterface {
  navLinks: Array<LinkInterface>;
  breadcrumbs: Array<LinkInterface>;
  children: any;
  title: string;
  subtitle?: string | JSX.Element;
  titleImg?: any;
  titleClass?: string;
  subtitleClass?: string;
  noLogo?: boolean;
  isOfficial?: boolean;
  className?: string;
  navTabsClassName?: string;
  isDefaultTop?: boolean;
}

const WithNavigation = ({
  children,
  breadcrumbs,
  navLinks,
  title,
  subtitle,
  titleImg,
  titleClass,
  subtitleClass,
  noLogo,
  isOfficial,
  className,
  navTabsClassName,
  isDefaultTop
}: WithNavigationInterface) => {
  const { scrollOffset, setScrollOffset, headerHeight, setHeaderHeight } =
    useContext(OffsetContext);

  return (
    <main className={styles["with-navigation-layout"]}>
      <NavigationHead
        breadcrumbsLinks={breadcrumbs}
        navLinks={navLinks}
        title={title}
        subtitle={subtitle}
        titleImg={titleImg}
        titleClass={titleClass}
        subtitleClass={subtitleClass}
        noLogo={noLogo}
        isOfficial={isOfficial}
        className={className}
        getHeight={setScrollOffset}
        getOffsetTop={setHeaderHeight}
      />
      <Navigation
        navLinks={navLinks}
        className={cn(styles["navigation-tabs"], navTabsClassName, {
          [styles["navigation-tabs-official"]]: isOfficial,
          [styles["navigation-tabs-application"]]: isDefaultTop
        })}
        anchorLinksScrollMargins={{
          top: scrollOffset,
          left: 0
        }}
        style={{
          top: headerHeight + "px"
        }}
      />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </main>
  );
};

export default WithNavigation;
