import { toastr } from "react-redux-toastr";

export const parseFilesErrors = (rejectedFiles: Array<any>) => {
  let tooManyFilesToastShowed = false;

  if (rejectedFiles) {
    rejectedFiles.map((rejectedFile) =>
      rejectedFile.errors.forEach((error) => {
        if (!tooManyFilesToastShowed) {
          switch (error.code) {
            case "too-many-files": {
              toastr.error("Помилка ", error.title);
              tooManyFilesToastShowed = true;
              break;
            }
            case "file-too-large": {
              toastr.error(
                "Помилка " + rejectedFile.file.name,
                "Максимальний розмір файлу - 5 МБ"
              );
              break;
            }

            default: {
              toastr.error("Помилка " + rejectedFile.file.name, error.title);
              break;
            }
          }
        }
      })
    );
  }
};
