import { takeLatest, takeEvery, call } from "redux-saga/effects";
import * as API from "services";
import uploadFiles from "scenes/UploadFiles/saga";

import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* createApplication({ payload }) {
  const request = API.createApplication;
  const {
    createApplicationSuccess: setSuccess,
    createApplicationError: setError
  } = actions;

  function onSuccess(response) {
    if (response && response.uuid) {
      window.location.href = `/personal/applications/${response.uuid}`;
    }
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}

function* fetchApplication({ payload }) {
  const request = API.fetchApplication;
  const {
    fetchApplicationSuccess: setSuccess,
    fetchApplicationError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* setApplicationType({ payload }) {
  const request = API.setApplicationType;
  const {
    setApplicationTypeSuccess: setSuccess,
    setApplicationTypeError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* loadInvoiceFile({ payload }) {
  const {
    saveLoadInvoiceFileCancelHandler: cancel,
    loadInvoiceFileError: error,
    loadInvoiceFileSuccess: success,
    loadInvoiceFileProgress: progress
  } = actions;

  yield call(
    uploadFiles,
    payload.fields,
    { cancel, error, success, progress },
    `applications/${payload.params.uuid}/files/${
      payload.params.replaceUuid ?? ""
    }`
  );
}

function* deleteInvoiceFile({ payload }) {
  const request = API.deleteInvoiceFile;
  const {
    deleteInvoiceFileSuccess: setSuccess,
    deleteInvoiceFileError: setError
  } = actions;

  if (payload.params.uuid) {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError
    });
  }
}

function* setInvoice({ payload }) {
  const request = API.setInvoice;
  const { setInvoiceSuccess: setSuccess, setInvoiceError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchSelectedOrganizationsList({ payload }) {
  const request = API.fetchSelectedOrganizationsList;
  const {
    fetchSelectedOrganizationsListSuccess: setSuccess,
    fetchSelectedOrganizationsListError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* setProducts({ payload }) {
  const request = API.setProducts;
  const { setProductsSuccess: setSuccess, setProductsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* deleteBasisDocument({ payload }) {
  const request = API.deleteBasisDocument;
  const {
    deleteBasisDocumentError: setError,
    deleteBasisDocumentSuccess: setSuccess
  } = actions;
  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* deleteAllBasisDocuments({ payload }) {
  const request = API.deleteAllBasisDocuments;
  const {
    deleteBasisDocumentError: setError,
    deleteAllBasisDocumentsSuccess: setSuccess
  } = actions;
  yield requestMiddleware({
    ...payload,
    request,
    setError,
    setSuccess
  });
}

function* extendApplicationTimer({ payload }) {
  const request = API.extendApplicationTimer;
  const {
    extendApplicationTimerSuccess: setSuccess,
    extendApplicationTimerError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchEApplicationData({ payload }) {
  const request = API.fetchEApplicationData;
  const {
    fetchEApplicationDataSuccess: setSuccess,
    fetchEApplicationDataError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* applyApplication({ payload }) {
  const request = API.applyApplication;
  const {
    applyApplicationSuccess: setSuccess,
    applyApplicationError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* setTrustedDocs({ payload }) {
  const request = API.setTrustedDocs;
  const { setTrustedDocsSuccess: setSuccess, setTrustedDocsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchDownloadBasicDocumentsExcel({ payload }) {
  const request = API.downloadBasicDocumentsExcel;
  const {
    downloadBasicDocumentsExcelSuccess: setSuccess,
    downloadBasicDocumentsExcelError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.createApplicationRequest().type, createApplication);
  yield takeLatest(actions.fetchApplicationRequest().type, fetchApplication);
  yield takeLatest(
    actions.setApplicationTypeRequest().type,
    setApplicationType
  );
  yield takeEvery(actions.loadInvoiceFileRequest().type, loadInvoiceFile);
  yield takeEvery(actions.deleteInvoiceFileRequest().type, deleteInvoiceFile);
  yield takeEvery(actions.setInvoiceRequest().type, setInvoice);
  yield takeEvery(
    actions.fetchSelectedOrganizationsListRequest().type,
    fetchSelectedOrganizationsList
  );
  yield takeEvery(actions.setProductsRequest().type, setProducts);
  yield takeEvery(
    actions.deleteBasisDocumentRequest().type,
    deleteBasisDocument
  );
  yield takeEvery(
    actions.deleteAllBasisDocumentsRequest().type,
    deleteAllBasisDocuments
  );
  yield takeLatest(
    actions.extendApplicationTimerRequest().type,
    extendApplicationTimer
  );
  yield takeLatest(
    actions.fetchEApplicationDataRequest().type,
    fetchEApplicationData
  );
  yield takeLatest(actions.applyApplicationRequest().type, applyApplication);
  yield takeLatest(actions.setTrustedDocsRequest().type, setTrustedDocs);
  yield takeLatest(
    actions.downloadBasicDocumentsExcelRequest().type,
    fetchDownloadBasicDocumentsExcel
  );
}
