import cn from "classnames";

import styles from "./header.module.scss";

interface HeaderWrapperInterface {
  className?: string;
  leftPart?: JSX.Element;
  centerPart?: JSX.Element;
  rightPart?: JSX.Element;
  asidePart?: JSX.Element;
}

const HeaderWrapper = ({
  className = "",
  leftPart,
  centerPart,
  rightPart,
  asidePart
}: HeaderWrapperInterface): JSX.Element => {
  return (
    <div className={cn(styles["header"], className)}>
      {leftPart && leftPart}
      {centerPart && centerPart}
      {rightPart && rightPart}
      {asidePart && asidePart}
    </div>
  );
};

export default HeaderWrapper;
