import { takeLatest, put } from "redux-saga/effects";
import * as API from "services";

import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchDocument({ payload }) {
  const request = API.fetchDocumentDetailedInfo;
  const { fetchDocumentSuccess: setSuccess, fetchDocumentError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchPreviousDocuments({ payload }) {
  const request = API.fetchPreviousDocumentsDetailedInfo;
  const {
    fetchPreviousDocumentsSuccess: setSuccess,
    fetchPreviousDocumentsError: setError,
    setPreviousWasteUsage
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess: function* (res) {
      const previousWasteUsage = [];
      if (res.length > 0) {
        if (res[0]?.wasteBasisDocumentExternalId) {
          yield requestMiddleware({
            ...payload,
            request,
            params: { basisDocumentIds: [res[0].wasteBasisDocumentExternalId] },
            onSuccess: function* (res) {
              yield previousWasteUsage.push(...res);
            },
            onError: function* (res) {
              yield previousWasteUsage.push({});
            }
          });
        }
      }
      yield put(setPreviousWasteUsage(previousWasteUsage));
    }
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDocumentRequest().type, fetchDocument);
  yield takeLatest(
    actions.fetchPreviousDocumentsRequest().type,
    fetchPreviousDocuments
  );
}
