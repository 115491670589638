import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchAnnulled({ payload }) {
  const request = API.fetchOfficialAnnulledApplications;
  const { fetchAnnulledSuccess: setSuccess, fetchAnnulledError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* signAnnulled({ payload }) {
  const request = API.signAnnulledApplications;
  const { signAnnulledSuccess: setSuccess, signAnnulledError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchAnnulledRequest.type, fetchAnnulled);
  yield takeLatest(actions.signAnnulledRequest.type, signAnnulled);
}
