import { createSlice } from "@reduxjs/toolkit";
import { IeDoc, IPagination } from "interfaces";

export interface IReceivedApplication {
  annulmentIsOrderedByOfficial: any;
  annulmentIsOrderedByExporter: any;
  uuid: string;
  certificateId: string;
  orderedAt: number;
  certificateCreatedAt: number;
  buyer: string;
  consignee: string;
  invoiceNumber: string;
  invoiceAt: number;
  customsClearance: boolean;
  eApplication: IeDoc;
  eCertificate: IeDoc;
}

export interface IReceivedApplications {
  data: Array<IReceivedApplication>;
  meta: IPagination;
}

export interface IReceivedApplicationsState {
  fetchingApplicationsLoading: boolean | null;
  applications: IReceivedApplications;
  responseError: any;
}

const initialState: IReceivedApplicationsState = {
  fetchingApplicationsLoading: null,
  applications: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "received",
  initialState,
  reducers: {
    fetchReceivedRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    fetchReceivedSuccess: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.applications = action.payload;
    },
    fetchReceivedError: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.responseError = action.payload;
    },

    clearReceived: (state) => {
      state.applications = initialState.applications;
    }
  }
});

export const { actions, reducer } = mainSlice;
