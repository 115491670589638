import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const ZNDocPage = (props: any): JSX.Element => {
  return (
    <Layout title={""} meta={""} headerType="INFO" infoType="TO_APPLICATION">
      <features.ZNDoc.page {...props} />
    </Layout>
  );
};

export default ZNDocPage;
