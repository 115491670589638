import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import {
  CommonButton,
  DatePicker,
  FormField,
  Notification,
  RadioButton,
  Title
} from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import {
  emailRgx,
  getSid,
  getToken,
  numberPhoneRgx,
  parseJwt,
  setCurrentSubjectUuid,
  setRole,
  setSid
} from "helpers";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { IUserSettings } from "../ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import useMediaQuery from "components/useMediaQuery";

import styles from "./settings-page.module.scss";

type TSettingsForm = {
  firstName: string;
  lastName: string;
  middleName: string;
  isDrfo: boolean;
  docType: string;
  passport?: {
    docSeries: string;
    docNumber: string;
    docIssueDate: Date;
    docIssuer: string;
  };
  idCard?: {
    docNumber: string;
    docIssueDate: Date;
    docExpireDate: Date;
    docIssuer: string;
  };
  phone: string;
  email: string;
};

const SettingsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [maxDate] = useState(new Date());
  const [isSid] = useState(getSid());
  const [authorised] = useState(getToken());
  const isMobile = useMediaQuery(768);

  const { subjects, currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { fetchSettingsSendLoading, fetchSettingsGetLoading, userSettings } =
    useSelector<IRootState, IUserSettings>((state) => state.settings);

  const [chosenDocument, setChosenDocument] = useState("passport");

  useEffect(() => {
    if (!isEmpty(userSettings)) {
      setChosenDocument(userSettings?.docType);
    }
  }, [userSettings]);

  useEffect(() => {
    if (!authorised) {
      navigate("/");
    }
  }, [authorised]);

  useEffect(() => {
    if (isSid && !isEmpty(currentSubject)) {
      const fields = {
        uuid: currentSubject.uuid,
        bUuid: currentSubject.organization.edrpou,
        orgUuid: currentSubject.organization.uuid,
        userDrfo: currentSubject.drfo
      };
      dispatch(features.settings.actions.getUserSettingsRequest({ fields }));
    }
  }, [currentSubject, dispatch, isSid]);

  const handleCancelClick = useCallback(() => {
    if (isSid) {
      navigate("/");
    } else {
      dispatch(features.auth.actions.signOutRequest());
    }
  }, []);

  const initialValues: TSettingsForm = {
    firstName:
      !isEmpty(userSettings) && userSettings?.firstName
        ? userSettings?.firstName || ""
        : currentSubject?.firstName || "",
    lastName:
      !isEmpty(userSettings) && userSettings?.lastName
        ? userSettings?.lastName || ""
        : currentSubject?.lastName || "",
    middleName:
      !isEmpty(userSettings) && userSettings?.middleName
        ? userSettings?.middleName || ""
        : currentSubject?.middleName || "",
    isDrfo:
      !isEmpty(userSettings) && userSettings.hasOwnProperty("isDrfo")
        ? userSettings?.isDrfo
        : !!currentSubject?.drfo || false,
    docType: (!isEmpty(userSettings) && userSettings?.docType) || "passport",
    passport:
      userSettings?.docType === "passport"
        ? {
            docSeries: userSettings?.docSeries || "",
            docNumber: userSettings?.docNumber || "",
            docIssueDate: new Date(userSettings?.docIssueDate) || null,
            docIssuer: userSettings?.docIssuer || ""
          }
        : {
            docSeries: "",
            docNumber: "",
            docIssueDate: null,
            docIssuer: ""
          },
    idCard:
      userSettings?.docType === "id-card"
        ? {
            docNumber: userSettings?.docNumber || "",
            docIssueDate: new Date(userSettings?.docIssueDate) || null,
            docExpireDate: new Date(userSettings?.docExpireDate) || null,
            docIssuer: userSettings?.docIssuer || ""
          }
        : {
            docNumber: "",
            docIssueDate: null,
            docExpireDate: null,
            docIssuer: ""
          },
    phone:
      !isEmpty(userSettings) && userSettings?.phone
        ? userSettings?.phone || ""
        : currentSubject?.phone || "",
    email:
      !isEmpty(userSettings) && userSettings?.email
        ? userSettings?.email || ""
        : currentSubject?.email || ""
  };
  const documentTypes = [
    {
      radioType: "passport",
      radioText: "Паспорт"
    },
    {
      radioType: "id-card",
      radioText: "ID картка"
    }
  ];

  const onFormSubmit = (values) => {
    const fields = {
      ...values,
      uuid: currentSubject.uuid,
      bUuid: currentSubject.organization.edrpou,
      orgUuid: currentSubject.organization.uuid,
      userDrfo: currentSubject.drfo,
      docSeries: values.passport.docSeries.toUpperCase(),
      docNumber:
        values.docType === "passport"
          ? values.passport.docNumber
          : values.idCard.docNumber,
      docIssueDate:
        values.docType === "passport"
          ? values.passport.docIssueDate
          : values.idCard.docIssueDate,
      docExpireDate: values.idCard.docExpireDate,
      docIssuer:
        values.docType === "passport"
          ? values.passport.docIssuer
          : values.idCard.docIssuer
    };

    delete fields.passport;
    delete fields.idCard;

    if (isSid) {
      dispatch(
        features.settings.actions.sendUserSettingsRequest({
          fields,
          onSuccess: () =>
            dispatch(
              features.modal.actions.showModal({
                modalType: "SUCCESSFUL_USER_SETTINGS_UPDATE"
              })
            )
        })
      );
    } else {
      dispatch(
        features.settings.actions.sendUserSettingsRequest({
          fields,
          onSuccess: () => {
            return dispatch(
              features.subjects.actions.generateSidRequest({
                fields: {
                  uuid: fields.uuid,
                  bUuid: fields.bUuid,
                  orgUuid: fields.orgUuid,
                  userDrfo: fields.userDrfo,
                  user: currentSubject,
                  orgs: subjects
                },
                onSuccess: (response) => {
                  setRole(parseJwt(response.data.sid).role);

                  setSid(response.data.sid);
                  setCurrentSubjectUuid(subjects[0].uuid);
                  window.location.href = "/";
                }
              })
            );
          }
        })
      );
    }
  };

  const passportValidationSchema = () => {
    if (
      chosenDocument === "passport" &&
      !isEmpty(currentSubject) &&
      !currentSubject?.drfo
    ) {
      return Yup.object().shape({
        docSeries: Yup.string().required("Введіть cерію"),
        docNumber: Yup.string().required("Введіть номер паспорту"),
        docIssueDate: Yup.date().required("Оберіть дату видачі"),
        docIssuer: Yup.string().required("Введіть суб'єкт видачі паспорту")
      });
    } else {
      return Yup.object().shape({});
    }
  };

  const idCardValidationSchema = () => {
    if (chosenDocument === "id-card") {
      return Yup.object().shape({
        docNumber: Yup.string().required("Введіть номер документа"),
        docIssueDate: Yup.date().required("Оберіть дату видачі"),
        docExpireDate: Yup.date().required("Оберіть дату закінчення дійсності"),
        docIssuer: Yup.string().required("Введіть орган, що видав документ")
      });
    } else {
      return Yup.object().shape({});
    }
  };

  const formik = useFormik<TSettingsForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Введіть ім'я"),
      lastName: Yup.string().required("Введіть прізвище"),
      passport: passportValidationSchema(),
      idCard: idCardValidationSchema(),
      phone: Yup.string()
        .required("Введіть номер телефону")
        .matches(numberPhoneRgx, "Невірний формат номеру телефону"),
      email: Yup.string()
        .required("Введіть електронну адресу")
        .matches(emailRgx, "Невірний формат пошти")
    }),
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: onFormSubmit
  });

  const handleDocumentTypeChange = (e) => {
    const event = e.target;
    formik.setFieldValue(event.name, event.value);
    setChosenDocument(event.value);
  };

  const handlePassportDocIssueDateChange = (date: Date) => {
    formik.setFieldValue("passport.docIssueDate", date);
  };

  const handleIdCardDocIssueDateChange = (date: Date) => {
    formik.setFieldValue("idCard.docIssueDate", date);
  };

  const handleIdCardDocExpireDateChange = (date: Date) => {
    formik.setFieldValue("idCard.docExpireDate", date);
  };

  return (
    <div className={styles["wrapper"]}>
      <Title size={32} className={styles["settings-title"]}>
        {isSid ? "Налаштування" : "Реєстрація користувача"}
      </Title>
      {!isSid && (
        <Notification
          text="При першому вході вам потрібно заповнити дані користувача "
          className={styles["notification"]}
        />
      )}
      <PreloaderWrapper
        loading={fetchSettingsSendLoading || fetchSettingsGetLoading}
      >
        <FormikProvider value={formik}>
          <Form className={styles["settings-form-container"]}>
            <div className={cn(styles["form-block"], styles["middle-block"])}>
              <Title size={20} className={styles["form-block__title"]}>
                Паспортні дані
              </Title>

              <FormField
                name="lastName"
                placeholder="Прізвище"
                value={formik.values.lastName || ""}
                disabled
              />
              <FormField
                name="firstName"
                placeholder="Ім'я"
                value={formik.values.firstName || ""}
                disabled
              />
              <FormField
                name="middleName"
                placeholder="По-батькові"
                value={formik.values.middleName || ""}
                disabled
              />
            </div>
            {!isEmpty(formik.values.isDrfo) && !formik.values.isDrfo && (
              <div className={cn(styles["form-block"])}>
                <Title size={20} className={styles["form-block__title"]}>
                  Тип документа
                </Title>
                {!isEmpty(documentTypes) && (
                  <div className={styles["radio-group-container"]}>
                    {documentTypes.map(({ radioType, radioText }) => {
                      return (
                        <div key={radioType}>
                          <RadioButton
                            title={radioText}
                            name="docType"
                            value={radioType}
                            checked={formik.values["docType"] === radioType}
                            onClick={handleDocumentTypeChange}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                {formik.values.docType === "passport" && (
                  <div className={styles["passport-container"]}>
                    <div className={styles["dual-passport-container"]}>
                      <FormField
                        name="passport.docSeries"
                        placeholder="Серія"
                        value={
                          formik.values.passport.docSeries.toUpperCase() || ""
                        }
                        className={styles["small-field"]}
                      />
                      <FormField
                        name="passport.docNumber"
                        placeholder="Номер"
                        mask="999999"
                        value={formik.values.passport.docNumber || ""}
                      />
                    </div>
                    <DatePicker
                      placeholder="Дата видачі"
                      name="passport.docIssueDate"
                      maxDate={maxDate}
                      withFormik
                      date={formik.values.passport.docIssueDate}
                      onChange={handlePassportDocIssueDateChange}
                      className={styles["middle-filed"]}
                    />
                    <FormField
                      name="passport.docIssuer"
                      placeholder="Ким виданий"
                      value={formik.values.passport.docIssuer || ""}
                    />
                  </div>
                )}
                {formik.values.docType === "id-card" && (
                  <div className={styles["passport-container"]}>
                    <FormField
                      name="idCard.docNumber"
                      placeholder="Номер"
                      mask="999999999"
                      value={formik.values.idCard.docNumber || ""}
                      className={styles["middle-filed"]}
                    />
                    <DatePicker
                      placeholder="Дата видачі"
                      name="idCard.docIssueDate"
                      maxDate={maxDate}
                      withFormik
                      date={formik.values.idCard.docIssueDate}
                      onChange={handleIdCardDocIssueDateChange}
                      className={styles["middle-filed"]}
                    />
                    <DatePicker
                      placeholder="Дійсний до"
                      name="idCard.docExpireDate"
                      withFormik
                      date={formik.values.idCard.docExpireDate}
                      onChange={handleIdCardDocExpireDateChange}
                      className={styles["middle-filed"]}
                    />
                    <FormField
                      name="idCard.docIssuer"
                      placeholder="Орган що видавав"
                      mask="9999"
                      value={formik.values.idCard.docIssuer || ""}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={cn(styles["form-block"], styles["middle-block"])}>
              <Title size={20} className={styles["form-block__title"]}>
                Контактні дані
              </Title>

              <FormField
                name="phone"
                mask="+380999999999"
                placeholder="Номер телефону"
                value={formik.values.phone || ""}
              />
              <FormField
                name="email"
                placeholder="Електронна пошта"
                value={formik.values.email || ""}
              />
            </div>
            <div className={styles["controls-container"]}>
              <CommonButton
                label={isSid ? "Скасувати" : "Назад"}
                outlined
                large={!isMobile}
                onClick={handleCancelClick}
              />
              <CommonButton
                label={isSid ? "Зберегти" : "Далі"}
                type="submit"
                large={!isMobile}
              />
            </div>
          </Form>
          <ScrollToFieldError />
        </FormikProvider>
      </PreloaderWrapper>
    </div>
  );
};

export default SettingsPage;
