import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, FormField } from "gov-ua-ui";
import * as Yup from "yup";

import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";

import features from "features";
import { parseResErrors } from "helpers";

import styles from "./annulment-decline.module.scss";

const AnnulmentDecline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { applicationId } = useParams();

  const handleFormSubmit = (value, { setFieldError }) => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Створюється єВідмова",
          loading: true
        }
      })
    );

    dispatch(
      features.officialApplicationReview.actions.fetchCertificateAnnulDeclineRequest(
        {
          params: {
            uuid: applicationId
          },
          fields: {
            decision: "decline",
            ...value
          },
          onSuccess: () => {
            dispatch(
              features.modal.actions.hideModal({
                modalType: "PRELOADER"
              })
            );
            navigate("e-decline-confirmation");
          },
          onError: (e) => {
            dispatch(
              features.modal.actions.hideModal({
                modalType: "PRELOADER"
              })
            );
            parseResErrors({
              setFieldError,
              errorsRes: e,
              fields: value
            });
          }
        }
      )
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      declineDescription: ""
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      declineDescription: Yup.string().required("Введіть причину відмови")
    }),
    onSubmit: handleFormSubmit
  });

  const cancel = useCallback(() => {
    dispatch(features.modal.actions.hideModal());
  }, [dispatch]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <FormField
          name="declineDescription"
          placeholder="Опишіть причину відмови в анулюванні"
        />
        <div className={styles["form-btns"]}>
          <CommonButton
            className={styles["form-btn"]}
            label="Скасувати"
            onClick={cancel}
            outlined
          />
          <CommonButton
            className={styles["form-btn"]}
            label="Підтвердити"
            type="submit"
          />
        </div>
      </Form>
      <ScrollToFieldError />
    </FormikProvider>
  );
};
export default AnnulmentDecline;
