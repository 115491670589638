import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, RadioButton } from "gov-ua-ui";
import { isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import features from "features";
import { converteFileToBase64 } from "helpers";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import SyncVisualMultipleUploader from "components/SyncVisualMultipleUploader/SyncVisualMultipleUploader";
import EditCodeForm from "../radioForms/EditCodeForm";
import EditEnterprisesForm from "../radioForms/EditEnterprisesForm";
import ProductTypesForm from "../radioForms/ProductTypesForm";
import WoodSortForm from "../radioForms/WoodSortForm";
import WoodTypeForm from "../radioForms/WoodTypeForm";

import styles from "./radio-section.module.scss";

const RadioSection = ({ requestType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [actualValidation, setActualValidation] = useState(null);
  const [loadedFiles, setLoadedFiles] = useState([]);

  const inputTypes = [
    {
      radioType: "edit",
      radioText: "Редагувати"
    },
    {
      radioType: "create",
      radioText: "Додати"
    }
  ];

  const radioForms = [
    {
      formId: 1,
      formType: "product",
      formComponent: ProductTypesForm
    },
    {
      formId: 2,
      formType: "ws",
      formComponent: WoodTypeForm
    },
    {
      formId: 3,
      formType: "wg",
      formComponent: WoodSortForm
    },
    {
      formId: 4,
      formType: "uktzed",
      formComponent: EditCodeForm
    },
    {
      formId: 5,
      formType: "org",
      formComponent: EditEnterprisesForm
    }
  ];

  const onFormSubmit = (values) => {
    const valuesForSubmit = {
      ...values
    };

    if (values.hasOwnProperty("oldName")) {
      valuesForSubmit.oldName = values.oldName.label;
    }

    if (values.hasOwnProperty("productName")) {
      valuesForSubmit.productName = values.productName.label;
    }

    if (values.hasOwnProperty("productSpecie")) {
      valuesForSubmit.productSpecie = values.productSpecie.label;
    }

    if (requestType === "org" && values.hasOwnProperty("name")) {
      if (valuesForSubmit.type !== "edit") valuesForSubmit.name = values.name;
      else valuesForSubmit.name = values.name.label;
    }

    if (requestType === "org" && values.hasOwnProperty("forestUser")) {
      valuesForSubmit.forestUser = values.forestUser ? "Так" : "Ні";
    }

    if (requestType === "org" && values.hasOwnProperty("oldForestUser")) {
      valuesForSubmit.oldForestUser = values.oldForestUser ? "Так" : "Ні";
    }

    if (values.hasOwnProperty("productStandard")) {
      valuesForSubmit.productStandard = values.productStandard.label;
    }

    if (valuesForSubmit.type === "create") {
      delete valuesForSubmit.oldName;
      delete valuesForSubmit.woodTypeCurrentName;
      delete valuesForSubmit.woodSortCurrentName;
      delete valuesForSubmit.oldUktzed;
      delete valuesForSubmit.oldCountry;
      delete valuesForSubmit.oldAddress;
      delete valuesForSubmit.oldForestUser;
      delete valuesForSubmit.oldEdrpou;
    }

    if (valuesForSubmit.type === "edit") {
      delete valuesForSubmit.isForestUser;
    }

    const processFiles = async () => {
      const processFile = async (el) => {
        try {
          let convertedFile = await converteFileToBase64(el);
          return {
            name: el.name,
            content: convertedFile
          };
        } catch (error) {
          toastr.error("Помилка, формат файлу не підтримується");
          return {
            name: el.name,
            content: null
          };
        }
      };

      const files = await Promise.all(loadedFiles.map(processFile));
      return files;
    };

    processFiles().then((res) =>
      dispatch(
        features.requestToSystemList.actions.fetchSendRequestsToSystem({
          params: {
            type: requestType,
            data: valuesForSubmit,
            files: res
          }
        })
      )
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      type: "",
      _type: requestType
    },
    validationSchema: actualValidation,
    onSubmit: onFormSubmit
  });

  const handleRadioChange = (e) => {
    const event = e.target;
    setActualValidation(null);
    formik.setFieldValue(event.name, null);
    setTimeout(() => formik.setFieldValue(event.name, event.value), 100);
  };

  const handleInnerFormValueChange = (innerFormValues, validation) => {
    formik.setValues({ ...formik.values, ...innerFormValues });
    setActualValidation(validation);
  };

  const handleBackClick = () => {
    navigate("/request-to-system-list");
  };

  const getRelevantRadioForm = useCallback(() => {
    const relevantForm = radioForms.find((el) => el.formType === requestType);
    return relevantForm;
  }, [requestType]);

  return (
    <div className={styles["radio-section__container"]}>
      <h4 className={styles["radio-section__title"]}>Спосіб обробки запиту</h4>
      <FormikProvider value={formik}>
        <Form className={styles["radio-section-container"]}>
          {!isEmpty(inputTypes) && (
            <div className={styles["radio-group-container"]}>
              {inputTypes.map(({ radioType, radioText }) => {
                return (
                  <div key={radioType}>
                    <RadioButton
                      title={radioText}
                      name="type"
                      value={radioType}
                      checked={formik.values["type"] === radioType}
                      onClick={handleRadioChange}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <PreloaderWrapper loading={formik.values["type"] === null}>
            {!isEmpty(formik.values["type"]) &&
              React.createElement(getRelevantRadioForm().formComponent, {
                type: formik.values["type"],
                onInnerFormValueChange: handleInnerFormValueChange
              })}
          </PreloaderWrapper>
          {!isEmpty(formik.values["type"]) && (
            <div className={styles["file-uploader__container"]}>
              <h4 className={styles["product-types-form__title"]}>
                Завантажити файли для уточнення запиту
              </h4>
              <div className={styles["product-types-form__field-container"]}>
                <SyncVisualMultipleUploader
                  loadedFiles={loadedFiles}
                  setLoadedFiles={setLoadedFiles}
                />
              </div>
            </div>
          )}
          <div className={styles["controls-container"]}>
            <CommonButton label={"Назад"} outlined onClick={handleBackClick} />
            <CommonButton
              label={"Надіслати"}
              type="submit"
              disabled={!formik.values.type}
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default RadioSection;
