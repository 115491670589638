import { createSlice } from "@reduxjs/toolkit";

export interface ISubject {
  organization: any;
  data: any;
  userRole: string;
  lastName: any;
  firstName: any;
  middleName: any;
  uuid: string;
  fullName: string;
  role: string;
  isForestUser?: boolean;
  drfo?: string;
  edrpou?: string;
  organizationName?: string;
  passportNumber?: string;
  passportSeries?: string;
  phone?: string;
  email?: string;
}

export interface INotificationsBoard {
  notificationMessage: string;
  updatedAt: string;
}

export interface ITermsOfService {
  conditions: string;
  term: string;
}

export interface ISubjectsState {
  fetchingSubjectsLoading: boolean | null;
  subjects: Array<ISubject>;
  fetchingSubjectInfoLoading: boolean;
  currentSubject: ISubject;
  responseError: any;
  isOnboardingPassed: boolean;
  notificationBoard: INotificationsBoard;
  fetchNotificationBoardLoading: boolean;
  fetchTermsOfServiceRequestLoading: boolean;
  termsOfService: ITermsOfService;
}

const initialState: ISubjectsState = {
  fetchingSubjectsLoading: null,
  subjects: [],
  fetchingSubjectInfoLoading: null,
  currentSubject: null,
  responseError: {},
  isOnboardingPassed: null,
  fetchNotificationBoardLoading: false,
  fetchTermsOfServiceRequestLoading: false,
  notificationBoard: null,
  termsOfService: null
};

export const mainSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    fetchUserDataRequest: (state) => {
      state.fetchingSubjectsLoading = true;
      state.fetchingSubjectInfoLoading = true;
    },
    fetchUserDataSuccess: (state, { payload }) => {
      const userData = {
        ...payload,
        fullName: `${payload.firstName.toUpperCase()} ${payload.middleName.toUpperCase()} ${payload.lastName.toUpperCase()}`,
        subjects: undefined
      };
      state.fetchingSubjectsLoading = false;
      state.fetchingSubjectInfoLoading = false;
      state.subjects = payload.subjects;
      state.currentSubject = userData;
    },
    fetchUserDataError: (state, action) => {
      state.fetchingSubjectsLoading = false;
      state.fetchingSubjectInfoLoading = false;
      state.responseError = action.payload;
    },

    generateSidRequest: (state) => {
      state.fetchingSubjectInfoLoading = true;
    },
    generateSidSuccess: (state) => {
      state.isOnboardingPassed = true;
    },
    generateSidError: (state) => {
      state.fetchingSubjectInfoLoading = false;
      state.isOnboardingPassed = false;
    },

    fetchNotificationBoardRequest: (state) => {
      state.fetchNotificationBoardLoading = true;
    },
    fetchNotificationBoardSuccess: (state, action) => {
      state.fetchNotificationBoardLoading = false;
      state.notificationBoard = action.payload;
    },
    fetchNotificationBoardError: (state) => {
      state.fetchNotificationBoardLoading = false;
    },

    fetchTermsOfServiceRequest: (state) => {
      state.fetchTermsOfServiceRequestLoading = true;
    },
    fetchTermsOfServiceSuccess: (state, action) => {
      state.fetchTermsOfServiceRequestLoading = false;
      state.termsOfService = action.payload.data;
    },
    fetchTermsOfServiceError: (state) => {
      state.fetchTermsOfServiceRequestLoading = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
