import { Form, FormikProvider, useFormik } from "formik";
import {
  CommonButton,
  Dialog,
  FormField,
  Notification,
  Select,
  Title
} from "gov-ua-ui";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import features from "features";
import { IOption, IRemark } from "interfaces";

import styles from "./add-remark-modal.module.scss";

interface AddRemarkModalInterface {
  title?: string;
  notificationText: string;
  declineLabel?: string;
  acceptLabel?: string;
  onAccept?: (remark) => void;
  onClose?: () => void;
  withoutCloseButton?: boolean;
  remark?: IRemark;
  blockType: string;
}

const AddRemarkModal = ({
  title,
  notificationText,
  declineLabel,
  acceptLabel,
  onAccept,
  onClose,
  withoutCloseButton = false,
  remark,
  blockType
}: AddRemarkModalInterface) => {
  const dispatch = useDispatch();

  const handleHide = () => dispatch(features.modal.actions.hideModal());

  const handleAccept = () => {
    handleHide();
    onAccept && formik.submitForm();
  };

  const handleClose = () => {
    handleHide();
    onClose && onClose();
  };

  interface FormikDataInterface {
    errorType: IOption;
    errorDescription: string;
  }

  const invoiceSectionErrors = [
    {
      value: "scan_copy",
      label: "Сканована копія"
    },
    {
      value: "document_details",
      label: "Реквізити документу"
    },
    {
      value: "document_error",
      label: "Помилка в документах"
    },
    {
      value: "other",
      label: "Інше"
    }
  ];

  const mockSelectRemarksType = {
    invoice: invoiceSectionErrors,
    contract: invoiceSectionErrors,
    specification: invoiceSectionErrors,
    products: [
      {
        value: "production_and_specie",
        label: "Продукція та порода"
      },
      {
        value: "geometric_dimensions",
        label: "Геометричні розміри"
      },
      {
        value: "product_yield_standards",
        label: "Норми виходу продукції"
      },
      {
        value: "document_error",
        label: "Помилка в документах"
      },
      {
        value: "other",
        label: "Інше"
      }
    ],
    application_basis_document: [
      {
        value: "mismatching_documents_data",
        label: "Електронні дані та сканована копія не співпадають"
      },
      {
        value: "product_yield_standards",
        label: "Норми виходу продукції"
      },
      {
        value: "document_error",
        label: "Помилка в документах"
      },
      {
        value: "other",
        label: "Інше"
      }
    ]
  };

  const onFormSubmit = (values: FormikDataInterface) => {
    const remark = {
      errorType: values.errorType.value,
      description: values.errorDescription,
      correct: false
    };
    onAccept(remark);
  };

  const formik = useFormik<FormikDataInterface>({
    validateOnChange: true,
    initialValues: {
      errorType: remark.errorType
        ? mockSelectRemarksType[blockType].find(
            (error) => error.value === remark.errorType
          )
        : mockSelectRemarksType[blockType][0],
      errorDescription: remark?.description ?? ""
    },
    enableReinitialize: true,
    onSubmit: onFormSubmit,
    validationSchema: Yup.object().shape({
      errorType: Yup.object().shape({
        value: Yup.string().required("Виберіть тип помилки")
      }),
      errorDescription: Yup.string().required("Поле обов’язкове")
    })
  });

  const isAcceptDisabled = useMemo(() => {
    return formik.values["errorDescription"].length == 0;
  }, [formik.values]);

  const handleTypeChange = (value) => {
    formik.setFieldValue("errorType", value);
  };

  return (
    <Dialog
      withoutCloseButton={withoutCloseButton}
      onClose={handleHide}
      className={styles["add-remark-modal"]}
      isOpen
    >
      <Title size={24} className={styles["add-remark-modal__title"]}>
        {title ? title : "Підтвердіть дію"}
      </Title>
      <Notification text={notificationText} />
      <FormikProvider value={formik}>
        <Form className={styles["describe-modal__form"]}>
          <div className={styles["describe-modal__small-field-item"]}>
            <Select
              value={formik.values["errorType"]}
              name="errorType"
              options={mockSelectRemarksType[blockType]}
              placeholder="Вкажіть тип помилки"
              onSelectChange={handleTypeChange}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
            />
          </div>
          <div className={styles["describe-modal__small-field-item"]}>
            <FormField
              name="errorDescription"
              placeholder="Опишіть помилку"
              as="textarea"
              className={"textarea-field"}
            />
          </div>
        </Form>
      </FormikProvider>
      <div className={styles["add-remark-modal__controls-container"]}>
        <CommonButton
          label={declineLabel ? declineLabel : "Скасувати"}
          outlined
          onClick={handleClose}
        />
        <CommonButton
          label={acceptLabel ? acceptLabel : "Підтвердити"}
          onClick={handleAccept}
          disabled={isAcceptDisabled}
        />
      </div>
    </Dialog>
  );
};
export default AddRemarkModal;
