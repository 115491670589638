import cn from "classnames";
import { useEffect, useState } from "react";

import useMediaQuery from "components/useMediaQuery";

import styles from "./circle-progress-bar.module.scss";

interface CircleProgressBarInterface {
  progress: number;
  className?: string;
  mobile?: boolean;
}

const RADIUS = 27;
const DASHES = 170;

const MOBILE_RADIUS = 15;
const MOBILE_DASHES = 95;

const CircleProgressBar = ({
  progress,
  className,
  mobile = true
}: CircleProgressBarInterface): JSX.Element => {
  const isMobile = useMediaQuery(992);

  const [percent, setPercent] = useState(0);

  const getRadius = (supportMobileCircle, isMobileWidth) => {
    if (supportMobileCircle) {
      return Math.PI * ((!isMobileWidth ? RADIUS : MOBILE_RADIUS) * 2);
    } else {
      return Math.PI * (RADIUS * 2);
    }
  };

  const circumference = getRadius(mobile, isMobile);

  useEffect(() => {
    let p = progress;

    if (progress > 100) p = 100;
    if (!progress || progress < 0) {
      p = 0;
    }

    const percentToFill = ((100 - p) / 100) * circumference;

    setPercent(Math.round(percentToFill));
  }, [progress, circumference]);
  return (
    <div className={cn(styles["bar-wrapper"], className)}>
      <div
        className={
          !mobile
            ? styles["circle-progress-bar-big-only"]
            : styles["circle-progress-bar"]
        }
        data-pct={progress || 0}
      >
        <svg
          className={styles["circle-progress-bar__svg"]}
          width={isMobile && mobile ? "34" : "60"}
          height={isMobile && mobile ? "34" : "60"}
        >
          <circle
            className={
              !mobile
                ? styles["circle-progress-bar__base-big-only"]
                : styles["circle-progress-bar__base"]
            }
            r={isMobile && mobile ? MOBILE_RADIUS : RADIUS}
            cx={isMobile && mobile ? "17" : "30"}
            cy={isMobile && mobile ? "17" : "30"}
            fill="transparent"
            strokeDasharray={isMobile && mobile ? MOBILE_DASHES : DASHES}
            strokeDashoffset="0"
          />
          <circle
            className={
              !mobile
                ? styles["circle-progress-bar__bar-big-only"]
                : styles["circle-progress-bar__bar"]
            }
            r={isMobile && mobile ? MOBILE_RADIUS : RADIUS}
            cx={isMobile && mobile ? "43" : "30"}
            cy={isMobile && mobile ? "43" : "30"}
            fill="transparent"
            strokeDasharray={isMobile && mobile ? MOBILE_DASHES : DASHES}
            strokeDashoffset={percent}
          />
        </svg>
      </div>
    </div>
  );
};

export default CircleProgressBar;
