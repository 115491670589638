import { CommonButton, Notification } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import features from "features";
import { IRootState } from "reducer";
import { IRequestToSystemListState } from "../../RequestToSystemListPage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import FormLayout from "components/layouts/FormLayout/FormLayout";
import OtherQuestionsForm from "../forms/OtherQuestionsForm";
import RadioSection from "../radioSection/RadioSection";

import styles from "./request-to-system-page.module.scss";

const breadcrumbs = [
  {
    text: "ЕкоСисема",
    to: `/`
  },
  {
    text: "Запит до системи",
    to: "/"
  }
];

const RequestToSystemPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { type } = useParams<{
    type: string;
  }>();

  const [correctRequest, setCorrectRequest] = useState(null);

  const {
    requestsToSystemList,
    sendRequestLoading,
    isRequestSend
  } = useSelector<IRootState, IRequestToSystemListState>(
    (state) => state.subjectRequestToSystemList
  );

  useEffect(() => {
    dispatch(
      features.requestToSystemList.actions.fetchRequestsToSystemListRequest()
    );
    return () =>
      dispatch(
        features.requestToSystemList.actions.resetSendRequestsToSystemData()
      );
  }, []);

  useEffect(() => {
    if (!isEmpty(requestsToSystemList))
      setCorrectRequest(requestsToSystemList?.find((el) => el.type === type));
  }, [requestsToSystemList]);

  const handleSuccessButtonClick = () => {
    dispatch(
      features.requestToSystemList.actions.resetSendRequestsToSystemData()
    );
    navigate("/");
  };

  return (
    <FormLayout breadcrumbs={breadcrumbs}>
      {!isEmpty(correctRequest) && (
        <div>
          <h1 className={styles["title"]}>
            {!isRequestSend ? correctRequest.title : "Успіх"}
          </h1>
          {!isRequestSend ? (
            <div>
              {Number(correctRequest.id) < 6 && (
                <PreloaderWrapper loading={sendRequestLoading}>
                  <RadioSection requestType={type} />
                </PreloaderWrapper>
              )}
              {Number(correctRequest.id) === 7 && (
                <PreloaderWrapper loading={sendRequestLoading}>
                  <OtherQuestionsForm type={correctRequest.type} />
                </PreloaderWrapper>
              )}
            </div>
          ) : (
            <div className={styles["success-container"]}>
              <Notification text="Форма успішно надіслана. Чекайте відповіді в продовж наступного робочого дня" />
              <CommonButton
                outlined
                label="На головну"
                onClick={handleSuccessButtonClick}
              />
            </div>
          )}
        </div>
      )}
    </FormLayout>
  );
};

export default RequestToSystemPage;
