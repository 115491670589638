import cn from "classnames";
import {
  DatePicker,
  FormField,
  Title,
  VisualUploadMultipleFiles
} from "gov-ua-ui";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import features from "features";
import { IFile } from "interfaces";

import Autocomplete from "components/Autocomplete/Autocomplete";

import styles from "./add-document-form.module.scss";

interface AddDocumentFormInterface {
  formik: any;
  fieldsPrefix: string;
  title: string;
  docNumberLabel: string;
  docDateLabel: string;
  stateAuthorityLabel: string;
  uploadTitle?: string;
  onLoadFiles: (
    acceptedFiles: any,
    rejectedFiles: any,
    replaceFile: any
  ) => void;
  onDeleteFile?: (file: any) => Promise<void>;
  loadedFiles: IFile[];
  searchParams?: string;
  maxDate?: Date;
}

const AddDocumentForm = ({
  formik,
  fieldsPrefix,
  title,
  docNumberLabel,
  docDateLabel,
  stateAuthorityLabel,
  uploadTitle,
  onLoadFiles,
  loadedFiles,
  onDeleteFile: deleteFile,
  searchParams,
  maxDate
}: AddDocumentFormInterface) => {
  const dispatch = useDispatch();

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);

    if (option.value) {
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: option.value
          }
        })
      );
    }
  }, []);

  const handleDateFromChange = (name, date: Date) => {
    formik.setFieldValue(name, date);
  };

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  return (
    <div className={styles["add-document-form"]}>
      <Title size={20} className={styles["add-document-form__title"]}>
        {title}
      </Title>

      <div className={styles["add-document-form__wrapper"]}>
        <div className={styles["add-document-form__fields-container"]}>
          <div className={styles["add-document-form__fields-row"]}>
            <div
              className={cn(
                styles["add-document-form__field-wrapper"],
                styles["add-document-form__small-wrapper"]
              )}
            >
              <FormField
                name={`${fieldsPrefix}Number`}
                placeholder={docNumberLabel}
              />
            </div>

            <div
              className={cn(
                styles["add-document-form__field-wrapper"],
                styles["add-document-form__small-wrapper"]
              )}
            >
              <DatePicker
                placeholder={docDateLabel}
                name={`${fieldsPrefix}Date`}
                withFormik
                date={formik.values[`${fieldsPrefix}Date`]}
                onChange={(date) => {
                  handleDateFromChange(`${fieldsPrefix}Date`, date);
                }}
                maxDate={maxDate}
              />
            </div>
          </div>

          <div
            className={cn(
              styles["add-document-form__field-wrapper"],
              styles["add-document-form__large-wrapper"]
            )}
          >
            <Autocomplete
              name={`${fieldsPrefix}StateAuthority`}
              value={formik.values[`${fieldsPrefix}StateAuthority`]}
              placeholder={stateAuthorityLabel}
              entity="organizations"
              onChange={onAutocompleteChange}
              params={searchParams ?? ""}
            />
          </div>
        </div>

        <VisualUploadMultipleFiles
          title={uploadTitle ? uploadTitle : "Завантажити скан-копію"}
          accept={{
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpg"]
          }}
          loadedFiles={loadedFiles}
          onDelete={deleteFile}
          onDocumentView={onDocumentView}
          onLoad={onLoadFiles}
        />
      </div>
    </div>
  );
};

export default AddDocumentForm;
