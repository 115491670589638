import { createSlice } from "@reduxjs/toolkit";
import { IeDoc } from "interfaces";

export type OfficialType = "official" | "headOfficial" | "dalruOfficial";

export interface IProgressedApplication {
  eAnnulmentApplication: IeDoc;
  certificateNumber?: string;
  productionOutput?: any;
  exporterOrganizationName: string;
  submissionDate: number;
  number: string;
  type?: string;
  uuid: string;
  buyer: string;
  invoiceNumber: number;
  invoiceAt: number;
  consignee: string;
  checkedPercent: number;
  costStandards: number;
  remarksNumber: number;
  processingTime: number;
  eApplication: IeDoc;
}

export interface IProgressedApplicationsState {
  fetchingInQueueApplicationsLoading: boolean | null;
  fetchingInProcessApplicationsLoading: boolean | null;
  application: IProgressedApplication;
  applications: [];
  responseError: any;
}

const initialState: IProgressedApplicationsState = {
  fetchingInQueueApplicationsLoading: null,
  application: null,
  responseError: {},
  fetchingInProcessApplicationsLoading: false,
  applications: null,
};

export const mainSlice = createSlice({
  name: "officialProgressed",
  initialState,
  reducers: {
    fetchProgressedRequest: (state, ) => {
      state.fetchingInProcessApplicationsLoading = true;
    },
    fetchProgressedSuccess: (state, action) => {
      state.fetchingInProcessApplicationsLoading = false;
      state.application = action.payload;
    },
    fetchProgressedError: (state, action) => {
      state.application = initialState.application;
      state.fetchingInProcessApplicationsLoading = false;
      state.responseError = action.payload;
    },

    clearProgressed: (state, ) => {
      state.application = initialState.application;
    },

    searchInQueueRequest: (state, ) => {
      state.fetchingInQueueApplicationsLoading = true;
    },
    searchInQueueSuccess: (state, action) => {
      state.fetchingInQueueApplicationsLoading = false;
      state.applications = action.payload.applications;
    },
    searchInQueueError: (state, action) => {
      state.applications = initialState.applications;
      state.fetchingInQueueApplicationsLoading = false;
      state.responseError = action.payload;
    }
  }
});

export const { actions, reducer } = mainSlice;
