import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

const ScrollToFieldError = () => {
  const [count, setCount] = useState(0);
  const { submitCount, isValid, errors, status, setStatus } =
    useFormikContext();

  useEffect(() => {
    if (
      (count < submitCount && !isEmpty(errors)) ||
      status === "scrollToForm"
    ) {
      setCount(submitCount);

      if (isValid) return;

      const fieldErrorNames = Object.keys(errors);
      if (fieldErrorNames.length <= 0) return;

      let fieldErrorName = fieldErrorNames[0];
      if (isArray(errors[fieldErrorName])) {
        const errorParent = errors[fieldErrorName];
        const arrayIndex = errorParent.findIndex((item) => item !== undefined);
        fieldErrorName +=
          "." + arrayIndex + "." + Object.keys(errorParent[arrayIndex])[0];
      }

      const element = document.querySelector<HTMLElement>(
        `input[name='${fieldErrorName}']`
      );

      if (!element) return;

      if (element.getAttribute("type") === "hidden") {
        element.removeAttribute("type");
        element.style.position = "absolute";
        element.style.opacity = "0";
        element.style.pointerEvents = "none";
      }

      element.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });

      if (status === "scrollToForm") setStatus(undefined);
    }
  }, [errors, count, submitCount, isValid, status, setStatus]);

  return null;
};

export default ScrollToFieldError;
