import Section from "components/Section/Section";
import SectionText from "components/Section/SectionText/SectionText";

import { ITrustedDoc as ApplicationTrustedDoc } from "scenes/subject/applications/ApplicationPage/interfaces/ITrustedDoc";

import styles from "./trusted-doc.module.scss";

interface ITrustedDoc extends ApplicationTrustedDoc {
  docs: Array<any>;
  additionalElements?: any;
  isAnnul: boolean;
}

const TrustedDoc = ({
  type,
  series,
  number,
  docs,
  additionalElements,
  isAnnul
}: ITrustedDoc) => {
  return (
    <Section
      title={`Документ, яким підтверджується право представляти інтереси заявника для ${
        isAnnul ? "анулювання" : "отримання"
      } сертифіката`}
      color="GREY"
    >
      <div className={styles["trusted-doc-section"]}>
        <div className={styles["trusted-doc-section__text-subsection"]}>
          <SectionText title="Тип документу">{type}</SectionText>
          <SectionText title="Серія">{series}</SectionText>
          <SectionText title="Номер">{number}</SectionText>
        </div>
        {docs.map((doc, index) => {
          return (
            <div
              className={styles["trusted-doc-section__doc-subsection"]}
              key={index}
            >
              {doc}
            </div>
          );
        })}
      </div>
      {additionalElements}
    </Section>
  );
};

export default TrustedDoc;
