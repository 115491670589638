import features from "features";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getRole } from "helpers";
import { useState } from "react";

import BlankPage from "components/BlankPage/BlankPage";
import OfficialOfficeNavigation from "components/OfficialOfficeNavigation/OfficialOfficeNavigation";

import styles from "./personal-office-official-layout.module.scss";

interface PersonalLayoutInterface {
  children?: any;
}

const PersonalOfficeOfficialLayout = ({
  children
}: PersonalLayoutInterface) => {
  const dispatch = useDispatch();
  const [userRole] = useState(getRole());

  useEffect(() => {
    if (userRole) {
      dispatch(features.officialDashboard.actions.fetchStatisticsRequest());
      if (userRole === "headOfficial" || userRole === "dalruOfficial")
        dispatch(features.officialProgressed.actions.searchInQueueRequest());
    }
  }, [dispatch, userRole]);

  return (
    <main className={styles["personal-layout"]}>
      <OfficialOfficeNavigation />
      {children || <BlankPage title="Вам не найдійшло жодного повідомлення" />}
    </main>
  );
};

export default PersonalOfficeOfficialLayout;
