import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { APP_URL } from "constant";
import features from "features";
import { getImageUrl } from "helpers";
import { IRootState } from "reducer";
import { IRegularQuestionsState } from "../ducks";

import Expand from "components/Expand/Expand";
import Preloader from "components/Preloader/Preloader";
import FormLayout from "components/layouts/FormLayout/FormLayout";
import useMediaQuery from "components/useMediaQuery";

import styles from "./regular-questions-page.module.scss";

const breadcrumbs = [
  {
    text: "ЕкоСисема",
    to: `/`
  },
  {
    text: "Про адміністративну послугу",
    to: "/"
  }
];

const RegularQuestionsPage = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(768);

  const { regularQuestionsLoading, regularQuestions } = useSelector<
    IRootState,
    IRegularQuestionsState
  >((state) => state.subjectRegularQuestions);

  useEffect(() => {
    dispatch(features.regularQuestions.actions.fetchRegularQuestionsRequest());
  }, []);

  return (
    <FormLayout breadcrumbs={breadcrumbs}>
      <h1 className={styles["title"]}>Про адміністративну послугу</h1>
      {regularQuestionsLoading ? (
        <Preloader />
      ) : (
        <div className={styles["get-certificate__questions"]}>
          {!isEmpty(regularQuestions) &&
            regularQuestions.map((item) => (
              <Expand title={item.question}>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                {item?.links && (
                  <div className={styles["video-container"]}>
                    <iframe
                      title="Your Unique Title"
                      width={isMobile ? "100%" : "560"}
                      height="315"
                      src={item?.links}
                      frameBorder="0"
                      allowFullScreen
                      data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                )}
                {item?.images &&
                  getImageUrl(item?.images)
                    .split(",")
                    .map(
                      (image) =>
                        !isEmpty(image) && (
                          <div className={styles["image-container"]}>
                            <img
                              src={`${APP_URL}/assets/${image}`}
                              alt="question"
                            />
                          </div>
                        )
                    )}
              </Expand>
            ))}
        </div>
      )}
    </FormLayout>
  );
};

export default RegularQuestionsPage;
