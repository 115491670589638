import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const PreviousUsagePage = (props: any): JSX.Element => {
  return (
    <Layout
      title={""}
      meta={""}
      headerType="NO_HEADER"
      infoType="TO_APPLICATION"
      isHideFooter
    >
      <features.previousUsage.page {...props} />
    </Layout>
  );
};

export default PreviousUsagePage;
