import { getRole, getToken } from "helpers";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children, role }: any) => {
  const token = getToken();
  const userRole = getRole() === "user" ? "user" : "officialUser";

  if (!token) {
    return <Navigate to={"/"} />;
  }

  if (role && role !== userRole) {
    return <Navigate to={"/"} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
