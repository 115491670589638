export const applicationStatuses = {
  received: {
    title: "Отримано",
    background: "#19BE6F",
    color: "white"
  },
  declined: {
    title: "Відмовлено",
    background: "#FA594F",
    color: "white"
  },
  annulled: {
    title: "Анульовано",
    background: "#464849",
    color: "white"
  },
  ordered: {
    title: "Замовлено",
    background: "#ffd600",
    color: "black"
  },
  draft: {
    title: "Чернетка",
    background: "#dadada",
    color: "black"
  }
};

export const applicationRouteStatuses = {
  received: "received",
  declined: "declined",
  annulled: "annulled",
  ordered: "ordered",
  draft: "drafts"
};

export const applicationOfficialStatuses = {
  received: {
    title: "Отримано",
    background: "#19BE6F",
    color: "white"
  },
  declined: {
    title: "Відмовлено",
    background: "#FA594F",
    color: "white"
  },
  annulled: {
    title: "Анульовано",
    background: "#464849",
    color: "white"
  }
};

export const applicationOfficialInQueueStatuses = {
  true: {
    title: "Обробляється",
    background: "#FFD600",
    color: "black"
  },
  false: {
    title: "В черзі",
    background: "#464849",
    color: "white"
  }
};

export const customsClearance = {
  true: {
    title: "ПРОЙШЛО",
    background: "#BADAEC",
    color: "black"
  },
  false: {
    title: "НЕ ПРОЙШЛО",
    background: "#FEECEB",
    color: "black"
  }
};
