import Section from "components/Section/Section";

import { VisualUploadMultipleFiles } from "gov-ua-ui";
import { IFile } from "interfaces";

import styles from "./grounds-for-cancellation.module.scss";

interface ApplicationTypeInterface {
  text: string;
  loadedFiles?: Array<IFile>;
  onDocumentView?: (file: any) => void;
}

const GroundsForCancellation = ({
  text,
  loadedFiles,
  onDocumentView
}: ApplicationTypeInterface): JSX.Element => {
  return (
    <Section
      title="Підстава анулювання"
      color={"WHITE"}
      titleClassName={styles["grounds-for-cancellation__title"]}
      id="grounds-for-annull"
    >
      <p className={styles["grounds-for-cancellation__text"]}>{text}</p>
      <div className={styles["grounds-for-cancellation__documents"]}>
        <p className={styles["grounds-for-cancellation__documents-title"]}>
          Копія документів
        </p>
        <VisualUploadMultipleFiles
          title={"Документи не завантажено"}
          accept={{
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpg"]
          }}
          containerClassName={
            styles["grounds-for-cancellation__documents-files"]
          }
          loadedFiles={loadedFiles}
          onDocumentView={onDocumentView}
          readonly
        />
      </div>
    </Section>
  );
};

export default GroundsForCancellation;
