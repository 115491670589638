import { createSlice } from "@reduxjs/toolkit";
export interface IAuthState {
  isAuthSuccessed: boolean;
}

const initialState: IAuthState = {
  isAuthSuccessed: false
};

export const mainSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInRequest: (state) => {
      state.isAuthSuccessed = false;
    },
    signInSuccess: (state) => {
      state.isAuthSuccessed = true;
    },
    signInError: (state) => {
      state.isAuthSuccessed = false;
    },

    signOutRequest: () => {},
    signOutSuccess: () => {},
    signOutError: () => {},

    initTokenRequest: () => {},
    initTokenSuccess: () => {},
    initTokenError: () => {}
  }
});

export const { actions, reducer } = mainSlice;
