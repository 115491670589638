import cn from "classnames";
import React from "react";

import styles from "./icon-button.module.scss";

interface IconButtonInterface {
  children?: any | undefined;
  icon: any;
  className?: string;
  classNameIcon?: string;
  alt?: string;
  disabled?: Boolean;
  onClick?: (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
}

const IconButton = ({
  children = undefined,
  icon,
  className = "",
  classNameIcon = "",
  alt = "",
  disabled,
  onClick
}: IconButtonInterface): JSX.Element => {
  return (
    <button
      className={cn(styles["icon-button"], className, {
        [styles["icon-button--disabled"]]: disabled
      })}
      onClick={!disabled ? onClick : () => {}}
    >
      <img
        src={icon}
        alt={alt}
        className={cn(styles["icon-button__icon"], classNameIcon)}
      />
      {children}
    </button>
  );
};

export default IconButton;
