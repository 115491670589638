import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchApplication({ payload }) {
  let request;
  if (
    payload.params.applicationType === "archived" ||
    payload.params.applicationType === "annulled"
  )
    request = API.fetchArchivedApplicationView;
  else if (payload.params.applicationType === "in-queue")
    request = API.fetchInQueueApplicationView;
  else request = API.fetchProgressedApplicationView;
  const {
    fetchApplicationSuccess: setSuccess,
    fetchApplicationError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* addApplicationRemark({ payload }) {
  const request = API.addProgressedApplicationView;
  const {
    addApplicationRemarkSuccess: setSuccess,
    addApplicationRemarkError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchApplicationCertificate({ payload }) {
  const request = payload.params.isPositive
    ? API.fetchECertificateData
    : API.fetchECertificateDeclineData;
  const {
    fetchApplicationCertificateSuccess: setSuccess,
    fetchApplicationCertificateError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* acceptCertificateDecision({ payload }) {
  const request = payload.params.isPositive
    ? API.progressedApplicationConfirm
    : API.progressedApplicationDecline;
  const {
    acceptCertificateDecisionSuccess: setSuccess,
    acceptCertificateDecisionError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* acceptCertificateAnnulDecision({ payload }) {
  const request = API.annulApplication;
  const {
    acceptCertificateAnnulDecisionSuccess: setSuccess,
    acceptCertificateAnnulDecisionError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchCertificateAnnulDecline({ payload }) {
  const request = API.fetchAnnulApplication;
  const {
    fetchCertificateAnnulDeclineSuccess: setSuccess,
    fetchCertificateAnnulDeclineError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchApplicationRequest().type, fetchApplication);
  yield takeLatest(
    actions.addApplicationRemarkRequest().type,
    addApplicationRemark
  );
  yield takeLatest(
    actions.acceptCertificateDecisionRequest().type,
    acceptCertificateDecision
  );
  yield takeLatest(
    actions.acceptCertificateAnnulDecisionRequest().type,
    acceptCertificateAnnulDecision
  );
  yield takeLatest(
    actions.fetchCertificateAnnulDeclineRequest().type,
    fetchCertificateAnnulDecline
  );
  yield takeLatest(
    actions.fetchApplicationCertificateRequest().type,
    fetchApplicationCertificate
  );
}
