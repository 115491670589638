import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, CommonButton, FormField } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";

import features from "features";
import { formatDate, parseResErrors } from "helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { IInvoice } from "scenes/subject/applications/ApplicationPage/interfaces";

import Autocomplete from "components/Autocomplete/Autocomplete";
import ResponseErrors from "components/ResponseErrors/ResponseErrors";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import Section from "components/Section/Section";
import SectionDoc from "components/Section/SectionDoc/SectionDoc";
import LoadSectionDoc from "components/certificateComponents/createCertificateComponents/LoadSectionDoc/LoadSectionDoc";
import Invoice from "components/certificateComponents/viewCertificateComponents/Invoice/Invoice";

import styles from "./create-invoice.module.scss";

interface ICreateInvoice {
  applicationUuid: string;
  isReadonly?: boolean;
  onEdit?: (status: boolean) => void;
}

const CreateInvoice = ({
  applicationUuid,
  isReadonly,
  onEdit
}: ICreateInvoice): JSX.Element => {
  const dispatch = useDispatch();
  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const [lockedForm, setLockedForm] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [unknownError, setUnknownError] = useState(null);

  const invoice: IInvoice = useMemo(() => {
    if (application && application.invoice) {
      return application.invoice;
    }

    return null;
  }, [application]);

  const isFilesUploaded = useMemo(() => {
    if (
      application.files["contract"].length === 0 ||
      application.files["invoice"].length === 0 ||
      application.files["specification"].length === 0
    ) {
      return false;
    } else if (
      application.files["contract"].find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    )
      return false;
    else if (
      application.files["invoice"].find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    )
      return false;
    else if (
      application.files["specification"].find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    )
      return false;
    return true;
  }, [application.files]);

  useEffect(() => {
    if (application?.invoice) {
      if (!isInitialized && isFilesUploaded) {
        setLockedForm(true);
        onEdit(false);
        setIsInitialized(true);
      }
    }
  }, [application, isFilesUploaded, isInitialized, onEdit]);

  useEffect(() => {
    if (application?.invoice) {
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: application.invoice.buyerExternalId
          },
          onSuccess: (response) => {
            formik.setFieldValue(`buyerExternalId`, {
              value: application.invoice.buyerExternalId,
              label: response.name
            });

            formik.setFieldValue(
              `buyerCountry`,
              response.country +
                (response.address ? `, ${response.address}` : "")
            );
          }
        })
      );

      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: application.invoice.consigneeExternalId
          },
          onSuccess: (response) => {
            formik.setFieldValue(`consigneeExternalId`, {
              value: application.invoice.consigneeExternalId,
              label: response.name
            });

            formik.setFieldValue(
              `consigneeCountry`,
              response.country +
                (response.address ? `, ${response.address}` : "")
            );
          }
        })
      );
    }
  }, [application, lockedForm]);

  const toggleLockedForm = () => {
    setLockedForm((prevState) => {
      onEdit(prevState);
      return !prevState;
    });
  };

  const handleFormSubmit = (values, { setFieldError }) => {
    setUnknownError(null);

    if (!isFilesUploaded) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }

    const fields = {
      buyerExternalId: parseInt(values.buyerExternalId?.value),
      consigneeExternalId: parseInt(values.consigneeExternalId?.value),
      contractNumber: values.contractNumber,
      contractDate: values.contractDate,
      invoiceNumber: values.invoiceNumber,
      invoiceDate: values.invoiceDate,
      specificationNumber: values.specificationNumber,
      specificationDate: values.specificationDate
    };

    dispatch(
      features.application.actions.setInvoiceRequest({
        fields,
        params: {
          uuid: applicationUuid
        },
        onSuccess: () => {
          toggleLockedForm();
          dispatch(
            features.application.actions.fetchApplicationRequest({
              params: { uuid: applicationUuid }
            })
          );
        },
        onError: (e) =>
          parseResErrors({
            setFieldError,
            errorsRes: e,
            fields: values,
            onUnknownErrors: setUnknownError
          })
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      contractNumber: invoice?.contractNumber || "",
      contractDate: invoice?.contractDate
        ? new Date(invoice?.contractDate)
        : "",
      invoiceNumber: invoice?.invoiceNumber || "",
      invoiceDate: invoice?.invoiceDate ? new Date(invoice?.invoiceDate) : "",
      specificationNumber: invoice?.specificationNumber || "",
      specificationDate: invoice?.specificationDate
        ? new Date(invoice?.specificationDate)
        : "",
      buyerExternalId: {
        value: "",
        label: ""
      },
      consigneeExternalId: {
        value: "",
        label: ""
      },
      buyerCountry: "",
      consigneeCountry: ""
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      buyerExternalId: Yup.object().shape({
        value: Yup.string().required("Виберіть покупця")
      }),
      consigneeExternalId: Yup.object().shape({
        value: Yup.string().required("Виберіть вантажоодержувача")
      }),
      contractNumber: Yup.string().required("Введіть номер договору"),
      contractDate: Yup.date().required("Введіть дату договору"),
      invoiceNumber: Yup.string().required("Введіть номер інвойсу"),
      invoiceDate: Yup.date().required("Введіть дату інвойсу"),
      specificationNumber: Yup.string().required("Введіть номер специфікації"),
      specificationDate: Yup.string().required("Введіть дату специфікації")
    }),
    onSubmit: handleFormSubmit
  });

  const handleFormCancel = useCallback(() => {
    if (!isFilesUploaded) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }
    formik.setValues(formik.initialValues);
    toggleLockedForm();
  }, [isFilesUploaded]);

  const onDocDateChange = (date: Date, name) => {
    formik.setFieldValue(name, date);
  };

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);

    if (option.value) {
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: option.value
          },
          onSuccess: (response) => {
            formik.setFieldValue(
              `${name.replace("ExternalId", "")}Country`,
              response.country +
                (response.address ? `, ${response.address}` : "")
            );
          }
        })
      );
    } else {
      formik.setFieldValue(`${name.replace("ExternalId", "")}Country`, "");
    }
  }, []);

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  return (
    <div className={styles["create-invoice-wrapper"]}>
      {lockedForm ? (
        <Invoice
          buyer={formik.values["buyerExternalId"].label}
          buyerCountry={formik.values["buyerCountry"]}
          consignee={formik.values["consigneeExternalId"].label}
          consigneeCountry={formik.values["consigneeCountry"]}
          additionalElements={
            !isReadonly ? (
              <CommonButton
                className={cn(styles["form-btn"], styles["invoice-btn"])}
                outlined={true}
                onClick={toggleLockedForm}
                label="Змінити"
              />
            ) : null
          }
          docs={[
            <SectionDoc
              textItems={[
                {
                  title: "Номер договору",
                  content: formik.values["contractNumber"]
                },
                {
                  title: "Дата договору",
                  content: formatDate(formik.values["contractDate"]).date
                }
              ]}
              textPosition="LEFT"
              loadedFiles={application.files["contract"]}
              onDocumentView={(file) => onDocumentView(file)}
            />,
            <SectionDoc
              textItems={[
                {
                  title: "Номер інвойсу",
                  content: formik.values["invoiceNumber"]
                },
                {
                  title: "Дата інвойсу",
                  content: formatDate(formik.values["invoiceDate"]).date
                }
              ]}
              textPosition="LEFT"
              loadedFiles={application.files["invoice"]}
              onDocumentView={(file) => onDocumentView(file)}
            />,
            <SectionDoc
              textItems={[
                {
                  title: "Номер специфікації",
                  content: formik.values["specificationNumber"]
                },
                {
                  title: "Дата специфікації",
                  content: formatDate(formik.values["specificationDate"]).date
                }
              ]}
              textPosition="LEFT"
              loadedFiles={application.files["specification"]}
              onDocumentView={(file) => onDocumentView(file)}
            />
          ]}
        />
      ) : (
        <Section
          title="Інвойс"
          color="WHITE"
          className={styles["create-invoice"]}
        >
          {!application?.invoice && (
            <Alert
              className={styles["create-invoice__reminder"]}
              type="warning"
              withIcon
            >
              Для збереження чернетки і подальшої роботи над заявкою заповніть і
              збережіть блок Інвойс. Якщо ви покинете сторінку без збереженого
              Інвойсу, створену вами чернетку заяви на сертифікат буде видалено.
            </Alert>
          )}
          <FormikProvider value={formik}>
            <Form className={styles["create-invoice__form"]}>
              <div className={styles["create-invoice__form-info"]}>
                <div className={styles["create-invoice__info-input"]}>
                  <Autocomplete
                    name="buyerExternalId"
                    placeholder="Покупець"
                    value={formik.values["buyerExternalId"]}
                    entity="organizations"
                    params="type=foreign"
                    onChange={onAutocompleteChange}
                  />

                  <FormField
                    name="buyerCountry"
                    placeholder="Країна"
                    noBorders={true}
                    disabled
                  />
                </div>
                <div className={styles["create-invoice__info-input"]}>
                  <Autocomplete
                    name="consigneeExternalId"
                    placeholder="Вантажоодержувач"
                    value={formik.values["consigneeExternalId"]}
                    entity="organizations"
                    params="type=foreign"
                    onChange={onAutocompleteChange}
                  />
                  <FormField
                    name="consigneeCountry"
                    placeholder="Країна"
                    noBorders={true}
                    disabled
                    className={styles["create-invoice__info-input-country"]}
                  />
                </div>
              </div>
              <div className={styles["create-invoice__form-files"]}>
                <LoadSectionDoc
                  docName="contract"
                  className={styles["create-invoice__form-file"]}
                  applicationUuid={applicationUuid}
                  loadedFiles={application.files["contract"]}
                  selectedDate={formik.values["contractDate"]}
                  docFilePlaceholder="Завантажити договір"
                  docNumberPlaceholder="Номер договору"
                  docDatePlaceholder="Дата договору"
                  onDocDateChange={onDocDateChange}
                />
                <LoadSectionDoc
                  docName="invoice"
                  className={styles["create-invoice__form-file"]}
                  applicationUuid={applicationUuid}
                  loadedFiles={application.files["invoice"]}
                  selectedDate={formik.values["invoiceDate"]}
                  docFilePlaceholder="Завантажити інвойс"
                  docNumberPlaceholder="Номер інвойсу"
                  docDatePlaceholder="Дата інвойсу"
                  onDocDateChange={onDocDateChange}
                />
                <LoadSectionDoc
                  docName="specification"
                  className={styles["create-invoice__form-file"]}
                  applicationUuid={applicationUuid}
                  selectedDate={formik.values["specificationDate"]}
                  loadedFiles={application.files["specification"]}
                  docFilePlaceholder="Завантажити специфікацію"
                  docNumberPlaceholder="Номер специфікації"
                  docDatePlaceholder="Дата специфікації"
                  onDocDateChange={onDocDateChange}
                />
              </div>
              <ResponseErrors errors={unknownError} />
              <div className={styles["form-btns"]}>
                {application?.invoice && (
                  <CommonButton
                    className={styles["form-btn"]}
                    label="Скасувати"
                    onClick={handleFormCancel}
                    outlined
                  />
                )}
                <CommonButton
                  className={styles["form-btn"]}
                  label="Зберегти"
                  type="submit"
                />
              </div>
            </Form>
            <ScrollToFieldError />
          </FormikProvider>
        </Section>
      )}
    </div>
  );
};

export default CreateInvoice;
