import {
  applicationOfficialInQueueStatuses,
  applicationStatuses
} from "dataset";
import { Status } from "gov-ua-ui";
import { formatDate } from "helpers/date";
import styles from "./navigation-subtitle.module.scss";

interface NavigationInterface {
  isProcessing?: boolean;
  isOfficial?: boolean;
  number: string;
  status: string;
  submissionDate: number;
}

const NavigationHeadSubtitle = ({
  isProcessing,
  number,
  submissionDate,
  status,
  isOfficial
}: NavigationInterface): JSX.Element => {
  return (
    <div className={styles["subtitle"]}>
      {number && <h3>{number}</h3>}
      {submissionDate && <h3>{formatDate(submissionDate).date}</h3>}
      {status && (
        <Status
          {...(isOfficial && status === "ordered"
            ? applicationOfficialInQueueStatuses[isProcessing]
            : applicationStatuses[status])}
        />
      )}
    </div>
  );
};

export default NavigationHeadSubtitle;
