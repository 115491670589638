import cn from "classnames";

import styles from "./section.module.scss";

interface SectionInterface {
  id?: string;
  title: string;
  color?: "GREY" | "WHITE";
  children?: Array<JSX.Element> | JSX.Element;
  className?: string;
  titleClassName?: string;
}

const Section = ({
  id,
  title,
  children,
  color = "WHITE",
  className,
  titleClassName
}: SectionInterface) => {
  return (
    <section
      className={cn(
        styles["section"],
        {
          [styles["section_white"]]: color === "WHITE",
          [styles["section_grey"]]: color === "GREY"
        },
        className
      )}
      id={id}
    >
      {title && (
        <h2 className={cn(styles["section__title"], titleClassName)}>
          {title}
        </h2>
      )}
      {children}
    </section>
  );
};

export default Section;
