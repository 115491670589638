import { CustomTooltip } from "gov-ua-ui";
import React, { useCallback, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { docTypes } from "constant";

import SectionDoc from "components/Section/SectionDoc/SectionDoc";

import EDocIcon from "assets/images/icons/e_doc.svg";
import EDocAnnulIcon from "assets/images/icons/e_doc_annul.svg";
import EDocAnnulAltIcon from "assets/images/icons/e_doc_annul_alt.svg";
import EDocAnnulApplicationIcon from "assets/images/icons/e_doc_annul_alt_2.svg";
import EDocApplicationIcon from "assets/images/icons/e_doc_application.svg";
import EDocRefusalIcon from "assets/images/icons/e_doc_refusal.svg";

import styles from "./e-doc-button.module.scss";

interface EDocButtonInterface {
  type: docTypes;
  docImg?: string;
  title?: string;
  parentId?: string;
  contentTitle?: string;
  contentSubtitle?: string;
  contentText?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const EDocButton = ({
  type,
  docImg,
  parentId,
  title,
  contentTitle,
  contentSubtitle,
  contentText,
  onClick
}: EDocButtonInterface): JSX.Element => {
  const [id] = useState(uuidv4());
  const docBtnRef = useRef<HTMLButtonElement>(null);

  const getEDocImage = useCallback(() => {
    switch (type) {
      case docTypes.EDOC:
        return EDocIcon;
      case docTypes.APPLICATION:
        return EDocApplicationIcon;
      case docTypes.REFUSAL:
        return EDocRefusalIcon;
      case docTypes.ANNUL:
        return EDocAnnulIcon;
      case docTypes.ANNUL_APPLICATION:
        return EDocAnnulApplicationIcon;
      case docTypes.ANNUL_ALT:
        return EDocAnnulAltIcon;
      case docTypes.CUSTOM:
        return docImg;
    }
  }, [docImg, type]);

  const renderTooltip = () => {
    return (
      <div className={styles["e-doc-button__tooltip-container"]}>
        <h3 className={styles["e-doc-button__tooltip-title"]}>{title}</h3>
        <SectionDoc
          textItems={[
            {
              title: contentTitle,
              subcontent: contentSubtitle,
              content: contentText
            }
          ]}
          textPosition="BOTTOM"
          docImg={getEDocImage()}
          className={styles["e-doc-button__tooltip-doc-section"]}
          documentImageClassName={
            styles["e-doc-button__tooltip-image-container"]
          }
        />
        <div className={styles["e-doc-button__tooltip-square"]} />
      </div>
    );
  };

  return (
    <CustomTooltip
      content={renderTooltip()}
      positionRegardingToElementId={parentId}
      contentClassName={styles["e-doc-button__tooltip"]}
    >
      <button
        className={styles["e-doc-button"]}
        onClick={onClick}
        ref={docBtnRef}
      >
        <img
          src={getEDocImage()}
          alt="e-Doc"
          className={styles["e-doc-button__image"]}
          id={id}
        />
      </button>
    </CustomTooltip>
  );
};

export default EDocButton;
