import cn from "classnames";
import styles from "./application-layout.module.scss";

interface ApplicationLayoutInterface {
  children: Array<JSX.Element> | JSX.Element | any;
  className?: string;
}

const ApplicationLayout = ({
  children,
  className
}: ApplicationLayoutInterface): JSX.Element => {
  return (
    <main className={cn(styles["application-layout"], className)}>
      {children}
    </main>
  );
};

export default ApplicationLayout;
