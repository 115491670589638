import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CommonButton } from "gov-ua-ui";
import { toastr } from "react-redux-toastr";

import features from "features";
import { getLastPartOfRoute, parseResErrors } from "helpers";
import { getPrevRoute } from "helpers/getPrevRoute";
import { IRootState } from "reducer";

import { ISubjectsState } from "scenes/Subjects/ducks";
import { progressedApplicationFileConfirm } from "services/official";
import { IApplicationReviewState } from "../../OfficialApplicationReviewPage/ducks";

import PdfReader from "components/PdfReader/PdfReader";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ResponseErrors from "components/ResponseErrors/ResponseErrors";

import styles from "./official-signature-certificate-page.module.scss";

const OfficialSignatureCertificatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isPositive] = useState(
    getLastPartOfRoute(pathname) === "e-certificate-confirmation"
  );

  const {
    fetchingApplicationCertificateLoading,
    applicationCertificate,
    application,
    fetchingApplicationCertificateError
  } = useSelector<IRootState, IApplicationReviewState>(
    (state) => state.officialApplicationReview
  );
  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const { applicationId } = useParams<{
    applicationId?: string;
  }>();

  const prevRoute = useMemo(() => {
    return getPrevRoute(pathname);
  }, [pathname]);

  const goBack = useCallback(() => {
    dispatch(
      features.officialApplicationReview.actions.clearApplicationCertificateData()
    );
    navigate(prevRoute);
  }, [dispatch, navigate, prevRoute]);

  const submit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "SIGNATURE",
        modalProps: {
          modalTitle: `${
            isPositive ? "Затвердити" : "Відмовити"
          } заяву на отримання сертифіката  ${application?.number}`,
          processedName: "Обробляв",
          processedBy: currentSubject.fullName,
          location: currentSubject.organizationName,
          isPositive: isPositive,
          percentages: application?.checkedPercent,
          fileUrl: applicationCertificate,
          getFileUrl: async () =>
            await progressedApplicationFileConfirm({
              params: {
                uuid: applicationId
              }
            }),

          onAccept: (signedFile) => {
            dispatch(features.modal.actions.hideModal());
            dispatch(
              features.modal.actions.showModal({
                modalType: "PRELOADER",
                modalProps: {
                  title: "Опрацювання запиту",
                  loading: true
                }
              })
            );
            dispatch(
              features.officialApplicationReview.actions.acceptCertificateDecisionRequest(
                {
                  params: { uuid: applicationId, isPositive: isPositive },
                  fields: {
                    signedFile
                  },
                  onSuccess: (val) => {
                    dispatch(features.modal.actions.hideModal());
                    if (val !== false) {
                      navigate("/official-main");
                      toastr.success(
                        "Успіх",
                        isPositive
                          ? "Заяву на отримання сертифіката підтверджено"
                          : "Заяву на отримання сертифіката відхилено"
                      );
                    } else {
                      toastr.error("Помилка", "Потрібно перевірити всі дані");
                    }
                  },
                  onError: (e) => {
                    parseResErrors({
                      errorsRes: e,
                      onUnknownErrors: (errors) => {
                        dispatch(
                          features.modal.actions.showModal({
                            modalType: "ERROR",
                            modalProps: {
                              title: "Помилка",
                              description: <ResponseErrors errors={errors} />,
                              large: true,
                              onHide: () => {
                                goBack();
                              }
                            }
                          })
                        );
                      }
                    });
                  }
                }
              )
            );
          }
        }
      })
    );
  }, [dispatch, isPositive, currentSubject, applicationId, navigate, goBack]);

  useEffect(() => {
    if (applicationCertificate === null) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: `Створюється ${isPositive ? "єСертифікат" : "єВідмова"}`,
            loading: true
          }
        })
      );
      dispatch(
        features.officialApplicationReview.actions.fetchApplicationCertificateRequest(
          {
            params: { uuid: applicationId, isPositive: isPositive },
            onSuccess: () => {
              dispatch(
                features.modal.actions.hideModal({
                  modalType: "PRELOADER"
                })
              );
            },
            onError: () => {
              dispatch(
                features.modal.actions.hideModal({
                  modalType: "PRELOADER"
                })
              );
            }
          }
        )
      );
    }
  }, [dispatch, applicationId, applicationCertificate, isPositive]);

  useEffect(() => {
    if (fetchingApplicationCertificateError.unknown != null) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "GENERATE_E_DOCUMENT_ERROR_MODAL",
          modalProps: {
            percentages: application?.checkedPercent,
            onClose: goBack,
            error: fetchingApplicationCertificateError.unknown
          }
        })
      );
    }
  }, [
    application?.checkedPercent,
    dispatch,
    fetchingApplicationCertificateError.unknown,
    goBack
  ]);

  return (
    <div className={styles["content"]}>
      <PreloaderWrapper loading={fetchingApplicationCertificateLoading}>
        <PdfReader file={applicationCertificate} />
        {!fetchingApplicationCertificateLoading && (
          <div className={styles["controls"]}>
            <CommonButton
              label={"Назад до редагування"}
              outlined={true}
              onClick={goBack}
            ></CommonButton>
            <CommonButton
              label={"Підписати та відправити"}
              onClick={submit}
            ></CommonButton>
          </div>
        )}
      </PreloaderWrapper>
    </div>
  );
};

export default OfficialSignatureCertificatePage;
