import { takeLatest } from "redux-saga/effects";
import * as API from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchDeclined({ payload }) {
  const request = API.fetchOfficialDeclinedApplications;
  const { fetchDeclinedSuccess: setSuccess, fetchDeclinedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchEDeclined({ payload }) {
  const request = API.fetchDeclinedApplicationFile;
  const { fetchEDeclinedSuccess: setSuccess, fetchEDeclinedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* signEDeclined({ payload }) {
  const request = API.signDeclinedApplicationFile;
  const { signEDeclinedSuccess: setSuccess, signEDeclinedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDeclinedRequest.type, fetchDeclined);
  yield takeLatest(actions.fetchEDeclinedRequest.type, fetchEDeclined);
  yield takeLatest(actions.signEDeclinedRequest.type, signEDeclined);
}
