import { Form, FormikProvider, useFormik } from "formik";
import { Checkbox, CommonButton, Notification } from "gov-ua-ui";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import features from "features";
import {
  parseResErrors,
  productValidationSchemaReq,
  productsReqParser,
  tableRowReq
} from "helpers";

import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import EditableProductsTable from "components/certificateComponents/createCertificateComponents/DefineProducts/EditableProductsTable";
import FormControls from "components/railDocsComponents/FormControls/FormControls";

import styles from "./create.return.waste.module.scss";

interface CreateReturnWasteInterface {
  documentUuid: string;
  onAccept?: () => void;
  size: string;
}

const CreateReturnWaste = ({
  documentUuid,
  onAccept,
  size
}: CreateReturnWasteInterface) => {
  const dispatch = useDispatch();

  // const { sizeList } = useSelector<IRootState, IDatasetState>(
  //   (state) => state.dataset
  // );

  // const handleSelectChange = (value, name) => {
  //   formik.setFieldValue(name, value);
  // };

  interface FormValues {
    standard: {
      value: string;
      label: string;
    };
    size: {
      value: string;
    };
    products: any[];
    count: string;
    amount: string;
    amountPDV: string;
  }

  const formInitialValues: FormValues = {
    //TODO move to back-end
    standard: {
      value: "00a49331-e52b-49b5-ac89-b13ac660d26d",
      label: "UA"
    },
    size: {
      value: size
    },
    products: [tableRowReq],
    count: "",
    amount: "",
    amountPDV: ""
  };

  const handleFormSubmit = (values) => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Ви впевненні, що внесли повну інформацію по зворотним відходам? Дані буде збережено у єдиній базі документів і подальше редагування буде неможливе!",
          acceptLabel: "Все вірно, продовжити",
          onAccept: () => {
            const geometricDimensionUuid = values.size.value;
            const productStandardUuid = values.standard.value;

            const products = productsReqParser(values.products, {
              productStandardUuid,
              geometricDimensionUuid,
              isWaste: true
            });

            dispatch(
              features.productUsage.actions.createReturnWasteRequest({
                params: {
                  documentUuid
                },
                fields: {
                  products
                },
                onSuccess: () => {
                  setTimeout(() => {
                    onAccept();
                    dispatch(
                      features.modal.actions.showModal({
                        modalType: "CONFIRM_ACTION",
                        modalProps: {
                          title: "Успіх!",
                          details: (
                            <>
                              <Notification
                                text={"Дані збережено!"}
                                className={styles["green-notification"]}
                              />
                              <CommonButton
                                label={"ОК"}
                                onClick={() =>
                                  dispatch(features.modal.actions.hideModal())
                                }
                                className={styles["close-btn"]}
                              />
                            </>
                          ),
                          hideControls: true
                        }
                      })
                    );
                  }, 0);
                },
                onError: (e) =>
                  parseResErrors({
                    setFieldError: formik.setFieldError,
                    errorsRes: e,
                    fields: values
                  })
              })
            );
          }
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // size: Yup.object().shape({
      //   value: Yup.string().required("Виберіть геометричний розмір")
      // }),
      products: productValidationSchemaReq(Yup, false, false)
    }),
    onSubmit: handleFormSubmit
  });

  function onBack(): void {
    formik.setFieldValue(
      "isUseCreateReturnWaste",
      !formik.values["isUseCreateReturnWaste"]
    );
  }

  // useEffect(() => {
  //   if (sizeList && sizeList.length && !formik.values["size"].value) {
  //     formik.setFieldValue("size", sizeList[0]);
  //   }
  // }, [sizeList]);

  return (
    <div className={styles["return-waste"]}>
      <FormikProvider value={formik}>
        <Checkbox
          name={"isUseCreateReturnWaste"}
          title={
            "Використовуються зворотні відходи, отримані в результаті переробки"
          }
          checked={formik.values["isUseCreateReturnWaste"]}
          onClick={() => {
            formik.setFieldValue(
              "isUseCreateReturnWaste",
              !formik.values["isUseCreateReturnWaste"]
            );
          }}
          className={styles["return-waste__checkbox"]}
        />

        {formik.values["isUseCreateReturnWaste"] && (
          <Form className={styles["specify-usage__table-form"]}>
            {/* <Select
              name="size"
              value={formik.values["size"]}
              options={sizeList}
              placeholder="Геометричні розміри"
              onSelectChange={handleSelectChange}
              withFormik
              className={styles["return-waste__select"]}
            /> */}
            <EditableProductsTable
              formik={formik}
              tableRow={tableRowReq}
              enabledColumns={[
                ...Object.keys(tableRowReq),
                "accounting",
                "actions"
              ]}
              withPDV
              isForWaste
            />
            <FormControls
              onDecline={onBack}
              declineLabel="Скасувати"
              acceptButtonType={"submit"}
              acceptLabel="Зберегти"
            />
          </Form>
        )}
        <ScrollToFieldError />
      </FormikProvider>
    </div>
  );
};

export default CreateReturnWaste;
