import { createSlice } from "@reduxjs/toolkit";

interface IZNDoc {
  date: string;
  files: Array<any>;
  number: string;
  recipientOrganizationEdrpou: string;
  recipientOrganizationExternalId: string;
  recipientOrganizationName: string;
  series: string;
  sourceOrganizationEdrpou: string;
  sourceOrganizationExternalId: string;
  sourceOrganizationName: string;
  supportDocumentTypeName: string;
  supportDocumentTypeUuid: string;
  ttnAdditionalDocuments: Array<any>;
  typeName: string;
  uuid: string;
}

export interface IZNDocState {
  fetchingDocumentLoading: boolean;
  document: IZNDoc;
}

const initialState: IZNDocState = {
  fetchingDocumentLoading: null,
  document: null
};

export const mainSlice = createSlice({
  name: "ZNDoc",
  initialState,
  reducers: {
    fetchDocumentRequest: (state) => {
      state.fetchingDocumentLoading = true;
    },
    fetchDocumentSuccess: (state, action) => {
      state.fetchingDocumentLoading = false;
      state.document = action.payload;
    },
    fetchDocumentError: (state) => {
      state.fetchingDocumentLoading = false;
    },
    clearDocument: (state) => {
      state.document = initialState.document;
    }
  }
});

export const { actions, reducer } = mainSlice;
