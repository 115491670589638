import { takeLatest } from "redux-saga/effects";
import { actions } from "./ducks";
import * as API from "services";
import { requestMiddleware } from "helpers";

function* getUserSettings({ payload }) {
  const request = API.getUserData;
  const { getUserSettingsSuccess: setSuccess, getUserSettingsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* sendUserSettings({ payload }) {
  const request = API.updateUserData;
  const {
    sendUserSettingsSuccess: setSuccess,
    sendUserSettingsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.getUserSettingsRequest().type, getUserSettings);
  yield takeLatest(actions.sendUserSettingsRequest().type, sendUserSettings);
}
