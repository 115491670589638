import { call, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as API from "services";
import uploadFiles from "scenes/UploadFiles/saga";

import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchSearchDocs({ payload }) {
  const request = API.fetchBasisDocs;

  const { fetchSearchDocsSuccess: setSuccess, fetchSearchDocsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* createDoc({ payload }) {
  const request = API.createDocument;
  const { createDocSuccess: setSuccess, createDocError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchDoc({ payload }) {
  const request = API.fetchDocument;
  const { fetchDocSuccess: setSuccess, fetchDocError: setError } = actions;
  if (payload.params?.children?.length > 0) {
    let uuid;
    let isError = false;
    const uuids = [];
    yield all(
      payload.params.children.map((doc) =>
        requestMiddleware({
          ...payload,
          params: {
            uuid: doc?.id ?? doc
          },
          request,
          setError,
          setSuccess,
          onSuccess: (res) => {
            uuids.push(res.uuid);
            uuid = res;
          },
          onError: (res) => {
            isError = true;
            if (payload.onError) payload.onError(res);
          }
        })
      )
    );
    if (!isError) {
      if (payload.fields?.zn === false) payload.onSuccess(uuids);
      else if (uuid) {
        payload.onSuccess(uuid);
      }
    }
  } else {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError
    });
  }
}

function* updateDoc({ payload }) {
  const request = API.updateDocument;

  const { updateDocSuccess: setSuccess, updateDocError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* unionDoc({ payload }) {
  const request = API.unionDocument;

  const { unionDocSuccess: setSuccess, unionDocError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* removeUnusedZSNDocs({ payload }) {
  const request = API.removeUnusedZSN;

  const {
    removeUnusedZSNDocsSuccess: setSuccess,
    removeUnusedZSNDocsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* uploadDocFile({
  payload: {
    params: { applicationUuid, basisDocumentUuid, replaceUuid },
    fields
  }
}) {
  const {
    uploadDocFileProgress: progress,
    uploadDocFileSuccess: success,
    uploadDocFileError: error,
    saveUploadDocFileCancelHandler: cancel
  } = actions;

  yield call(
    uploadFiles,
    fields,
    { progress, success, error, cancel },
    `basis_documents/${basisDocumentUuid}/files/${replaceUuid ?? ""}`,
    basisDocumentUuid
  );
}

function* deleteDocFile({ payload }) {
  const request = API.deleteBasisDocumentFile;
  const { deleteDocFileSuccess: setSuccess, deleteDocFileError: setError } =
    actions;

  if (payload.params.fileUuid) {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      isReturnRequestPayload: true
    });
  }
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchSearchDocsRequest().type, fetchSearchDocs);
  yield takeLatest(actions.createDocRequest().type, createDoc);
  yield takeLatest(actions.fetchDocRequest().type, fetchDoc);
  yield takeLatest(actions.updateDocRequest().type, updateDoc);
  yield takeLatest(actions.unionDocRequest().type, unionDoc);
  yield takeLatest(
    actions.removeUnusedZSNDocsRequest().type,
    removeUnusedZSNDocs
  );
  yield takeEvery(actions.uploadDocFileRequest().type, uploadDocFile);
  yield takeEvery(actions.deleteDocFileRequest().type, deleteDocFile);
}
