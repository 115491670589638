import { Row } from "@tanstack/react-table";

interface IGetStartIndexOfRowByPage {
  currentPage?: number;
  itemsPerPage?: number;
}

export const getStartIndexOfRowByPage = ({
  currentPage,
  itemsPerPage
}: IGetStartIndexOfRowByPage): number => {
  if (currentPage && itemsPerPage) return (currentPage - 1) * itemsPerPage + 1;
  return null;
};

export const testDigitsAfterComma = (val, digits) => {
  const valueDigits = val.toString().split(".")?.[1]?.length;
  return valueDigits ?? 0 <= digits;
};

interface IGetSelectHandlerByFieldProps {
  data: Row<any>[];
  fieldNames: string[];
}

type TGetSelectHandlerByField = (props: IGetSelectHandlerByFieldProps) => {
  isAllAvailableRowsSelected: boolean;
  toggleAvailableRows: () => void;
};

export const getSelectHandlerByField: TGetSelectHandlerByField = ({
  data,
  fieldNames
}) => {
  let isAllAvailableRowsSelected = false;
  const disabledRows = [];
  const enabledRows = [];
  const availableFields = data.filter((field) => {
    return fieldNames.filter((fieldName) => {
      const isNumber = typeof field.original[fieldName];

      return (
        (isNumber && field.original[fieldName] > 0) ||
        (!isNumber && field.original[fieldName] !== null)
      );
    }).length;
  });

  data.forEach((row) => {
    const isDisabled = fieldNames.filter((fieldName) => {
      const isNumber = typeof row.original[fieldName];

      return (
        (isNumber && row.original[fieldName] <= 0) ||
        (!isNumber && row.original[fieldName] == null)
      );
    }).length;

    if (!isDisabled) {
      if (!row.getIsSelected()) {
        isAllAvailableRowsSelected = false;
        disabledRows.push(row);
      } else {
        enabledRows.push(row);
      }
    }
  });

  if (!disabledRows.length) isAllAvailableRowsSelected = false;
  if (
    enabledRows.length > 0 &&
    enabledRows.length ===
      (availableFields.length > 0 && availableFields.length)
  )
    isAllAvailableRowsSelected = true;

  const toggleAvailableRows = () =>
    !!disabledRows.length
      ? disabledRows.forEach((row) => row.toggleSelected())
      : enabledRows.forEach((row) => row.toggleSelected());

  return {
    isAllAvailableRowsSelected,
    toggleAvailableRows
  };
};
