import { CommonButton } from "gov-ua-ui";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams } from "react-router-dom";

import features from "features";
import { parseResErrors } from "helpers";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { IOfficialDeclinedApplicationsForSigningState } from "scenes/official/OfficialDeclinedApplicationsForSigningPage/ducks";

import PdfReader from "components/PdfReader/PdfReader";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ResponseErrors from "components/ResponseErrors/ResponseErrors";

import styles from "./official-signature-declined-certificate-page.module.scss";

const OfficialSignatureCertificatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );
  const {
    applicationDeclined,
    isLoadingFetchEDeclined,
    fetchingEDeclinedError,
    applicationDeclinedReason
  } = useSelector<IRootState, IOfficialDeclinedApplicationsForSigningState>(
    (state) => state.officialDeclinedApplicationsForSigning
  );

  const { applicationId } = useParams<{
    applicationId?: string;
  }>();

  const goBack = useCallback(() => {
    dispatch(
      features.officialDeclinedApplicationsForSigning.actions.clearEDecline()
    );
    navigate("/official-annul/declined/");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (applicationDeclinedReason.length === 0) goBack();
  }, [applicationDeclinedReason.length, goBack]);

  const submit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "SIGNATURE",
        modalProps: {
          modalTitle: "Затвердити відмову в анулюванні сертифіката",
          processedName: "Обробляв",
          processedBy: currentSubject.fullName,
          location: currentSubject.organizationName,
          fileUrl: applicationDeclined,
          onAccept: (signedFile) => {
            dispatch(features.modal.actions.hideModal());
            dispatch(
              features.modal.actions.showModal({
                modalType: "PRELOADER",
                modalProps: {
                  title: "Опрацювання запиту",
                  loading: true
                }
              })
            );
            dispatch(
              features.officialDeclinedApplicationsForSigning.actions.signEDeclinedRequest(
                {
                  params: { uuid: applicationId },
                  fields: {
                    declineDescription: applicationDeclinedReason,
                    signedFile
                  },
                  onSuccess: (val) => {
                    dispatch(features.modal.actions.hideModal());
                    if (val !== false) {
                      navigate("/official-main");
                      toastr.success(
                        "Успіх",
                        "Заяву на анулювання сертифіката, підписано"
                      );
                    } else {
                      toastr.error("Помилка", "Потрібно перевірити всі дані");
                    }
                  },
                  onError: (e) => {
                    parseResErrors({
                      errorsRes: e,
                      onUnknownErrors: (errors) => {
                        dispatch(
                          features.modal.actions.showModal({
                            modalType: "ERROR",
                            modalProps: {
                              title: "Помилка",
                              description: <ResponseErrors errors={errors} />,
                              large: true,
                              onHide: () => {
                                goBack();
                              }
                            }
                          })
                        );
                      }
                    });
                  }
                }
              )
            );
          }
        }
      })
    );
  }, [
    dispatch,
    currentSubject,
    applicationDeclined,
    applicationId,
    applicationDeclinedReason,
    navigate,
    goBack
  ]);

  useEffect(() => {
    if (fetchingEDeclinedError.unknown != null) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "GENERATE_E_DOCUMENT_ERROR_MODAL",
          modalProps: {
            onClose: goBack,
            error: fetchingEDeclinedError.unknown
          }
        })
      );
    }
  }, [dispatch, fetchingEDeclinedError.unknown, goBack]);

  return (
    <div className={styles["content"]}>
      <PreloaderWrapper loading={isLoadingFetchEDeclined}>
        <PdfReader file={applicationDeclined} />
        {!isLoadingFetchEDeclined && (
          <div className={styles["controls"]}>
            <CommonButton
              label={"Назад до редагування"}
              outlined={true}
              onClick={goBack}
            ></CommonButton>
            <CommonButton
              label={"Підписати та відправити"}
              onClick={submit}
            ></CommonButton>
          </div>
        )}
      </PreloaderWrapper>
    </div>
  );
};

export default OfficialSignatureCertificatePage;
