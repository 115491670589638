import { takeLatest } from "redux-saga/effects";
import { fetchDeclinedApplications } from "services";
import { requestMiddleware } from "helpers";
import { actions } from "./ducks";

function* fetchDeclined({ payload }) {
  const request = fetchDeclinedApplications;
  const { fetchDeclinedSuccess: setSuccess, fetchDeclinedError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchDeclinedRequest().type, fetchDeclined);
}
