import { CommonButton } from "gov-ua-ui";
import React from "react";

import Section from "components/Section/Section";

import styles from "./e-docs-section.module.scss";

interface EDocsSectionInterface {
  docs: Array<{
    docTitle: string;
    docAdditionalInfoTitle?: string;
    docAdditionalInfoValue?: string;
    docImg: string;
    onClick?: (e: React.MouseEvent) => void;
  }>;
}

const EDocsSection = ({ docs }: EDocsSectionInterface): JSX.Element => {
  return (
    <Section title="" className={styles["edocs-section"]}>
      {docs.map((docs, index) => {
        return (
          <Section
            title={docs.docTitle}
            className={styles["edocs-section__subsection"]}
            key={index}
          >
            <div className={styles["edocs-section__subsection-body"]}>
              <img src={docs.docImg} alt="єСертифікат" />
              <CommonButton
                outlined={true}
                label="Переглянути"
                onClick={docs.onClick}
              />
              {docs.docAdditionalInfoValue && (
                <div className={styles["body-footer"]}>
                  <div className={styles["body-footer__title"]}>
                    {docs.docAdditionalInfoTitle}
                  </div>
                  <div className={styles["body-footer__value"]}>
                    {docs.docAdditionalInfoValue}
                  </div>
                </div>
              )}
            </div>
          </Section>
        );
      })}
    </Section>
  );
};

export default EDocsSection;
