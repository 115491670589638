export const educationalMaterials = [
  {
    id: 0,
    title: "Отримання єСертифіката",
    description: "пункт-1",
    links: "https://www.youtube.com/embed/tgbNymZ7vqY"
  },
  {
    id: 1,
    title: "Заява на анулювання сертифікату походження деривини",
    description: "пункт-1"
  },
  {
    id: 2,
    title: "Тема процесу",
    description: "пункт-1"
  },
  {
    id: 3,
    title: "Заява на анулювання сертифікату походження деривини",
    description: "пункт-1"
  },
  {
    id: 4,
    title: "Додати перехідний документ",
    description: "пункт-1"
  },
  {
    id: 5,
    title: "Отримання єСертифіката",
    description: "пункт-1"
  }
];
