import ApiProvider from "./API";

export const createApplication = ({ fields }) => {
  return ApiProvider()
    .client.post(`/applications`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchApplication = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/applications/${params.uuid}`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchAnnulmentApplicationView = ({ params }) => {
  return ApiProvider()
    .client.get(`applications/${params?.uuid}/annulment`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchEAnnulmentData = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`applications/${params?.uuid}/annulment/submit`, {
      params: fields
    })
    .then((response) => response)
    .catch((error) => error.response);
};

//delete
const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const fetchEAnnulmentFileData = ({ params, fields }) => {
  return ApiProvider()
    .client.put(
      `applications/${params?.applicationUuid}/annulment/submit`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const annulmentSubmit = async ({ params, fields }) => {
  // For dev[START]
  let file;
  const signedFile = fields.signedFile;
  if (!signedFile) {
    const data = await fetchEAnnulmentFileData({ params, fields });
    file = await imageUrlToBase64(data.data.url);
  }
  // For dev[END]

  return ApiProvider()
    .client.post(`applications/${params.applicationUuid}/annulment/submit`, {
      ...fields,
      signedFile: file ?? signedFile
    })
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteAnnulmentRequestFile = ({ params }) => {
  return ApiProvider()
    .client.delete(
      `/applications/${params?.applicationUuid}/annulment/files/${params.uuid}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchDraftsApplications = ({ fields }) => {
  return ApiProvider()
    .client.get(`/applications/drafts`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchOrderedApplications = ({ fields }) => {
  return ApiProvider()
    .client.get(`/applications/ordered`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchReceivedApplications = ({ fields }) => {
  return ApiProvider()
    .client.get(`/applications/received`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchDeclinedApplications = ({ fields }) => {
  return ApiProvider()
    .client.get(`/applications/declined`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchAnnulledApplications = ({ fields }) => {
  return ApiProvider()
    .client.get(`/applications/annulled`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const setApplicationType = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`/applications/${params.uuid}/wood_cards`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const downloadBasicDocumentsExcel = ({ params }) => {
  return ApiProvider()
    .client.get(`/applications/${params.uuid}/usage_report`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteInvoiceFile = ({ params, fields }) => {
  return ApiProvider()
    .client.delete(
      `/applications/${params.applicationUuid}/files/${params.uuid}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const setInvoice = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`/applications/${params.uuid}/invoices`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const setTrustedDocs = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`/applications/${params.uuid}/trusted_documents`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchApplications = ({ params, fields }) => {
  return ApiProvider()
    .client.get(`/applications/search`, { params: fields })
    .then((response) => response)
    .catch((error) => error.response);
};

export const setProducts = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`/applications/${params.uuid}/products`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const deleteDraft = ({ params, fields }) => {
  return ApiProvider()
    .client.delete(`/applications/${params.uuid}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const copyDraft = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`/applications/${params.uuid}/copy`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const extendApplicationTimer = ({ params, fields }) => {
  return ApiProvider()
    .client.post(`/applications/${params.uuid}/extend_timer`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const joinDocument = ({ params, fields }) => {
  return ApiProvider()
    .client.post(
      `/applications/${params.applicationUuid}/basis_documents/${params.docId}`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const applyApplication = async ({ params, fields }) => {
  // For dev[START]
  let file;
  const signedFile = fields.signedFile;
  if (!signedFile) {
    const data = await fetchEApplicationFileData({ params });
    file = await imageUrlToBase64(data.data.url);
  }
  // For dev[END]

  return ApiProvider()
    .client.post(`/applications/${params.applicationUuid}/submit`, {
      ...fields,
      signedFile: file ?? signedFile
    })
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchEApplicationData = ({ params }) => {
  return ApiProvider()
    .client.get(`/applications/${params.uuid}/submit`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const fetchEApplicationFileData = ({ params }) => {
  return ApiProvider()
    .client.put(`/applications/${params.applicationUuid}/submit`)
    .then((response) => response)
    .catch((error) => error.response);
};
