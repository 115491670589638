import * as LayoutPage from "scenes/Layout/Layout";
import * as MainPage from "scenes/MainPage/MainPage";
import * as ErrorPage from "scenes/ErrorPage/ErrorPage";
import * as Modal from "scenes/Modal/Modal";
import * as Dataset from "scenes/Dataset/Dataset";
import * as AuthPage from "scenes/Auth";
import * as SettingsPage from "scenes/SettingsPage/SettingsPage";

// scenes for subjects
import * as ApplicationPage from "scenes/subject/applications/ApplicationPage/ApplicationPage";
import * as SignatureApplicationPage from "scenes/subject/applications/SignatureApplicationPage/SignatureApplicationPage";
import * as CertificateAnnulRequestPage from "scenes/subject/certificates/CertificateAnnulRequestPage/CertificateAnnulRequestPage";
import * as SignatureCertificateAnnulPage from "scenes/subject/certificates/SignatureCertificateAnnulPage/SignatureCertificateAnnulPage";
import * as AnnulledApplicationsPage from "scenes/subject/applications/AnnulledApplicationsPage/AnnulledApplications";
import * as DraftApplicationsPage from "scenes/subject/applications/DraftApplicationsPage/DraftApplicationsPage";
import * as OrderedApplicationsPage from "scenes/subject/applications/OrderedApplicationsPage/OrderedApplicationsPage";
import * as DeclinedApplicationsPage from "scenes/subject/applications/DeclinedApplicationsPage/DeclinedApplicationsPage";
import * as ReceivedApplicationsPage from "scenes/subject/applications/ReceivedApplicationsPage/ReceivedApplicationsPage";
import * as SearchApplicationsPage from "scenes/subject/applications/SearchApplicationsPage/SearchApplicationsPage";
import * as SearchBasisDocPage from "scenes/subject/documents/SearchBasisDocPage/SearchBasisDocPage";
import * as SubjectDocs from "scenes/subject/documents/";
import * as TTNDocPage from "scenes/subject/documents/TTNDocPage/TTNDocPage";
import * as TNDocPage from "scenes/subject/documents/TNDocPage/TNDocPage";
import * as MDDocPage from "scenes/subject/documents/MDDocPage/MDDocPage";
import * as ZNDocPage from "scenes/subject/documents/ZNDocPage/ZNDocPage";
import * as ProductUsagePage from "scenes/subject/documents/ProductUsage/ProductUsage";
import * as PrevioustUsagePage from "scenes/subject/documents/PreviousUsage/PreviousUsage";

import * as Subjects from "scenes/Subjects/Subjects";
import * as RegularQuestionsPage from "scenes/subject/helps/RegularQuestionsPage/RegularQuestionsPage";
import * as EducationalMaterialsPage from "scenes/subject/helps/EducationalMaterialsPage/EducationalMaterialsPage";
import * as LegislativeBasePage from "scenes/subject/helps/LegislativeBasePage/LegislativeBasePage";
import * as RequestToSystemPage from "scenes/subject/helps/RequestToSystemPage/RequestToSystemPage";
import * as RequestToSystemListPage from "scenes/subject/helps/RequestToSystemListPage/RequestToSystemListPage";
import * as SuccessDocumentCreationPage from "scenes/subject/certificates/SuccessDocumentCreationPage/SuccessDocumentCreationPage";

// scenes for an official
import * as OfficialDashboardPage from "scenes/official/OfficialDashboardPage/OfficialDashboardPage";
import * as OfficialAnnulledApplicationsForSigningPage from "scenes/official/OfficialAnnulledApplicationsForSigningPage/OfficialAnnulledApplicationsForSigning";
import * as OfficialDeclinedApplicationsForSigningPage from "scenes/official/OfficialDeclinedApplicationsForSigningPage/OfficialDeclinedApplicationsForSigning";
import * as OfficialArchivalPage from "scenes/official/OfficialArchivalPage/OfficialArchivalPage";
import * as OfficialInProgressPage from "scenes/official/OfficialInProgressPage/OfficialInProgressPage";
import * as OfficialApplicationReviewPage from "scenes/official/certificates/OfficialApplicationReviewPage/OfficialApplicationReviewPage";
import * as OfficialSignatureCertificatePage from "scenes/official/certificates/OfficialSignatureCertificatePage/OfficialSignatureCertificatePage";

import * as OfficialCertificateAnnulRequestPage from "scenes/official/certificates/OfficialCertificateAnnulRequestPage/OfficialCertificateAnnulRequestPage";
import * as OfficialSignatureCertificateAnnulPage from "scenes/official/certificates/OfficialSignatureCertificateAnnulPage/OfficialSignatureCertificateAnnulPage";
import * as OfficialSignatureCertificateDeclineAnnulPage from "scenes/official/certificates/OfficialSignatureCertificateDeclineAnnulPage/OfficialSignatureCertificateDeclineAnnulPage";
import * as OfficialSignatureDeclinedCertificatePage from "scenes/official/certificates/OfficialSignatureDeclinedCertificatePage/OfficialSignatureDeclinedCertificatePage";

const features = {};

const CALL_DISABLED_FEATURE = "equipenine/CALL_DISABLE_FEATURE";

const overrideValues = (object, newValue) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    newObject[key] = newValue;
  });
  return newObject;
};

const registerFeature = ({
  name,
  enabled,
  reducer,
  actions,
  types,
  selectors,
  pages,
  ...rest
}) => {
  if (!name) {
    throw new Error("Feature name is missing");
  }
  if (name in features) {
    throw new Error(`Feature with name ${name} already exists`);
  }

  if (enabled) {
    features[name] = {
      enabled,
      reducer,
      actions,
      types,
      selectors,
      pages,
      ...rest
    };
  } else {
    // Override feature stuff to not do everything if it is not enabled
    const feature = {
      enabled,
      // selectors always have to be public cause of failures in isPending
      selectors
    };
    if (reducer && typeof reducer === "function") {
      feature.reducer = () => ({});
    }
    if (actions && typeof actions === "object") {
      feature.actions = overrideValues(actions, () => {
        console.info(
          `Action can not be executed cause feature ${name} is disabled`
        );
        return {
          type: CALL_DISABLED_FEATURE
        };
      });
    }
    if (pages && typeof pages === "object") {
      feature.pages = overrideValues(pages, {});
    }
    features[name] = feature;
  }
};

registerFeature({
  name: "modal",
  enabled: true,
  ...Modal
});

registerFeature({
  name: "dataset",
  enabled: true,
  ...Dataset
});

registerFeature({
  name: "auth",
  enabled: true,
  ...AuthPage
});

registerFeature({
  name: "TTNDoc",
  enabled: true,
  ...TTNDocPage
});

registerFeature({
  name: "TNDoc",
  enabled: true,
  ...TNDocPage
});

registerFeature({
  name: "ZNDoc",
  enabled: true,
  ...ZNDocPage
});

registerFeature({
  name: "MDDoc",
  enabled: true,
  ...MDDocPage
});

registerFeature({
  name: "productUsage",
  enabled: true,
  ...ProductUsagePage
});

registerFeature({
  name: "previousUsage",
  enabled: true,
  ...PrevioustUsagePage
});

registerFeature({
  name: "searchBasisDoc",
  enabled: true,
  ...SearchBasisDocPage
});

registerFeature({
  name: "subjectDocs",
  enabled: true,
  ...SubjectDocs
});

registerFeature({
  name: "error",
  enabled: true,
  ...ErrorPage
});

registerFeature({
  name: "subjects",
  enabled: true,
  ...Subjects
});

registerFeature({
  name: "application",
  enabled: true,
  ...ApplicationPage
});

registerFeature({
  name: "certificateAnnulRequest",
  enabled: true,
  ...CertificateAnnulRequestPage
});

registerFeature({
  name: "signatureCertificateAnnul",
  enabled: true,
  ...SignatureCertificateAnnulPage
});

registerFeature({
  name: "signatureApplication",
  enabled: true,
  ...SignatureApplicationPage
});

registerFeature({
  name: "successDocumentCreation",
  enabled: true,
  ...SuccessDocumentCreationPage
});

registerFeature({
  name: "officialApplicationReview",
  enabled: true,
  ...OfficialApplicationReviewPage
});

registerFeature({
  name: "officialSignatureCertificate",
  enabled: true,
  ...OfficialSignatureCertificatePage
});

registerFeature({
  name: "officialCertificateAnnulRequest",
  enabled: true,
  ...OfficialCertificateAnnulRequestPage
});

registerFeature({
  name: "signatureOfficialCertificateAnnul",
  enabled: true,
  ...OfficialSignatureCertificateAnnulPage
});

registerFeature({
  name: "signatureOfficialCertificateDeclineAnnul",
  enabled: true,
  ...OfficialSignatureCertificateDeclineAnnulPage
});

registerFeature({
  name: "signatureOfficialCertificateDeclined",
  enabled: true,
  ...OfficialSignatureDeclinedCertificatePage
});

registerFeature({
  name: "searchApplications",
  enabled: true,
  ...SearchApplicationsPage
});

registerFeature({
  name: "annulledApplications",
  enabled: true,
  ...AnnulledApplicationsPage
});

registerFeature({
  name: "declinedApplications",
  enabled: true,
  ...DeclinedApplicationsPage
});

registerFeature({
  name: "orderedApplications",
  enabled: true,
  ...OrderedApplicationsPage
});

registerFeature({
  name: "receivedApplications",
  enabled: true,
  ...ReceivedApplicationsPage
});

registerFeature({
  name: "draftApplications",
  enabled: true,
  ...DraftApplicationsPage
});

registerFeature({
  name: "regularQuestions",
  enabled: true,
  ...RegularQuestionsPage
});

registerFeature({
  name: "educationalMaterials",
  enabled: true,
  ...EducationalMaterialsPage
});

registerFeature({
  name: "legislativeBase",
  enabled: true,
  ...LegislativeBasePage
});

registerFeature({
  name: "requestToSystemList",
  enabled: true,
  ...RequestToSystemListPage
});

registerFeature({
  name: "requestToSystem",
  enabled: true,
  ...RequestToSystemPage
});

registerFeature({
  name: "officialDashboard",
  enabled: true,
  ...OfficialDashboardPage
});

registerFeature({
  name: "officialProgressed",
  enabled: true,
  ...OfficialInProgressPage
});

registerFeature({
  name: "officialArchival",
  enabled: true,
  ...OfficialArchivalPage
});

registerFeature({
  name: "officialAnnulledApplicationsForSigning",
  enabled: true,
  ...OfficialAnnulledApplicationsForSigningPage
});

registerFeature({
  name: "officialDeclinedApplicationsForSigning",
  enabled: true,
  ...OfficialDeclinedApplicationsForSigningPage
});

registerFeature({
  name: "main",
  enabled: true,
  ...MainPage
});

registerFeature({
  name: "settings",
  enabled: true,
  ...SettingsPage
});

registerFeature({
  name: "layout",
  enabled: true,
  ...LayoutPage
});

export default features;
