import cn from "classnames";
import { useEffect, useRef } from "react";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

import eLisTitleIcon from "assets/images/icons/e_lis_title.svg";
import eLisTreeIcon from "assets/images/icons/e_lis_tree.svg";

import styles from "./navigation.module.scss";

export interface LinkInterface {
  to: string;
  text: string;
  anchor?: boolean;
  customContent?: any;
  customStyles?: string;
}

interface NavigationInterface {
  navLinks: Array<LinkInterface>;
  breadcrumbsLinks: Array<LinkInterface>;
  title: string;
  subtitle?: string | JSX.Element;
  titleImg?: any;
  titleClass?: string;
  subtitleClass?: string;
  noLogo?: boolean;
  isOfficial?: boolean;
  className?: string;
  getHeight: (height: number) => void;
  getOffsetTop: (height: number) => void;
}

const NavigationHead = ({
  breadcrumbsLinks,
  title,
  titleClass,
  subtitle,
  subtitleClass,
  titleImg,
  noLogo,
  isOfficial,
  className,
  getHeight,
  getOffsetTop
}: NavigationInterface): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      getOffsetTop(ref.current?.offsetTop);
      getHeight(ref.current?.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [getHeight, getOffsetTop]);

  return (
    <section
      className={cn(
        styles["navigation"],
        { [styles["navigation-official"]]: isOfficial },
        className
      )}
      ref={ref}
    >
      {!isOfficial && (
        <Breadcrumbs
          className={styles["navigation__breadcrumbs"]}
          elements={breadcrumbsLinks}
        />
      )}
      <div
        className={cn(styles["navigation-header"], {
          [styles["navigation-header_no-logo"]]: noLogo
        })}
      >
        <div
          className={cn(styles["navigation-header__image"], {
            [styles["navigation-header_no-img"]]: !titleImg
          })}
        >
          {titleImg}
        </div>
        <div className={styles["navigation-header__text-wrapper"]}>
          <h1 className={cn(styles["navigation-header__title"], titleClass)}>
            {title}
          </h1>
          {typeof subtitle === "string" ? (
            <h3
              className={cn(
                styles["navigation-header__subtitle"],
                subtitleClass
              )}
            >
              {subtitle}
            </h3>
          ) : (
            subtitle
          )}
        </div>
        {!noLogo && (
          <div className={styles["navigation-header__logo-wrapper"]}>
            <img src={eLisTitleIcon} alt="е-Ліс" />
            <img src={eLisTreeIcon} alt="у-Ліс" />
          </div>
        )}
      </div>
    </section>
  );
};

export default NavigationHead;
