import { useSelector } from "react-redux";

import { IRootState } from "reducer";
import { IModalState } from "scenes/Modal/ducks";

import AddRemarkModal from "./AddRemarkModal/AddRemarkModal";
import BasisDocViewModal from "./BasisDocViewModal/BasisDocViewModal";
import ConfirmActionModal from "./ConfirmActionModal/ConfirmActionModal";
import ErrorModal from "./ErrorModal/ErrorModal";
import GenerateEDocumentErrorModal from "./GenerateEDocumentErrorModal/GenerateEDocumentErrorModal";
import PdfViewModal from "./PdfViewModal/PdfViewModal";
import PictureViewModal from "./PictureViewModal/PictureViewModal";
import PreloaderModal from "./PreloaderModal/PreloaderModal";
import SignatureModal from "./SignatureModal/SignatureModal";
import SuccessfulUserSettingsUpdateModal from "./SuccessfulUserSettingsUpdateModal/SuccessfulUserSettingsUpdateModal";

const MODAL_COMPONENTS = {
  PRELOADER: PreloaderModal,
  GENERATE_E_DOCUMENT_ERROR_MODAL: GenerateEDocumentErrorModal,
  SIGNATURE: SignatureModal,
  PDF_VIEW: PdfViewModal,
  BASIS_DOC_VIEW: BasisDocViewModal,
  PICTURE_VIEW: PictureViewModal,
  CONFIRM_ACTION: ConfirmActionModal,
  ERROR: ErrorModal,
  ADD_REMARK: AddRemarkModal,
  SUCCESSFUL_USER_SETTINGS_UPDATE: SuccessfulUserSettingsUpdateModal
};

const ModalRoot = () => {
  const { modalType, modalProps } = useSelector<IRootState, IModalState>(
    (state) => state.modal
  );

  if (!modalType) {
    return <></>;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

export default ModalRoot;
