import { createSlice } from "@reduxjs/toolkit";
import { IeDoc, IPagination } from "interfaces";

export interface IAnnulledApplication {
  uuid: string;
  certificateId: string;
  certificateCreatedAt: number;
  orderedAt: number;
  buyer: string;
  consignee: string;
  invoiceNumber: string;
  invoiceAt: number;
  annulationStatus: string;
  eAnnulmentApplication: IeDoc;
  eAnnulation: IeDoc;
  eApplication: IeDoc;
}

export interface IAnnulledApplications {
  data: Array<IAnnulledApplication>;
  meta: IPagination;
}

export interface IAnnulledApplicationsState {
  fetchingApplicationsLoading: boolean | null;
  applications: IAnnulledApplications;
  responseError: any;
}

const initialState: IAnnulledApplicationsState = {
  fetchingApplicationsLoading: null,
  applications: null,
  responseError: {}
};

export const mainSlice = createSlice({
  name: "annulled",
  initialState,
  reducers: {
    fetchAnnulledRequest: (state) => {
      state.fetchingApplicationsLoading = true;
    },
    fetchAnnulledSuccess: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.applications = action.payload;
    },
    fetchAnnulledError: (state, action) => {
      state.fetchingApplicationsLoading = false;
      state.responseError = action.payload;
    },

    clearAnnulled: (state) => {
      state.applications = initialState.applications;
    }
  }
});

export const { actions, reducer } = mainSlice;
