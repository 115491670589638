import {
  BASIS_DOC_TYPE,
  BASIS_DOC_TYPE_NAME
} from "scenes/subject/documents/interfaces/interfaces";

const getPreviewFiles = (loadedFiles, additionalDocuments) => {
  const files = loadedFiles?.map((file) => {
    const additionalData = additionalDocuments?.find(
      (item) => item.typeName === file.type
    );
    return {
      ...file,
      isAdditionalData: !!additionalData,
      waybillType: additionalData?.waybillType?.toUpperCase()
    };
  });
  if (!files) return [];

  return files.map((file) => {
    let newName = file.name;

    switch (file.type) {
      case BASIS_DOC_TYPE.TTN_SCANCOPY:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;
      case BASIS_DOC_TYPE.TTN_CERTIFICATE:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;
      case BASIS_DOC_TYPE.TTN_WARRANT:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;

      case BASIS_DOC_TYPE.TN_SCANCOPY:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;

      case BASIS_DOC_TYPE.ZN_SCANCOPY:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;
      case BASIS_DOC_TYPE.ZSN_SCANCOPY:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;

      case BASIS_DOC_TYPE.MD_SCANCOPY:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;
      case BASIS_DOC_TYPE.MD_CMR_OR_ZN: {
        newName =
          `${file.waybillType ?? BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;
      }
      case BASIS_DOC_TYPE.MD_SPECIFICATION:
        newName = `${BASIS_DOC_TYPE_NAME[file.type]}, ` + newName;
        break;

      default:
        newName = "Скан-копія, " + newName;
        break;
    }

    return {
      ...file,
      name: newName
    };
  });
};

export default getPreviewFiles;
